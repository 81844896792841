import React from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import { AppRoute } from '../config/route-config';
import { UserProvider } from '../context/user/UserProvider';
import { PermissionsProvider } from '../context/permission/PermissionsProvider';
import { UserFetcher } from '../components/UserFetcher/UserFetcher';
// import { Placeholder2 } from '../components/PlaceHolder2/PlaceHolder2';
import { Drop } from '../racstrapComponent/dropComponent/drop';
import { Finaldrops } from '../racstrapComponent/finalDropComponent/finalDrop';
import { PastDropLog } from '../racstrapComponent/DropLogs/PastDroplog';
import { DrawerAudit } from '../racstrapComponent/drawerAudit/drawerAudit';
import { PaidIn } from '../racstrapComponent/CashPaid/PaidInComponent';
import { PaidOut } from '../racstrapComponent/CashPaid/PaidOutComponent';
import { DropReconciliation } from '../racstrapComponent/DropReconciliation/DropReconciliation';
import { DepositReceipt } from '../racstrapComponent/DropReconciliation/DepositReceipt';

import { DropReconciliationSummary } from '../racstrapComponent/DropReconciliation/DropReconciliationSummary';
import { DropReconciliationDetails } from '../racstrapComponent/DropReconciliation/DropReconciliationDetail';
export const routesTestId = 'routesTestId';

export const Routes = () => {
  // eslint-disable-next-line no-console
  console.log('inside cash mgmt routes', useHistory());

  return (
    <UserProvider>
      <PermissionsProvider>
        <UserFetcher />
        <div data-testid={routesTestId}>
          <Switch>
            <Route exact path={AppRoute.Index}>
              <Redirect to={AppRoute.Drops} />
            </Route>
            <Route exact path={AppRoute.Drops}>
              <Drop />
            </Route>
            <Route exact path={AppRoute.Finaldrops}>
              <Finaldrops />
            </Route>
            <Route exact path={AppRoute.paidIn}>
              <PaidIn />
            </Route>
            <Route exact path={AppRoute.paidOut}>
              <PaidOut />
            </Route>
            <Route exact path={AppRoute.PastDrop}>
              <PastDropLog />
            </Route>
            <Route exact path={AppRoute.CashDrop}>
              <PastDropLog />
            </Route>

            <Route exact path={AppRoute.CashDrop}>
              <PastDropLog />
            </Route>
            <Route exact path={AppRoute.DrawerAudit}>
              <DrawerAudit />
            </Route>
            <Route
              exact
              path="/cashmgmt/reconcil"
              component={DropReconciliation}
            />
            <Route
              exact
              path="/cashmgmt/reconcil/summary"
              component={DropReconciliationSummary}
            />
            <Route
              exact
              path="/cashmgmt/reconcil/detail"
              component={DropReconciliationDetails}
            />
            <Route
              exact
              path="/cashmgmt/reconcil/depositreceipt"
              component={DepositReceipt}
            />
            {/* <DrawerAudit /> */}
            {/* <Route exact path={AppRoute.Placeholder2}>
            <Placeholder2 />
          </Route> */}
          </Switch>
        </div>
      </PermissionsProvider>
    </UserProvider>
  );
};
