/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  sonarjs/cognitive-complexity */
import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Card,
  CardContent,
  RACTextbox,
  RACButton,
  RACTableCell,
  RACTableRow,
  RACTable,
  RACCheckBox,
  RACModalCard,
  CircularProgress,
} from '@rentacenter/racstrap';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import { customerStyles } from '../customerStyles';
import { ReactComponent as SuccessIcon } from '../../assets/images/success-icon.svg';
import { ReactComponent as Alerticon } from '../../assets/images/no-records-found.svg';
// import { NonCashBeingDropedDrawerAudit } from "./Non-CashDetails";
// import { CashDetails } from "./CashDetail";
// import { drawerAuditDrawerTotal } from "../constants/sampleConstants";
// import { nonCashBeingDropped } from "../constants/sampleConstants";
import { getDrawerDetail, manageDrawerAudit, getConfigDetails } from '../../api/user';
import SecondFactor from '../SecondAuthen/secondAuthen';
import moment from 'moment';
import { formatMoney } from "../../constants/constants";
import clsx from 'clsx';
export interface denominationQuantityInterface {
  denominationQuantity100: string;
  denominationQuantity50: string;
  denominationQuantity20: string;
  denominationQuantity10: string;
  denominationQuantity5: string;
  denominationQuantity2: string;
  denominationQuantity1: string;
  denominationQuantity001: string;
  denominationQuantity005: string;
  denominationQuantity010: string;
  denominationQuantity025: string;
  denominationQuantity050: string;
}

export function DrawerAudit() {
  const classes = customerStyles();
  const history = useHistory();
  const [drawerAuditDetails, setDrawerAuditDetails] = useState<any>();
  const [successPopup, setSuccessPopup] = useState(false);
  const [loader, setLoader] = useState(false);
  const [ismanageDrawerFailed, setIsmanageDrawerFailed] = useState(false);
  const [defaultStringAmount, setdefaultStringAmount] = useState('0');
  const [defaultLoader, setDefaultLoader] = useState(false);
  const [dropAmount, setDropAmount] = useState({
    cash: '0.00',
    nonCash: '0.00',
  });
  const [over_Short, setOver_Short] = useState<{
    cash: string;
    nonCash: string;
  }>({ cash: '0.00', nonCash: '0.00' });
  const [overShortNotes, setOverShortNotes] = useState({
    storeMsg: '',
    drawerAuditNotes: '',
  });
  const [pendingPettyCash, setPendingPettyCash] = useState({
    pendingPaidOutRequest: '0',
    pettyCash: '0',
  });
  const [secondLevelAuthenOn, setSecondLevelAuthenOn] = useState(false);
  const [denominationQuantity, setDenominationQuantity] =
    useState<denominationQuantityInterface>({
      denominationQuantity100: '0',
      denominationQuantity50: '0',
      denominationQuantity20: '0',
      denominationQuantity10: '0',
      denominationQuantity5: '0',
      denominationQuantity2: '0',
      denominationQuantity1: '0',
      denominationQuantity001: '0',
      denominationQuantity005: '0',
      denominationQuantity010: '0',
      denominationQuantity025: '0',
      denominationQuantity050: '0',
    });
  const [modals, setModals] = useState({
    confirmationCancelIsopen: false,
    confirmationSaveIsOpen: false,
    overShortExplanation: false,
  });
  useEffect(() => {
    pageLoad();
  }, []);
  const pageLoad = async () => {
    try {
      setLoader(true)
      setDefaultLoader(true)
      const storeNumber = window.sessionStorage.getItem('storeNumber');
      const DrawerDetails = await Promise.all(
        [getDrawerDetail({ storeNumber: storeNumber, businessDate: moment().format('YYYY-MM-DD'), }),

        (storeNumber && storeNumber != null && storeNumber != undefined && storeNumber != '' && storeNumber != 'null' && storeNumber!= 'undefined') ?
        getConfigDetails({
          storeNumbers: [sessionStorage.getItem('storeNumber')]
          , paramKeyNames: ['MaxDrawerLimit', 'ReconciliationMaxLimit','DrawerDefaultAmount'],
        }):Promise.resolve(),]);

      const drawerActivity = DrawerDetails[0]
      const defaultAmount = DrawerDetails[1]
      setLoader(false)
      setDefaultLoader(false)
      setdefaultStringAmount(defaultAmount?.storeProfileResponse?.configDetails[0].configDetails.filter((el: any) => el.paramKeyName == 'DrawerDefaultAmount')[0].paramValue)
      const nonCashResponse: any = [];
      drawerActivity?.storeTransaction?.nonCashDetails.forEach((el: any) => {
        nonCashResponse.push({ ...el, isChecked: false })
      });
      setDrawerAuditDetails({
        storeTransaction: drawerActivity?.storeTransaction,
        NonCashDetails: nonCashResponse,
      });
      //Need to change once the api call passes
      const totalCash: any =
        drawerActivity !== undefined &&
          drawerActivity.storeTransaction !== undefined &&
          drawerActivity.storeTransaction !== null
          ? (parseFloat(drawerActivity.storeTransaction.totalCash) + parseFloat(defaultAmount?.storeProfileResponse?.configDetails[0].configDetails.filter((el: any) => el.paramKeyName == 'DrawerDefaultAmount')[0].paramValue))
          : defaultAmount?.storeProfileResponse?.configDetails[0].configDetails.filter((el: any) => el.paramKeyName == 'DrawerDefaultAmount')[0].paramValue;
      const totalNonCash: any =
        drawerActivity !== undefined &&
          drawerActivity.storeTransaction !== undefined &&
          drawerActivity.storeTransaction !== null
          ? drawerActivity.storeTransaction.totalNonCash.toString()
          : '0';

      setOver_Short({ ...over_Short, cash: (parseFloat(totalCash) * -1).toFixed(2), nonCash: (parseFloat(totalNonCash) * -1).toFixed(2) });
    } catch (e) {
      console.log(e);
    }
  };
  const totalCash = () => {
    const totalCash = drawerAuditDetails?.storeTransaction?.totalCash ? parseFloat(drawerAuditDetails?.storeTransaction?.totalCash) : 0;

    return totalCash >= 0 ? '$' + ` ` + (totalCash + parseFloat(defaultStringAmount)).toFixed(2) : '($' + ` ` + ((totalCash + parseFloat(defaultStringAmount)) * - 1).toFixed(2) + ')';

    // () > 0 ? parseFloat(drawerAuditDetails.storeTransaction.totalCash)+ parseFloat(defaultStringAmount).toFixed(2) : '(' + parseFloat(drawerAuditDetails.storeTransaction.totalCash)+ parseFloat(defaultStringAmount).toFixed(2) +')')

  }
  const totalNonCash = () => {
    const totalNonCash = drawerAuditDetails?.storeTransaction?.totalNonCash ? parseFloat(drawerAuditDetails?.storeTransaction?.totalNonCash) : 0;
    return totalNonCash >= 0 ? '$' + ` ` + totalNonCash.toFixed(2) : '($' + ` ` + (totalNonCash * -1).toFixed(2) + ')';
  }
  function handleModels(key: string, value: boolean) {
    setModals({ ...modals, [key]: value });
  }

  const calculateTotalNonCash = () => {
    const totalAmount = parseFloat(
      drawerAuditDetails !== undefined &&
        drawerAuditDetails.storeTransaction !==
        undefined &&
        drawerAuditDetails.storeTransaction !== null &&
        drawerAuditDetails.storeTransaction
          .totalNonCash !== undefined &&
        drawerAuditDetails.storeTransaction
          .totalNonCash !== null
        ? drawerAuditDetails.storeTransaction.totalNonCash
        : '0'
    ) +
      parseFloat(
        drawerAuditDetails !== undefined &&
          drawerAuditDetails.storeTransaction !==
          undefined &&
          drawerAuditDetails.storeTransaction
            .totalCash !== undefined &&
          drawerAuditDetails.storeTransaction
            .totalCash !== null
          ? drawerAuditDetails.storeTransaction.totalCash
          : '0'
      );
       return totalAmount >= 0 ? '$' + ` ` + (totalAmount + parseFloat(defaultStringAmount)).toFixed(2) : '($' + ` ` + ((totalAmount - parseFloat(defaultStringAmount)) * -1).toFixed(2) + ')'
  }
  const UpdateInfoMsgPopup = () => {
    return (
      <Grid item md={12} className="px-2">
        <Grid
          item
          md={12}
          className="text-center"
          style={{ textAlign: 'center' }}
        >
          {ismanageDrawerFailed ? (
            <Alerticon style={{ textAlign: 'center' }}></Alerticon>
          ) : (
            <SuccessIcon style={{ textAlign: 'center' }}></SuccessIcon>
          )}
          <Typography
            variant={'body2'}
            style={{ marginTop: '1%', marginBottom: '1%' }}
          >
            {ismanageDrawerFailed
              ? 'Something went wrong'
              : // eslint-disable-next-line sonarjs/no-duplicate-string
              'Drawer Audit Successfully Completed'}
          </Typography>
        </Grid>
        <Grid
          container
          className="text-center mt-4"
          style={{ justifyContent: 'center', marginTop: '20px' }}
        >
          <RACButton
            className={`${classes.spacerMR2} ${classes.foc}`}
            size="small"
            color="primary"
            variant="contained"
            isRounded={false}
            style={{
              textTransform: 'none',
              // eslint-disable-next-line sonarjs/no-duplicate-string
              width: '100px !important',
              height: '35px',
            }}
            onClick={() => {
              history.push({
                pathname: `/dashboard/homepage`,
              })
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  // const hideupdatePopUp = () => {
  //   setSuccessPopup(false);
  // };
  const seconduthenResponses = async (e: Event) => {
    setDefaultLoader(false)
    setLoader(true);

    const denomination = [];
    setDefaultLoader(true)
    for (const [key, value] of Object.entries(denominationQuantity)) {
      if (value !== '0') {
        denomination.push({
          denomination:
            key == 'denominationQuantity100'
              ? '100'
              : key == 'denominationQuantity50'
                ? '50'
                : key == 'denominationQuantity20'
                  ? '20'
                  : key == 'denominationQuantity10'
                    ? '10'
                    : key == 'denominationQuantity5'
                      ? '5'
                      : key == 'denominationQuantity2'
                        ? '2'
                        : key == 'denominationQuantity1'
                          ? '1'
                          : key == 'denominationQuantity001'
                            ? '0.01'
                            : key == 'denominationQuantity005'
                              ? '0.05'
                              : key == 'denominationQuantity010'
                                ? '0.10'
                                : key == 'denominationQuantity025'
                                  ? '0.25'
                                  : key == 'denominationQuantity050'
                                    ? '0.50'
                                    : '0',

          quantity: Number(value),
        });
      }
    }

    const payload = {
      auditedNonCash:
        dropAmount.nonCash !== undefined ? dropAmount.nonCash : '0.00',
      auditedCash:
        dropAmount.cash !== undefined &&
          pendingPettyCash.pettyCash !== undefined
          ? (
            parseFloat(dropAmount.cash) +
            parseFloat(pendingPettyCash.pettyCash !== '' ? pendingPettyCash.pettyCash : '0')
          ).toFixed(2)
          : '0.00',
      pendingPaidOut:
        pendingPettyCash.pendingPaidOutRequest !== undefined
          ? pendingPettyCash.pendingPaidOutRequest
          : '0.00',
      overShortCash: (((parseFloat(over_Short.cash))) -
        (parseFloat(pendingPettyCash.pettyCash !== '' ? pendingPettyCash.pettyCash : '0') * -1 + parseFloat(pendingPettyCash.pendingPaidOutRequest !== '' ? pendingPettyCash.pendingPaidOutRequest : '0') * -1)).toString(),
      overShortNonCash: parseFloat(over_Short.nonCash).toFixed(2),
      auditNotes:
        overShortNotes.drawerAuditNotes !== undefined
          ? overShortNotes.drawerAuditNotes
          : '',
      storeMessage:
        overShortNotes.storeMsg !== undefined ? overShortNotes.storeMsg : '',
      storeNumber: window.sessionStorage.getItem('storeNumber'),
      denominationDetails: denomination,
    };
    const drawerAuditResponse = await manageDrawerAudit(payload);
    if (
      drawerAuditResponse !== undefined &&
      drawerAuditResponse !== '' &&
      drawerAuditResponse !== null &&
      drawerAuditResponse.drawerAuditId !== undefined
    ) {
      setIsmanageDrawerFailed(false)
    }
    setSuccessPopup(true);
    setLoader(false);
    setDefaultLoader(false)
    setDefaultLoader(false)
  };
  const truncateString = (str: string): string => {
    if (str?.length > 12 && str!==undefined && str!==null && str!=='') {
      return (str?.substring(0, 10) + '...').toString();
    }
    return str;
  };
  const handleNonCashTableContent = () => {
    return drawerAuditDetails !== undefined &&
      drawerAuditDetails.NonCashDetails !== undefined &&
      drawerAuditDetails.NonCashDetails !== null &&
      drawerAuditDetails.NonCashDetails.length > 0
      ? renderNonCashTableContent()
      : renderNonCashNoDatasFound();
  };
  const renderNonCashNoDatasFound = () => {
    return (
      <>
        <Modal
          isOpen={loader}
          className={classes.loaderStyle}
          id="openpopup"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          aria-hidden="true"
        >
          <Grid>
            <Grid>
              <Grid className={classes.RACLoaderPage}>
                <CircularProgress />
              </Grid>
            </Grid>
          </Grid>
        </Modal>
        <RACTableRow hover backgroundColor="white">
          <RACTableCell></RACTableCell>
          <RACTableCell></RACTableCell>
          <RACTableCell></RACTableCell>
          <RACTableCell>
            <Typography
              style={{ marginTop: '65%', marginBottom: '70%' }}
              variant="h4"
            >
              No Record
            </Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography
              style={{
                marginTop: '65%',
                marginBottom: '70%',
                marginLeft: '-32%',
              }}
              variant="h4"
            >
              Found
            </Typography>
          </RACTableCell>
          {/* <RACTableCell></RACTableCell> */}
          <RACTableCell></RACTableCell>
          <RACTableCell></RACTableCell>
          <RACTableCell></RACTableCell>
          <RACTableCell></RACTableCell>
        </RACTableRow>
      </>
    );
  };
  function handleCancelBtn() {
    handleModels('confirmationCancelIsopen', true);
  }
  function handleCancelNoBtn() {
    handleModels('confirmationCancelIsopen', false);
  }
  function handleSaveBtn() {
    setDefaultLoader(true);
    if (
      over_Short !== undefined && overShortNotes.drawerAuditNotes.trim() == '' &&
      (parseFloat(over_Short.nonCash) > 0.0 ||
        (((parseFloat(over_Short.cash))) -
          (parseFloat(pendingPettyCash.pettyCash !== '' ? pendingPettyCash.pettyCash : '0') * -1 + parseFloat(pendingPettyCash.pendingPaidOutRequest !== '' ? pendingPettyCash.pendingPaidOutRequest : '0') * -1)) != 0.0)) {
      setModals({
        ...modals,
        overShortExplanation: true,
      });
    } else {
      handleModels('confirmationSaveIsOpen', true);
    }
    setDefaultLoader(false);
  }
  function handleSaveNoBtn() {
    handleModels('confirmationSaveIsOpen', false);
  }
  function handleOverShortOkBtn() {
    handleModels('overShortExplanation', false);
  }
  function handleYes_SaveBtn() {
    setDefaultLoader(true)
    setSecondLevelAuthenOn(true);
    handleModels('confirmationSaveIsOpen', false);
  }
  function OverShortExplanationModelCard() {
    return (
      <RACModalCard
        isOpen={modals.overShortExplanation}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => handleModels('overShortExplanation', false)}
        // eslint-disable-next-line react/no-children-prop
        children={AuditNotesPopupIsOpen()}
      // title="Validation Message"
      />
    );
  }
  function ConfirmationSavePopupModelCard() {
    return (
      <RACModalCard
        isOpen={modals.confirmationSaveIsOpen}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => handleModels('confirmationSaveIsOpen', false)}
        // eslint-disable-next-line react/no-children-prop
        children={ConfirmationSavePopupIsOpen()}
      // title="Validation Message"
      />
    );
  }
  const ConfirmationSavePopupIsOpen = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <Typography className={`${classes.RACPOPMsg} ${classes.marginTop}`}>
            Are you sure want to save?
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
        >
          <RACButton
            className={`${classes.spacerMR2} ${classes.foc}`}
            size="small"
            variant="outlined"
            color="primary"
            isRounded={false}
            onClick={() => handleSaveNoBtn()}
          // onClick={() => handleModels('validateNonCashIsOpen',false)}
          >
            No
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => handleYes_SaveBtn()}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const AuditNotesPopupIsOpen = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <Typography className={`${classes.RACPOPMsg} ${classes.marginTop}`}>
            Audit Notes are required if over/short.
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
        >
          <RACButton
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => handleOverShortOkBtn()}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  function ConfirmationCancelModelCard() {
    return (
      <RACModalCard
        isOpen={modals.confirmationCancelIsopen}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => handleModels('confirmationCancelIsopen', false)}
        // eslint-disable-next-line react/no-children-prop
        children={ConfirmationCancelPopupIsOpen()}
      // title="Validation Message"
      />
    );
  }
  const ConfirmationCancelPopupIsOpen = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <Typography className={`${classes.RACPOPMsg} ${classes.marginTop}`}>
            Your changes will not be saved.
            <Grid>Are you sure you want to exit?</Grid>
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
        >
          <RACButton
            className={`${classes.spacerMR2} ${classes.foc}`}
            size="small"
            variant="outlined"
            color="primary"
            isRounded={false}
            onClick={() => handleCancelNoBtn()}
          // onClick={() => handleModels('validateNonCashIsOpen',false)}
          >
            No
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => {
              history.push({
                pathname: `/dashboard/homepage`,
              });
            }}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const calculateNonCash = () => {
    const nonCash = over_Short !== undefined ? parseFloat(over_Short.nonCash) : 0;
    return nonCash >= 0 ? '$' + ` ` + nonCash.toFixed(2) : '($' + ` ` + (nonCash * -1).toFixed(2) + ')'

  }
  const handlePendingPaidOutRequest = (e: any) => {
    setPendingPettyCash({
      ...pendingPettyCash,
      pendingPaidOutRequest: e.target.value,
    });
  };
  const handlePettyCash = (e: any) => {
    setPendingPettyCash({ ...pendingPettyCash, pettyCash: e.target.value });
  };
  const handleStoreMsg = (e: any) => {
    setOverShortNotes({ ...overShortNotes, storeMsg: e.target.value });
  };
  const handleDrawerAuditNotes = (e: any) => {
    setOverShortNotes({ ...overShortNotes, drawerAuditNotes: e.target.value });
  };
  const handleNonCashDetail = (e: any, recID: any) => {
    // debugger;
    let nonCashbeingdrop = parseFloat(dropAmount.nonCash);
    if (drawerAuditDetails !== undefined) {
      // eslint-disable-next-line sonarjs/no-all-duplicated-branches
      if (drawerAuditDetails !== undefined ? e.target.checked : '') {
        for (
          let nonCashDetails = 0;
          nonCashDetails < drawerAuditDetails.NonCashDetails.length;
          nonCashDetails++
        ) {
          if (
            drawerAuditDetails !== undefined
              ? drawerAuditDetails.NonCashDetails[nonCashDetails].receiptTransactionId ==
              recID
              : ''
          ) {
            nonCashbeingdrop += parseFloat(
              drawerAuditDetails.NonCashDetails[nonCashDetails].cashAmount
            );
            break;
          }
        }
      } else {
        for (
          let nonCashDetails = 0;
          nonCashDetails < drawerAuditDetails.NonCashDetails.length;
          nonCashDetails++
        ) {
          if (
            drawerAuditDetails !== undefined
              ? drawerAuditDetails.NonCashDetails[nonCashDetails].receiptTransactionId ==
              recID
              : ''
          ) {
            nonCashbeingdrop -= parseFloat(
              drawerAuditDetails.NonCashDetails[nonCashDetails].cashAmount
            );
            break;
          }
        }
      }
    }
    const nonCashArr =
      drawerAuditDetails &&
      drawerAuditDetails.NonCashDetails.map((el: any) => {
        if (!el.isChecked && e.target.checked && el.receiptTransactionId == recID) {
          el.isChecked = true;
        } else if (el.isChecked && !e.target.checked && el.receiptTransactionId == recID) {
          el.isChecked = false;
        }
        return el;
      });
    setDrawerAuditDetails({
      ...drawerAuditDetails,
      NonCashDetails: nonCashArr,
    });
    setDropAmount({ ...dropAmount, nonCash: nonCashbeingdrop.toString() });
    // let nonCashShortAmount = 0
    let nonCashShortAmount: any =
      drawerAuditDetails !== undefined &&
        drawerAuditDetails.storeTransaction !== undefined &&
        drawerAuditDetails.storeTransaction.totalNonCash !== undefined &&
        drawerAuditDetails.storeTransaction.totalNonCash !== null
        ? drawerAuditDetails.storeTransaction.totalNonCash
        : '';
    for (
      let nonCashDetails = 0;
      nonCashDetails < drawerAuditDetails.NonCashDetails.length;
      nonCashDetails++
    ) {
      if (
        drawerAuditDetails !== undefined
          ? drawerAuditDetails.NonCashDetails[nonCashDetails].isChecked
          : ''
      ) {
        nonCashShortAmount -= parseFloat(
          drawerAuditDetails.NonCashDetails[nonCashDetails].cashAmount
        );
      }
    }
    setOver_Short({ ...over_Short, nonCash: ((nonCashShortAmount * -1).toFixed(2)) });
  };

  const calculateTotalOverShortAmount = () => {

    const overshortNonCash = over_Short !== undefined ? parseFloat(over_Short.nonCash) : 0;
    const overshortCash = ((parseFloat(over_Short.cash)) -
      ((parseFloat(pendingPettyCash.pettyCash !== '' ? pendingPettyCash.pettyCash : '0') * -1) + parseFloat(pendingPettyCash.pendingPaidOutRequest !== '' ? pendingPettyCash.pendingPaidOutRequest : '0') * -1));

    const totalAmount = overshortNonCash + overshortCash;

    return totalAmount >= 0 ? '$' + ` ` + totalAmount.toFixed(2) : '($' + ` ` + (totalAmount * -1).toFixed(2) + ')';
  }
  const handleNonEmptyValue = (e: any) => {
    switch (e.target.id) {
      case 'petty':
        if (e.target.value == '' || Number(e.target.value) == 0) {
          setPendingPettyCash({ ...pendingPettyCash, pettyCash: '0' })
        }
        break;
      case 'pending':
        if (e.target.value == '' || Number(e.target.value) == 0) {
          setPendingPettyCash({ ...pendingPettyCash, pendingPaidOutRequest: '0' })
        }
        break;
      case '$100':
        if (e.target.value == '' || Number(e.target.value) == 0) {
          setDenominationQuantity({ ...denominationQuantity, denominationQuantity100: '0' })
        }
        break;
      case '$50':
        if (e.target.value == '' || Number(e.target.value) == 0) {
          setDenominationQuantity({ ...denominationQuantity, denominationQuantity50: '0' })
        }
        break;
      case '$20':
        if (e.target.value == '' || Number(e.target.value) == 0) {
          setDenominationQuantity({ ...denominationQuantity, denominationQuantity20: '0' })
        }
        break;
      case '$10':
        if (e.target.value == '' || Number(e.target.value) == 0) {
          setDenominationQuantity({ ...denominationQuantity, denominationQuantity10: '0' })
        }
        break;
      case '$5':
        if (e.target.value == '' || Number(e.target.value) == 0) {
          setDenominationQuantity({ ...denominationQuantity, denominationQuantity5: '0' })
        }
        break;
      case '$2':
        if (e.target.value == '' || Number(e.target.value) == 0) {
          setDenominationQuantity({ ...denominationQuantity, denominationQuantity2: '0' })
        }
        break;
      case '$1':
        if (e.target.value == '' || Number(e.target.value) == 0) {
          setDenominationQuantity({ ...denominationQuantity, denominationQuantity1: '0' })
        }
        break;
      default: setPendingPettyCash({ ...pendingPettyCash, pettyCash: (e.target.value).toFixed(2), pendingPaidOutRequest: (e.target.value).toFixed(2) })

    }

  }
  const handleDrawerAuditBtn = (e: any) => {
    switch (e.currentTarget.id) {
      case '001$':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmount(
            '0.01',
            (
              Number(denominationQuantity.denominationQuantity001) + 1
            ).toString()
          ),
        });
        setDenominationQuantity({
          ...denominationQuantity,
          denominationQuantity001: (
            Number(denominationQuantity.denominationQuantity001) + 1
          ).toString(),
        });
        break;
      case '005$':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmount(
            '0.05',
            (
              Number(denominationQuantity.denominationQuantity005) + 1
            ).toString()
          ),
        });
        setDenominationQuantity({
          ...denominationQuantity,
          denominationQuantity005: (
            Number(denominationQuantity.denominationQuantity005) + 1
          ).toString(),
        });
        break;
      case '010$':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmount(
            '0.10',
            (
              Number(denominationQuantity.denominationQuantity010) + 1
            ).toString()
          ),
        });
        setDenominationQuantity({
          ...denominationQuantity,
          denominationQuantity010: (
            Number(denominationQuantity.denominationQuantity010) + 1
          ).toString(),
        });
        break;
      case '025$':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmount(
            '0.25',
            (
              Number(denominationQuantity.denominationQuantity025) + 1
            ).toString()
          ),
        });
        setDenominationQuantity({
          ...denominationQuantity,
          denominationQuantity025: (
            Number(denominationQuantity.denominationQuantity025) + 1
          ).toString(),
        });
        break;
      case '050$':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmount(
            '0.50',
            (
              Number(denominationQuantity.denominationQuantity050) + 1
            ).toString()
          ),
        });
        setDenominationQuantity({
          ...denominationQuantity,
          denominationQuantity050: (
            Number(denominationQuantity.denominationQuantity050) + 1
          ).toString(),
        });
        break;
      case '100$':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmount(
            '100',
            (
              Number(denominationQuantity.denominationQuantity100) + 1
            ).toString()
          ),
        });
        setDenominationQuantity({
          ...denominationQuantity,
          denominationQuantity100: (
            Number(denominationQuantity.denominationQuantity100) + 1
          ).toString(),
        });
        break;
      case '50$':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmount(
            '50',
            (Number(denominationQuantity.denominationQuantity50) + 1).toString()
          ),
        });
        setDenominationQuantity({
          ...denominationQuantity,
          denominationQuantity50: (
            Number(denominationQuantity.denominationQuantity50) + 1
          ).toString(),
        });
        break;
      case '20$':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmount(
            '20',
            (Number(denominationQuantity.denominationQuantity20) + 1).toString()
          ),
        });
        setDenominationQuantity({
          ...denominationQuantity,
          denominationQuantity20: (
            Number(denominationQuantity.denominationQuantity20) + 1
          ).toString(),
        });
        break;
      case '10$':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmount(
            '10',
            (Number(denominationQuantity.denominationQuantity10) + 1).toString()
          ),
        });
        setDenominationQuantity({
          ...denominationQuantity,
          denominationQuantity10: (
            Number(denominationQuantity.denominationQuantity10) + 1
          ).toString(),
        });
        break;
      case '5$':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmount(
            '5',
            (Number(denominationQuantity.denominationQuantity5) + 1).toString()
          ),
        });
        setDenominationQuantity({
          ...denominationQuantity,
          denominationQuantity5: (
            Number(denominationQuantity.denominationQuantity5) + 1
          ).toString(),
        });
        break;
      case '2$':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmount(
            '2',
            (Number(denominationQuantity.denominationQuantity2) + 1).toString()
          ),
        });
        setDenominationQuantity({
          ...denominationQuantity,
          denominationQuantity2: (
            Number(denominationQuantity.denominationQuantity2) + 1
          ).toString(),
        });
        break;
      case '1$':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmount(
            '1',
            (Number(denominationQuantity.denominationQuantity1) + 1).toString()
          ),
        });
        setDenominationQuantity({
          ...denominationQuantity,
          denominationQuantity1: (
            Number(denominationQuantity.denominationQuantity1) + 1
          ).toString(),
        });
        break;
    }
  };
  const calculateTotalAmount = (denomination: string, value: string) => {
    const cashAmount =
      parseFloat(
        denomination == '0.01'
          ? value
          : denominationQuantity.denominationQuantity001 !== ''
            ? denominationQuantity.denominationQuantity001
            : '0'
      ) *
      0.01 +
      parseFloat(
        denomination == '0.05'
          ? value
          : denominationQuantity.denominationQuantity005 !== ''
            ? denominationQuantity.denominationQuantity005
            : '0'
      ) *
      0.05 +
      parseFloat(
        denomination == '0.10'
          ? value
          : denominationQuantity.denominationQuantity010 !== ''
            ? denominationQuantity.denominationQuantity010
            : '0'
      ) *
      0.1 +
      parseFloat(
        denomination == '0.25'
          ? value
          : denominationQuantity.denominationQuantity025 !== ''
            ? denominationQuantity.denominationQuantity025
            : '0'
      ) *
      0.25 +
      parseFloat(
        denomination == '0.50'
          ? value
          : denominationQuantity.denominationQuantity050 !== ''
            ? denominationQuantity.denominationQuantity050
            : '0'
      ) *
      0.5 +
      parseFloat(
        denomination == '1'
          ? value
          : denominationQuantity.denominationQuantity1 !== ''
            ? denominationQuantity.denominationQuantity1
            : '0'
      ) *
      1 +
      parseFloat(
        denomination == '10'
          ? value
          : denominationQuantity.denominationQuantity10 !== ''
            ? denominationQuantity.denominationQuantity10
            : '0'
      ) *
      10 +
      parseFloat(
        denomination == '100'
          ? value
          : denominationQuantity.denominationQuantity100 !== ''
            ? denominationQuantity.denominationQuantity100
            : '0'
      ) *
      100 +
      parseFloat(
        denomination == '2'
          ? value
          : denominationQuantity.denominationQuantity2 !== ''
            ? denominationQuantity.denominationQuantity2
            : '0'
      ) *
      2 +
      parseFloat(
        denomination == '20'
          ? value
          : denominationQuantity.denominationQuantity20 !== ''
            ? denominationQuantity.denominationQuantity20
            : '0'
      ) *
      20 +
      parseFloat(
        denomination == '5'
          ? value
          : denominationQuantity.denominationQuantity5 !== ''
            ? denominationQuantity.denominationQuantity5
            : '0'
      ) *
      5 +
      parseFloat(
        denomination == '50'
          ? value
          : denominationQuantity.denominationQuantity50 !== ''
            ? denominationQuantity.denominationQuantity50
            : '0'
      ) *
      50;
    let totalCash = drawerAuditDetails?.storeTransaction?.totalCash ? parseFloat(drawerAuditDetails?.storeTransaction?.totalCash) : 0;
    totalCash = totalCash + parseFloat(defaultStringAmount)
    const overshortCash = ((totalCash * -1) + cashAmount);
    setOver_Short({ ...over_Short, cash: (overshortCash).toFixed(2) });
    return cashAmount.toFixed(2);
  };
  const handleDrawerAuditInp = (e: any) => {
    switch (e.target.name) {
      case '$001':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmount(
            '0.01',
            e.target.value !== '' ? e.target.value : '0'
          ),
        });
        setDenominationQuantity({
          ...denominationQuantity,
          denominationQuantity001: e.target.value,
        });
        break;
      case '$005':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmount(
            '0.05',
            e.target.value !== '' ? e.target.value : '0'
          ),
        });
        setDenominationQuantity({
          ...denominationQuantity,
          denominationQuantity005: e.target.value,
        });
        break;
      case '$010':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmount(
            '0.10',
            e.target.value !== '' ? e.target.value : '0'
          ),
        });
        setDenominationQuantity({
          ...denominationQuantity,
          denominationQuantity010: e.target.value,
        });
        break;
      case '$025':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmount(
            '0.25',
            e.target.value !== '' ? e.target.value : '0'
          ),
        });
        setDenominationQuantity({
          ...denominationQuantity,
          denominationQuantity025: e.target.value,
        });
        break;
      case '$050':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmount(
            '0.50',
            e.target.value !== '' ? e.target.value : '0'
          ),
        });
        setDenominationQuantity({
          ...denominationQuantity,
          denominationQuantity050: e.target.value,
        });
        break;
      case '$100':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmount(
            '100',
            e.target.value !== '' ? e.target.value : '0'
          ),
        });
        setDenominationQuantity({
          ...denominationQuantity,
          denominationQuantity100: e.target.value,
        });
        break;
      case '$50':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmount(
            '50',
            e.target.value !== '' ? e.target.value : '0'
          ),
        });
        setDenominationQuantity({
          ...denominationQuantity,
          denominationQuantity50: e.target.value,
        });
        break;
      case '$20':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmount(
            '20',
            e.target.value !== '' ? e.target.value : '0'
          ),
        });
        setDenominationQuantity({
          ...denominationQuantity,
          denominationQuantity20: e.target.value,
        });
        break;
      case '$10':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmount(
            '10',
            e.target.value !== '' ? e.target.value : '0'
          ),
        });
        setDenominationQuantity({
          ...denominationQuantity,
          denominationQuantity10: e.target.value,
        });
        break;
      case '$5':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmount(
            '5',
            e.target.value !== '' ? e.target.value : '0'
          ),
        });
        setDenominationQuantity({
          ...denominationQuantity,
          denominationQuantity5: e.target.value,
        });
        break;
      case '$2':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmount(
            '2',
            e.target.value !== '' ? e.target.value : '0'
          ),
        });
        setDenominationQuantity({
          ...denominationQuantity,
          denominationQuantity2: e.target.value,
        });
        break;
      case '$1':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmount(
            '1',
            e.target.value !== '' ? e.target.value : '0'
          ),
        });
        setDenominationQuantity({
          ...denominationQuantity,
          denominationQuantity1: e.target.value,
        });
        break;
    }
  };

  const calculateShortCash = () => {
    const overshortCash = (((parseFloat(over_Short.cash))) -
      (parseFloat(pendingPettyCash.pettyCash !== '' ? pendingPettyCash.pettyCash : '0') * -1 + parseFloat(pendingPettyCash.pendingPaidOutRequest !== '' ? pendingPettyCash.pendingPaidOutRequest : '0') * -1));
    return overshortCash >= 0 ? '$' + ` ` + overshortCash.toFixed(2) : '($' + ` ` + (overshortCash * -1).toFixed(2) + ')'
  }
  const renderCashTableHead = () => {
    return (
      <React.Fragment>
        <RACTableCell className={`${classes.denomination}`}>
          <Typography variant="h5">Denomination</Typography>
        </RACTableCell>
        <RACTableCell className={`${classes.tableCell}`}>
          <Grid style={{ paddingLeft: '15%' }}>
            <Typography variant="h5">Total Quantity</Typography>
          </Grid>
        </RACTableCell>
        <RACTableCell className={`${classes.totalWidth}`}>
          <Grid style={{ paddingRight: '35px', textAlign: 'right' }}>
            <Typography variant="h5">Total</Typography>
          </Grid>
        </RACTableCell>
      </React.Fragment>
    );
  };
  const renderCashTableContent = () => {
    return (
      <>
        <React.Fragment>
          {/* $0.01 */}
          <RACTableRow
            style={{ height: '4rem' }}
            className={` ${classes.tableRow}`}
          >
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACButton
                onClick={(e: any) => handleDrawerAuditBtn(e)}
                name="001$"
                id="001$"
                className={`${classes.RACbutton} ${classes.denominationwidth}`}
              >
                $ 0.01
              </RACButton>
            </RACTableCell>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACTextbox
                className={`${classes.quantitywid} ${classes.tableroW}`}
                value={denominationQuantity.denominationQuantity001.toString()}
                maxlength={9}
                type="number"
                name="$001"
                id="$001"
                Onblur={(e: any) => handleNonEmptyValue(e)}
                OnChange={(e: any) => handleDrawerAuditInp(e)}
              />
            </RACTableCell>
            <RACTableCell
              style={{ color: '#4A5174' }}
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <Typography style={{ paddingRight: '35px', textAlign: 'right' }}>
                ${' '}
                {formatMoney((
                  parseFloat(denominationQuantity.denominationQuantity001 !== '' ? denominationQuantity.denominationQuantity001 : '0') *
                  0.01
                ).toFixed(2))}
              </Typography>
            </RACTableCell>
          </RACTableRow>
          {/* 0.05$ */}
          <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACButton
                onClick={(e: any) => handleDrawerAuditBtn(e)}
                name="005$"
                id="005$"
                className={`${classes.RACbutton} ${classes.denominationwidth}`}
              >
                $ 0.05
              </RACButton>
            </RACTableCell>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACTextbox
                className={`${classes.quantitywid} ${classes.tableroW}`}
                value={denominationQuantity.denominationQuantity005.toString()}
                maxlength={9}
                type="number"
                name="$005"
                id="$005"
                Onblur={(e: any) => handleNonEmptyValue(e)}
                OnChange={(e: any) => handleDrawerAuditInp(e)}
              />
            </RACTableCell>
            <RACTableCell
              style={{ color: '#4A5174' }}
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <Typography style={{ paddingRight: '35px', textAlign: 'right' }}>
                ${' '}
                {formatMoney((
                  parseFloat(denominationQuantity.denominationQuantity005 !== '' ? denominationQuantity.denominationQuantity005 : '0') *
                  0.05
                ).toFixed(2))}
              </Typography>
            </RACTableCell>
          </RACTableRow>
          {/* $0.10 */}
          <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACButton
                onClick={(e: any) => handleDrawerAuditBtn(e)}
                name="010$"
                id="010$"
                className={`${classes.RACbutton} ${classes.denominationwidth}`}
              >
                $ 0.10
              </RACButton>
            </RACTableCell>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACTextbox
                className={`${classes.quantitywid} ${classes.tableroW}`}
                value={denominationQuantity.denominationQuantity010.toString()}
                maxlength={9}
                type="number"
                name="$010"
                id="$010"
                Onblur={(e: any) => handleNonEmptyValue(e)}

                OnChange={(e: any) => handleDrawerAuditInp(e)}
              />
            </RACTableCell>
            <RACTableCell
              style={{ color: '#4A5174' }}
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <Typography style={{ paddingRight: '35px', textAlign: 'right' }}>
                ${' '}
                {formatMoney((
                  parseFloat(denominationQuantity.denominationQuantity010 !== '' ? denominationQuantity.denominationQuantity010 : '0') * 0.1
                ).toFixed(2))}
              </Typography>
            </RACTableCell>
          </RACTableRow>
          {/* $0.25 */}
          <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACButton
                onClick={(e: any) => handleDrawerAuditBtn(e)}
                name="025$"
                id="025$"
                className={`${classes.RACbutton} ${classes.denominationwidth}`}
              >
                $ 0.25
              </RACButton>
            </RACTableCell>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACTextbox
                className={`${classes.quantitywid} ${classes.tableroW}`}
                value={denominationQuantity.denominationQuantity025.toString()}
                maxlength={9}
                type="number"
                name="$025"
                id="$025"
                Onblur={(e: any) => handleNonEmptyValue(e)}

                OnChange={(e: any) => handleDrawerAuditInp(e)}
              />
            </RACTableCell>
            <RACTableCell
              style={{ color: '#4A5174' }}
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <Typography style={{ paddingRight: '35px', textAlign: 'right' }}>
                ${' '}
                {formatMoney((
                  parseFloat(denominationQuantity.denominationQuantity025 !== '' ? denominationQuantity.denominationQuantity025 : '0') *
                  0.25
                ).toFixed(2))}
              </Typography>
            </RACTableCell>
          </RACTableRow>
          {/*0.50 $ */}
          <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACButton
                onClick={(e: any) => handleDrawerAuditBtn(e)}
                name="050$"
                id="050$"
                className={`${classes.RACbutton} ${classes.denominationwidth}`}
              >
                $ 0.50
              </RACButton>
            </RACTableCell>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACTextbox
                className={`${classes.quantitywid} ${classes.tableroW}`}
                value={denominationQuantity.denominationQuantity050.toString()}
                maxlength={9}
                type="number"
                name="$050"
                id="$050"
                Onblur={(e: any) => handleNonEmptyValue(e)}

                OnChange={(e: any) => handleDrawerAuditInp(e)}
              />
            </RACTableCell>
            <RACTableCell
              style={{ color: '#4A5174' }}
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <Typography style={{ paddingRight: '35px', textAlign: 'right' }}>
                ${' '}
                {formatMoney((
                  parseFloat(denominationQuantity.denominationQuantity050 !== '' ? denominationQuantity.denominationQuantity050 : '0') * 0.5
                ).toFixed(2))}
              </Typography>
            </RACTableCell>
          </RACTableRow>
          {/* 1$ */}
          <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACButton
                onClick={(e: any) => handleDrawerAuditBtn(e)}
                name="1$"
                id="1$"
                className={`${classes.RACbutton} ${classes.denominationwidth}`}
              >
                $ 1
              </RACButton>
            </RACTableCell>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACTextbox
                className={`${classes.quantitywid} ${classes.tableroW}`}
                value={denominationQuantity.denominationQuantity1.toString()}
                maxlength={9}
                type="number"
                name="$1"
                id="$1"
                Onblur={(e: any) => handleNonEmptyValue(e)}

                OnChange={(e: any) => handleDrawerAuditInp(e)}
              />
            </RACTableCell>
            <RACTableCell
              style={{ color: '#4A5174' }}
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <Typography style={{ paddingRight: '35px', textAlign: 'right' }}>
                ${' '}
                {formatMoney((
                  parseFloat(denominationQuantity.denominationQuantity1 !== '' ? denominationQuantity.denominationQuantity1 : '0') * 1
                ).toFixed(2))}
              </Typography>
            </RACTableCell>
          </RACTableRow>
          {/* 2$ */}
          <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACButton
                onClick={(e: any) => handleDrawerAuditBtn(e)}
                name="2$"
                id="2$"
                className={`${classes.RACbutton} ${classes.denominationwidth}`}
              >
                $ 2
              </RACButton>
            </RACTableCell>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACTextbox
                className={`${classes.quantitywid} ${classes.tableroW}`}
                value={denominationQuantity.denominationQuantity2.toString()}
                maxlength={9}
                type="number"
                name="$2"
                id="$2"
                Onblur={(e: any) => handleNonEmptyValue(e)}
                OnChange={(e: any) => handleDrawerAuditInp(e)}
              />
            </RACTableCell>

            <RACTableCell
              style={{ color: '#4A5174' }}
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <Typography style={{ paddingRight: '35px', textAlign: 'right' }}>
                ${' '}
                {formatMoney((
                  parseFloat(denominationQuantity.denominationQuantity2 != '' ? denominationQuantity.denominationQuantity2 : '0') * 2
                ).toFixed(2))}
              </Typography>
            </RACTableCell>
          </RACTableRow>
          {/* 5$ */}
          <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACButton
                onClick={(e: any) => handleDrawerAuditBtn(e)}
                name="5$"
                id="5$"
                className={`${classes.RACbutton} ${classes.denominationwidth}`}
              >
                $ 5
              </RACButton>
            </RACTableCell>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACTextbox
                className={`${classes.quantitywid} ${classes.tableroW}`}
                value={denominationQuantity.denominationQuantity5.toString()}
                maxlength={9}
                type="number"
                name="$5"
                id="$5"
                Onblur={(e: any) => handleNonEmptyValue(e)}

                OnChange={(e: any) => handleDrawerAuditInp(e)}
              />
            </RACTableCell>
            <RACTableCell
              style={{ color: '#4A5174' }}
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <Typography style={{ paddingRight: '35px', textAlign: 'right' }}>
                ${' '}
                {formatMoney((
                  parseFloat(denominationQuantity.denominationQuantity5 !== '' ? denominationQuantity.denominationQuantity5 : '0') * 5
                ).toFixed(2))}
              </Typography>
            </RACTableCell>
          </RACTableRow>
          {/* 10$ */}
          <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACButton
                onClick={(e: any) => handleDrawerAuditBtn(e)}
                name="10$"
                id="10$"
                className={`${classes.RACbutton} ${classes.denominationwidth}`}
              >
                $ 10
              </RACButton>
            </RACTableCell>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACTextbox
                className={`${classes.quantitywid} ${classes.tableroW}`}
                value={denominationQuantity.denominationQuantity10.toString()}
                maxlength={9}
                type="number"
                name="$10"
                id="$10"
                Onblur={(e: any) => handleNonEmptyValue(e)}

                OnChange={(e: any) => handleDrawerAuditInp(e)}
              />
            </RACTableCell>
            <RACTableCell
              style={{ color: '#4A5174' }}
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <Typography style={{ paddingRight: '35px', textAlign: 'right' }}>
                ${' '}
                {formatMoney((
                  parseFloat(denominationQuantity.denominationQuantity10 !== '' ? denominationQuantity.denominationQuantity10 : '0') * 10
                ).toFixed(2))}
              </Typography>
            </RACTableCell>
          </RACTableRow>
          {/* 20$ */}
          <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACButton
                onClick={(e: any) => handleDrawerAuditBtn(e)}
                name="20$"
                id="20$"
                className={`${classes.RACbutton} ${classes.denominationwidth}`}
              >
                $ 20
              </RACButton>
            </RACTableCell>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACTextbox
                className={`${classes.quantitywid} ${classes.tableroW}`}
                value={denominationQuantity.denominationQuantity20.toString()}
                maxlength={9}
                type="number"
                name="$20"
                id="$20"
                Onblur={(e: any) => handleNonEmptyValue(e)}

                OnChange={(e: any) => handleDrawerAuditInp(e)}
              />
            </RACTableCell>
            <RACTableCell
              style={{ color: '#4A5174' }}
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <Typography style={{ paddingRight: '35px', textAlign: 'right' }}>
                ${' '}
                {formatMoney((
                  parseFloat(denominationQuantity.denominationQuantity20 !== '' ? denominationQuantity.denominationQuantity20 : '0') * 20
                ).toFixed(2))}
              </Typography>
            </RACTableCell>
          </RACTableRow>
          {/* 50$ */}
          <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACButton
                onClick={(e: any) => handleDrawerAuditBtn(e)}
                name="50$"
                id="50$"
                className={`${classes.RACbutton} ${classes.denominationwidth}`}
              >
                $ 50
              </RACButton>
            </RACTableCell>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACTextbox
                className={`${classes.quantitywid} ${classes.tableroW}`}
                value={denominationQuantity.denominationQuantity50.toString()}
                maxlength={9}
                type="number"
                name="$50"
                id="$50"
                Onblur={(e: any) => handleNonEmptyValue(e)}

                OnChange={(e: any) => handleDrawerAuditInp(e)}
              />
            </RACTableCell>
            <RACTableCell
              style={{ color: '#4A5174' }}
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <Typography style={{ paddingRight: '35px', textAlign: 'right' }}>
                ${' '}
                {formatMoney((
                  parseFloat(denominationQuantity.denominationQuantity50 !== '' ? denominationQuantity.denominationQuantity50 : '0') * 50
                ).toFixed(2))}
              </Typography>
            </RACTableCell>
          </RACTableRow>
          {/* 100$ */}
          <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACButton
                onClick={(e: any) => handleDrawerAuditBtn(e)}
                name="100$"
                id="100$"
                className={`${classes.RACbutton} ${classes.denominationwidth}`}
              >
                $ 100
              </RACButton>
            </RACTableCell>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACTextbox
                className={`${classes.quantitywid} ${classes.tableroW}`}
                value={denominationQuantity.denominationQuantity100.toString()}
                maxlength={9}
                type="number"
                name="$100"
                id="$100"
                Onblur={(e: any) => handleNonEmptyValue(e)}

                OnChange={(e: any) => handleDrawerAuditInp(e)}
              />
            </RACTableCell>
            <RACTableCell
              style={{ color: '#4A5174' }}
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <Typography style={{ paddingRight: '35px', textAlign: 'right' }}>
                ${' '}
                {formatMoney((
                  parseFloat(denominationQuantity.denominationQuantity100 !== '' ? denominationQuantity.denominationQuantity100 : '0') * 100
                ).toFixed(2))}
              </Typography>
            </RACTableCell>
          </RACTableRow>
        </React.Fragment>
      </>
    );
  };
  const tableStricky: any = () => {
    if (defaultLoader) {
      return -0;
    }
  }
  const renderNonCashTableHead = () => {
    return (
      <React.Fragment>
        <RACTableCell
          className={`"racstrap-tablecell-BgColor"`}
          style={{ width: '14.2%', zIndex: tableStricky() }}
        >
          <Typography variant="h5">Customer</Typography>
        </RACTableCell>
        <RACTableCell
          className={`"racstrap-tablecell-BgColor"`}
          style={{ width: '14.2%', zIndex: tableStricky() }}
        >
          <Typography variant="h5">Doc Type</Typography>
        </RACTableCell>
        <RACTableCell
          className={`"racstrap-tablecell-BgColor"`}
          style={{ width: '14.2%', zIndex: tableStricky() }}
        >
          <Typography variant="h5">Doc #</Typography>
        </RACTableCell>
        <RACTableCell
          className={`"racstrap-tablecell-BgColor"`}
          style={{ width: '14.2%', zIndex: tableStricky() }}
        >
          <Typography variant="h5">Name on Doc</Typography>
        </RACTableCell>
        <RACTableCell
          className={`"racstrap-tablecell-BgColor"`}
          style={{
            width: '14.2%',
            paddingLeft: '2.8%', zIndex: tableStricky()
          }}
        >
          <Typography variant="h5">Receipt #</Typography>
        </RACTableCell>
        <RACTableCell
          className={`"racstrap-tablecell-BgColor"`}
          style={{
            width: '14.2%',
            textAlign: 'right',
            zIndex: tableStricky()
          }}
        >
          <Typography variant="h5">Amount</Typography>
        </RACTableCell>
        <RACTableCell
          className={`"racstrap-tablecell-BgColor"`}
          style={{ width: '14.2%', zIndex: tableStricky() }}
        >
          <Typography variant="h5" style={{ textAlign: 'center' }}>
            Verified
          </Typography>
        </RACTableCell>
      </React.Fragment>
    );
  };
  const renderNonCashTableContent = (): any => {
    return drawerAuditDetails.NonCashDetails?.map((data: any) => {
      return (
        <>
          <React.Fragment>
            {/* Row 1 */}
            <RACTableRow className={classes.tableRow} style={{ width: '100%' }}>
              <RACTableCell title={data.vendorName}>
                <Typography style={{ color: '#4a5174' }}>
                  {truncateString(data.vendorName)}
                </Typography>
              </RACTableCell>
              <RACTableCell>
                <Typography style={{ color: '#4a5174' }}>
                  {data.tenderType}
                </Typography>
              </RACTableCell>
              <RACTableCell>
                <Typography style={{ color: '#4a5174' }}>
                  {data.documentNumber}
                </Typography>
              </RACTableCell>
              <RACTableCell>
                <Typography style={{ color: '#4a5174' }}>
                  {data.documentWrittenBy}
                </Typography>
              </RACTableCell>
              <RACTableCell style={{ textAlign: 'center' }}>
                <Typography style={{ color: '#4a5174' }}>
                  {data.reciptNumber}
                </Typography>
              </RACTableCell>
              <RACTableCell>
                <Typography
                  style={{
                    color: '#4a5174',
                    textAlign: 'right',
                    // paddingRight:"45px"
                  }}
                >
                  $ {formatMoney(parseFloat(data.cashAmount).toFixed(2))}
                </Typography>
              </RACTableCell>
              <RACTableCell style={{ textAlign: 'center' }}>
                <RACCheckBox
                  onClick={(e: any) => handleNonCashDetail(e, data.receiptTransactionId)}
                  style={{ marginLeft: '13px' }}
                  size="medium"
                />
              </RACTableCell>
            </RACTableRow>
          </React.Fragment>
        </>
      );
    });
  };
  return (
    <><>
      {secondLevelAuthenOn ? (
        <SecondFactor
          setTwoFactorCancelClick={() => setSecondLevelAuthenOn(false)}
          setTwoFactorCompleted={(e: any) => seconduthenResponses(e)}
          currentRole="CAR"
          moduleName="Accept Payment"
        ></SecondFactor>
      ) : null}
      {/* parent container */}
      <Grid style={{
        marginBottom: '2%',
        paddingRight: '0.75rem',
        paddingLeft: '0.75rem',
        marginRight: 'auto',
        marginLeft: 'auto',
        // marginTop: '60px',
        paddingBottom: '80px !important',
      }}
      >
        <ConfirmationCancelModelCard />
        <ConfirmationSavePopupModelCard />
        <OverShortExplanationModelCard />
        {/* Subparent conatiner */}
        <Grid style={{ width: '100%', float: 'left' }}>
          <Grid className={classes.colMd12mt3}>
            <Grid className={classes.row}>
              {/* Drawer total starts here */}
              <Typography variant="h4" className={classes.header}>
                Drawer Audit
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* Subparent Container */}
        <Grid style={{ width: '100%', float: 'left', marginBottom: "11%" }}>
          {/* cash Details */}
          <Grid style={{ width: '30%' }}>
            {/* <Grid style={{width:"100%"}}> */}
            {/* <CashDetails></CashDetails> */}
            <Grid
              // className={classes.mt3}
              item
              style={{ float: 'left', width: '100%' }}
            >
              <Typography variant="h4" className={classes.header}>
                Cash Detail
              </Typography>

              <Card className={classes.card}>
                <CardContent
                  style={{
                    flex: '1 1 auto',
                    padding: '1rem 1rem',
                    paddingBottom: '10px !important',
                    marginLeft: '23px',
                  }}
                  className={` ${classes.p2}`}
                >
                  <Grid className={`${classes.row} ${classes.mb3}`}>
                    <Grid className={classes.racColMD12}>
                      <RACTable
                        className={`${classes.table} ${classes.tableBorder}`}
                        renderTableHead={renderCashTableHead}
                        renderTableContent={renderCashTableContent} />
                      <Grid
                        style={{
                          width: '65%',
                          padding: '8px',
                          background: '#EFF4FF',
                          marginRight: '17px',
                          marginLeft: '7px',
                          marginTop: '3.2%',
                          border: 'none',
                          borderRadius: '4px',
                          marginBottom: '6px',
                        }}
                        className={`${classes.floatRight}`}
                      >
                        <Grid
                          className={classes.floatLeft}
                          style={{ width: '45%' }}
                        >
                          <Typography
                            style={{
                              color: '#2179FE',
                              padding: '1px',
                              paddingLeft: '12px',
                            }}
                          >
                            Total Cash
                          </Typography>
                        </Grid>
                        <Grid
                          style={{ width: '55%' }}
                          className={classes.floatLeft}
                        >
                          <Typography
                            style={{
                              color: '#2179FE',
                              padding: '1px',
                              paddingRight: '24px',
                              textAlign: 'right',
                            }}
                          >
                            $ {formatMoney(parseFloat(dropAmount.cash).toFixed(2))}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            {/* </Grid> */}
          </Grid>
          <Grid style={{ width: '68%', float: 'right' }}>
            {/* Drawer Total */}
            <Grid style={{ width: '100%' }}>
              <Typography variant="h5" style={{ marginBottom: '15px' }}>
                Drawer Total
              </Typography>
              <Card className={classes.card} style={{ padding: '0.5%' }}>
                <CardContent className={classes.cardBody}>
                  <Grid
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      marginTop: '0',
                      marginRight: '7px',
                    }}
                  >
                    <Grid className={classes.colMd2}>
                      <Typography
                        variant="h5"
                        style={{
                          marginBottom: '0.2rem',
                          paddingTop: '1%',
                          color: '#111111',
                          // fontFamily: "OpenSans-semibold",
                          fontSize: '14px',
                          float: 'left',
                          width: '32%',
                        }}
                      >
                        Non-Cash
                      </Typography>
                      <Typography className={classes.formValue}>
                        {/* {formatMoney(parseFloat(
      drawerAuditDetails !== undefined &&
        drawerAuditDetails.storeTransaction !== undefined &&
        drawerAuditDetails.storeTransaction !== null &&
        drawerAuditDetails.storeTransaction.totalNonCash !==
          undefined &&
        drawerAuditDetails.storeTransaction.totalNonCash !== null
        ? ( (drawerAuditDetails.storeTransaction.totalNonCash >=0) ? (drawerAuditDetails.storeTransaction.totalNonCash) : '(' + (-(drawerAuditDetails.storeTransaction.totalNonCash)) + ')' )
        : '0'
    ).toFixed(2))} */}
                        {formatMoney(totalNonCash())}
                      </Typography>
                    </Grid>
                    <Grid className={classes.colMd2}>
                      <Typography
                        variant="h5"
                        style={{
                          marginBottom: '0.2rem',
                          color: '#111111',
                          paddingTop: '1%',
                          // fontFamily: "OpenSans-semibold",
                          fontSize: '14px',
                          float: 'left',
                          width: '18%',
                        }}
                      >
                        Cash
                      </Typography>
                      <Typography className={classes.formValue}>
                        {/* ${drawerTotalGrid.cash.toFixed(2)} */}
                        {formatMoney(totalCash())}
                      </Typography>
                      <Typography className={classes.formLabelDefaultStarting}>
                        (Including Default Starting Till)
                      </Typography>
                    </Grid>
                    <Grid className={classes.colMd2}>
                      <Typography
                        variant="h5"
                        // className={classes.formLabelTotalCash}
                        style={{
                          paddingRight: '20px',
                          //  textAlign:"right",
                          paddingTop: '1%',
                          marginBottom: '0.2rem',
                          color: '#111111',
                          // fontFamily: "OpenSans-semibold",
                          fontSize: '14px',
                          float: 'left',
                          width: '20%',
                        }}
                      >
                        Total
                      </Typography>
                      <Typography className={classes.formValue}>
                        {/*returns the Total amount including nonCash and cash and the default amount.*/}
                        {formatMoney(calculateTotalNonCash())}
                      </Typography>
                    </Grid>
                    <Grid className={classes.colMd2}>
                      <Typography
                        variant="h5"
                        style={{
                          paddingTop: '1%',
                          marginBottom: '0.2rem',
                          color: '#111111',
                          // fontFamily: "OpenSans-semibold",
                          fontSize: '14px',
                          float: 'left',
                          width: '53%',
                        }}
                      >
                        Credit Card Total
                      </Typography>
                      <Typography className={classes.formValue}>
                        {/*returns only the default amount from the object drawerTotalGrid.*/}
                        {/* ${drawerTotalGrid.defaultStartingValue.toFixed(2)} */}
                        ${' '}
                        {formatMoney(parseFloat(
                          drawerAuditDetails !== undefined &&
                            drawerAuditDetails.storeTransaction !== undefined
                            ? drawerAuditDetails.storeTransaction
                              .creditCardTotal
                            : '0'
                        ).toFixed(2))}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            {/* Audit Totals */}
            <Grid style={{ width: '100%' }}>
              <Typography
                variant="h5"
                style={{ marginBottom: '15px', marginTop: '19px' }}
              >
                Audit Total
              </Typography>
              <Card
                style={{
                  border: 'none',
                  borderRadius: '1rem',
                  boxShadow: '0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important',
                  //  paddingBottom: "-1rem !important"
                }}
              >
                <CardContent
                  style={{
                    flex: '1 1 auto',
                    paddingLeft: '2%',
                    // paddingBottom: "-24px !important"
                  }}
                >
                  <Grid
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      //    marginTop: "0",
                    }}
                  >
                    <Grid
                      style={{
                        flex: '0 0 auto',
                        width: '17%',
                        marginTop: '0.6rem',
                        paddingTop: '6px',
                      }}
                    >
                      <Typography
                        variant="h5"
                        style={{
                          paddingTop: '1%',
                          color: '#111111',
                          //    fontFamily: "OpenSans-semibold",
                          fontSize: '14px',
                          float: 'left',
                          width: '47%',
                        }}
                      >
                        Non-Cash
                      </Typography>
                      <Typography className={classes.formValue}>
                        $ {formatMoney(parseFloat(dropAmount.nonCash).toFixed(2))}
                      </Typography>
                    </Grid>
                    <Grid
                      style={{
                        flex: '0 0 auto',
                        width: '15%',
                        marginTop: '0.6rem',
                      }}
                    >
                      <Typography
                        variant="h5"
                        style={{
                          // marginBottom: "0.2rem",
                          paddingTop: '7.8px',
                          color: '#111111',
                          // fontFamily: "OpenSans-semibold",
                          fontSize: '14px',
                          float: 'left',

                          width: '29%',
                        }}
                      >
                        Cash
                      </Typography>
                      <Typography
                        style={{ paddingTop: '7px' }}
                        className={classes.formValue}
                      >
                        {/* ${drawerTotalGrid.cash.toFixed(2)} */}${' '}
                        {formatMoney((
                          parseFloat(dropAmount?.cash)).toFixed(2))}
                        {/* parseFloat(pendingPettyCash.pettyCash !==''? pendingPettyCash.pettyCash :'0')  +  parseFloat(pendingPettyCash.pendingPaidOutRequest !==''? pendingPettyCash.pendingPaidOutRequest :'0') */}
                      </Typography>
                    </Grid>
                    <Grid
                      style={{
                        flex: '0 0 auto',
                        width: '32%',
                        marginTop: '0.6rem',
                      }}
                    >
                      {/* <Grid style={{
                              //    display: "flex",
                              // flexWrap: "wrap",
                              marginTop: "0",
                              //  marginRight: "-0.75rem",
                              width: "100%"
                          }} > */}
                      <Grid style={{ width: '61%' }}>
                        <Typography
                          variant="h5"
                          style={{
                            // marginBottom: "0.2rem",
                            color: '#111111',
                            // fontFamily: "OpenSans-semibold",
                            fontSize: '14px',
                            float: 'left',
                            width: '100%',
                            paddingTop: '7.5px',
                          }}
                        >
                          Pending Paid-out Request
                        </Typography>
                      </Grid>
                      <Grid style={{ float: 'left', width: '39%' }}>
                        <RACTextbox
                          className={classes.pendingPetty}
                          type="text"
                          name="pending"
                          id="pending"
                          value={((pendingPettyCash.pendingPaidOutRequest).toString())}
                          // maxlength={30}
                          required={false}
                          inputlabel=""
                          Onblur={(e: any) => handleNonEmptyValue(e)}
                          OnChange={(e: any) => {
                            handlePendingPaidOutRequest(e);
                          } }
                          isCurrency={false} />
                        {/* </Grid> */}
                      </Grid>
                      {/* </Typography>
                      {/* <Typography className={classes.formValue}>
                          {/*returns the Total amount including nonCash and cash and the default amount.*/}

                      {/* ${ }
                      </Typography>  */}
                    </Grid>
                    <Grid
                      style={{
                        flex: '0 0 auto',
                        width: '21%',
                        marginTop: '0.6rem',
                      }}
                    >
                      <Grid style={{ width: '40%' }}>
                        <Typography
                          variant="h5"
                          style={{
                            // marginBottom: "0.2rem",
                            color: '#111111',
                            // fontFamily: "OpenSans-semibold",
                            fontSize: '14px',
                            float: 'left',
                            width: '100%',
                            marginLeft: '-5%',
                            paddingTop: '7.5px',
                          }}
                        >
                          Petty Cash
                        </Typography>
                      </Grid>
                      <Grid
                        style={{
                          float: 'left',
                          paddingLeft: '2.5%',
                          width: '60%',
                        }}
                      >
                        <RACTextbox
                          className={classes.pendingPetty}
                          type="text"
                          name="petty"
                          id="petty"
                          value={(pendingPettyCash.pettyCash)}
                          // maxlength={30}
                          required={false}
                          inputlabel=""
                          Onblur={(e: any) => handleNonEmptyValue(e)}
                          OnChange={(e: any) => {
                            handlePettyCash(e);
                          } }
                          isCurrency={false} />
                      </Grid>
                      {/*returns only the default amount from the object drawerTotalGrid.*/}
                      {/* ${drawerTotalGrid.defaultStartingValue.toFixed(2)} */}
                    </Grid>
                    <Grid
                      style={{
                        flex: '0 0 auto',
                        width: '14.9%',
                        marginTop: '0.6rem',
                        paddingTop: '6px',
                      }}
                    >
                      <Typography
                        variant="h5"
                        style={{
                          marginBottom: '0.2rem',
                          color: '#111111',
                          //  fontFamily: "OpenSans-semibold",
                          fontSize: '14px',
                          float: 'left',
                          width: '34%',
                        }}
                      >
                        Total
                      </Typography>
                      <Typography className={classes.formValue}>
                        {/*returns only the default amount from the object drawerTotalGrid.*/}
                        {/* ${drawerTotalGrid.defaultStartingValue.toFixed(2)} */}
                        ${' '}
                        {formatMoney((
                          parseFloat(dropAmount?.cash) + parseFloat(dropAmount?.nonCash) +
                          parseFloat(pendingPettyCash.pettyCash !== '' ? pendingPettyCash.pettyCash : '0') + parseFloat(pendingPettyCash.pendingPaidOutRequest !== '' ? pendingPettyCash.pendingPaidOutRequest : '0')).toFixed(2))}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            {/* Non-Cash */}
            <Grid style={{ width: '100%' }}>
              {/* <NonCashBeingDropedDrawerAudit></NonCashBeingDropedDrawerAudit> */}
              <Grid>
                <Typography
                  variant="h5"
                  className={classes.header}
                  style={{ marginTop: '20px', marginBottom: '20px' }}
                >
                  Non-Cash Details
                </Typography>
                <Card style={{ paddingBottom: '2%' }} className={`${classes.card} ${classes.scrollbar}`}>
                  <CardContent className={`${classes.cardBody} ${classes.p2}`}>
                    <RACTable
                      className={`${classes.table} ${classes.tableBorder} ${classes.mb0}`}
                      renderTableHead={renderNonCashTableHead}
                      renderTableContent={handleNonCashTableContent}
                      stickyHeader />
                    {drawerAuditDetails !== undefined &&
                      drawerAuditDetails.NonCashDetails !== undefined &&
                      drawerAuditDetails.NonCashDetails !== null ? (
                      drawerAuditDetails.NonCashDetails.length > 0 ? (
                        <Grid
                          className={`${classes.floatRight} ${classes.totalNonCash}`}
                        >
                          <Grid
                            className={classes.floatLeft}
                            style={{ width: '65%' }}
                          >
                            <Typography
                              style={{
                                color: '#2179FE',
                                padding: '1px',
                                paddingLeft: '15px',
                                width: '100%',
                              }}
                            >
                              Total Non-Cash Being Dropped
                            </Typography>
                          </Grid>
                          <Grid
                            style={{ width: '35%' }}
                            className={classes.floatLeft}
                          >
                            <Typography
                              style={{
                                color: '#2179FE',
                                padding: '1px',
                                paddingRight: '10%',
                                width: '100%',
                                textAlign: 'right',
                              }}
                            >
                              $ {formatMoney(parseFloat(dropAmount.nonCash).toFixed(2))}
                            </Typography>
                          </Grid>
                        </Grid>
                      ) : null
                    ) : (
                      ''
                    )}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            {/* over/short Summary */}
            <Grid style={{ width: '100%' }}>
              <Grid style={{ width: '65%' }}>
                <Typography
                  variant="h5"
                  className={classes.topBottomSpaceOverShort}
                >
                  Over / Short Summary
                </Typography>
                <Card className={classes.card}>
                  <CardContent className={classes.cardBody}>
                    <Grid
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        marginTop: '0',
                        marginRight: '7px',
                      }}
                    >
                      <Grid
                        style={{
                          flex: '0 0 auto',
                          width: '35%',
                        }}
                      >
                        <Typography
                          variant="h5"
                          className={classes.overShortNonCash}
                        >
                          Non-Cash
                        </Typography>
                        <Typography className={classes.formValue}>
                          {formatMoney(calculateNonCash())}
                        </Typography>
                      </Grid>
                      <Grid
                        style={{
                          flex: '0 0 auto',
                          width: '35%',
                        }}
                      >
                        <Typography
                          variant="h5"
                          className={classes.overShortCash}
                        >
                          Cash
                        </Typography>
                        <Typography className={classes.formValue}>
                          {formatMoney(calculateShortCash())}
                        </Typography>
                      </Grid>
                      <Grid
                        style={{
                          flex: '0 0 auto',
                          width: '30%',
                        }}
                      >
                        <Typography
                          variant="h5"
                          className={classes.overShortTotal}
                        >
                          Total
                        </Typography>
                        <Typography className={classes.formValue}>
                          {/*returns the Total amount including nonCash and cash and the default amount.*/}
                          {/* if overShort Cash and non cash are greater than zero means no need to show() */}

                          {formatMoney(calculateTotalOverShortAmount())}
                          {/* {drawerGridTotalAmt} */}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            {/* Drawer Audit Notes */}
            <Grid style={{ width: '100%' }}>
              <Grid>
                <Grid style={{ width: '60%', float: 'left' }}>
                  <Grid
                    className={`${classes.mt3} ${classes.columnStyle}`}
                    item
                  >
                    <Grid className={classes.row} style={{ width: '100%' }}>
                      <Typography
                        variant="h5"
                        className={classes.topBottomSpace}
                      >
                        {/* <Typography variant="h4" style={{marginTop:"30px",marginBottom:"20px"}}  style={{marginTop:"15px"}}  className={classes.header}> */}
                        Notes to send to store messages{' '}
                        <Typography
                          variant="h6"
                          style={{ float: 'right', paddingLeft: '4px' }}
                        >
                          (Record anything needing action)
                        </Typography>
                        {/* </Typography> */}
                      </Typography>
                    </Grid>
                    <textarea
                      rows={3}
                      cols={54}
                      value={overShortNotes.storeMsg}
                      className={`${classes.dropNotes} ${classes.textArea}`}
                      onChange={(e) => handleStoreMsg(e)}
                    ></textarea>
                  </Grid>
                </Grid>
              </Grid>

              <Grid style={{ width: '35%', float: 'left', marginLeft: '5%' }}>
                <Grid className={`${classes.mt3} ${classes.columnStyle}`} item>
                  <Typography variant="h5" className={classes.topBottomSpace}>
                    Drawer Audit Notes
                  </Typography>
                  <textarea
                    className={`${classes.textArea} ${classes.dropNotes}`}
                    rows={3}
                    cols={54}
                    value={overShortNotes.drawerAuditNotes}
                    onChange={(e) => handleDrawerAuditNotes(e)}
                  ></textarea>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid>
        <Grid container classes={{
          root: clsx(classes.customerToolbar, classes.fixedBottom),
        }} style={{ zIndex: 0 }}>
          <Grid className={classes.footerFixed}>
            <Grid style={{ float: 'left' }}>
              <RACButton
                variant="outlined"
                color="secondary"
                onClick={(e: any) => handleCancelBtn()}
              >
                Cancel
              </RACButton>
            </Grid>

            <Grid style={{ float: 'right' }}>
              <RACButton
                variant="contained"
                color="primary"
                onClick={() => handleSaveBtn()}
              >
                Save
              </RACButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </><Grid>
        <RACModalCard
          isOpen={successPopup}
          maxWidth="xs"
          closeIcon={true}
          borderRadius="20px"
          onClose={() => {
            history.push({
              pathname: `/dashboard/homepage`,
            });
          } }
          // eslint-disable-next-line react/no-children-prop
          children={UpdateInfoMsgPopup()} />
      </Grid></>
  );
}