export enum AppRoute {
  Index = '/',
  Placeholder1 = '/placeholder1',
  Placeholder2 = '/placeholder2',
  Drop = '/drop',
  Drops = '/cashmgmt/drop',
  PastDrop = '/cashmgmt/pastdrop',
  CashDrop = '/cashmgmt/cashdrop',
  DrawerAudit = '/cashmgmt/audit',
  paidIn = '/cashmgmt/paidin',
  paidOut = '/cashmgmt/paidout',
  Finaldrops = '/cashmgmt/final/drop',
  Reconciliation = '/cashmgmt/reconcil',
  Depositreceipt = '/cashmgmt/dropreceipt',
}
