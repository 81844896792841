/* eslint-disable */
import React, { useEffect } from "react";
import { Grid, Typography, makeStyles, RACModalCard, RACButton, CircularProgress } from "@rentacenter/racstrap";
import { StoreExceptionType } from "../../constants/constants";
import { storeException } from "../../api/user";
import { CashmgmtDropDetailContext } from "../../context/CashMgmtContext";
interface storeExceptionInterFace {
    refCode?: any;
    identifierId?: any;
    storeExceptionCancelled?: any
    storeExceptionCompleted?: any
    identifierType?: any

}
export default function StoreException(props: storeExceptionInterFace) {
    let { storeExceptionPayload, setstoreExceptionPayload } = React.useContext(CashmgmtDropDetailContext)


    const { refCode, identifierId, storeExceptionCancelled, storeExceptionCompleted, identifierType } = props

    const [storeExceptionPopup, setStoreExceptionPopup] = React.useState(true)
    let [exceptionText, SetexceptionText] = React.useState('')
    let [remainingLength, setremainingLength] = React.useState(255)
    const [exceptionDesc, setexceptionDesc] = React.useState('')


    useEffect(() => {
        const exceptionDesc = StoreExceptionType.filter((obj: any) => obj.abbreviation == refCode)
        setexceptionDesc(exceptionDesc[0].description)
    }, [])

    const exceptionTextEnterFn = (inputText: any) => {
        exceptionText = inputText
        SetexceptionText(inputText)

        remainingLength = 255 - exceptionText.length
        setremainingLength(remainingLength)


    }
    const setStoreExceptionCancelClick = async () => {
        SetexceptionText('')
        setexceptionDesc('')
        setremainingLength(255)
        setStoreExceptionPopup(false)
        storeExceptionCancelled()
    }
    const setStoreExceptionCompletedClick = async () => {
        setStoreExceptionPopup(false)
        const payload = [

            {
                identifierId: identifierId ? props.identifierId : null,
                identifierType: identifierType ? identifierType : null,
                storeNumber: window.sessionStorage.getItem('storeNumber'),
                exceptionTypeRefCode: refCode,
                exceptionText: exceptionText
            }

        ]

        const updatedPayload = [...storeExceptionPayload, ...payload]
        storeExceptionPayload = updatedPayload
        setstoreExceptionPayload(storeExceptionPayload)
        storeExceptionCompleted(updatedPayload)
    }
    const StoreExceptionPopup = () => {
        return (
            <>
                <Typography style={{ marginTop: '10px' }}>
                    Explanation <span style={{ color: 'red' }}> *</span>
                </Typography>
                <textarea
                    value={exceptionText}
                    rows={8}
                    cols={73}
                    maxLength={255}
                    onChange={(e: any) => {
                        exceptionTextEnterFn(e.target.value.trimStart())

                    }}
                    style={{ resize: 'none', borderRadius: '5px', marginTop: '5px', padding: '5px' }}
                >

                </textarea>
                <span style={{ marginTop: '15px', fontFamily: 'OpenSans-semibold', color: '#00000085' }}>{remainingLength}/255 characters left.</span>
                <Grid style={{ float: 'right', marginTop: '34px' }}>
                    <RACButton
                        style={{ marginRight: '10px' }}

                        color='default'
                        variant='outlined'
                        disabled={false}
                        onClick={() => setStoreExceptionCancelClick()}
                    >
                        Cancel
                    </RACButton>
                    <RACButton
                        style={{ marginRight: '35px' }}
                        color='primary'
                        variant='contained'
                        disabled={exceptionText?.length > 0 ? false : true}
                        onClick={() => { setStoreExceptionCompletedClick() }}
                    >
                        Save
                    </RACButton>
                </Grid>


            </>
        )
    }

    return (
        <Grid>
            <RACModalCard
                title={"Enter Explanation For " + exceptionDesc + " Exception"}
                isOpen={storeExceptionPopup}
                closeIcon={true}
                maxWidth="sm"
                onClose={() => setStoreExceptionCancelClick()}
                borderRadius={"25px !important"}
                children={StoreExceptionPopup()}
            />



        </Grid>
    )
}