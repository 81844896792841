/* eslint-disable */
// eslint-disable-next-line prettier/prettier
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable prettier/prettier */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { createContext, ReactChild, ReactFragment, ReactPortal, useState } from 'react'

export const CashmgmtDropDetailContext = createContext<any>('')


export const CashmgmmtProvider = (props: any) => {

    const [currentTotalDropDetail, setcurrentTotalDropDetail] = useState('hello');
    let [storeExceptionPayload, setstoreExceptionPayload] = useState<any>([])

    return (
        <CashmgmtDropDetailContext.Provider

            value={{
                currentTotalDropDetail, setcurrentTotalDropDetail,
                storeExceptionPayload, setstoreExceptionPayload
            }}>{props.children}

        </CashmgmtDropDetailContext.Provider>
    )
}