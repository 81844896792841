/* eslint-disable no-debugger */
/* eslint-disable  no-console */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  sonarjs/no-redundant-boolean */
/* eslint-disable  sonarjs/no-duplicate-string */
/* eslint-disable  react-hooks/exhaustive-deps */
/* eslint-disable prettier/prettier */
/* eslint-disable  sonarjs/cognitive-complexity */
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import {
    Grid,
    Card,
    CardContent,
    Typography,
    RACTable,
    RACTableCell,
    RACTableRow,
    RACButton,
    CircularProgress,
    RACModalCard
} from '@rentacenter/racstrap';

import { customerStyles } from '../DropReconciliation/customerStyles';
import { DropReconciliationSummary } from './DropReconciliationSummary';
import { getDrawerDetail } from '../../api/user';
import { CashmgmtContext } from '../../context/CashManagementContext';
import { CashmgmtDropDetailContext } from '../../context/CashMgmtContext';

import moment from 'moment';
import clsx from 'clsx';
// import { getDropSummaryDetail } from '../api/user';
export function DropReconciliation() {
    const history = useHistory()
    const classes = customerStyles();
    const [nodropfound, setnodropfound] = useState(false)
    // const dropSummaryDetail = [
    //     {
    //         activityDate: '12-12-2022',
    //         totalNonCash: '100.00',
    //         totalCash: '220.00',
    //         overOrShortCash: '10.00',
    //         overOrShortNonCash: '-100.00',
    //         overOrShortNotes: '0.00',
    //         dropedBy: '2202001',
    //         verfiedBy: 'store1,Co-Worker1',
    //         drawerManagementId: 1,
    //         dropEnvelope: '123446',
    //     },
    //     {
    //         activityDate: '12-12-2022',
    //         totalNonCash: '200.00',
    //         totalCash: '100.00',
    //         overOrShortCash: '0.00',
    //         overOrShortNonCash: '0.00',
    //         overOrShortNotes: '0.00',
    //         dropedBy: '2202001',
    //         verfiedBy: 'store1,Co-Worker1',
    //         drawerManagementId: 2,
    //         dropEnvelope: '123445',
    //     },
    //     {
    //         activityDate: '13-12-2022',
    //         totalNonCash: '1000.00',
    //         totalCash: '300.00',
    //         overOrShortCash: '10.00',
    //         overOrShortNonCash: '110.00',
    //         overOrShortNotes: '0.00',
    //         dropedBy: '2202001',
    //         verfiedBy: 'store1,Co-Worker2',
    //         drawerManagementId: 3,
    //         dropEnvelope: '123246',
    //     },
    // ];
    const [
        dropReconciliationSummaryDisable,
        setDropReconciliationSummaryDisable,
    ] = useState(false);
    const {
        dropReconciliationData,
        setDropReconciliationData, setIsRecociled
    } = useContext(CashmgmtContext);
    const {
        currentTotalDropDetail, setcurrentTotalDropDetail
    } = useContext(CashmgmtDropDetailContext);
    const [loader, setloader] = useState(false);
    const [processpopUp, setprocesspopUp] = useState(false);
    const DropReconciliationProcessBtn = (date: string, index: any) => {
        if(index != 0){
            setprocesspopUp(true)
            return
        }
        let secondPage: any = [];
        if (resultValue !== undefined) {
            Object.entries(resultValue).forEach(([key, value]: any) => {
                if (key == date) {
                    secondPage = value !== undefined ? value : [];
                }
            });

        }
        // setSecondPageValue(secondPage);
        // setDropReconciliationSummaryDisable(true);
        const totalDropSummary = Object.freeze(secondPage);
        const clone = JSON.parse(JSON.stringify(secondPage))
        setcurrentTotalDropDetail({ ...currentTotalDropDetail, totalDropSummary: totalDropSummary, totalDropSummaryCopy: clone })
        history.push({ pathname: '/cashmgmt/reconcil/summary', state: { secondPage } })
    };
    type objTypeArr = {
        id: number;
        date: string;
        totalDropCount: number;
    };
    const [firstPageValue, setFirstPageValue] = useState<objTypeArr[]>();
    const [resultValue, setResultValue] = useState<any>();
    const [secondPageValue, setSecondPageValue] = useState<any>();
    useEffect(() => {
        pageLoad1();
    }, []);
    const pageLoad1 = async () => {
        
        try {

            let dropSummary: any = ''
            const historyData: any = history.location.state
            let isApiCallHappenend = false
            if (historyData && historyData.split('_')[0] == 'RECONCILCOMPLETED') {
                setloader(true)
                setIsRecociled(false);
                dropSummary = await getDrawerDetail({ storeNumber: window.sessionStorage.getItem('storeNumber') })
                setloader(false)
                // dropSummary = dropReconciliationData.totalDrops.filter((el: any) => moment(el.activityDate).format('MM/DD/YYYY') != moment(historyData.split('_')[1]).format('MM/DD/YYYY'))
                dropSummary = dropSummary?.dropSummary?.filter((el: any) => moment(new Date()).format("YYYY-MM-DD") > moment(el.activityDate).format("YYYY-MM-DD"))
                const isAllReconcilled = dropReconciliationData?.totalDrops?.filter((el: any) => (el.validateChecked == false || !el.validateChecked))
                if (dropSummary && dropSummary.length == 0 || isAllReconcilled?.length == 0) {
                    setnodropfound(true)
                    return
                }
            }
            if(historyData == 'REDIRECT'){
                setDropReconciliationData(null)
                dropSummary = currentTotalDropDetail.totalDrops
                isApiCallHappenend = true
                // const activityIds = dropReconciliationData.dropDetails.map((el: any) => el.drawerManagementId)

                // dropSummary = dropReconciliationData.totalDrops.filter((el: any) => {
                //     if (!activityIds.includes(String(el.activityId))) {
                //         return el;
                //     }
                // })
            }
            else if (!dropReconciliationData || !dropReconciliationData.totalDrops || !isApiCallHappenend) {
                let res: any = ''
                if (!dropSummary || dropSummary.length == 0) {
                    setloader(true)
                    res = await getDrawerDetail({ storeNumber: window.sessionStorage.getItem('storeNumber') })
                    res = res?.dropSummary
                    isApiCallHappenend = true
                    setloader(false)
                } else {
                    res = dropSummary
                }

                if (!res || res.length == 0) {
                    setnodropfound(true)
                    return
                }
                let updatedOrder: any = res && res.sort((a: any, b: any) => Date.parse(a.activityDate) - Date.parse(b.activityDate))
                updatedOrder = res.filter((el: any) => !el.bankDepositId)
                const dropRecContext = dropReconciliationData || {}
                dropRecContext.totalDrops = updatedOrder
                setDropReconciliationData(dropRecContext)
                

                dropSummary = updatedOrder
            }
            
            const allDrop = new Map()
            
            dropSummary && dropSummary.length > 0 && dropSummary.forEach((el: any) => {
                if (allDrop.get(moment(el.activityDate).format("YYYY-MM-DD"))) {
                    const tempVal = allDrop.get(moment(el.activityDate).format("YYYY-MM-DD"))
                    tempVal.push(el)
                    allDrop.set(moment(el.activityDate).format("YYYY-MM-DD"), tempVal)
                } else {
                    allDrop.set(moment(el.activityDate).format("YYYY-MM-DD"), [el])
                }
            })
            // [...allDrop.values()].length > 0 && allDrop.forEach((dropItems:any)=>{
            //     let count = 0
            //     dropItems.forEach((element:any) => {
            //         if (element.dropReconciliationId){
            //             count++
            //         }
            //     });
            //     if(count == dropItems.length){
            //         allDrop.delete(moment(dropItems[0].activityDate).format("YYYY-MM-DD"))
            //     }
            //     console.log(count)
            // })
            console.log('allDrop', [...allDrop.values()])
            if ([...allDrop.values()].length == 0) {
                setnodropfound(true)
                return
            }
            dropSummary = [...allDrop.values()].length > 0 && [...allDrop.values()].flat(Infinity)?.filter((el: any) => (moment(new Date()).format("YYYY-MM-DD") > moment(el.activityDate).format("YYYY-MM-DD")) && el.dropReceiptTransactionId == null)
            //dropSummary = [...allDrop.values()].flat(Infinity)
            const obj: any = {}
            if (dropSummary && dropSummary.length == 0) {
                setnodropfound(true)
                return
            }
            const totalDropDates = Array.from(new Set(dropSummary.map((el: any) => moment(el.activityDate).format('MM/DD/YYYY'))))
            const cloneTotalDrop = JSON.parse(JSON.stringify(dropSummary))
            setcurrentTotalDropDetail({ ...currentTotalDropDetail, totalDrops: cloneTotalDrop })
            totalDropDates.forEach((el: any) => {
                obj[el] = dropSummary.filter((activityDateElement: any) => moment(activityDateElement.activityDate).format('MM/DD/YYYY') == el)
            })
            // type objType = {
            //     prop1: string;
            // };
            // const result: objType = dropSummary.reduce(function (
            //     r: any,
            //     a: any
            // ) {
            //     r[a.activityDate] = r[a.activityDate] || [];
            //     r[a.activityDate].push(a);
            //     return r;
            // })
            setResultValue(obj);
            const firstPage: { id: number; date: string; totalDropCount: any }[] =
                [];

            let i = 0;
            Object.entries(obj).forEach((el: any) => {
                // const count:any= value.length
                firstPage.push({
                    id: i,
                    date: el[0],
                    totalDropCount: el[1].length
                });
                i += 1;
            });
            console.log('************FirstPAge*********', firstPageValue);
            setFirstPageValue(firstPage);
            //  console.log("secondPage",secondPage)
        } catch (e) {
            setloader(false)
            console.log(e);
        }
    };

    const renderDropDetailsHead = () => {
        return (
            <React.Fragment>
                <RACTableCell className={classes.DateofDrop}>Date of Drop</RACTableCell>
                <RACTableCell className={classes.Drops}># of Drops</RACTableCell>
                <RACTableCell className={classes.Action}>Action</RACTableCell>
            </React.Fragment>
        );
    };

    const renderDropDetailsContent = (): any => {
        return firstPageValue?.map((data: any, index: any) => {
            const date: any = data.date.split('T')
            return (
                <>
                    <React.Fragment>
                        <RACTableRow className={classes.tableRow}>
                            <RACTableCell>
                                <Typography style={{ color: '#4a5174' }}>
                                    {moment(data.date).format('MM/DD/YYYY')}
                                </Typography>
                            </RACTableCell>
                            <RACTableCell>
                                <Typography style={{ color: '#4a5174', textAlign: 'center' }}>
                                    {data.totalDropCount}
                                </Typography>
                            </RACTableCell>
                            <RACTableCell>
                                <RACButton
                                    variant="contained"
                                    style={{
                                        float: 'right',
                                        color: 'Blue',
                                        backgroundColor: '#E0EAFF',
                                        width: '40%',
                                    }}
                                    size="small"
                                    id={data.date}
                                    onClick={() => {
                                        DropReconciliationProcessBtn(data.date, index);
                                    }}
                                >
                                    Process
                                </RACButton>
                            </RACTableCell>
                        </RACTableRow>
                    </React.Fragment>
                </>
            );
        });
    };
    const handleProcessBtn = () => {
        return (
            <Grid item md={12}>
                <Grid
                    item
                    md={12}
                    style={{
                        marginLeft: '1.5%',
                        marginRight: '1.5%',
                    }}
                    className={`${classes.textCenter}`}
                >
                    <Typography className={`${classes.RACPOPMsg} ${classes.marginTop}`}>
                        Please select the 1st row to process!
                    </Typography>
                </Grid>
                <Grid
                    container
                    className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
                >
                    <RACButton
                        className={`${classes.spacerMR2} ${classes.foc}`}
                        size="small"
                        variant="contained"
                        color="primary"
                        isRounded={false}
                        onClick={() => setprocesspopUp(false)}
                    // onClick={() => handleModels('validateNonCashIsOpen',false)}
                    >
                        Ok
                    </RACButton>
                </Grid>
            </Grid>
        );
    };
    function HandleProcessModelCard() {
        return (
            <RACModalCard
                isOpen={processpopUp}
                maxWidth="xs"
                closeIcon={true}
                borderRadius="20px"
                onClose={() => setprocesspopUp(false)}
                // eslint-disable-next-line react/no-children-prop
                children={handleProcessBtn()}
            // title="Validation Message"
            />
        );
    }
    const redirectPageToReconcil = () => {
        setDropReconciliationSummaryDisable(false)
    }
    const noDropFoundModal = () => {
        return (
            <Grid item md={12}>
                <Grid
                    item
                    md={12}
                    style={{
                        marginLeft: '1.5%',
                        marginRight: '1.5%',
                    }}
                    className={`${classes.textCenter}`}
                >
                    <Typography className={`${classes.RACPOPMsg} ${classes.marginTop}`}>
                        There is no drop that need to be validated
                    </Typography>
                </Grid>
                <Grid
                    container
                    className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
                >
                    <RACButton
                        className={`${classes.spacerMR2} ${classes.foc}`}
                        size="small"
                        variant="contained"
                        color="primary"
                        isRounded={false}
                        onClick={() => history.push(`/dashboard/homepage`)}
                    >
                        Ok
                    </RACButton>
                </Grid>
            </Grid>
        );
    };
    return dropReconciliationSummaryDisable == false ? (
        <>
            {/* Parent container */}
            <Grid style={{ marginBottom: '35%' }} className={classes.container}>
                <RACModalCard
                    isOpen={nodropfound}
                    maxWidth="xs"
                    closeIcon={true}
                    borderRadius="20px"
                    onClose={() => history.push(`/dashboard/homepage`)}
                    // eslint-disable-next-line react/no-children-prop
                    children={noDropFoundModal()}
                // title="Validation Message"
                />
                <Modal
                    isOpen={loader}
                    className={classes.loaderStyle}
                    id="openpopup"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    aria-hidden="true"
                >
                    <Grid>
                        <Grid>
                            <Grid className={classes.RACLoaderPage}>
                                <CircularProgress />
                            </Grid>
                        </Grid>
                    </Grid>
                </Modal>
                {/*Heading SubContainer*/}
                <HandleProcessModelCard />
                <Grid style={{ width: '100%', float: 'left' }}>
                    <Grid className={classes.colMd12mt3}>
                        <Grid className={classes.row} style={{ marginBottom: '3%' }}>
                            {/* Drawer total starts here */}
                            <Typography variant="h4" className={classes.header}>
                                Drops to be Validated by Business Day
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    style={{
                        width: '55%',
                        marginRight: '22.5%',
                        marginLeft: '22.5%',
                        float: 'left',
                        marginBottom:'100px'
                    }}
                >
                    <Card className={classes.card}>
                        <CardContent className={classes.cardBody}>
                            <RACTable
                                className={`${classes.table} ${classes.tableBorder} ${classes.mb0}`}
                                renderTableHead={renderDropDetailsHead}
                                renderTableContent={renderDropDetailsContent}
                            />
                        </CardContent>
                    </Card>
                    <Grid container classes={{
                        root: clsx(classes.customerToolbar, classes.fixedBottom),
                    }}
                        style={{ zIndex: 0 }}>
                        <Grid className={classes.footerFixed}>
                            <Grid className={classes.floatLeft}>
                                <RACButton
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => history.push(`/dashboard/homepage`)}
                                >
                                    Cancel
                                </RACButton>
                            </Grid>

                            {/* <Grid style={{ float: "right"}}>
            <RACButton variant="contained" color="primary">
              Save
            </RACButton>
          </Grid> */}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            
        </>
    ) : (
            <DropReconciliationSummary redirectPageToReconcil={redirectPageToReconcil} dropSummaryDetail={secondPageValue} />
    );
}
