/* eslint-disable sonarjs/no-nested-template-literals */
/* eslint-disable prettier/prettier */
/* eslint-disable  no-console */
/* eslint-disable  sonarjs/no-redundant-boolean */
/* eslint-disable  sonarjs/no-duplicate-string */
/* eslint-disable  react-hooks/exhaustive-deps */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable   sonarjs/cognitive-complexity*/
/* eslint-disable   sonarjs/prefer-immediate-return*/
/* eslint-disable  sonarjs/no-use-of-empty-return-value */
/* eslint-disable  @typescript-eslint/no-empty-function */
/* eslint-disable  no-debugger */


import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  RACButton,
  List,
  ListItem,
  RACTable,
  RACTableCell,
  RACTableRow,
  RACCheckBox,
  RACTextbox,
  RACModalCard
} from '@rentacenter/racstrap';
import { customerStyles } from '../DropReconciliation/customerStyles';
// import { TotalsSummary } from "./TotalSummary";
// import { DropReconcilationTotalsSummary } from "./DropTotalSummary";
// import { DropLists } from "./DropList";
import { DropReconciliationDetails } from '../DropReconciliation/DropReconciliationDetail';
import { CashmgmtContext } from '../../context/CashManagementContext';
import { getDrawerDetail } from '../../api/user';
import { CashmgmtDropDetailContext } from '../../context/CashMgmtContext';
import moment from 'moment'
import clsx from 'clsx';

export function DropReconciliationSummary(props: any) {
  const classes = customerStyles();
  const history = useHistory();
  const {
    dropReconciliationData,
    setDropReconciliationData
  } = useContext(CashmgmtContext);
  const {
    currentTotalDropDetail, setcurrentTotalDropDetail
  } = useContext(CashmgmtDropDetailContext);
  const [overShortExplanationNotes, setOverShortExplanationNotes] =
    useState('');
  const [dropSummaryDetails, setDropSummaryDetails] = useState<any[]>([])
  const [dropSummaryTotals, setDropSummaryTotals] = useState({
    totalCash: 0.0,
    totalNonCash: 0.0,
    overShortCash: 0.0,
    overShortNonCash: 0.0,
  });
  const [dropReconSummaryTotals, setDropReconSummaryTotals] = useState({
    cash: 0.0,
    nonCash: 0.0,
    addInNonCash: 0.0,
    totalCash: 0.0,
    overShort: 0.0
  });
  const [partialReconcilledData, setPartialReconcilledData] = useState({
    cash: 0.0,
    nonCash: 0.0,
    addInNonCash: 0.0,
    totalCash: 0.0,
    overShort: 0.0
  });
  const [modals, setModals] = useState({
    OverShortAmountIsOpen: false,
    overShortTotalIsOpen: false
  });
  function handleModels(key: string, value: boolean) {
    // console.log('Model value',key,value)
    setModals({ ...modals, [key]: value });
  }
  const dropSummaryValidateBtn = async (activityId: any, index: any, mode?: any, stage: any = 'VALIDATED') => {
    try {
      const dropSummaryTotalsObj = {
        addInNonCash: dropReconSummaryTotals.addInNonCash,
        cash: dropReconSummaryTotals.cash + partialReconcilledData.cash,
        nonCash: dropReconSummaryTotals.nonCash + partialReconcilledData.nonCash,
        overShort: dropReconSummaryTotals.overShort + partialReconcilledData.overShort,
        totalCash: dropReconSummaryTotals.totalCash + partialReconcilledData.totalCash,
      }
      const dataObj: any = dropReconciliationData
      dataObj.dropSummaryDetails = dropSummaryDetails;
      dataObj.dropSummaryTotals = dropSummaryTotals;
      dataObj.dropReconSummaryTotals = dropReconSummaryTotals;
      dataObj.validateDropId = activityId;
      dataObj.validateIndex = index;
      dataObj.secondPage = historyData?.secondPage
      // setDropReconSummaryTotals({
      //   ...dropReconSummaryTotals,
      //   cash: Number(dropReconSummaryTotals.cash) + Number(dropReconciliationData?.dropReconSummaryTotals.cash) + Number(dropReconValues.dropAmount.cash),
      //   nonCash: Number(dropReconSummaryTotals.nonCash) + Number(dropReconciliationData?.dropReconSummaryTotals.nonCash) + Number(dropReconValues.dropAmount.nonCash),
      //   totalCash: Number(dropReconSummaryTotals.totalCash) + AddtionOfdropAmount,
      //   addInNonCash: Number(dropReconSummaryTotals.addInNonCash) + Number(dropReconciliationData?.dropReconSummaryTotals.addInNonCash) + Math.abs(Number(dropReconValues.over_Short.nonCash)),
      //   overShort: Number(dropReconSummaryTotals.addInNonCash) + (Number(dropReconciliationData?.dropReconSummaryTotals.overShort) + Number(dropReconValues.over_Short.cash)) + Number(dropReconValues.over_Short.nonCash)
      // })
      setDropReconciliationData(dataObj);
      const isValidatedDrop: any = dropReconciliationData?.dropDetails?.some((el: any) => el.drawerManagementId == activityId)
      debugger
      if(mode =='RE-VALIDATE')
        history.push({ pathname: '/cashmgmt/reconcil/detail', state: { mode: 'RE-VALIDATE' }  })
      else if (mode == 'VIEW' && !isValidatedDrop) {
        history.push({ pathname: '/cashmgmt/reconcil/detail', state: { mode: 'VIEW', state :stage =='RE-VALIDATE'?'VIEW VALIDATE': undefined } })
        return
      } else if (mode == 'VIEW' && isValidatedDrop) {
        currentTotalDropDetail
        const recData = currentTotalDropDetail?.totalDropSummaryCopy?.filter((el: any) => el.drawerManagementId == activityId)
        const recActualData = dropReconciliationData?.dropDetails?.filter((el: any) => el.drawerManagementId == activityId)
        const data: any = {
          activityId: activityId,
          activityDate: recData[0].activityDate,
          notes: recActualData[0].dropNotes,
          envelopNumber: recData[0].envelopNumber,
          overShortNotes: recActualData[0].overShortDropNotes,
          isFinalDrop: recData[0].isFinalDrop,
          overOrShortCash: recData[0].overOrShortCash,
          overOrShortNonCash: recData[0].overOrShortNonCash,
          totalCash: recData[0].totalCash,
          totalNonCash: recData[0].totalNonCash,
          verifiedBy: recData[0].verifiedBy,
          droppedBy: recData[0].entCreatedBy == null ? recData[0].createdBy : recData[0].entCreatedBy,
          nonCashDetails: recActualData[0].nonCashDetails,
          cashDetailsRes: recActualData[0].qauntityDetails,
          denominationActualQuantity: recActualData[0].denominationActualQuantity,
          denominationExpectedQuantity: recActualData[0].denominationExpectedQuantity,
          over_Short: recActualData[0].over_Short,
          dropAmount: recActualData[0].dropAmount,
        }
        console.log(data)
        history.push({ pathname: '/cashmgmt/reconcil/detail', state: { mode: 'VIEW', data: data, stage: 'VALIDATED', state :stage =='RE-VALIDATE'?'VIEW VALIDATE': undefined  } })
        return
      }
      else
        history.push({ pathname: '/cashmgmt/reconcil/detail' })

      // setValidateDropId(activityId);
      // setvalidateIndex(index)
      // setDropReconciliationDetails(true);
    } catch (e) {
      console.log(e)
    }

  };
  const historyData: any = history.location?.state
  useEffect(() => {
    debugger
    console.log(historyData, "historyData in the summary");
    
    console.log(dropReconciliationData?.dropSummaryDetails, "dropReconciliationData?.dropSummaryDetails");
    console.log(dropReconciliationData, "dropReconciliationData");
    console.log(currentTotalDropDetail, "currentTotalDropDetail");
    console.log(partialReconcilledData,"seftPartialReconcilledData");
    console.log(dropReconSummaryTotals,"DropReconSummaryTotals");
    let dropSummaryDetail: any = []

    if(historyData=='RE-VALIDATED' && dropReconciliationData.tempObj){
console.log('RE-VALIDATED',partialReconcilledData );
console.log(dropReconciliationData,"dropReconciliationData");
const dropReconValues = JSON.parse(JSON.stringify( dropReconciliationData.tempObj));
dropReconciliationDetailsReValidationCompleted(dropReconciliationData.tempObj)
dropSummaryDetail = dropReconciliationData?.dropSummaryDetails
const AddtionOfdropAmount =  Number(dropReconValues.dropAmount.cash) + Number(dropReconValues.dropAmount.nonCash);
setDropReconSummaryTotals({
  ...dropReconSummaryTotals,
  cash: Number(dropReconSummaryTotals.cash)  + Number(dropReconValues.dropAmount.cash),
  nonCash: Number(dropReconSummaryTotals.nonCash) +  + Number(dropReconValues.dropAmount.nonCash),
  totalCash: Number(dropReconSummaryTotals.totalCash) + AddtionOfdropAmount,
  addInNonCash: Number(dropReconSummaryTotals.addInNonCash) +  Math.abs(Number(dropReconValues.over_Short.nonCash)),
  overShort: Number(dropReconSummaryTotals.overShort) +  Number(dropReconValues.over_Short.nonCash) + Number(dropReconValues.over_Short.cash)
})
/* 
  setDropReconSummaryTotals({
        cash: curDropReconcilledcash += el.reconcilledTotalCash ? Number(el.reconcilledTotalCash) : 0.00,
        nonCash: curDropReconcillednonCash += el.reconcilledTotalNonCash ? Number(el.reconcilledTotalNonCash) : 0.00,
        addInNonCash: curDropReconcilledaddInNonCash,
        totalCash: curDropReconcilledtotalCash = Number(curDropReconcilledcash) + Number(curDropReconcillednonCash),
        overShort: curDropReconcilledoverShort+= (el.reconcilledOverOrShortCash || el.reconcilledOverOrShortNonCash) ? Number(el.reconcilledOverOrShortCash) + Number(el.reconcilledOverOrShortNonCash) : 0.00
      })
 */
let dropReconcilledcash = 0.0;
let dropReconcillednonCash = 0.0;
let dropReconcilledaddInNonCash = 0.0;
let dropReconcilledtotalCash = 0.0;
let dropReconcilledoverShort = 0.0
dropSummaryDetail.forEach((el: any) => {
  console.log(el.drawerManagementId != dropReconciliationData.validateDropId,"el.drawerManagementId != dropReconciliationData.tempObj.validateDropId");
  console.log(dropReconciliationData.validateDropId,"value check",el.drawerManagementId);
  
  if (el.dropReconciliationId && (el.drawerManagementId != dropReconciliationData.validateDropId)) {
    console.log(el,"The e1 foreach")
    dropReconcilledcash += el.reconcilledTotalCash ? Number(el.reconcilledTotalCash) : 0.00
    dropReconcillednonCash += el.reconcilledTotalNonCash ? Number(el.reconcilledTotalNonCash) : 0.00
    dropReconcilledaddInNonCash += 0.00
    dropReconcilledtotalCash += el.reconcilledTotalCash +el.reconcilledTotalNonCash 
    dropReconcilledoverShort += (el.reconcilledOverOrShortCash || el.reconcilledOverOrShortNonCash) ? Number(el.reconcilledOverOrShortCash) + Number(el.reconcilledOverOrShortNonCash) : 0.00
  }
})
setPartialReconcilledData({
  cash: dropReconcilledcash,
  nonCash: dropReconcillednonCash,
  addInNonCash: dropReconcilledaddInNonCash,
  totalCash: dropReconcilledtotalCash,
  overShort: dropReconcilledoverShort
})
// 1.0 -- setting the currently reconciliated value into the currentTotalDropDetail -- starts here
 const dropSummaryDetailCopy : any = JSON.parse(JSON.stringify(currentTotalDropDetail?.totalDropSummaryCopy))
 const dropReconValue = JSON.parse(JSON.stringify( dropReconciliationData.tempObj));
 if(dropSummaryDetailCopy.length> 0){
  const arr : any= [];
  dropSummaryDetailCopy.forEach((element: any )=> {
    if(element.drawerManagementId == dropReconciliationData.validateDropId){
      element.reconcilledOverOrShortCash = dropReconValue.over_Short.cash
      element.reconcilledOverOrShortNonCash = dropReconValue.over_Short.nonCash
    }
    arr.push(element)
  });
  setcurrentTotalDropDetail({...currentTotalDropDetail,totalDropSummaryCopy : arr})
 }
// 1.0 -- setting the currently reconciliated value into the currentTotalDropDetail -- ends here 

    }
    if (historyData == 'REDIRECT') {
      const arr = JSON.parse(JSON.stringify(currentTotalDropDetail.totalDropSummary))
      dropSummaryDetail = arr
      // const tempPropSummaryDetail: any = dropSummaryDetail.map((obj: any) => {
      //   obj.reconDropAmount = "0.00";
      //   obj.validateChecked = false
      //   return obj
      // })
      setDropSummaryDetails(dropSummaryDetail);
      // setDropReconciliationData(null)
    }
    if (historyData == 'VALIDATED') {
      dropReconciliationDetailsCompleted(dropReconciliationData.tempObj || [])
      dropSummaryDetail = dropReconciliationData?.dropSummaryDetails
    }
    if (historyData && historyData?.secondPage || (historyData=='RE-VALIDATED' && dropReconciliationData?.secondPage)) {
      setDropReconciliationData({ ...dropReconciliationData, secondPage: historyData?.secondPage ? historyData?.secondPage :dropReconciliationData?.secondPage })
      dropSummaryDetail = historyData?.secondPage ? historyData?.secondPage :dropReconciliationData?.secondPage
      // eslint-disable-next-line sonarjs/no-identical-functions
      const tempPropSummaryDetail = dropSummaryDetail.map((obj: any) => {
        obj.reconDropAmount = "0.00";
        obj.validateChecked = false
        return obj
      })
      setDropSummaryDetails(tempPropSummaryDetail);
    }

    console.log("props second page value", dropSummaryDetail)
    let totalCash = 0.0;
    let totalNonCash = 0.0;
    let overShortCash = 0.0;
    let overShortNonCash = 0.0;
    // let overShortCashCopy = 0.0;
    // let overShortNonCashCopy = 0.0;
    const dropSummaryDetailCopy = JSON.parse(JSON.stringify(dropSummaryDetail))
    // const dropSummaryDetailCopyData = dropSummaryDetailCopy.map((el:any)=>{
    //   el.overShortCashCopy = el.overOrShortCash
    //   el.overShortNonCashCopy = el.overOrShortNonCash
    // })
    if (historyData == 'REDIRECT') {
      setDropReconciliationData({ ...dropReconciliationData, dropSummaryDetailCopy: dropSummaryDetailCopy })
    }

    // setDropSummaryCurrentTotal(dropSummaryDetailCopy)
    console.log(dropSummaryDetail,"props second page valueass");
    
    dropSummaryDetail.map((value: any) => {

      totalCash += parseFloat(value.totalCash !== undefined && value.totalCash !== null ? value.totalCash : '0');
      totalNonCash += parseFloat(value.totalNonCash !== undefined && value.totalNonCash !== null ? value.totalNonCash : '0');
      overShortCash += parseFloat(value.overOrShortCash !== undefined && value.overOrShortCash !== null ? value.overOrShortCash : '0');
      overShortNonCash += parseFloat(value.overOrShortNonCash !== undefined && value.overOrShortNonCash !== null ? value.overOrShortNonCash : '0');
    });
    setDropSummaryTotals({
      ...dropSummaryTotals,
      totalCash: totalCash,
      totalNonCash: totalNonCash,
      overShortCash: overShortCash,
      overShortNonCash: overShortNonCash,
    });
    const count = dropSummaryDetailCopy.filter((el: any) => el.validateChecked == true)
    let dropReconcilledcash = 0.0;
    let dropReconcillednonCash = 0.0;
    let dropReconcilledaddInNonCash = 0.0;
    let dropReconcilledtotalCash = 0.0;
    let dropReconcilledoverShort = 0.0
    // reconcilledOverOrShortCash: 0reconcilledOverOrShortNonCash: 0reconcilledTotalCash: 0reconcilledTotalNonCash: 17.31
    /* eslint-disable */
    dropSummaryDetail.forEach((el: any) => {
      if (el.dropReconciliationId) {
        console.log(el)
        dropReconcilledcash += el.reconcilledTotalCash ? Number(el.reconcilledTotalCash) : 0.00
        dropReconcillednonCash += el.reconcilledTotalNonCash ? Number(el.reconcilledTotalNonCash) : 0.00
        dropReconcilledaddInNonCash += 0.00
        dropReconcilledoverShort += (el.reconcilledOverOrShortCash || el.reconcilledOverOrShortNonCash) ? Number(el.reconcilledOverOrShortCash) + Number(el.reconcilledOverOrShortNonCash) : 0.00
      }
    })
    console.log('Total Cash.....', dropReconcilledcash, dropReconcillednonCash, dropReconcilledtotalCash, dropReconcilledoverShort);

    dropReconcilledtotalCash = Number(dropReconcilledcash) + Number(dropReconcillednonCash)
    if(historyData!='RE-VALIDATED'){
      console.log("Into the file");
    setPartialReconcilledData({
      cash: dropReconcilledcash,
      nonCash: dropReconcillednonCash,
      addInNonCash: dropReconcilledaddInNonCash,
      totalCash: dropReconcilledtotalCash,
      overShort: dropReconcilledoverShort
    })
}
    let curDropReconcilledcash = 0.0;
    let curDropReconcillednonCash = 0.0;
    let curDropReconcilledaddInNonCash = 0.0;
    let curDropReconcilledtotalCash = 0.0;
    let curDropReconcilledoverShort = 0.0
    const isReconcilledNow = dropReconciliationData?.dropSummaryDetails?.filter((el: any) => !el.dropReconciliationId && el.validateChecked)

    if (isReconcilledNow && isReconcilledNow.length > 0) {

      isReconcilledNow.forEach((el: any) => {
        curDropReconcilledcash += el.reconcilledTotalCash ? Number(el.reconcilledTotalCash) : 0.00
        curDropReconcillednonCash += el.reconcilledTotalNonCash ? Number(el.reconcilledTotalNonCash) : 0.00
        curDropReconcilledaddInNonCash += 0.0;
        curDropReconcilledoverShort += (el.reconcilledOverOrShortCash || el.reconcilledOverOrShortNonCash) ? Number(el.reconcilledOverOrShortCash) + Number(el.reconcilledOverOrShortNonCash) : 0.00
      })
      curDropReconcilledtotalCash = Number(curDropReconcilledcash) + Number(curDropReconcillednonCash)

      setDropReconSummaryTotals({
        cash: curDropReconcilledcash,
        nonCash: curDropReconcillednonCash,
        addInNonCash: curDropReconcilledaddInNonCash,
        totalCash: curDropReconcilledtotalCash,
        overShort: curDropReconcilledoverShort
      })
    }

    console.log('Total Cash', totalCash, totalNonCash);
    console.log("prop value", dropSummaryDetail[0]);

  }, []);
  console.log(partialReconcilledData, "partialReconcilledData");
  console.log(dropReconSummaryTotals,"dropReconSummaryTotals");
  
  
  const getStoreTransactionNonCash = () => {
    //   const totalNonCash = ( 
    //       parseFloat(  
    //           dropReconciliationData?.dropSummaryDetailCopy !== undefined &&
    //           dropReconciliationData?.dropSummaryDetailCopy?.totalNonCash !== undefined &&
    //           dropReconciliationData?.dropSummaryDetailCopy?.totalNonCash!== null 
    //           ? (dropReconciliationData?.dropSummaryDetailCopy?.totalNonCash).toString() : '0' ) +
    //           parseFloat(  
    //           dropReconciliationData?.dropSummaryDetailCopy !== undefined &&
    //           dropReconciliationData?.dropSummaryDetailCopy?.overShortNonCash !== undefined &&
    //           dropReconciliationData?.dropSummaryDetailCopy?.overShortNonCash!== null && 
    //           dropReconciliationData?.dropSummaryDetailCopy?.overShortNonCash.toString().trim()
    //           ? (dropReconciliationData?.dropSummaryDetailCopy?.overShortNonCash).toString() : '0') 
    //   );
    let totalNonCash = 0
    currentTotalDropDetail && currentTotalDropDetail.totalDropSummaryCopy && currentTotalDropDetail?.totalDropSummaryCopy?.forEach((el: any) => {
      totalNonCash += el.totalNonCash
      if (el.overOrShortNonCash && Number(el.overOrShortNonCash) != 0) {
        totalNonCash += Math.abs(Number(el.overOrShortNonCash))
      }
      // else if ((el.overOrShortNonCash && Number(el.overOrShortNonCash) > 0)) {
      //   totalNonCash -= Number(el.overOrShortNonCash)
      // }
    })
    return totalNonCash.toFixed(2);

  };
  const getStoreTransactionCash = () => {
    console.log(currentTotalDropDetail)

    let totalCash = 0

    currentTotalDropDetail && currentTotalDropDetail.totalDropSummaryCopy && currentTotalDropDetail?.totalDropSummaryCopy?.forEach((el: any) => {
      totalCash += el.totalCash

      if (el.overOrShortCash && Number(el.overOrShortCash) < 0) {
        totalCash += Math.abs(Number(el.overOrShortCash))
      } else if ((el.overOrShortCash && Number(el.overOrShortCash) > 0)) {
        totalCash -= Number(el.overOrShortCash)
      }
    })
    // const totalCash = ( 
    //     parseFloat(  
    //         dropSummaryTotals !== undefined &&
    //         dropSummaryTotals.totalCash !== undefined &&
    //         dropSummaryTotals.totalCash!== null &&
    //         dropSummaryTotals.totalCash.toString().trim()
    //         ? (dropSummaryTotals.totalCash).toString() : '0' )  + 
    //         parseFloat(  
    //         dropSummaryTotals !== undefined &&
    //         dropSummaryTotals.overShortCash !== undefined &&
    //         dropSummaryTotals.overShortCash!== null && 
    //         dropSummaryTotals.overShortCash.toString().trim()
    //          ? (dropSummaryTotals.overShortCash).toString() : '0')
    // );

    return totalCash.toFixed(2);
  };
  const getStoreTransactionTotal = () => {
    const total = parseFloat(getStoreTransactionCash()) + parseFloat(getStoreTransactionNonCash())
    // dropReconciliationData && dropReconciliationData.dropSummaryDetailCopy && dropReconciliationData?.dropSummaryDetailCopy?.forEach((el: any) => {
    //   total += el.totalCash
    //   total += el.totalNonCash
    // })
    // const total= ( 
    //    ( parseFloat(  
    //         dropSummaryTotals !== undefined &&
    //         dropSummaryTotals.totalCash !== undefined &&
    //         dropSummaryTotals.totalCash!== null &&
    //         dropSummaryTotals.totalCash.toString().trim()
    //         ? (dropSummaryTotals.totalCash).toString() : '0' )  - 
    //         parseFloat(  
    //         dropSummaryTotals !== undefined &&
    //         dropSummaryTotals.overShortCash !== undefined &&
    //         dropSummaryTotals.overShortCash!== null && 
    //         dropSummaryTotals.overShortCash.toString().trim()
    //          ? (dropSummaryTotals.overShortCash).toString() : '0')) +
    //          ( parseFloat(  
    //             dropSummaryTotals !== undefined &&
    //             dropSummaryTotals.totalNonCash !== undefined &&
    //             dropSummaryTotals.totalNonCash!== null 
    //             ? (dropSummaryTotals.totalNonCash).toString() : '0' ) - 
    //             parseFloat(  
    //             dropSummaryTotals !== undefined &&
    //             dropSummaryTotals.overShortNonCash !== undefined &&
    //             dropSummaryTotals.overShortNonCash!== null && 
    //             dropSummaryTotals.overShortNonCash.toString().trim()
    //             ? (dropSummaryTotals.overShortNonCash).toString() : '0') )

    // );
    return total.toFixed(2);
  };
  const OverShortTotalSummary = () => {
    const total =
      (Number(dropSummaryTotals.totalCash) +
        Number(dropSummaryTotals.totalNonCash)) - parseFloat(getStoreTransactionTotal())
    return total;
  }
  function overShortAck_NoBtn() {
    handleModels('OverShortAmountIsOpen', false);
  }
  function overShortAck_YesBtn() {
    setModals({
      ...modals,
      overShortTotalIsOpen: true,
      OverShortAmountIsOpen: false,
    });
  }
  function handleOverShortExplanationNotes(e: any) {
    setOverShortExplanationNotes(e.target.value.trim().length == 0 ? e.target.value.trim() : e.target.value);
  }
  function handleOverShortTotal_CancelBtn() {
    setOverShortExplanationNotes('');
    handleModels('overShortTotalIsOpen', false)

  }
  const overShortTotalIsOpenPopUp = () => {
    return (
      <Grid style={{ width: '100%' }}>
        <Grid className={classes.row}>
          <Grid style={{ width: '40%' }}>
            <Typography
              className={classes.RACPOPMsg}
              style={{ marginTop: '-1rem' }}
            >
              Over / Short Total
            </Typography>
            <Grid className={classes.row} style={{ marginTop: '8%' }}>
              <Grid style={{ width: '40%' }}>
                <Typography>Non-Cash</Typography>
              </Grid>
              <Grid style={{ width: '50%' }}>
                <RACTextbox
                  disabled={true}
                  value={`${Number(dropReconciliationData?.overShortCashDetails?.overShortNonCash) >= 0 ? `$${Number(dropReconciliationData?.overShortCashDetails?.overShortNonCash).toFixed(2)}` : `$${Math.abs(dropReconciliationData?.overShortCashDetails?.overShortNonCash).toFixed(2)}`}`}
                  className={`${classes.overShort}`}
                  maxlength={9}
                  type="number"
                // name="$001"
                // id="$001"
                // Onblur={(e)=>e.preventDefault()}
                />
              </Grid>
            </Grid>
            <Grid className={classes.row} style={{ marginTop: '8%' }}>
              <Grid style={{ width: '40%' }}>
                <Typography>Cash</Typography>
              </Grid>
              <Grid style={{ width: '50%' }}>
                <RACTextbox
                  disabled={true}
                  className={`${classes.overShort}`}
                  value={`${Number(dropReconciliationData?.overShortCashDetails?.overShortCash) > 0 ? `$${Number(dropReconciliationData?.overShortCashDetails?.overShortCash).toFixed(2)}` : `$${Math.abs(Number(dropReconciliationData?.overShortCashDetails?.overShortCash)).toFixed(2)}`}`}
                  maxlength={9}
                  type="number"
                // name="$001"
                // id="$001"
                // Onblur={(e)=>e.preventDefault()}
                // OnChange={(e) => handleCurrentTillInp(e)}
                />
              </Grid>
            </Grid>
            <Grid className={classes.row} style={{ marginTop: '8%' }}>
              <Grid style={{ width: '40%' }}>
                <Typography>Total</Typography>
              </Grid>
              <Grid style={{ width: '50%' }}>
                <RACTextbox
                  disabled={true}
                  className={`${classes.overShort}`}
                  value={`${Number(dropReconciliationData?.overShortCashDetails?.overShortCash) + Number(dropReconciliationData?.overShortCashDetails?.overShortNonCash) > 0 ? `$${(Number(dropReconciliationData?.overShortCashDetails?.overShortCash) + Number(dropReconciliationData?.overShortCashDetails?.overShortNonCash)).toFixed(2)}` : `$${Math.abs((Number(dropReconciliationData?.overShortCashDetails?.overShortCash) + Number(dropReconciliationData?.overShortCashDetails?.overShortNonCash))).toFixed(2)}`}`}
                  maxlength={9}
                  type="number"
                // name="$001"
                // id="$001"
                // Onblur={(e)=>e.preventDefault()}
                // OnChange={(e) => handleCurrentTillInp(e)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid style={{ width: '58%', marginRight: '2%' }}>
            <Typography
              className={classes.RACPOPMsg}
              style={{ marginTop: '-1rem' }}
            >
              Over / Short Explanation Notes
              <span style={{ color: 'red' }}> *</span>
            </Typography>
            <textarea
              style={{
                marginTop: '5%',
                width: '100%',
                padding: '6px',
                paddingBottom: '31px',
                resize: 'none',
                border: '1px solid #C4C4C4',
                paddingTop: '5px',
                paddingRight: '5px',
                // marginRight:"4px",
                // paddingLeft:"20px",
                borderRadius: '0.6rem',
                backgroundColor: '#fff',
                transition:
                  'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                fontSize: '14px',
                fontFamily: 'OpenSans-semibold',
              }}
              // onChange={(e) => handleDropNotesInp(e)}
              rows={6}
              cols={54}
              value={overShortExplanationNotes}
              onChange={(e) => handleOverShortExplanationNotes(e)}
              className={classes.dropNotes}
            ></textarea>
          </Grid>
        </Grid>
        <Grid
          style={{ float: 'right' }}
          container
          className={`${classes.explanationContent} ${classes.spacerMT4}`}
        >
          <RACButton
            className={`${classes.spacerMR2} ${classes.foc}`}
            size="small"
            variant="outlined"
            color="primary"
            isRounded={false}
            onClick={() => handleOverShortTotal_CancelBtn()}
          >
            Cancel
          </RACButton>
          <RACButton
            disabled={overShortExplanationNotes != '' ? false : true}
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => handleOverShortTotal_SaveBtn()}
          >
            Save
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  function handleOverShortTotal_SaveBtn() {
    handleModels('overShortTotalIsOpen', false);
    setDropReconciliationData({ ...dropReconciliationData, overShortExplanationNotes: overShortExplanationNotes })
    history.push("/cashmgmt/reconcil/depositreceipt");
  }

  const renderDropListHead = () => {
    return (
      <React.Fragment>
        <RACTableCell>
          <Grid style={{ textAlign: 'center' }}>Drop</Grid>
          <Grid style={{ textAlign: 'center' }}>Envelope #</Grid>
        </RACTableCell>
        <RACTableCell
          style={{
            paddingBottom: '2.5%',
          }}
        >
          Drop Date
        </RACTableCell>
        <RACTableCell
          style={{
            // width:"8%",
            paddingBottom: '2.5%',
          }}
        >
          Drop Time
        </RACTableCell>
        <RACTableCell
          style={{
            width: '16%',
            paddingBottom: '2.5%',
          }}
        >
          User Name
        </RACTableCell>
        <RACTableCell
          style={{
            // width:"9%",
            paddingBottom: '2.5%',
            textAlign: 'center',
          }}
        >
          Over/Short
        </RACTableCell>
        <RACTableCell
          style={{
            // width:"15%",
            textAlign: 'center',
          }}
        >
          <Grid
          // style={{paddingLeft:"20px"}}
          >
            Expected Drop
          </Grid>
          <Grid
          //  style={{paddingLeft:"15px"}}
          >
            Amount
          </Grid>
        </RACTableCell>
        <RACTableCell style={{ textAlign: 'center' }}>
          <Grid>Reconciled Drop</Grid>
          <Grid> Amount</Grid>
        </RACTableCell>
        <RACTableCell
          style={{
            // width:"8%",
            paddingLeft: '2.8%',
            paddingBottom: '2.5%',
            textAlign: 'left',
          }}
        >
          Validate
        </RACTableCell>
        <RACTableCell
          style={{
            // width:"5.5%",
            paddingBottom: '2.5%',
          }}
        >
          Validated
        </RACTableCell>
      </React.Fragment>
    );
  };
  
  const renderDropListContent = (): any => {
    return dropSummaryDetails?.map((data: any, index: any) => {
      console.log(data)
      const date: any = data.activityDate?.split('T')
      data.validateChecked = data.dropReconciliationId ? true : data.validateChecked
      // data.overOrShortNonCash = data.validateChecked ? -Math.abs(Number(data.reconcilledOverOrShortNonCash)) : -Math.abs(Number(data.overOrShortNonCash))
      // data.overOrShortCash = data.validateChecked ? data.reconcilledOverOrShortCash : data.overOrShortCash
      const over_ShortCash = data.validateChecked ? -Math.abs(Number(data.reconcilledOverOrShortNonCash)) : -Math.abs(Number(data.overOrShortNonCash))
      const over_ShortNonCash = data.validateChecked ? data.reconcilledOverOrShortCash : data.overOrShortCash
      data.reconDropAmount = data.validateChecked ? Number(data.reconcilledTotalCash) + Number(data.reconcilledTotalNonCash) : data.reconDropAmount


      return (
        <>
          <React.Fragment>
            <RACTableRow className={classes.tableRow}>
              <RACTableCell>
                <Typography style={{ textAlign: 'center', cursor: 'pointer' }}>
                  <span style={{ color: '#3182FF', cursor: 'pointer' }}>
                    <a onClick={() => dropSummaryValidateBtn(data.drawerManagementId, index, 'VIEW', data.validateChecked == true ? 'RE-VALIDATE': undefined)}>{data.envelopNumber}</a>{' '}
                  </span>
                </Typography>
              </RACTableCell>
              <RACTableCell>
                <Typography style={{ color: '#4a5174' }}>
                  {moment(date[0]).format('MM/DD/YYYY')}
                </Typography>
              </RACTableCell>
              <RACTableCell>
                <Typography style={{ color: '#4a5174' }}>
                  {moment(data.activityDate).format('hh:mm A')}
                </Typography>
              </RACTableCell>
              <RACTableCell>
                <Typography style={{ color: '#4a5174' }}>
                  {((!data.entCreatedBy || data.entCreatedBy.replaceAll('null', '').trim().length == 0) && (!data.createdBy || data.createdBy.replaceAll('null', '').trim().length == 0)) ? 'EodFinalDrop' : !data.createdBy || data.createdBy.replaceAll('null', '').trim().length == 0 ? data.entCreatedBy : data.createdBy}
                </Typography>
              </RACTableCell>
              <RACTableCell>
                <Typography style={{ color: '#4a5174', textAlign: 'center' }}>
                  {/* {(data.overOrShortNonCash + data.overOrShortCash) < 0 ? `($${-(data.overOrShortNonCash + data.overOrShortCash).toFixed(2)})` : `$${(data.overOrShortNonCash + data.overOrShortCash).toFixed(2)}`} */}
                  {Number(over_ShortCash + over_ShortNonCash) < 0 ? `($${Math.abs(Number(over_ShortNonCash + over_ShortCash)).toFixed(2)})` : `$${Number(over_ShortNonCash + over_ShortCash).toFixed(2)}`}
                </Typography>
              </RACTableCell>
              <RACTableCell>
                <Typography style={{ color: '#4a5174', textAlign: 'center' }}>
                  ${(
                    (parseFloat(data.totalCash) + parseFloat(data.totalNonCash)) - (parseFloat(data.overOrShortCash) + Number(data.overOrShortNonCash))
                  ).toFixed(2)}
                </Typography> 
              </RACTableCell>
              <RACTableCell>
                <Typography style={{ color: '#4a5174', textAlign: 'center' }}>
                  ${Number(data.reconDropAmount).toFixed(2)}
                </Typography>
              </RACTableCell>
              <RACTableCell>
                <RACButton
                  variant="contained"
                  style={{
                    color: 'blue',
                    backgroundColor: '#E0EAFF',
                    float: 'left',
                  }}
                  // disabled={data.validateChecked}
                  size="small"
                  onClick={() => {
                    dropSummaryValidateBtn(data.drawerManagementId, index,(data.validateChecked == true ?'RE-VALIDATE' :'VALIDATE'  ));
                  }}
                >
                  {data.validateChecked == false ?'Validate' : 'Re-Validate'}
                </RACButton>
              </RACTableCell>
              <RACTableCell style={{ textAlign: 'center', width: '5%' }}>
                <RACCheckBox
                  label=""
                  id="validated"
                  name="validated"
                  value="validated"
                  required={false}
                  checked={data.validateChecked}
                  disabled={true}
                ></RACCheckBox>
              </RACTableCell>
            </RACTableRow>
          </React.Fragment>
        </>
      );
    });

  };
  function OverShortAmountModalCard() {
    return (
      <RACModalCard
        isOpen={modals.OverShortAmountIsOpen}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => handleModels('OverShortAmountIsOpen', false)}
        // eslint-disable-next-line react/no-children-prop
        children={OverShortAmountPopUp()}
      // title="Validation Message"
      />);
  }
  const OverShortAmountPopUp = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          {/* //Including both cash and Noncash */}
          <Typography className={`${classes.RACPOPMsg} ${classes.marginTop}`}>
            There is an Over/Short amount of{' '}{Number(partialReconcilledData.overShort) + Number(dropReconSummaryTotals.overShort) < 0 ? `($${Math.abs(partialReconcilledData.overShort + dropReconSummaryTotals.overShort).toFixed(2)})` : `$${Number(partialReconcilledData.overShort + dropReconSummaryTotals.overShort).toFixed(2)}`}
            <Grid>Are you sure you want to continue?</Grid>
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
        >
          <RACButton
            className={`${classes.spacerMR2} ${classes.foc}`}
            size="small"
            variant="outlined"
            color="primary"
            isRounded={false}
            onClick={() => overShortAck_NoBtn()}
          // onClick={() => handleModels('validateNonCashIsOpen',false)}
          >
            No
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => overShortAck_YesBtn()}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  function handleFinalValidation(): any {

    calculateIndividualShortOfCashOrNonCash()

    if ((Number(partialReconcilledData.overShort) + Number(dropReconSummaryTotals.overShort)) != 0) {
      setModals({ ...modals, OverShortAmountIsOpen: true })
    } else {
      console.log("props", props);
      history.push("/cashmgmt/reconcil/depositreceipt");
    }

  }
  const calculateIndividualShortOfCashOrNonCash = () => {
    debugger
    const overShortCashDetails = { overShortCash: 0, overShortNonCash: 0, totalOverShort: 0 }
    const isAllReconcilledData = currentTotalDropDetail?.totalDropSummaryCopy?.filter((el: any) => el.dropReconciliationId != null)
    if (isAllReconcilledData && isAllReconcilledData.length > 0) {
      isAllReconcilledData.forEach((el: any) => {

        overShortCashDetails.overShortCash += Number(el.reconcilledOverOrShortCash)
        overShortCashDetails.overShortNonCash += Number(el.reconcilledOverOrShortNonCash)
      })
    }
    const dropReconSummaryTotalsVar = {
      cash: Number(dropReconSummaryTotals.cash) + Number(partialReconcilledData.cash),
      nonCash: Number(dropReconSummaryTotals.nonCash) + Number(partialReconcilledData.nonCash),
      totalCash: (Number(dropReconSummaryTotals.cash) + Number(dropReconSummaryTotals.nonCash)) + ((Number(partialReconcilledData.cash) + Number(partialReconcilledData.nonCash))),
      addInNonCash: Number(dropReconSummaryTotals.addInNonCash) + Number(partialReconcilledData.addInNonCash),
      overShort: Number(dropReconSummaryTotals.overShort) + (Number(partialReconcilledData.overShort))
    }
    if (currentTotalDropDetail?.totalDropSummaryCopy?.length == isAllReconcilledData?.length) {
      overShortCashDetails.totalOverShort += Number(overShortCashDetails.overShortCash) + Number(overShortCashDetails.overShortNonCash)
      setDropReconciliationData({ ...dropReconciliationData, overShortCashDetails, dropReconSummaryTotals: dropReconSummaryTotalsVar })
    } else {

      const uniqueDrops = Array.from(new Set(dropReconciliationData?.dropDetails?.filter((el: any) => el.drawerManagementId)))
      uniqueDrops.forEach((el: any) => {
        el.reconcilledOverOrShortCash = el.reconcilledOverOrShortCash || 0.00
        el.reconcilledOverOrShortNonCash = el.reconcilledOverOrShortNonCash || 0.00

        overShortCashDetails.overShortCash += Number(el.reconcilledOverOrShortCash)
        overShortCashDetails.overShortNonCash += Math.abs(Number(el.reconcilledOverOrShortNonCash))
      })
      overShortCashDetails.overShortNonCash = -Math.abs(overShortCashDetails.overShortNonCash)
      overShortCashDetails.totalOverShort += Number(overShortCashDetails.overShortCash) + Number(overShortCashDetails.overShortNonCash)
      setDropReconciliationData({ ...dropReconciliationData, overShortCashDetails, dropReconSummaryTotals: dropReconSummaryTotalsVar })
      console.log(uniqueDrops)
    }
  }
  const dropReconciliationDetailsCompleted = (dropReconValues: any) => {
    debugger;
    console.log("dropReconValues", dropReconValues);
    const AddtionOfdropAmount = dropReconciliationData?.dropReconSummaryTotals.totalCash + Number(dropReconValues.dropAmount.cash) + Number(dropReconValues.dropAmount.nonCash);
    setDropReconSummaryTotals({
      ...dropReconSummaryTotals,
      cash: Number(dropReconSummaryTotals.cash) + Number(dropReconciliationData?.dropReconSummaryTotals.cash) + Number(dropReconValues.dropAmount.cash),
      nonCash: Number(dropReconSummaryTotals.nonCash) + Number(dropReconciliationData?.dropReconSummaryTotals.nonCash) + Number(dropReconValues.dropAmount.nonCash),
      totalCash: Number(dropReconSummaryTotals.totalCash) + AddtionOfdropAmount,
      addInNonCash: Number(dropReconSummaryTotals.addInNonCash) + Number(dropReconciliationData?.dropReconSummaryTotals.addInNonCash) + Math.abs(Number(dropReconValues.over_Short.nonCash)),
      overShort: Number(dropReconSummaryTotals.overShort) + (Number(dropReconciliationData?.dropReconSummaryTotals.overShort) + Number(dropReconValues.over_Short.cash)) + Number(dropReconValues.over_Short.nonCash)
    })
    dropReconciliationData.dropSummaryDetails[dropReconValues.validateIndex].reconDropAmount = Number(dropReconValues.dropAmount.cash) + Number(dropReconValues.dropAmount.nonCash);
    dropReconciliationData.dropSummaryDetails[dropReconValues.validateIndex].validateChecked = true;
    // dropReconciliationData.dropSummaryDetails[dropReconValues.validateIndex].overOrShortNonCash = Number(dropReconValues.over_Short.nonCash);
    // dropReconciliationData.dropSummaryDetails[dropReconValues.validateIndex].overOrShortCash = Number(dropReconValues.over_Short.cash);

    dropReconciliationData.dropSummaryDetails[dropReconValues.validateIndex].reconcilledOverOrShortCash = Number(dropReconValues.over_Short.cash);
    dropReconciliationData.dropSummaryDetails[dropReconValues.validateIndex].reconcilledOverOrShortNonCash = Number(dropReconValues.over_Short.nonCash);
    dropReconciliationData.dropSummaryDetails[dropReconValues.validateIndex].reconcilledTotalCash = Number(dropReconValues.dropAmount.cash);
    dropReconciliationData.dropSummaryDetails[dropReconValues.validateIndex].reconcilledTotalNonCash = Number(dropReconValues.dropAmount.nonCash);

    setDropSummaryDetails(dropReconciliationData.dropSummaryDetails)
    if (!dropReconciliationData?.nonCashResponse && !dropReconciliationData?.denominationActualQuantity) {
      dropReconciliationData.nonCashResponse = dropReconValues?.nonCashDetailsRes;
      dropReconciliationData.denominationActualQuantity = [];
      dropReconciliationData.denominationActualQuantity.push(dropReconValues.tempObjdenominationActualQuantity)
    }
    else {
      dropReconValues?.nonCashDetailsRes?.forEach((obj: any) => {
        dropReconciliationData?.nonCashResponse?.push(obj);
      })
      dropReconciliationData.denominationActualQuantity.push(dropReconValues.tempObjdenominationActualQuantity);
    }
    if (!dropReconciliationData.dropDetails) {
      dropReconciliationData.dropDetails = []
      dropReconciliationData.dropDetails.push(dropReconValues.currentDropDetail)
    } else {
      dropReconciliationData.dropDetails.push(dropReconValues.currentDropDetail)
    }
    setDropReconciliationData(dropReconciliationData);
  }
  const dropReconciliationDetailsReValidationCompleted = (dropReconValues: any) => {
    debugger;
    console.log("dropReconValues", dropReconValues);
    const AddtionOfdropAmount = dropReconciliationData?.dropReconSummaryTotals.totalCash + Number(dropReconValues.dropAmount.cash) + Number(dropReconValues.dropAmount.nonCash);
    setDropReconSummaryTotals({
      ...dropReconSummaryTotals,
      cash: Number(dropReconSummaryTotals.cash) + Number(dropReconciliationData?.dropReconSummaryTotals.cash) + Number(dropReconValues.dropAmount.cash),
      nonCash: Number(dropReconSummaryTotals.nonCash) + Number(dropReconciliationData?.dropReconSummaryTotals.nonCash) + Number(dropReconValues.dropAmount.nonCash),
      totalCash: Number(dropReconSummaryTotals.totalCash) + AddtionOfdropAmount,
      addInNonCash: Number(dropReconSummaryTotals.addInNonCash) + Number(dropReconciliationData?.dropReconSummaryTotals.addInNonCash) + Math.abs(Number(dropReconValues.over_Short.nonCash)),
      overShort: Number(dropReconSummaryTotals.overShort) + (Number(dropReconciliationData?.dropReconSummaryTotals.overShort) + Number(dropReconValues.over_Short.cash)) + Number(dropReconValues.over_Short.nonCash)
    })
    dropReconciliationData.dropSummaryDetails[dropReconValues.validateIndex].reconDropAmount = Number(dropReconValues.dropAmount.cash) + Number(dropReconValues.dropAmount.nonCash);
    dropReconciliationData.dropSummaryDetails[dropReconValues.validateIndex].validateChecked = true;
    // dropReconciliationData.dropSummaryDetails[dropReconValues.validateIndex].overOrShortNonCash = Number(dropReconValues.over_Short.nonCash);
    // dropReconciliationData.dropSummaryDetails[dropReconValues.validateIndex].overOrShortCash = Number(dropReconValues.over_Short.cash);

    dropReconciliationData.dropSummaryDetails[dropReconValues.validateIndex].reconcilledOverOrShortCash = Number(dropReconValues.over_Short.cash);
    dropReconciliationData.dropSummaryDetails[dropReconValues.validateIndex].reconcilledOverOrShortNonCash = Number(dropReconValues.over_Short.nonCash);
    dropReconciliationData.dropSummaryDetails[dropReconValues.validateIndex].reconcilledTotalCash = Number(dropReconValues.dropAmount.cash);
    dropReconciliationData.dropSummaryDetails[dropReconValues.validateIndex].reconcilledTotalNonCash = Number(dropReconValues.dropAmount.nonCash);

    setDropSummaryDetails(dropReconciliationData.dropSummaryDetails)
    if (!dropReconciliationData?.nonCashResponse && !dropReconciliationData?.denominationActualQuantity) {
      dropReconciliationData.nonCashResponse = dropReconValues?.nonCashDetailsRes;
      dropReconciliationData.denominationActualQuantity = [];
      dropReconciliationData.denominationActualQuantity.push(dropReconValues.tempObjdenominationActualQuantity)
    }
    else {
      dropReconValues?.nonCashDetailsRes?.forEach((obj: any) => {
        dropReconciliationData?.nonCashResponse?.push(obj);
      })
    }
    if (!dropReconciliationData.dropDetails) {
      dropReconciliationData.dropDetails = []
      dropReconciliationData.dropDetails.push(dropReconValues.currentDropDetail)
    } else {
      let a = dropReconciliationData.dropDetails.filter((e: any)=> e.drawerManagementId !=dropReconValues.currentDropDetail.drawerManagementId)
      a.push(dropReconValues.currentDropDetail)
      dropReconciliationData.dropDetails= [...a]
    }
    let denominationActualArray : any=[]
dropReconciliationData.dropDetails.forEach((e : any )=>denominationActualArray.push(e.denominationActualQuantity) )
    dropReconciliationData.denominationActualQuantity= denominationActualArray;
    setDropReconciliationData(dropReconciliationData);
  }
  // const calculateDenominationQuatity = (val:any)=>{
  //   const struct = []
  //   let val:any= {
  //     denominationQuantity100: '0',
  //     denominationQuantity50: '0',
  //     denominationQuantity20: '0',
  //     denominationQuantity10: '0',
  //     denominationQuantity5: '0',
  //     denominationQuantity2: '0',
  //     denominationQuantity1: '0',
  //     denominationQuantity001: '0',
  //     denominationQuantity005: '0',
  //     denominationQuantity010: '0',
  //     denominationQuantity025: '0',
  //     denominationQuantity050: '0',
  //   }
  //   if(val.denominationQuantity001 !== '0') struct.push({quatity:val.denominationQuantity001,denomination:'0.01'})
  //   if(val.denominationQuantity1 !== '0') struct.push({quatity:val.denominationQuantity1,denomination:'1'})
  //   if(val.denominationQuantity2 !== '0') struct.push({quatity:val.denominationQuantity2,denomination:'2'})
  //   if(val.denominationQuantity005 !== '0') struct.push({quatity:val.denominationQuantity005,denomination:'0.05'})
  //   if(val.denominationQuantity5 !== '0') struct.push({quatity:val.denominationQuantity5,denomination:'5'})
  //   if(val.denominationQuantity010 !== '0') struct.push({quatity:val.denominationQuantity010,denomination:'0.10'})
  //   if(val.denominationQuantity10 !== '0') struct.push({quatity:val.denominationQuantity10,denomination:'10'})
  //   if(val.denominationQuantity20 !== '0') struct.push({quatity:val.denominationQuantity20,denomination:'20'})
  //   if(val.denominationQuantity025 !== '0') struct.push({quatity:val.denominationQuantity025,denomination:'0.25'})
  //   if(val.denominationQuantity050 !== '0') struct.push({quatity:val.denominationQuantity050,denomination:'0.50'})
  //   if(val.denominationQuantity50 !== '0') struct.push({quatity:val.denominationQuantity50,denomination:'50'})
  //   if(val.denominationQuantity100 !== '0') struct.push({quatity:val.denominationQuantity100,denomination:'100'})
  //   return struct;
  // }
  // const redirectPageToSummary = () => {
  //   setDropReconciliationDetails(false)
  // }
  // const redirectPageToReconcil = () => {
  //   props.redirectPageToReconcil()
  // }
  return (
    <>
      <Grid
        style={{
          marginBottom: '35%',
          // width: "100%",
          // paddingRight: "0.75rem",
          // paddingLeft: "0.75rem",
          marginRight: 'auto',
          marginLeft: 'auto',
          marginTop: '60px',
          paddingBottom: '80px !important',
        }}
      //  className={classes.container}
      >
        <OverShortAmountModalCard />
        {/* <OverShortTotalModalcard /> */}
        <RACModalCard
          isOpen={modals.overShortTotalIsOpen}
          maxWidth="sm"
          closeIcon={true}
          borderRadius="20px"
          onClose={() => handleModels('overShortTotalIsOpen', false)}
          // eslint-disable-next-line react/no-children-prop
          children={overShortTotalIsOpenPopUp()}
        // title="Validation Message"
        />
        {/* Breadcrumbs */}
        <Grid>
          <List className={`${classes.breadcrumb} ${classes.py1}`}>
            <ListItem
              style={{ paddingLeft: '0px !important' }}
              className={`${classes.breadcrumbItemActive} ${classes.breadCrumbMarg} `}
            >
              <a
                style={{ cursor: 'pointer', paddingLeft: '0px !important' }}
                onClick={() => history.push(`/dashboard/homepage`)}
              >
                Dashboard
              </a>
            </ListItem>
            <ListItem
              className={`${classes.breadcrumbItem} ${classes.breadcrumbArrow} ${classes.racpadLinkCustomer}`}
            >
              {/* {customerInformation?.firstName
                    ? customerInformation?.firstName +
                      ' ' +
                      customerInformation?.lastName
                    : ''}{' '}
                  - {customerId} */}
            </ListItem>
            <ListItem
              style={{ paddingLeft: '0px !important' }}
              className={`${classes.breadcrumbItemActive} ${classes.breadCrumbMarg} `}
            >
              <a
                style={{ cursor: 'pointer', paddingLeft: '0px !important' }}
                onClick={() => history.push({ pathname: '/cashmgmt/reconcil', state: 'RECONCILCOMPLETED_RECONCILCOMPLETED' })}
              >
                Drop Reconciliation
              </a>
            </ListItem>
            <ListItem
              className={`${classes.breadcrumbItem} ${classes.breadcrumbArrow} ${classes.racpadLinkCustomer}`}
            >
              {/* {customerInformation?.firstName
                    ? customerInformation?.firstName +
                      ' ' +
                      customerInformation?.lastName
                    : ''}{' '}
                  - {customerId} */}
            </ListItem>
            <ListItem
              style={{
                width: 'auto',
                fontFamily: 'OpenSans-semibold',
                fontSize: '12px',
                color: '#3182FF',
                paddingLeft: '3px',
                paddingRight: '0',
              }}
              className={` ${classes.breadCrumbMarg} `}
            >
              <a
                style={{ cursor: 'pointer', paddingLeft: '0px !important' }} onClick={() => history.push({ pathname: `/cashmgmt/reconcil/summary`, state: 'REDIRECT' })}
              >
                Summary
              </a>
            </ListItem>
          </List>
        </Grid>
        {/*Heading SubContainer*/}
        <Grid style={{ width: '100%', float: 'left' }}>
          <Grid
            style={{ flex: '0 0 auto', width: '100%', marginTop: '0.5rem' }}
          >
            <Grid
              className={classes.row}
              style={{
                marginLeft: '2px',
                marginBottom: '0.5%',
                paddingLeft: '2px',
              }}
            >
              {/* Drawer total starts here */}
              <Typography
                variant="h4"
                style={{
                  width: '100%',
                  fontSize: '18px',
                  fontFamily: 'OpenSans-bold',
                }}
              >
                Drop Reconciliation Summary
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid style={{ width: '100%', paddingLeft: '10%', paddingRight: '8%' }}>
          <Grid className={classes.row}>
            <Grid style={{ width: '55%', marginRight: '2%' }}>
              {/* <TotalsSummary></TotalsSummary> */}
              <Grid style={{ width: '100%', float: 'left' }}>
                {/*Heading SubContainer*/}
                <Grid style={{ width: '100%' }}>
                  <Typography variant="h5" className={classes.header}>
                    Totals Summary
                  </Typography>
                  <Card className={classes.card}>
                    <CardContent className={classes.cardBody}>
                      <Grid
                        style={{
                          borderBottom: '1px solid',
                          borderBottomColor: '#E0EAFF',
                        }}
                      >
                        <Grid style={{ width: '100%' }}>
                          <Grid className={classes.row}>
                            <Grid style={{ width: '35%', marginRight: '15%' }}>
                              <Typography
                                variant="h5"
                                style={{
                                  marginTop: '5px',
                                  marginBottom: '17px',
                                }}
                              >
                                Drop Summary Totals
                              </Typography>
                              <Grid
                                className={classes.row}
                                style={{
                                  marginTop: '15px',
                                  marginBottom: '15px',
                                }}
                              >
                                <Typography style={{ width: '50%' }}>
                                  Non-Cash
                                </Typography>
                                <Typography
                                  style={{
                                    width: '50%',
                                    textAlign: 'right',
                                    color: '#4a5174',
                                  }}
                                >
                                  {/* ${getStoreTransactionNonCash()} */}
                                  ${dropSummaryTotals.totalNonCash.toFixed(2)}
                                </Typography>
                              </Grid>
                              <Grid
                                className={classes.row}
                                style={{
                                  marginTop: '15px',
                                  marginBottom: '15px',
                                }}
                              >
                                <Typography style={{ width: '50%' }}>
                                  Cash
                                </Typography>
                                <Typography
                                  style={{
                                    width: '50%',
                                    textAlign: 'right',
                                    color: '#4a5174',
                                  }}
                                >
                                  ${dropSummaryTotals.totalCash.toFixed(2)}
                                  {/* ${(getStoreTransactionCash())} */}
                                </Typography>
                              </Grid>
                              <Grid
                                className={classes.row}
                                style={{
                                  marginTop: '15px',
                                  marginBottom: '20px',
                                }}
                              >
                                <Typography style={{ width: '50%' }}>
                                  Total
                                </Typography>
                                <Typography
                                  style={{
                                    width: '50%',
                                    textAlign: 'right',
                                    color: '#4a5174',
                                  }}
                                >
                                  {/* ${getStoreTransactionTotal()} */}
                                  ${(
                                    dropSummaryTotals.totalCash +
                                    dropSummaryTotals.totalNonCash
                                  ).toFixed(2)}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid
                              style={{
                                width: '40%',
                                marginLeft: '6%',
                                marginRight: '4%',
                                float: 'right',
                              }}
                            >
                              <Typography
                                variant="h5"
                                style={{
                                  marginTop: '5px',
                                  marginBottom: '15px',
                                }}
                              >
                                Store Transaction Totals
                              </Typography>
                              <Grid
                                className={classes.row}
                                style={{
                                  marginTop: '15px',
                                  marginBottom: '15px',
                                }}
                              >
                                <Typography style={{ width: '40%' }}>
                                  Non-Cash
                                </Typography>
                                <Typography
                                  style={{
                                    width: '60%',
                                    textAlign: 'right',
                                    color: '#4a5174',
                                  }}
                                >
                                  {/* ${dropSummaryTotals.totalNonCash.toFixed(2)} */}
                                  ${getStoreTransactionNonCash()}

                                </Typography>
                              </Grid>
                              <Grid
                                className={classes.row}
                                style={{
                                  marginTop: '15px',
                                  marginBottom: '15px',
                                }}
                              >
                                <Typography style={{ width: '40%' }}>
                                  Cash
                                </Typography>
                                <Typography
                                  style={{
                                    width: '60%',
                                    textAlign: 'right',
                                    color: '#4a5174',
                                  }}
                                >
                                  {/* ${dropSummaryTotals.totalCash.toFixed(2)} */}
                                  ${(getStoreTransactionCash())}
                                </Typography>
                              </Grid>
                              <Grid
                                className={classes.row}
                                style={{
                                  marginTop: '15px',
                                  marginBottom: '20px',
                                }}
                              >
                                <Typography style={{ width: '40%' }}>
                                  Total
                                </Typography>
                                <Typography
                                  style={{
                                    width: '60%',
                                    textAlign: 'right',
                                    color: '#4a5174',
                                  }}
                                >
                                  {/* ${(
                                    dropSummaryTotals.totalCash +
                                    dropSummaryTotals.totalNonCash
                                  ).toFixed(2)} */}
                                  ${getStoreTransactionTotal()}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        style={{
                          marginBottom: '2%',
                          marginTop: '3%',
                          border: 'none',
                          borderRadius: '4px',
                          width: '75%',
                          padding: '5px',
                          background: '#EFF4FF',
                          marginRight: '1%',
                        }}
                        className={`${classes.float} `}
                      >
                        <Grid
                          style={{ width: '75%' }}
                          className={classes.floatLeft}
                        >
                          <Typography
                            style={{
                              color: '#2179FE',
                              padding: '5px',
                              paddingLeft: '13px',
                              width: '100%',
                            }}
                          >
                            Over/Short (Drop Summary - Store Transaction)
                          </Typography>
                        </Grid>
                        <Grid
                          style={{
                            // paddingLeft:"4%",
                            width: '25%',
                            textAlign: 'right',
                          }}
                          className={classes.floatLeft}
                        >
                          <Typography
                            variant="h5"
                            style={{
                              color: '#2179FE',
                              paddingRight: '18px',
                              padding: '5px',
                              width: '100%',
                              float: 'right',
                            }}
                          >
                            {OverShortTotalSummary() < 0 ? `($${Math.abs(OverShortTotalSummary()).toFixed(2)} )` : `$${OverShortTotalSummary().toFixed(2)} `}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid style={{ width: '37%' }}>
              {/* <DropReconcilationTotalsSummary></DropReconcilationTotalsSummary> */}
              <Grid style={{ width: '100%', float: 'left' }}>
                {/*Heading SubContainer*/}
                <Grid style={{ width: '100%' }}>
                  <Typography
                    variant="h5"
                    style={{
                      width: '100%',
                      fontSize: '18px',
                      fontFamily: 'OpenSans-bold',
                      marginBottom: '15px',
                    }}
                  >
                    Drop Reconciliation Totals Summary
                  </Typography>
                  <Card
                    className={classes.card}
                    style={{ background: '#4f86f7' }}
                  >
                    <CardContent className={classes.cardBody}>
                      <Grid
                        className={classes.row}
                        style={{ marginTop: '5px', marginBottom: '15px' }}
                      >
                        <Typography style={{ width: '65%', color: 'white' }}>
                          Non-Cash
                        </Typography>
                        <Typography
                          style={{
                            width: '27%',
                            textAlign: 'right',
                            color: 'white',
                          }}
                        >
                          ${(Number(partialReconcilledData.nonCash) + Number(dropReconSummaryTotals.nonCash)).toFixed(2)}
                        </Typography>
                      </Grid>
                      <Grid
                        className={classes.row}
                        style={{ marginTop: '15px', marginBottom: '15px' }}
                      >
                        <Typography style={{ width: '65%', color: 'white' }}>
                          Add-In Non-Cash
                        </Typography>
                        <Typography
                          style={{
                            width: '27%',
                            textAlign: 'right',
                            color: 'white',
                          }}
                        >
                          ${(Number(partialReconcilledData.addInNonCash) + Number(dropReconSummaryTotals.addInNonCash)).toFixed(2)}
                        </Typography>
                      </Grid>
                      <Grid
                        className={classes.row}
                        style={{ marginTop: '15px', marginBottom: '15px' }}
                      >
                        <Typography style={{ width: '65%', color: 'white' }}>
                          Cash
                        </Typography>
                        <Typography
                          style={{
                            width: '27%',
                            textAlign: 'right',
                            color: 'white',
                          }}
                        >
                          ${(Number(partialReconcilledData.cash) + Number(dropReconSummaryTotals.cash)).toFixed(2)}
                        </Typography>
                      </Grid>
                      <Grid
                        className={classes.row}
                        style={{ marginTop: '15px', marginBottom: '15px' }}
                      >
                        <Typography style={{ width: '65%', color: 'white' }}>
                          Total
                        </Typography>
                        <Typography
                          style={{
                            width: '27%',
                            textAlign: 'right',
                            color: 'white',
                          }}
                        >
                          ${(Number(partialReconcilledData.totalCash) + Number(dropReconSummaryTotals.totalCash)).toFixed(2)}
                        </Typography>
                      </Grid>
                      <Grid
                        style={{
                          marginBottom: '2%',
                          marginTop: '3%',
                          border: 'none',
                          borderRadius: '4px',
                          width: '100%',
                          paddingLeft: '5px',
                          paddingTop: '5px',
                          paddingBottom: '5px',
                          background: '#EFF4FF',
                          float: 'right',
                          backgroundColor: 'white',
                        }}
                        className={`${classes.float} `}
                      >
                        <Grid
                          className={classes.floatLeft}
                          style={{ width: '65%' }}
                        >
                          <Typography
                            style={{
                              color: '#2179FE',
                              padding: '2px',
                              paddingLeft: '9px',
                              width: '100%',
                              marginTop: '2px',
                              marginBottom: '5px',
                            }}
                          >
                            <Grid> Over/Short (Drop Reconciliation</Grid>
                            Totals Summary - Store Transaction)
                          </Typography>
                        </Grid>
                        <Grid
                          style={{ paddingLeft: '4%', width: '35%' }}
                          className={classes.floatLeft}
                        >
                          <Typography
                            variant="h5"
                            style={{
                              color: '#2179FE',
                              padding: '0px',
                              //   float:"right",
                              textAlign: 'right',
                              paddingRight: '28px',
                              width: '100%',
                            }}
                          >
                            {Number(partialReconcilledData.overShort) + Number(dropReconSummaryTotals.overShort) < 0 ? `($${Math.abs(partialReconcilledData.overShort + dropReconSummaryTotals.overShort).toFixed(2)})` : `$${Number(partialReconcilledData.overShort + dropReconSummaryTotals.overShort).toFixed(2)}`}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid style={{ width: '100%', paddingLeft: '10%', paddingRight: '8%' }}>
          {/* < DropLists></DropLists> */}
          <Grid style={{ width: '100%', float: 'left' }}>
            {/*Heading SubContainer*/}
            <Grid style={{ width: '95%', marginTop: '2%' }}>
              <Typography variant="h5" className={classes.header}>
                Drop List
              </Typography>
              <Card className={`${classes.card} ${classes.scrollbar}`}>
                <CardContent
                  style={{
                    flex: '1 1 auto',
                    paddingLeft: '1rem',
                    paddingTop: '1rem',
                    paddingRight: '0rem !important',
                    //  padding: "1rem 1rem",
                    //  paddingBottom: "10px !important",
                  }}
                >
                  <RACTable
                    className={`${classes.table} ${classes.tableBorder} ${classes.mb0}`}
                    renderTableHead={renderDropListHead}
                    renderTableContent={renderDropListContent}
                    stickyHeader
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid container classes={{
            root: clsx(classes.customerToolbar, classes.fixedBottom),
          }}
            style={{ zIndex: 0 }}>
            <Grid className={classes.footerFixed}>
              <Grid className={classes.floatLeft}>
                <RACButton onClick={() => history.push(`/dashboard/homepage`)} variant="outlined" color="secondary">
                  Cancel
                </RACButton>
              </Grid>

              <Grid style={{ float: 'right' }}>
                <RACButton variant="contained" color="primary"
                  disabled={!dropSummaryDetails?.every((el: any) => el.validateChecked == true || el.dropReconciliationId != null)}
                  onClick={() => handleFinalValidation()}>
                  Final Validation
                </RACButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

{/* <DropReconciliationDetails
  dropDetails={dropDetails}
  activityId={validateDropId}
  validateIndex={validateIndex}
  // redirectPageToSummary={redirectPageToSummary}
  // redirectPageToReconcil={redirectPageToReconcil}
  dropReconciliationDetailsCompleted={dropReconciliationDetailsCompleted}
></DropReconciliationDetails> */}