/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/prefer-immediate-return */
/* eslint-disable prettier/prettier */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { RACCOLOR } from '@rentacenter/racstrap';
import { makeStyles } from '@material-ui/core';
//import {ReactComponent as GlobalSearch} from "../SearchButton/GlobalSearch.svg";
export const customerStyles = () => {
  const UseClasses = makeStyles((theme) => ({
    amountFieldInGrid: {
      color: "#4a5174",
      textAlign: "right",
      paddingRight: "2%",
    },
    amounttxt:{
      color: "#4a5174", textAlign: "right", paddingRight: "14%"
    },
    numericCharacter:{
      marginTop: '3%' 
    },
    tenderType:{
      width: "100%", marginBottom: "2%" 
    },
    paidInMarginLeft:{ marginLeft: "5px" },
    containerWid: { width: "100%", float: "left" },
    printBtn:{
      float: 'right', marginRight: '5px' 
    },
    paidInTypeWidth:{
      width: "14%" 
    },
    paidInwid:{width: "85%"},
    MoneyOrderRadioWidth:{width:"10%"},
    radioWidth:{width: "6%"},
    wid100:{
      width: "100%"
    },
    payerTotalWidth:{
      width: "30%" 
    },
    payerVendor:{
      width: "30%", float: "left", marginRight: "2%"
    },
    PaidInCategoryDes:{
      width: "100%",
      float: "left",
      paddingBottom: "20px",
    },
    moneyOrderWidth:{
      width: "18%"
    },
    writtenByWid:{
      width: "15%"
    },
    writtenByGrid:{
      width: "98%", float: "left"
    },
    amountWidth:{width: "98%", float: "left", marginRight: "2%"},
    AmountWid:{
      width: "17%" 
    },
    paidInMarginBtm:{marginBottom: "15px"},
    tenderTypeMarginTop:{
      marginTop: "5px", marginBottom: "8px"
    },
    totalAmountWid:{
      color: '#2179FE',
      padding: '1px',
      paddingRight: '24px',
      textAlign: 'right',
      // marginLeft:'35px',
      marginRight: '0.5px'
    },
    paddingmargin: { padding: "1%" },
    paddingbottom: { marginBottom: "5%" },
    stylebottom: { width: "100%", float: "left" },
    minleftpadding: { paddingLeft: "0.1%" },
    widthforgridForNonCah: { width: "40%" },
    stylemarginright: { marginRight: "22px" },
    paidinisandstyle: { float: "right", width: "19%" },
    typhographystylepadding: {
      color: "#2179FE",
      padding: "1px",
      paddingLeft: "12px",
    },
    sytleforbottom: { marginBottom: "36%" },
    widthofstyle: { width: "60%" },
    stylealong: {
      color: "#2179FE",
      padding: "1px",
      paddingRight: "24px",
      textAlign: "right",
    },
    classwithwidth: { width: "100%", marginLeft: "10%" },
    maxpaddingstyle: {
      width: "75%",
      marginRight: "12.5%",
      marginLeft: "12.5%",
      float: "left",
    },
    droppaidin: { width: "100%", float: "left", overflow: "hidden" },
    styleforgrid: {
      width: "25%",
      padding: "8px",
      background: "#EFF4FF",
      marginRight: "17.9%",
      //   marginLeft: "7px",
      marginTop: "1%",
      border: "none",
      borderRadius: "4px",
      //   marginBottom:"-5px"
    },
    stylecolormulti: { paddingLeft: "0px !important" },
    stylenotimportant: { cursor: "pointer", paddingLeft: "0px !important" },
    stylegotreturn: { paddingLeft: "0px !important" },
    paddingoutstyle: {
      width: "auto",
      fontFamily: "OpenSans-semibold",
      fontSize: "12px",
      color: "#3182FF",
      paddingLeft: "3px",
      paddingRight: "0",
    },
    stylepaddingout: { cursor: "pointer", paddingLeft: "0px !important" },
    multicolour: {
      color: "#4a5174",
      textAlign: "right",
      paddingRight: "14%",
    },
    styleinlinecolour: { color: "#4a5174" },
    stylepaidouts: {
      marginTop: "5px",

      width: "100%",

      padding: "6px",

      paddingBottom: "31px",

      resize: "none",

      border: "1px solid #C4C4C4",

      paddingTop: "5px",
      borderRadius: "0.6rem",
      backgroundColor: "#fff",
      transition:
        "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
      fontSize: "14px",
      fontFamily: "OpenSans-semibold",
    },
    styletyphographyreceipt: {
      marginTop: "14px",

      width: "100%",

      fontSize: "16px",

      //  fontFamily: "",

      marginBottom: "15px",
    },
    receiptSubmitted:{
      width: "30%", float: "right"
    },
    stylemarginleft: { marginLeft: "5px" },
    styleleft: { float: "left" },
    cursorpointer: { color: "blue", cursor: "pointer" },
    rows: { width: "100%" },
    rowsleft: { marginBottom: "15px", width: "30%" },
    widthforgrid: { width: "17%", float: "left" },
    rowsright: {
      background: "#F5F5F5",
      resize: "none",
      border: "1px solid #C4C4C4",
      padding: "0.375rem 0.75rem",
      borderRadius: "0.6rem",
      width: "60%",
      //   backgroundColor: "#fff",
      transition:
        "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
      fontSize: "14px",
      fontFamily: "OpenSans-semibold",
    },
    styleprintpopup: { paddingBottom: "10%", marginBottom: "0.5%" },
    styeltyphography: {
      resize: "none",
      border: "1px solid #C4C4C4",
      padding: "0.375rem 0.75rem",
      borderRadius: "0.6rem",
      width: "85%",
      //   backgroundColor: "#fff",
      transition:
        "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
      fontSize: "14px",
      fontFamily: "OpenSans-semibold",
    },
    stylespaidins: {
      marginLeft: "2%",
      marginRight: "2%",
    },
    styleorgane: {
      color: "#B50000",
      backgroundColor: "#FFD1D1",
      width: "40%",
    },
    stylebluecolour: {
      float: "right",
      color: "Blue",
      backgroundColor: "#E0EAFF",
      width: "40%",
    },
    paidinsout: { paddingRight: "5px", width: "50%" },
    stylepaidinout: { float: "right", cursor: "pointer" },
    styelrowbottom: {
      width: "40%",
      float: "left",
      marginTop: "7.5%",
      marginLeft: "0%",
      cursor: "pointer",
    },
    stylebottomrow: { marginBottom: "15px" },
    stylebottomdown: { width: "85%" },
    stylefloatleft: { float: "left" },
    anchortagstyle: { color: "blue", cursor: "pointer" },
    paidinwidth: { width: "17%" },
    paidinwidthtop: { width: "98%", float: "left", marginRight: "2%" },
    stylerightbottom: { color: "red" },
    rightbuttontext: { paddingRight: "1.5%", textAlign: "right" },
    styleright: { marginBottom: "15px" },
    paddingrightstyle: { color: "#4a5174" },
    paddingleftstyle: { width: "100%", float: "left", overflow: "hidden" },
    paidoutright: { color: "#4a5174" },
    paidoutleft: {
      float: "right",
      color: "Blue",
      backgroundColor: "#E0EAFF",
      width: "40%",
    },
    paidingsummary: { paddingLeft: "5px", width: "50%" },
    floatrightforpaidin: {
      color: "#B50000",
      backgroundColor: "#FFD1D1",
      width: "40%",
    },
    stylefloatright: { float: "right" },
    widthstylebottom: { width: "14%" },
    paidinwidthstyle: { width: "15%" },
    stylewithpaidin: { paddingBottom: "10%", marginBottom: "5%" },
    paidstyletype: { width: "60%", float: "left" },
    widthhidden: { width: "25%" },
    paidoutinstyle: {
      float: "left",
      paddingTop: "10.5%",
      marginLeft: "0%",
      cursor: "pointer",
    },
    paidinoutpadding: {
      resize: "none",
      border: "1px solid #C4C4C4",
      padding: "0.375rem 0.75rem",
      borderRadius: "0.6rem",
      width: "87%",

      transition:
        "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
      fontSize: "14px",
      fontFamily: "OpenSans-semibold",
    },
    descriptiontextArea:{
      resize: 'none',
      border: "1px solid #C4C4C4",
      padding: "0.375rem 0.75rem",
      borderRadius: "0.6rem",
      width: "85%",
      //   backgroundColor: "#fff",
      transition:
          "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
      fontSize: "14px",
      fontFamily: "OpenSans-semibold",
    },
    styletextarea: {
      background: "#F5F5F5",
      resize: "none",
      border: "1px solid #C4C4C4",
      padding: "0.375rem 0.75rem",
      borderRadius: "0.6rem",
      width: "87%",
      transition:
        "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
      fontSize: "14px",
      fontFamily: "OpenSans-semibold",
    },
    paidoutstyle: { marginBottom: "15px" },
    psidinfloatleft: { width: "98%", float: "left" },
    divstyle: { width: "6%" },
    stylewidth: { width: "6%" },
    stylerights: { width: "10%" },
    stylediv: { marginTop: "5px", marginBottom: "8px" },
    topleft: { width: "100%", marginBottom: "2%" },
    stylewidths: { width: "100%", float: "left" },
    topmargin: { marginTop: "3%" },
    textpadding: { padding: "1%" },
    textmarginbottom: { marginBottom: "5%" },
    colMd12mt3: {
      flex: "0 0 auto",
      width: "100%",
      marginTop: "1rem !important",
    },
    racFixedFooterMargin: {
      marginBottom: "120px",
    },
    row: {
      display: "flex",
      flexWrap: "wrap",
      marginTop: "0",
      marginRight: "-0.75rem",
    },
    printpopup: {
      width: "100%",
    },
    anchorview: {
      marginTop: "0.1%",
    },
    amounttextAlign:{
      paddingRight: "3%", textAlign: "right"
    },
    popupforprint: {
      padding: "3%",
      marginBottom: "25%",
    },
    mt4: {
      marginTop: theme.typography.pxToRem(24),
    },
    w100: {
      width: "100%",
    },
    my3: {
      marginTop: theme.typography.pxToRem(16),
      marginBottom: theme.typography.pxToRem(16),
    },
    header: {
      width: "100%",
      fontSize: "18px",
      fontFamily: "OpenSans-bold",
      marginBottom: "15px",
    },
    card: {
      border: "none",
      borderRadius: "1rem",
      boxShadow: "0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important",
    },
    cardBody: {
      flex: "1 1 auto",
      padding: "1rem 1rem",
      paddingBottom: "10px !important",
    },
    racColMD12: {
      flex: "0 0 auto",
      width: "100%",
    },
    receiptSubmittedWid:{
      marginTop: "2px",
      width: "60%"
    },
    PaidOut: {
      marginTop: "2px",
      width: "98% !important",
      marginRight: "2%",
    },
    PaidOutCash: {
      marginTop: "5px",
    },
    mt3: {
      marginTop: "1rem !important",
    },
    dropNotes: {
      "&:focus": {
        color: "#212529",
        backgroundColor: "#fff",
        borderColor: "#86b7fe",
        outline: "0",
        boxShadow: "0 0 0 0.25rem rgb(13 110 253 / 25%)",
      },
    },
    amount: {
      width: "75% !important",

      "& input": {
        height: "23px",
      },
    },
    item: {
      "& input": {
        height: "23px",
      },
      float: "right",
    },

    dropdown: {
      height: "23px",
    },
    footerGrid: {
      paddingTop: "120px !important",
      marginRight: "-0.75rem",
      marginLeft: "-0.75rem",
      display: "flex",
      flexWrap: "wrap",
    },
    footerFixed: {
      backgroundColor: "#fff !important",
      padding: "1rem !important",
      boxShadow: "0 0.5rem 1rem rgb(0 0 0 / 15%) !important",
      position: "fixed",
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1030,
    },
    vendor: {
      width: "85% !important",
      "& input": {
        height: "23px",
      },
    },
    writtenBy: {
      marginLeft: "2%",
      width: "90% !important",

      "& input": {
        height: "23px",
      },
      float: "right",
    },
    heightDropdown: {
      // '&div':{
      // '&div':{
      // '&div':{
      "&div": {
        height: "24px",
      },
      // }

      // }
      // },
    },
    colorred: {
      backgroundColor: "red",
    },
    DateofDrop: {
      width: "40%",
      marginRight: "1%",
    },
    Drops: {
      width: "30%",
      marginRight: "1%",
      paddingLeft: "6.8%",
    },
    paidInType: {
      width: "25%",
    },
    paidOutType: {
      width: "15%",
    },
    descripton: {
      width: "25%",
    },
    descriptonCashPaidOut: {
      width: "30%",
    },
    coWorkerName: {
      width: "10%",
    },
    Amount: {
      width: "25%",
    },
    AmountCashpaidOut: {
      width: "15%",
    },
    loaderStyle: {
      border: "none",
      backgroundColor: "transparent",
      // userFocus: 'none',
      // MozUserFocus: 'none',
      outline: "none",
    },
    floatRight: {
      float: "right",
    },
    fixedBottom: {
      position: "fixed",
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1030,
      backgroundColor: `${RACCOLOR.WHITE}`,
      boxShadow: "0 0.5rem 1rem rgb(0 0 0 / 15%)",
      padding: "1rem",
    },
    customerToolbar: {
      position: "fixed",
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1030,
      backgroundColor: `${RACCOLOR.WHITE}`,
      boxShadow: "0 0.5rem 1rem rgb(0 0 0 / 15%)",
      paddingLeft: theme.typography.pxToRem(16),
      paddingRight: theme.typography.pxToRem(16),
      paddingTop: theme.typography.pxToRem(16),
      paddingBottom: theme.typography.pxToRem(16),
    },
    RACLoaderPage: {
      textAlign: "center",
      marginTop: "250px",
      fontSize: "16px",
      marginLeft: "30px",
      marginRight: "30px",
      outline: "none",
    },
    Actionbtnn: {
      width: "25%",
      paddingRight: "9%",
      textAlign: "right",
    },
    ActionbtnCashPaidOut: {
      width: "15%",
      paddingRight: "5.4%",
      textAlign: "right",
    },

    Action: {
      width: "15%",
      marginRight: "16.5%",
      float: "right",
    },
    tableRow: {
      background: "none",
    },
    table: {
      width: "100%",
      color: "#212529",
      verticalAlign: "top",
      borderColor: "#dee2e6",
      height: "30px",
    },
    tableBorder: {
      captionSide: "bottom",
      borderCollapse: "collapse",
    },
    tableCell: {
      width: "30%",
    },
    mb0: {
      marginBottom: "0 !important",
    },
    floatLeft: {
      float: "left",
    },
    float: {
      float: "right",
    },
    breadcrumb: {
      display: "flex",
      flexWrap: "wrap",
      padding: 0,
      marginBottom: theme.typography.pxToRem(1),
      listStyle: "none",
    },
    breadcrumbItem: {
      width: "auto",
      fontFamily: "OpenSans-semibold",
      fontSize: "14px",
    },
    breadcrumbItemActive: {
      width: "auto",
      fontFamily: "OpenSans-semibold",
      fontSize: "12px",
      // color: '#3182FF',
      paddingLeft: "3px",
      paddingRight: 0,
    },
    breadCrumbMarg: {
      // marginLeft: '20px',
    },
    paidOutDes:{
      width: "100%",
      float: "left",
      //    marginLeft: "2.5%",
      paddingBottom: "20px",
    },
    breadcrumbArrow: {
      "&:before": {
        content: '""',
        width: 0,
        height: 0,
        borderTop: "5px solid transparent",
        borderLeft: "7px solid #707070",
        borderBottom: "5px solid transparent",
        marginTop: 0,
        float: "left",
        // paddingRight: '13px',
        color: "#6c757d",
        paddingLeft: 0,
      },
    },
    py1: {
      paddingTop: theme.typography.pxToRem(4),
      paddingBottom: theme.typography.pxToRem(0),
    },
    racpadLinkCustomer: {
      fontFamily: "OpenSans-bold",
      fontSize: "14px",
      color: "#2179FE",
      textDecoration: "none",
    },
    paddingBreadCrumb: {},
    radioPosition: {
      "&div": {
        "&label": {
          "&span": {
            "&input": {
              // position:"absolute",
              paddingLeft: "0 !important",
            },
          },
        },
      },
    },
    textCenter: {
      textAlign: "center",
    },
    RACPOPMsg: {
      fontFamily: "OpenSans-bold",
      fontSize: theme.typography.pxToRem(16),
      letterSpacing: 0,
    },
    justifyContentCenter: {
      justifyContent: "center",
    },
    spacerMT4: {
      marginTop: theme.typography.pxToRem(24),
    },
    me2: {
      marginRight: theme.typography.pxToRem(8),
    },
    foc: {
      "&:focus": {
        borderColor: "#80bdff",
        boxShadow: "1px 1px 6px 3px #80bdff",
      },
    },
    spacerMR2: {
      marginRight: theme.typography.pxToRem(8),
    },
    explanationContent: {
      justifyContent: "right",
    },

    // add:{
    //   padding: "17px",
    //   paddingleft: "55px",
    //   width: "300px",
    //   border: "1px solid #f5f5f5",
    //   fontsize: "13px",
    //   color: "gray",
    //   backgroundimage: "public/Slide1.jpg",
    //   backgroundrepeat: "no-repeat",
    //   backgroundposition: "left center",
    //   outline: 0,
    // },
    // racSearchPrimary: {
    //   // color: `${RACCOLOR.LINEN}`,
    //   // backgroundColor: `${RACCOLOR.SATIN_WHITE}`,
    //   // borderColor: `${RACCOLOR.SATIN_WHITE}`,
    //   padding: "20px 40px 20px 40px",
    //   fontFamily: "OpenSans-semibold",

    //   backgroundImage: `url(${GlobalSearch})`,
    //   backgroundRepeat: "no-repeat",
    //   backgroundPosition: "34px",

    //   marginTop: "33px",
    //   borderRadius: "5px",
    //   cursor: "pointer",
    // },
  }));
  const classes = UseClasses();
  return classes;
  };  
