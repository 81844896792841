import axios, { CancelTokenSource } from 'axios';
import { Auth } from '@aws-amplify/auth';

import { ApiUrls, appConfig } from '../config/app-config';

export async function getJWToken() {
  try {
    const currentSession = await Auth.currentSession();

    return currentSession.getIdToken().getJwtToken();
  } catch (error) {
    throw new Error(`An error occurred: ${error}.`);
  }
}
async function getAccessToken() {
  try {
    const currentSession = await Auth.currentSession();

    return currentSession.getAccessToken().getJwtToken();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('error while getting access token', error);
    throw new Error(`An error occurred: ${error}.`);
  }
}
export async function defaultClient(
  endpoint: string,
  { requestType, body, ...customConfig }: any = {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  apiUrl: any,
  needAccessToken = false
  // access token is required for accessing AM APIs, but not for general ones (e.g. user APIs)
) {
  const jwtToken = await getJWToken();

  const headers: any = {
    // eslint-disable-next-line sonarjs/no-duplicate-string
    'Content-Type': 'application/json; charset=UTF-8',
    storeNumber: window.sessionStorage.getItem('storeNumber'),
  };
  if (jwtToken) {
    headers.Authorization = `Bearer ${jwtToken}`;
  }
  if (needAccessToken) {
    const accessToken =
      window.sessionStorage.getItem('ac') || (await getAccessToken());
    headers.accessToken = accessToken;
    headers.storeNumber = window.sessionStorage.getItem('storeNumber');
  }

  const requestConfig: any = {
    method: requestType,
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  if (body) {
    requestConfig.data = JSON.stringify(body);
  }

  // eslint-disable-next-line no-console
  console.log('api url from app config', apiUrl);
  // eslint-disable-next-line no-console
  console.log('api request config', requestConfig);
  return await axios(`${apiUrl}/${endpoint}`, requestConfig).then(
    (response: any) => response.data
  );
}
export async function client(
  endpoint: string,
  { requestType, body, ...customConfig }: any = {},
  apiUrl: ApiUrls[keyof ApiUrls] = appConfig.apiUrls.micro,
  AccessToken?: string,
  needAccessToken = true
) {
  const jwtToken = await getJWToken();

  const headers: any = {
    'Content-Type': 'application/json; charset=UTF-8',
    storeNumber: window.sessionStorage.getItem('storeNumber'),
  };
  if (needAccessToken) {
    const accessToken =
      window.sessionStorage.getItem('ac') || (await getAccessToken());
    // eslint-disable-next-line no-console
    console.log('client access token', accessToken);
    headers.AccessToken = accessToken;
  }
  if (jwtToken) {
    headers.Authorization = `Bearer ${jwtToken}`;
  }

  const requestConfig: any = {
    method: requestType,
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  if (body) {
    requestConfig.data = JSON.stringify(body);
  }

  return axios(`${apiUrl}/${endpoint}`, requestConfig).then(
    (response) => response.data
  );
}
export async function clientCall(
  endpoint: string,
  { requestType, body, ...customConfig }: any = {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  apiUrl: any,
  // access token is required for accessing AM APIs, but not for general ones (e.g. user APIs)djvbh
  AccessToken?: string,
  needAccessToken = true
) {
  try {
    const jwtToken = await getJWToken();

    const headers: any = {
      'Content-Type': 'application/json; charset=UTF-8',
      storeNumber: window.sessionStorage.getItem('storeNumber'),
    };

    if (needAccessToken) {
      const accessToken =
        window.localStorage.getItem('ac') || (await getAccessToken());
      // eslint-disable-next-line no-console
      console.log('client access token', accessToken);
      // eslint-disable-next-line no-console
      console.log('GetPriceQuote Payload --->', body);
      headers.AccessToken = accessToken;
    }
    if (jwtToken) {
      headers.Authorization = `Bearer ${jwtToken}`;
    }

    const requestConfig: any = {
      method: requestType,
      ...customConfig,
      headers: {
        ...headers,
        ...customConfig.headers,
      },
    };
    if (body) {
      requestConfig.data = JSON.stringify(body);
    }
    // eslint-disable-next-line no-console
    console.log('api url from app config', apiUrl);
    // eslint-disable-next-line no-console
    console.log('api request config', requestConfig);
    return await axios(`${apiUrl}/${endpoint}`, requestConfig).then(
      (response) => response
    );
  } catch (e: any) {
    // eslint-disable-next-line no-console
    console.log('catch', e);
    // eslint-disable-next-line no-console
    console.log('catch response', e.response);
    return e.response;
  }
}
export const getCancelTokenSource = (): CancelTokenSource =>
  axios.CancelToken.source();
