/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable no-dupe-keys*/
import { makeStyles } from '@material-ui/core';
//For F_Final Drop .
export const customerStyles = () => {
  const UseClasses = makeStyles((theme) => ({
    container: {
      width: '100%',
      paddingRight: '0.75rem',
      paddingLeft: '0.75rem',
      marginRight: 'auto',
      marginLeft: 'auto',
      marginTop: '60px',
      paddingBottom: '80px !important',
    },
    scrollbar: { overflow: 'auto', height: '250px', width: '100%' },
    RACLoaderPage: {
      textAlign: 'center',
      marginTop: '250px',
      fontSize: '16px',
      marginLeft: '30px',
      marginRight: '30px',
      outline: 'none',
    },
    businessDate: {
      float: 'left',
      marginBottom: '3%',
    },
    printpopup: {
      width: '100%',
    },
    updatePopupMarginWid:{
      marginTop: '1%', marginBottom: '1%' 
    },
    updatePopupTextAlign:{
      textAlign: 'center'
    },
    popupforprint: {
      padding: '3%',
      marginBottom: '25%',
    },
    anchorview: {
      marginTop: '0.1%',
    },
    cashBeingDropWid:{
      width:"100%",float:"left",marginBottom:"11%"
    },
    header: {
      width: '100%',
      fontSize: '18px',
      fontFamily: 'OpenSans-bold',
      marginBottom: '15px',
    },
    me2: {
      marginRight: theme.typography.pxToRem(8),
    },
    rowMt2: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: '0.5rem !important',
    },
    colMd12mt3: {
      flex: '0 0 auto',
      width: '100%',
      marginTop: '1rem !important',
    },
    row: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: '0',
      marginRight: '-0.75rem',
    },
    tableCell: {
      width: '30%',
    },
    tableCellDenomination: {
      width: '30%',
    },
    tableRow: {
      background: 'none',
    },
    RACbutton: {
      color: '#0d6efd',
      borderColor: '#0d6efd',
      display: 'inline-block',
      fontWeight: 400,
      lineHeight: '1.5',
      textAlign: 'center',
      textDecoration: 'none',
      verticalAlign: 'middle',
      userSelect: 'none',
      backgroundColor: 'transparent',
      border: '1px solid transparent',
      padding: '0.375rem 0.75rem',
      fontSize: '1rem',
      borderRadius: '0.25rem',
      width: '56%',
      transition:
        'color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
      '&:hover': {
        color: '#fff',
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
      },
    },
    RACbuttonOverShort: {
      color: '#0d6efd',
      borderColor: 'black',
      display: 'inline-block',
      fontWeight: 400,
      lineHeight: '1.5',
      textAlign: 'center',
      textDecoration: 'none',
      verticalAlign: 'middle',
      userSelect: 'none',
      backgroundColor: 'transparent',
      border: '1px solid transparent',
      padding: '0.375rem 0.75rem',
      fontSize: '1rem',
      borderRadius: '0.25rem',
      width: '56%',
      transition:
        'color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
      '&:hover': {
        color: '#fff',
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
      },
    },
    RACTextBox: {
      width: '50% !important',
      '&:focus': {
        color: '#212529',
        backgroundColor: '#fff',
        borderColor: '#86b7fe',
        outline: '0',
        boxShadow: '0 0 0 0.25rem rgb(13 110 253 / 25%)',
      },
      '& input': {
        padding: '9px',
      },
    },
    tableRow: {
      background: 'none',
      width: '50%',
    },
    tableCell1: {
      paddingTop: '0 !important',
    },

    mt3: {
      marginTop: '1rem !important',
    },
    card: {
      border: 'none',
      borderRadius: '1rem',
      boxShadow: '0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important',
    },
    cardBody2: {
      flex: '1 1 auto',
      padding: '1rem 1rem',
      paddingBottom: '10px !important',
      marginLeft: '1px',
    },
    p2: {
      padding: '0.5rem !important',
    },
    mb3: {
      marginBottom: '1rem !important',
    },
    racColMD12: {
      flex: '0 0 auto',
      width: '100%',
    },
    table: {
      width: '100%',
      color: '#212529',
      verticalAlign: 'top',
      borderColor: '#dee2e6',
    },
    tableBorder: {
      captionSide: 'bottom',
      borderCollapse: 'collapse',
    },
    float: {
      float: 'right',
    },
    tableRow1: {
      width: '96%',
      padding: '5px',
      background: '#EFF4FF',
      marginRight: '17px',
      marginLeft: '7px',
      // marginBottom:"1px"
    },
    floatLeft: {
      float: 'left',
    },
    SeeTodaysDropWid:{
      float: 'right', width: '20%'
    },
    drawerTotal: {
      color: '#2179FE',
      padding: '1px',
      paddingLeft: '24px',
    },
    customerToolbar: {
      position: 'fixed',
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1030,
      backgroundColor: `white`,
      boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
      paddingLeft: theme.typography.pxToRem(16),
      paddingRight: theme.typography.pxToRem(16),
      paddingTop: theme.typography.pxToRem(16),
      paddingBottom: theme.typography.pxToRem(16),
    },
    cashPaddingTop:{
      paddingTop: '4%'
    },
    formValueCash:{
      width: '100%', paddingTop: '6.5px'
    },
    formValueTotal:{flex: '0 0 auto', width: '20%'},
    formValueTotalTypo:{
      marginBottom: '0.2rem',
      paddingTop: '6.5px',
      color: '#111111',
      //  fontFamily: "OpenSans-semibold",
      fontSize: '14px',
      width: '32%',
    },
    formValueTotalAmount:{
      fontFamily: 'OpenSans-semibold',
      fontSize: '14px',
      paddingTop: '6.5px',
      color: '#4A5174',
      width: '68%',
    },
    DropVerifiedByGrid:{
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: '0% !important',
    },
    DropVerifiedByGridWid:{
      width: '25%'
    },
    DropVerifiedByTypoWid:{
      width: '100%',
      fontSize: '18px',

      // fontFamily: "OpenSans-bold",
      marginBottom: '15px',
    },
    DropNotesGrid:{
      width: '72.2%',
      float: 'left',
      marginLeft: '2.5%',
      marginTop: '0% !important',
      paddingBottom: '20px',
    },
    DropNotesTextArea:{
      width: '100%',
      border: '1px solid #C4C4C4',
      padding: '0.375rem 0.75rem',
      borderRadius: '0.6rem',
      backgroundColor: '#fff',
      transition:
        'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
      fontSize: '14px',
      fontFamily: 'OpenSans-semibold',
      resize: 'none',
    },
    fixedBottom: {
      position: 'fixed',
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1030,
      backgroundColor: `white`,
      boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
      padding: '1rem',
    },
    floatRight: {
      width: '96',
      float: 'right',
      marginBottom: '18.5px',
      marginTop: '15px',
      marginRight: '15px',
    },
    racColMD7: {
      flex: '0 0 auto',
      width: '50%',
    },
    DropEnvelopeWidth:{
      width: '50%'
    },
    nonCashWidth:{
      flex: '0 0 auto', width: '24%' 
    },
    mt2: {
      marginTop: '0.5rem !important',
    },
    cardBody: {
      flex: '1 1 auto',
      padding: '1rem 1rem',
      paddingBottom: '10px !important',
    },
    colMd2: {
      flex: '0 0 auto',
      width: '24.6666%',
    },
    tableCellTotal: {
      width: '40%',
      textAlign: 'right',
      // textAlign:"center"
    },
    total: {
      textAlign: 'right',
      paddingRight: '6%',
    },
    formLabelNonCash: {
      marginBottom: '0.2rem',
      color: '#111111',
      // fontFamily: "OpenSans-semibold",
      fontSize: '14px',
      float: 'left',
      width: '49%',
    },
    formValue: {
      fontFamily: 'OpenSans-semibold',
      fontSize: '14px',
      color: '#4A5174',
    },
    formLabelCash: {
      marginBottom: '0.2rem',
      color: '#111111',
      // fontFamily: "OpenSans-semibold",
      fontSize: '14px',
      float: 'left',
      width: '29%',
    },
    formLabelDefaultStarting: {
      marginBottom: '0.2rem',
      color: '#656565',
      fontFamily: 'OpenSans-semibold',
      fontSize: '12px',
      float: 'left',
      width: '100%',
    },
    formLabelTotalCash: {
      marginBottom: '0.2rem',
      color: '#111111',
      // fontFamily: "OpenSans-semibold",
      fontSize: '14px',
      float: 'left',
      width: '29%',
    },
    formLabelDefaultCashDrop: {
      marginBottom: '0.2rem',
      color: '#111111',
      // fontFamily: "OpenSans-semibold",
      fontSize: '14px',
      float: 'left',
      width: '69%',
    },
    mb0: {
      marginBottom: '0 !important',
    },
    tableRow2: {
      width: '38%',
      padding: '5px',
      background: '#EFF4FF',
      marginRight: '30px',
    },
    dropNotes: {
      '&:focus': {
        width: '100%',
        color: '#212529',
        backgroundColor: '#fff',
        borderColor: '#86b7fe',
        outline: '0',
        boxShadow: '0 0 0 0.25rem rgb(13 110 253 / 25%)',
      },
    },
    footerGrid: {
      paddingTop: '120px !important',
      marginRight: '-0.75rem',
      marginLeft: '-0.75rem',
      display: 'flex',
      flexWrap: 'wrap',
    },
    footerFixed: {
      backgroundColor: '#fff !important',
      padding: '1rem !important',
      boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%) !important',
      position: 'fixed',
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1030,
    },
    textWidth: {
      width: '75% !important',

      '& input': {
        height: '23px',
      },
    },
    textWidthamount: {
      // '&div':{padding:"4px"},

      width: '76% !important',

      '& input': {
        height: '28px',
      },
    },
    // '&div':{padding:"4px"}
    currency: {
      '&div': {
        '&div': {
          '&div': {
            '&div': {
              //       '&div':{
              padding: '10px',
              backgroundColor: 'red',
            },
          },
        },
      },
      // }
    },
    dollarClass: {
      padding: '11px',
      width: '24%',
    },
    quantity: {
      width: '92%',
      '& input': {
        height: '23px',
      },
    },
    tableCellTotalQuantity: {
      width: '34.33%',
      paddingLeft: '4%',
      textAlign: 'center',
    },
    currenttill: {
      width: '100%',
      '& input': {
        height: '23px',
      },
      marginRight: '2%',
    },
    overShort: {
      width: '100%',
      '& input': {
        height: '23px',
      },
      border: 'null',
      borderRadius: '5px',
      // marginRight:"2%"
    },

    totalQuantity: {
      width: '100%',
      '& input': {
        height: '23px',
      },
      borderRadius: '2px',
      marginLeft: '14%',
    },
    validatebtn: {
      '& input': {
        height: '23px',
      },
    },
    textCenter: {
      textAlign: 'center',
    },

    RACPOPMsg: {
      fontFamily: 'OpenSans-bold',
      fontSize: theme.typography.pxToRem(16),
      letterSpacing: 0,
    },
    marginTop: {
      marginTop: '-1rem',
    },
    justifyContentCenter: {
      justifyContent: 'center',
    },

    explanationContent: {
      justifyContent: 'right',
    },
    spacerMT4: {
      marginTop: theme.typography.pxToRem(24),
    },
    spacerMR2: {
      marginRight: theme.typography.pxToRem(8),
    },
    foc: {
      '&:focus': {
        borderColor: '#80bdff',
        boxShadow: '1px 1px 6px 3px #80bdff',
      },
    },
    rowMt2: {
      display: 'flex',
      flexWrap: 'wrap',
      //eslint-disable-next-line sonarjs/no-duplicate-string
      marginTop: '0.5rem !important',
    },
    colMd12mt3: {
      flex: '0 0 auto',
      width: '100%',
      marginTop: '1rem !important',
    },
    row: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: '0',
      marginRight: '-0.75rem',
    },
    header: {
      width: '100%',
      fontSize: '18px',
      fontFamily: 'OpenSans-bold',
      marginBottom: '15px',
    },
    card: {
      border: 'none',
      borderRadius: '1rem',
      boxShadow: '0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important',
    },
    cardBody: {
      flex: '1 1 auto',
      padding: '1rem 1rem',
      paddingBottom: '10px !important',
    },
    mt3: {
      marginTop: '1rem !important',
    },
    cardBody2: {
      flex: '1 1 auto',
      padding: '1rem 1rem',
      paddingBottom: '10px !important',
      marginLeft: '5%',
      marginRight: '6%',
    },
    p2: {
      padding: '0.5rem !important',
    },
    racColMD12: {
      flex: '0 0 auto',
      width: '100%',
    },
    mb3: {
      marginBottom: '1rem !important',
    },
    table: {
      width: '100%',
      color: '#212529',
      verticalAlign: 'top',
      borderColor: '#dee2e6',
    },
    tableBorder: {
      captionSide: 'bottom',
      borderCollapse: 'collapse',
    },
    float: {
      float: 'right',
    },
    tableCellDenomination: {
      width: '33.33%',
      // marginRight:"10% !important"
    },
    totalCashBeingDrop:{
      border: 'none',
      borderRadius: '6px',
      width: '80%',
      padding: '5px',
      background: '#EFF4FF',
      marginTop: '5%',
      marginBottom: '0.7%',
    },
    totalQuantity: {
      width: '80%',
      marginLeft: '20%',
      '& input': {
        height: '23px',
      },

      // marginLeft:"33px"
    },
    total: {
      textAlign: 'right',

      // paddingRight:"9%"
    },
    typoTotal: {
      marginRight: '4%',
    },
    tableCellTotal: {
      width: '32.33%',
    },
    TotalCashBeingWidth:{
      width: '60%'
    },
    TotalCashBeingGridWidth:{
      width: '40%'
    },
    tableRow1: {
      width: '63%',
      padding: '5px',
      background: '#EFF4FF',
      marginRight: '30px',
    },
    dropAmountCash:{
      marginRight: '7px',
      color: '#2179FE',
      padding: '4px',
      textAlign: 'right',
      paddingLeft: '0px',
    },
    totalNonCashhdropAmountCash:{
      marginRight: '14%',
      color: '#2179FE',
      padding: '2px',
      textAlign: 'right',
      paddingLeft: '0px',
    },
    DropInformationWid:{
      float: 'left',
      width: '60%',
      marginLeft: '3.5%',
      marginBottom: '20px',
    },
    DropInformationMarginBtm:{
      marginBottom: '20px',
    },
    DropInformationPadding:{
      padding: '0.5%' 
    },
    nonCashBeingDropWid:{
      float: 'left',
      width: '60%',
      marginLeft: '3.5%',
      paddingBottom: '19px',
    },
    Customer:{
      width:"16%" , marginLeft:"8px"
    },
    DocType:{
      width:"14%" 
    },
    Doc:{
      width:"12%"
    },
    TotalnonCashWidth:{
      border: 'none',
      borderRadius: '6px',
      width: '45%',
      padding: '5px',
      background: '#EFF4FF',
      marginTop: '1%',
      marginBottom: '0.5%',
      marginRight: '8.8%',
    },
    Verified:{width:"12%",textAlign:"center"},
    NameonDoc:{width:"16%"},
    NoRecordFound:{
      marginTop:"12%",marginLeft:"42%"
    },
    nonCashBeingDropCardWid:{
      paddingBottom: '2%',overflow: 'auto',overflowX:"hidden", height: '250px', width: '100%' 
    },

    floatLeft: {
      float: 'left',
    },
    drawerTotal: {
      color: '#2179FE',
      padding: '4px',
      paddingLeft: '24px',
    },
    RACbutton: {
      color: '#0d6efd',
      borderColor: '#0d6efd',
      display: 'inline-block',
      fontWeight: 400,
      lineHeight: '1.5',
      textAlign: 'center',
      textDecoration: 'none',
      verticalAlign: 'middle',
      userSelect: 'none',
      backgroundColor: 'transparent',
      border: '1px solid transparent',
      padding: '0.375rem 0.75rem',
      fontSize: '1rem',
      borderRadius: '0.25rem',
      width: '56%',
      transition:
        'color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
      '&:hover': {
        color: '#fff',
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
      },
    },
    RACTextBox: {
      width: '50% !important',
      '&:focus': {
        color: '#212529',
        backgroundColor: '#fff',
        borderColor: '#86b7fe',
        outline: '0',
        boxShadow: '0 0 0 0.25rem rgb(13 110 253 / 25%)',
      },
      '& input': {
        padding: '9px',
      },
    },
    currenttill: {
      width: '83%',
      '& input': {
        height: '23px',
      },
      marginRight: '0px',
    },
    tableRow: {
      background: 'none',
    },
    tableCell1: {
      paddingTop: '0 !important',
    },
    typographyPadding:{
      paddingTop: '1px'
    },
    formLabelDropEnvelope: {
      width: '30%',
      marginTop: '1.7%',
      marginLeft: '3%',
    },
    envelopeNumbertextBox: {
      width: '80% !important',

      '& input': {
        height: '23px',
      },
      // marginLeft: "5%",
      // padding: "1%",
    },
    formValue: {
      fontFamily: 'OpenSans-semibold',
      fontSize: '14px',
      color: '#4A5174',
    },
    racColMD7: {
      flex: '0 0 auto',
      width: '58.33%',
    },
    mt2: {
      marginTop: '0.5rem !important',
    },
    colMd2: {
      flex: '0 0 auto',
      width: '25%',
    },
    formLabelNonCash: {
      marginBottom: '0.2rem',
      color: '#111111',
      // fontFamily: "OpenSans-semibold",
      fontSize: '14px',
      float: 'left',
      width: '40%',
    },
    formLabelCash: {
      marginBottom: '0.2rem',
      color: '#111111',
      // fontFamily: "OpenSans-semibold",
      fontSize: '14px',
      float: 'left',
      width: '27% !important',
    },

    formLabelDefaultStarting: {
      marginBottom: '0.2rem',
      color: '#4A5174',
      fontFamily: 'OpenSans-semibold',
      fontSize: '12px',
      float: 'left',
      width: '100%',
    },
    formLabelDefaultCash: {
      marginBottom: '0.2rem',
      color: '#111111',
      // fontFamily: "OpenSans-semibold",
      fontSize: '14px',
      float: 'left',
      width: '70%',
    },
    totalNoncashwidths:{
      width: '70%'
    },
    totalNoncashTypowidth:{
      width: '30%' 
    },
    colLg4: {
      flex: '0 0 auto',
      width: '24.333333%',
      marginLeft: '2%',
    },
    columnMd2: {
      flex: '0 0 auto',
      width: '65%',
    },
    formLabelMaxDrop: {
      marginBottom: '0.2rem',
      color: '#111111',
      // fontFamily: "OpenSans-semibold",
      fontSize: '14px',
      float: 'left',
    },
    colMd4: {
      flex: '0 0 auto',
      width: '35%',
    },
    cashText: {
      fontFamily: 'OpenSans-bold',
      fontSize: '18px',
      color: '#2179FE',
    },
    mb0: {
      marginBottom: '0 !important',
    },
    tableRow2: {
      width: '38%',
      padding: '5px',
      background: '#EFF4FF',
      marginRight: '30px',
    },
    DropEnvelope:{
      paddingTop: '6.5px',
      color: '#111111',
      //  fontFamily: "OpenSans-semibold",
      fontSize: '14px',
      float: 'left',
      width: '100%',
    },
    DropEnvelopeWid:{
      width: '46%'
    },
    dropNotes: {
      '&:focus': {
        color: '#212529',
        backgroundColor: '#fff',
        borderColor: '#86b7fe',
        outline: '0',
        boxShadow: '0 0 0 0.25rem rgb(13 110 253 / 25%)',
      },
    },
    footerGrid: {
      // paddingTop: "120px !important",
      marginRight: '-0.75rem',
      marginLeft: '-0.75rem',
      display: 'flex',
      flexWrap: 'wrap',
    },
    footerFixed: {
      backgroundColor: '#fff !important',
      padding: '1rem !important',
      boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%) !important',
      position: 'fixed',
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1030,
    },
    textWidth: {
      width: '75% !important',

      '& input': {
        height: '23px',
      },
    },
    racTablestyleWidth:{
      width: '14.5%' 
    },
    racCheckBoxMarginLeft:{
      marginLeft: '10px'
    },
    textCenter: {
      textAlign: 'center',
    },
    textRight:{
      textAlign: 'right',
    },
    RACPOPMsg: {
      fontFamily: 'OpenSans-bold',
      fontSize: theme.typography.pxToRem(16),
      letterSpacing: 0,
    },
    marginTop: {
      marginTop: '-1rem',
    },
    justifyContentCenter: {
      justifyContent: 'center',
    },
    spacerMT4: {
      marginTop: theme.typography.pxToRem(24),
    },
    spacerMR2: {
      marginRight: theme.typography.pxToRem(8),
    },
    foc: {
      '&:focus': {
        borderColor: '#80bdff',
        boxShadow: '1px 1px 6px 3px #80bdff',
      },
    },
    quantitywid: {
      width: '100%',
      '& input': {
        height: '23px',
      },
      paddingLeft: '23px',
    },
    tableroW: {
      paddingTop: '0 !important',
      paddingBotttom: '0 !important',
    },
    denominationwidth: {
      width: '88%',
      '& input': {
        height: '23px',
      },
    },
    pendingPetty: {
      color: '#4A5174',
      width: '68% !important',

      '& input': {
        height: '17px',
      },
      border: 'none',
      borderRadius: '3px',
      float: 'right',
    },
    totalNonCash: {
      marginRight: '13%',
      marginBottom: '0.5%',
      marginTop: '3%',
      border: 'none',
      borderRadius: '4px',
      width: '40%',
      padding: '9px',
      background: '#EFF4FF',
    },
    topBottomSpaceOverShort: {
      marginTop: '15px',
      marginBottom: '20px',
    },
    overShortNonCash: {
      marginBottom: '0.2rem',
      paddingTop: '2px',
      color: '#111111',
      fontSize: '14px',
      float: 'left',
      width: '39%',
    },
    overShortCash: {
      marginBottom: '0.2rem',
      color: '#111111',
      paddingTop: '2px',
      fontSize: '14px',
      float: 'left',
      width: '30%',
    },
    overShortTotal: {
      marginBottom: '0.2rem',
      color: '#111111',
      paddingTop: '2px',
      fontSize: '14px',
      float: 'left',
      width: '32%',
    },
    columnStyle: {
      float: 'right',
      width: '100%',

      paddingBottom: '20px',
    },
    topBottomSpace: {
      marginTop: '15px',
      marginBottom: '10px',
    },
    textArea: {
      marginTop: '5px',
      width: '100%',
      padding: '6px',
      paddingBottom: '31px',
      resize: 'none',
      border: '1px solid #C4C4C4',
      paddingTop: '5px',
      paddingRight: '5px',
      borderRadius: '0.6rem',
      backgroundColor: '#fff',
      transition:
        'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
      fontSize: '14px',
      fontFamily: 'OpenSans-semibold',
    },
    denomination: {
      width: '28%',
    },
    totalWidth: {
      width: '40%',
    },
    loaderStyle: {
      border: 'none',
      backgroundColor: 'transparent',
      // userFocus: 'none',
      // MozUserFocus: 'none',
      outline: 'none',
    },
    nonCashGridWid:{
      width: '43%'
    },
    dropAmountWids:{
      width: '57%',
    },
    formValueDropAmount:{
      width: '100%', paddingTop: '6.5px' 
    },
    formValueGridDropAmount:{
      flex: '0 0 auto', width: '23%' 
    },
    nonCashTypoWid:{marginBottom: '0.2rem',
    paddingTop: '8.5%',
    color: '#111111',
    //   fontFamily: "OpenSans-semibold",
    fontSize: '14px',
    float: 'left',
    width: '100%'},
   updatePopupRacBtn:{
    textTransform: 'none',
    width: '100px !important',
    height: '35px',
   },
   tableContentheight:{
    height: '4rem'
   },
   cashBeingDropped:{
    float: 'left', width: '35%'
   },
   racTableCellColor:{
    color: '#4A5174'
   },
   racTableRowhyt:{
    height: '4rem' 
   },
   seeTodaysBtnMarginRyt:{
    marginRight: '22px'
   }
  }));
  return UseClasses();
};
