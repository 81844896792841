/* eslint-disable */
/* eslint-disable sonarjs/no-nested-template-literals */
/* eslint-disable sonarjs/no-redundant-jump */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { customerStyles } from '../customerStyles';
import clsx from 'clsx';
import {
  Grid,
  Typography,
  Card,
  CardContent,
  RACTextbox,
  RACButton,
  RACModalCard,
  RACTableCell,
  RACTableRow,
  RACCheckBox,
  RACTable,
  CircularProgress,
} from '@rentacenter/racstrap';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import { ReactComponent as SuccessIcon } from '../../assets/images/success-icon.svg';
import { ReactComponent as Alerticon } from '../../assets/images/no-records-found.svg';
import SecondFactor from '../SecondAuthen/secondAuthen';
import StoreException from '../CashPaid/StoreException';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
// import { CurrentTillFD } from "./CurrentTillFD";
// import { CashBeingDroppedFD } from "./CashBeingDroppedFD";
// import { drawerTotal } from "../constants/sampleConstants";
import { getDrawerDetail, manageDrop, getConfigDetails, storeException } from '../../api/user';
import { CashmgmtDropDetailContext } from '../../context/CashMgmtContext';
import { formatMoney } from "../../constants/constants";
// import { faHandHoldingMedical } from '@fortawesome/free-solid-svg-icons';
// import { drawerTotal } from '../constants/sampleConstants';
// import { number, string } from "yup";
// import { drop } from "lodash";
export interface denominationQuantityInterface {
  denominationQuantity100: string;
  denominationQuantity50: string;
  denominationQuantity20: string;
  denominationQuantity10: string;
  denominationQuantity5: string;
  denominationQuantity2: string;
  denominationQuantity1: string;
  denominationQuantity001: string;
  denominationQuantity005: string;
  denominationQuantity010: string;
  denominationQuantity025: string;
  denominationQuantity050: string;
}
export function Finaldrops() {
  const classes = customerStyles();
  const history = useHistory();
  let { storeExceptionPayload, setstoreExceptionPayload } = React.useContext(CashmgmtDropDetailContext)


  const [modals, setModals] = useState({
    validateNonCashIsOpen: false,
    currentTillAcceptBtnLessIsOpen: false,
    currentTillAcceptBtnGreaterIsOpen: false,
    CashBeingValidateGreaterBtn: false,
    CashBeingValidateLessBtn: false,
    handleValidateCashAck_YesBtn: false,
    ConfirmationSaveIsOpen: false,
    secondLevelAuthenOn: false,
    explanationModalIsOpen: false,
    ExplanationCancelPopupIsOpen: false,
    OverShortAmountIsOpen: false,
    overShortTotalIsOpen: false,
    ConfirmationCancelIsOpen: false,
    ConfiguredDropMaxTimeLimit: false,
    saveBtnLessDefaultStartingTill: false,
    finalNotRequired: false,
  });
  const [StoreExceptionEnable, setStoreExceptionEnable] = useState(false)

  const [denominationQuantityCT, setDenominationQuantityCT] =
    useState<denominationQuantityInterface>({
      denominationQuantity100: '0',
      denominationQuantity50: '0',
      denominationQuantity20: '0',
      denominationQuantity10: '0',
      denominationQuantity5: '0',
      denominationQuantity2: '0',
      denominationQuantity1: '0',
      denominationQuantity001: '0',
      denominationQuantity005: '0',
      denominationQuantity010: '0',
      denominationQuantity025: '0',
      denominationQuantity050: '0',
    });
  const [denominationQuantityCBD, setDenominationQuantityCBD] =
    useState<denominationQuantityInterface>({
      denominationQuantity100: '0',
      denominationQuantity50: '0',
      denominationQuantity20: '0',
      denominationQuantity10: '0',
      denominationQuantity5: '0',
      denominationQuantity2: '0',
      denominationQuantity1: '0',
      denominationQuantity001: '0',
      denominationQuantity005: '0',
      denominationQuantity010: '0',
      denominationQuantity025: '0',
      denominationQuantity050: '0',
    });
  // const [saveDisable,setSaveDisable] = useState<booleanType>({totalCash: false,totalNonCash: false, dropEnvpNum: false
  // })
  const [currentTill, setCurrentTill] = useState<string>('0');
  // const[cashBeingDrop,setCashBeingDrop] =useState<string>('0')
  // const [nonCashValidateDisable,setNonCashValidateDisable] =useState(true)
  const [disableAmtBtn,setDisableAmtBtn]=useState(false);
  const [cashBeingDropAmount, setCashBeingDropAmount] = useState('0');
  const [drawerDetails, setDrawerDetails] = useState<any>();
  const [dropAmount, setDropAmount] = useState({ cash: '0', nonCash: '0' });
  // const [loader, setLoader] = useState(false);
  const [over_Short, setOver_Short] = useState({ cash: '0', nonCash: '0' });
  const [disableFieldsCurrentTill, setDisableFieldsCurrentTill] =
    useState(false);
  const [saveBtnEnable,setSaveBtnEnable] = useState(false)
  const [nonCashEnable,setNonCashEnable]=useState(false)
  const [disableFieldsCashBeingDropped, setDisableFieldsCashBeingDropped] =
    useState(true);
  const [dropEnvelopePopup,setDropEnvelopePopup] = useState(false);
  const [isSkeleton, setIsSkeleton] = useState(true);
  const [successPopup, setSuccessPopup] = useState(false);
  const [isSuccess, setIsSucess] = useState(true);
  const [envNumber, setEnvNumber] = useState('');
  const [dropNotes, setDropNotes] = useState('');
  const [drawerTotalAmt, setDrawerTotalAmt] = useState('');
  const [expectedTotalAmt, setExpectedTotalAmt] = useState('');
  const [secondLevelAuthenOn, setSecondLevelAuthenOn] = useState(false);
  const [overShortExplanationNotes, setOverShortExplanationNotes] =
    useState('');
  const [defaultStringAmount, setdefaultStringAmount] = useState('0');
  const [shortExplanation, setShortExplanation] = useState('');
  const [validateYes, setValidateYes] = useState(false);
  const [nonCashEnableSave,setNonCashEnableSave] = useState(false);
  //   const [nonCashNoData, setNonCashNoData] = useState(false);
  const [enableSave, setEnableSave] = useState(false);
  const [loader, setLoader] = useState(false);
  const [defaultLoader , setDefaultLoader] = useState(false);
  const [saveEnableDefaultStartingTill , setSaveEnableDefaultStartingTill ] = useState(true)
  const [saveEnable , setSaveEnable] = useState(false)
  const [saveLessDefaultStartTill,setSaveLessDefaultStartTill] = useState(false);
  //  PC_01,Sd_1.1
  useEffect(() => {
    pageLoad();
  }, []);
  const pageLoad = async () => {
    try {
      // debugger;
      setLoader(true)
      setDefaultLoader(true)
      const storeNumber = window.sessionStorage.getItem('storeNumber');
      const DrawerDetails = await Promise.all(
        [getDrawerDetail({storeNumber: storeNumber,businessDate: moment().format('YYYY-MM-DD'),}),
        (storeNumber && storeNumber != null && storeNumber != undefined && storeNumber != '' && storeNumber != 'null' && storeNumber!= 'undefined') ?
          getConfigDetails({storeNumbers: [sessionStorage.getItem('storeNumber')],paramKeyNames: ['MaxDrawerLimit' ,'ReconciliationMaxLimit','DrawerDefaultAmount','DropMaxTimeLimit'],}): Promise.resolve(),]);
      const draweractivity = DrawerDetails[0]
      const dropMaxTime=DrawerDetails[1]
      const currentTime = moment().format('hh:mm A')
      setLoader(false)
      setDefaultLoader(true)
      setdefaultStringAmount(dropMaxTime.storeProfileResponse.configDetails[0].configDetails.filter((el: any) => el.paramKeyName == 'DrawerDefaultAmount')[0].paramValue)
      console.log("currentTime", currentTime)
      if (currentTime < moment(dropMaxTime.storeProfileResponse.configDetails[0].configDetails.filter((el: any) => el.paramKeyName == 'DropMaxTimeLimit')[0].paramValue).format('hh:mm A')){
        handleModels('ConfiguredDropMaxTimeLimit',true)
      }
      console.log('Final Drop response', draweractivity);
      const nonCashResponse: any = [];
      draweractivity !== undefined &&
        draweractivity.storeTransaction !== undefined &&
        draweractivity.storeTransaction.nonCashDetails !== undefined &&
        draweractivity.storeTransaction.nonCashDetails !== null &&
        draweractivity.storeTransaction.nonCashDetails.length > 0
        ? draweractivity?.storeTransaction?.nonCashDetails.map((el: any) => {
          nonCashResponse.push({ ...el, isChecked: false });
        })
        : [];
      setDrawerDetails({
        ...drawerDetails,
        storeTransaction: draweractivity?.storeTransaction,
        NonCashDetails: nonCashResponse,
      });
      if (draweractivity !== undefined && draweractivity !== null) {
        setIsSkeleton(false);
      }
      if(draweractivity?.storeTransaction?.totalCash < 0){
        setDisableAmtBtn(true);
        setSaveEnable(true);
      }
      const totalCash =
        draweractivity !== undefined &&
          draweractivity.storeTransaction !== undefined &&
          draweractivity.storeTransaction.totalCash !== undefined &&
          draweractivity.storeTransaction.totalCash !== null
          ? parseFloat(draweractivity.storeTransaction.totalCash)
          : 0;
      setDrawerTotalAmt(
        (
          parseFloat(dropMaxTime.storeProfileResponse.configDetails[0].configDetails.filter((el: any) => el.paramKeyName == 'DrawerDefaultAmount')[0].paramValue ) +
          parseFloat(
            draweractivity !== undefined &&
              draweractivity.storeTransaction !== undefined &&
              draweractivity.storeTransaction.totalNonCash !== undefined &&
              draweractivity.storeTransaction.totalNonCash !== null
              ? draweractivity.storeTransaction.totalNonCash
              : '0'
          ) +
          parseFloat(
            draweractivity !== undefined &&
              draweractivity.storeTransaction !== undefined &&
              draweractivity.storeTransaction !== null &&
              draweractivity.storeTransaction.totalCash !== undefined &&
              draweractivity.storeTransaction.totalCash !== null
              ? draweractivity.storeTransaction.totalCash
              : '0.00'
          )
        ).toFixed(2)
      );
      if( draweractivity.storeTransaction.nonCashDetails.length == 0){
        setNonCashEnable(true)
        setNonCashEnableSave(true)
      }
      setExpectedTotalAmt(
        (
          parseFloat(
            draweractivity !== undefined &&
              draweractivity.storeTransaction !== undefined &&
              draweractivity.storeTransaction.totalNonCash !== undefined &&
              draweractivity.storeTransaction.totalNonCash !== null
              ? draweractivity.storeTransaction.totalNonCash
              : '0'
          ) +
          parseFloat(
            draweractivity !== undefined &&
              draweractivity.storeTransaction !== undefined &&
              draweractivity.storeTransaction !== null &&
              draweractivity.storeTransaction.totalCash !== undefined &&
              draweractivity.storeTransaction.totalCash !== null
              ? draweractivity.storeTransaction.totalCash
              : '0.00'
          )
        ).toFixed(2)
      );
    } catch (e) {
      console.log(e);
    }
    // if (drawerDetails?.NonCashDetails.length == 0) {
    //   setNonCashNoData(true);
    // }
  };
  const getNonCashDetails = () => {
    const totalNonCash = parseFloat(
      drawerDetails !== undefined &&
        drawerDetails?.storeTransaction !== undefined &&
        drawerDetails?.storeTransaction !== null &&
        drawerDetails?.storeTransaction !== '' &&
        drawerDetails?.storeTransaction?.totalNonCash !== undefined
        ? drawerDetails?.storeTransaction?.totalNonCash
        : '0.00'
    );
    return totalNonCash >=0 ? '$'+` `+ totalNonCash.toFixed(2) :  '($'+` `+ (totalNonCash * -1).toFixed(2) +')'
    // return totalNonCash.toFixed(2);
  };
  const getCashDetails = () => {
    const totalCash = parseFloat(
      drawerDetails !== undefined &&
        drawerDetails?.storeTransaction !== undefined &&
        drawerDetails?.storeTransaction !== null &&
        drawerDetails?.storeTransaction.totalCash !== undefined &&
        drawerDetails?.storeTransaction.totalCash !== null
        ? drawerDetails?.storeTransaction.totalCash
        : 0
    );
    return(totalCash + parseFloat(defaultStringAmount)) >=0 ?  '$'+` `+ (totalCash + parseFloat(defaultStringAmount)).toFixed(2) :  '($'+` `+(-(totalCash + parseFloat(defaultStringAmount))).toFixed(2) +')'
    // return (totalCash + parseFloat(defaultStringAmount)).toFixed(2);
  };
  const getExpectedCashDetails = () => {
    const totalCash = parseFloat(
      drawerDetails !== undefined &&
        drawerDetails?.storeTransaction !== undefined &&
        drawerDetails?.storeTransaction !== null &&
        drawerDetails?.storeTransaction.totalCash !== undefined &&
        drawerDetails?.storeTransaction.totalCash !== null
        ? drawerDetails?.storeTransaction.totalCash
        : 0
    );
    return totalCash >=0 ? '$'+` `+ totalCash.toFixed(2) : '($'+` `+(totalCash * -1).toFixed(2)+')';
  };
  // function ConfiguredDropMaxLimit() {
  //   return (
  //     <RACModalCard
  //       isOpen={modals.ConfiguredDropMaxTimeLimit}
  //       maxWidth="sm"
  //       closeIcon={true}
  //       borderRadius="20px"
  //       onClose={() => handleModels('ConfiguredDropMaxTimeLimit', false)}
  //       // eslint-disable-next-line react/no-children-prop
  //       children={ConfiguredDropMaxLimitPopup()}
  //     // title="Validation Message"
  //     />
  //   );
  // }
  const ConfiguredDropMaxLimitPopup = () => {
    return (
      <Grid item md={12}>
        <Grid
          item
          md={12}
          className={`${classes.textCenter} ${classes.marginTop}`}
        >
          <Typography className={classes.RACPOPMsg}>
            The time now is less than the configured final drop time.
            <Typography className={classes.RACPOPMsg}> do you want to continue?</Typography>
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
        >
          <RACButton
            className={`${classes.spacerMR2} ${classes.foc}`}
            size="small"
            variant="outlined"
            color="primary"
            isRounded={false}
            onClick={() => ConfiguredDropMaxLimit_NoBtn()}
          // onClick={() => handleModels('validateNonCashIsOpen',false)}
          >
            No
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => ConfiguredDropMaxLimit_YesBtn()}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  function ConfiguredDropMaxLimit_NoBtn(){
    history.push({
      pathname: `/dashboard/homepage`,
    });
  }
  function ConfiguredDropMaxLimit_YesBtn(){
    handleModels('ConfiguredDropMaxTimeLimit',false)
  }
  function handleModels(key: string, value: boolean) {
    // console.log('Model value',key,value)
    setModals({ ...modals, [key]: value });
  }
  const handleNonCashDetail = (e: any, recID: any) => {
    console.log("defaultStringAmount",defaultStringAmount)
    // debugger;
    let nonCashbeingdrop = Number(dropAmount.nonCash);
    if (drawerDetails !== undefined) {
      if (drawerDetails !== undefined ? e.target.checked : '') {
        for (
          let nonCashDetails = 0;
          nonCashDetails < drawerDetails?.NonCashDetails.length;
          nonCashDetails++
        ) {
          if (
            drawerDetails !== undefined
              ? drawerDetails?.NonCashDetails[nonCashDetails].receiptTransactionId
              ==
              recID
              : ''
          ) {
            nonCashbeingdrop += Number(
              drawerDetails?.NonCashDetails[nonCashDetails].cashAmount
            );
            break;
          }
        }
      } else {
        for (
          let nonCashDetails = 0;
          nonCashDetails < drawerDetails?.NonCashDetails.length;
          nonCashDetails++
        ) {
          if (
            drawerDetails !== undefined
              ? drawerDetails?.NonCashDetails[nonCashDetails].receiptTransactionId
              ==
              recID
              : ''
          ) {
            nonCashbeingdrop -= Number(
              drawerDetails?.NonCashDetails[nonCashDetails].cashAmount
            );
            break;
          }
        }
      }
    }

    // for (let nonCashDetails = 0; nonCashDetails < drawerDetails?.NonCashDetails.length; nonCashDetails++) {
    //     if (e.target.checked) {
    //         nonCashbeingdrop += Number(drawerDetails?.NonCashDetails[nonCashDetails].amount)
    //     }else{
    //         nonCashbeingdrop += Number(drawerDetails?.NonCashDetails[nonCashDetails].amount)
    //     }
    // }
    const nonCashArr =
      drawerDetails &&
      drawerDetails?.NonCashDetails.map((el: any) => {
        if (!el.isChecked && e.target.checked && el.receiptTransactionId
          == recID) {
          el.isChecked = true;
        } else if (
          el.isChecked &&
          !e.target.checked &&
          el.receiptTransactionId
          == recID
        ) {
          el.isChecked = false;
        }
        return el;
      });
    setDrawerDetails({ ...drawerDetails, NonCashDetails: nonCashArr });
    // if (drawerDetails?.storeTransaction.totalNonCash < nonCashbeingdrop) {
    //     handleModels('currentTillAcceptBtnLessIsOpen', true)
    // }
    setDropAmount({ ...dropAmount, nonCash: nonCashbeingdrop.toFixed(2) });
  };
  const disableNonCashValidateBtn = (): boolean => {
    let isDisable = false;
    if (
      drawerDetails !== undefined &&
      drawerDetails?.NonCashDetails !== undefined
    ) {
      for (
        let nonCashDetails = 0;
        nonCashDetails < drawerDetails?.NonCashDetails.length;
        nonCashDetails++
      ) {
        if (
          drawerDetails !== undefined &&
            drawerDetails?.NonCashDetails !== undefined &&
            drawerDetails?.NonCashDetails !== '' &&
            drawerDetails?.NonCashDetails !== null
            ? drawerDetails?.NonCashDetails[nonCashDetails].isChecked
            : ''
        ) {
          isDisable = false;
          break;
        }
      }
    }
    return isDisable;
  };
  const handleValidateNonCash = () => {
    if (
      drawerDetails !== undefined &&
        drawerDetails?.storeTransaction?.totalNonCash !== undefined &&
        drawerDetails?.storeTransaction?.totalNonCash !== '' &&
        drawerDetails?.storeTransaction?.totalNonCash !== null
        ? parseFloat(dropAmount.nonCash) <
        parseFloat(drawerDetails?.storeTransaction?.totalNonCash)
        : ''
    ) {
      handleModels('validateNonCashIsOpen', true);
    }
    if (
      drawerDetails !== undefined &&
        drawerDetails?.storeTransaction?.totalNonCash !== undefined &&
        drawerDetails?.storeTransaction?.totalNonCash !== '' &&
        drawerDetails?.storeTransaction?.totalNonCash !== null
        ? parseFloat(dropAmount.nonCash) ==
        parseFloat(drawerDetails?.storeTransaction.totalNonCash)
        : ''
    ) {
      setValidateYes(true);
      setEnableSave(true);
      setNonCashEnableSave(true);
      // setSaveEnable(true);
    }
  };
  const handleCurrentTillAcceptBtn = () => {
    // debugger;
    if (parseFloat(currentTill) < parseFloat(defaultStringAmount)) {
      handleModels('currentTillAcceptBtnLessIsOpen', true);
    } else if (parseFloat(currentTill) >= parseFloat(defaultStringAmount)) {
      handleModels('currentTillAcceptBtnGreaterIsOpen', true);
    }
  };
  //Cash being Dropped

  const seconduthenResponses = async (e: Event) => {
    // debugger;
    setSecondLevelAuthenOn(false);
    if (
      parseFloat(over_Short.cash) !== 0 ||
      parseFloat(over_Short.nonCash) !== 0
    ) {
      // setModals({
      //   ...modals,
      //   explanationModalIsOpen: true,
      // });

      setStoreExceptionEnable(true)
    } else {

      setLoader(true);
      setDefaultLoader(true)
      const CashBeingDropped = [];
      const NonCashBeingDropped: any = [];
      drawerDetails?.NonCashDetails.map((value: any) => {
        if (value.isChecked) {
          NonCashBeingDropped.push({
            receiptTransactionId: value.receiptTransactionId.toString(),
            amount: value.cashAmount.toFixed(2),
          });
        }
      });
      for (const [key, value] of Object.entries(denominationQuantityCBD)) {
        if (value !== '0') {
          CashBeingDropped.push({
            denomination:
              key == 'denominationQuantity100'
                ? '100'
                : key == 'denominationQuantity50'
                  ? '50'
                  : key == 'denominationQuantity20'
                    ? '20'
                    : key == 'denominationQuantity10'
                      ? '10'
                      : key == 'denominationQuantity5'
                        ? '5'
                        : key == 'denominationQuantity2'
                          ? '2'
                          : key == 'denominationQuantity1'
                            ? '1'
                            : key == 'denominationQuantity001'
                              ? '0.01'
                              : key == 'denominationQuantity005'
                                ? '0.05'
                                : key == 'denominationQuantity010'
                                  ? '0.10'
                                  : key == 'denominationQuantity025'
                                    ? '0.25'
                                    : key == 'denominationQuantity050'
                                      ? '0.50'
                                      : '0',

            totalQuatity: Number(value),
          });
        }
      }
      const payload = {
        storeNumber: window.sessionStorage.getItem('storeNumber'),

        dropVerifiedBy: '',

        dropNotes: dropNotes !== undefined ? dropNotes : '',

        dropEnvelopNumber: envNumber !== undefined ? envNumber : '',

        isFinalDrop: '1',

        defaultCashAmount: String(defaultStringAmount),

        cashBeingDropped: CashBeingDropped,

        nonCashBeingDropped: NonCashBeingDropped,

        nonCashOverallShort:
          over_Short.nonCash !== undefined ? String(parseFloat(over_Short.nonCash).toFixed(2)) : '0.00',

        cashOverallShort:
          over_Short.cash !== undefined ? String(parseFloat(over_Short.cash).toFixed(2)) : '0.00',

        overAllShortNotes:
          overShortExplanationNotes !== undefined
            ? overShortExplanationNotes
            : '',
      };
      console.log('Drop payload', payload);
      console.log('Drop payload', payload);
      const response = await manageDrop(payload);

      setLoader(false);
      setDefaultLoader(false)
      const errorcode = 'Drop Envelop Number Already Exits'
      const status= 200 ;
      // setSuccessPopup(true);
      if (response.status == status) {
        setSuccessPopup(true);
      }
      else if(response.data.errors[0].error == errorcode){
        setDropEnvelopePopup(true)
      }
      else { setSuccessPopup(true); setIsSucess(false) }
    }
  };

  function handleCancelBtn() {
    handleModels('ConfirmationCancelIsOpen', true);
  }
  function SaveBtnLessDefaultStartingTillModelCard() {
    // debugger;
    return (
      <RACModalCard
        // className={classes.ModelCardWidth}
        isOpen={modals.saveBtnLessDefaultStartingTill}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => {handleModels('saveBtnLessDefaultStartingTill', false); setDisableFieldsCurrentTill(false);}}
        // eslint-disable-next-line react/no-children-prop
        children={saveBtnLessDefaultStartingTillPopup()}
      // title="Validation Message"
      />
    );
  }
  const handle_SaveDefaultStartingTillOkBtn =() =>{
    handleModels('saveBtnLessDefaultStartingTill' ,false)
    setSaveEnableDefaultStartingTill(false)
    setDisableFieldsCurrentTill(false);
    setCashBeingDropAmount('0');
    setCurrentTill('0');
    setSaveLessDefaultStartTill(true);
  }
  const saveBtnLessDefaultStartingTillPopup = () => {
    return (
      <Grid item md={12}>
        <Grid
          item
          md={12}
          style={{
            marginLeft: '1.5%',
            marginRight: '1.5%',
          }}
          className={`${classes.textCenter}`}
        >
          <Typography className={`${classes.RACPOPMsg} ${classes.marginTop}`}>
            The total cash left in drawer should not be less than the default
            starting till.
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
        >
          <RACButton
            className={`${classes.spacerMR2} ${classes.foc}`}
            size="small"
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => handle_SaveDefaultStartingTillOkBtn()}
          // onClick={() => handleModels('validateNonCashIsOpen',false)}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  function handleSaveBtn() {
    console.log("dropAmountCash",dropAmount.cash , "dropAmountNonCash",dropAmount.nonCash)
    console.log("cash",over_Short.cash,"nonCash",over_Short.nonCash)
    if((parseFloat(dropAmount.cash) == 0 && (parseFloat(currentTill) <= parseFloat(defaultStringAmount))) && drawerDetails.storeTransaction.totalNonCash ==0){
      handleModels('finalNotRequired',true);
    }
    else if(Number((parseFloat(currentTill) - parseFloat(dropAmount.cash)).toFixed(2)) < parseFloat(defaultStringAmount) && dropAmount.cash!=='0'){
      handleModels('saveBtnLessDefaultStartingTill',true)
    }
    else {  handleModels('ConfirmationSaveIsOpen', true);
    }
  }
  const handleCashBeingdropValidateBtn = () => {
    let denominationField =true;
    if (
      Number(denominationQuantityCBD.denominationQuantity001) >
      Number(denominationQuantityCT.denominationQuantity001) ||
      Number(denominationQuantityCBD.denominationQuantity005) >
      Number(denominationQuantityCT.denominationQuantity005) ||
      Number(denominationQuantityCBD.denominationQuantity010) >
      Number(denominationQuantityCT.denominationQuantity010) ||
      Number(denominationQuantityCBD.denominationQuantity025) >
      Number(denominationQuantityCT.denominationQuantity025) ||
      Number(denominationQuantityCBD.denominationQuantity050) >
      Number(denominationQuantityCT.denominationQuantity050) ||
      Number(denominationQuantityCBD.denominationQuantity1) >
      Number(denominationQuantityCT.denominationQuantity1) ||
      Number(denominationQuantityCBD.denominationQuantity10) >
      Number(denominationQuantityCT.denominationQuantity10) ||
      Number(denominationQuantityCBD.denominationQuantity100) >
      Number(denominationQuantityCT.denominationQuantity100) ||
      Number(denominationQuantityCBD.denominationQuantity2) >
      Number(denominationQuantityCT.denominationQuantity2) ||
      Number(denominationQuantityCBD.denominationQuantity20) >
      Number(denominationQuantityCT.denominationQuantity20) ||
      Number(denominationQuantityCBD.denominationQuantity5) >
      Number(denominationQuantityCT.denominationQuantity5) ||
      Number(denominationQuantityCBD.denominationQuantity50) >
      Number(denominationQuantityCT.denominationQuantity50)
    ) {
      denominationField=false;
    }
    if(parseFloat(dropAmount.cash) == 0 && ((parseFloat(drawerDetails?.storeTransaction.totalCash)+ parseFloat(defaultStringAmount))>parseFloat(defaultStringAmount))){
      setOver_Short({...over_Short, cash:(-parseFloat(drawerDetails?.storeTransaction.totalCash)).toFixed(2)})
    }
    if (!denominationField) {
      handleModels('CashBeingValidateGreaterBtn', true);
      return;
    } else if (
      drawerDetails !== undefined &&
        drawerDetails?.storeTransaction?.totalCash !== undefined &&
        drawerDetails?.storeTransaction?.totalCash !== '' &&
        drawerDetails?.storeTransaction?.totalCash !== null
        ? (parseFloat(dropAmount.cash) !==
          parseFloat(drawerDetails?.storeTransaction.totalCash ) && ( parseFloat(drawerDetails?.storeTransaction.totalCash )!== 0) )
        : '0.00'
    ) {
      setSaveEnable(true)
      handleModels('CashBeingValidateLessBtn', true);
    } else if (
      drawerDetails !== undefined &&
        drawerDetails?.storeTransaction?.totalCash !== undefined &&
        drawerDetails?.storeTransaction?.totalCash !== '' &&
        drawerDetails?.storeTransaction?.totalCash !== null
        ? parseFloat(dropAmount.cash) ==
        Number(drawerDetails?.storeTransaction?.totalCash)
        : '0.00'
    ) {
      if(saveLessDefaultStartTill){
        setSaveBtnEnable(true)
      }
      setSaveEnable(true)
      setDisableFieldsCashBeingDropped(true);
      setCashBeingDropAmount(dropAmount.cash);
      console.log("dropAmount.cash",dropAmount.cash)
      setDenominationQuantityCT({
        denominationQuantity001: (Math.abs(Number(denominationQuantityCBD.denominationQuantity001) - Number(denominationQuantityCT.denominationQuantity001))).toString(),
        denominationQuantity005: (Math.abs(Number(denominationQuantityCBD.denominationQuantity005) - Number(denominationQuantityCT.denominationQuantity005))).toString(),
        denominationQuantity010: (Math.abs(Number(denominationQuantityCBD.denominationQuantity010) - Number(denominationQuantityCT.denominationQuantity010))).toString(),
        denominationQuantity025: (Math.abs(Number(denominationQuantityCBD.denominationQuantity025) - Number(denominationQuantityCT.denominationQuantity025))).toString(),
        denominationQuantity050: (Math.abs(Number(denominationQuantityCBD.denominationQuantity050) - Number(denominationQuantityCT.denominationQuantity050))).toString(),
        denominationQuantity1: (Math.abs(Number(denominationQuantityCBD.denominationQuantity001) - Number(denominationQuantityCT.denominationQuantity001))).toString(),
        denominationQuantity10: (Math.abs(Number(denominationQuantityCBD.denominationQuantity10) - Number(denominationQuantityCT.denominationQuantity10))).toString(),
        denominationQuantity100: (Math.abs(Number(denominationQuantityCBD.denominationQuantity100) - Number(denominationQuantityCT.denominationQuantity100))).toString(),
        denominationQuantity2: (Math.abs(Number(denominationQuantityCBD.denominationQuantity2) - Number(denominationQuantityCT.denominationQuantity2))).toString(),
        denominationQuantity20: (Math.abs(Number(denominationQuantityCBD.denominationQuantity20) - Number(denominationQuantityCT.denominationQuantity20))).toString(),
        denominationQuantity5: (Math.abs(Number(denominationQuantityCBD.denominationQuantity5) - Number(denominationQuantityCT.denominationQuantity5))).toString(),
        denominationQuantity50: (Math.abs(Number(denominationQuantityCBD.denominationQuantity50) - Number(denominationQuantityCT.denominationQuantity50))).toString()
      })
    }
  };
  function handleYes_SaveBtn() {
    setSecondLevelAuthenOn(true);
    handleModels('ConfirmationSaveIsOpen', false);
    //   if (
    //     parseFloat(over_Short.cash) !== 0 ||
    //     parseFloat(over_Short.nonCash) !== 0
    //   ) {
    //     // setModals({
    //     //   ...modals,
    //     //   explanationModalIsOpen: true,
    //     // });
    //     setModals({
    //       ...modals,
    //       explanationModalIsOpen: true,
    //       ConfirmationSaveIsOpen: false,
    //     });

    // setModals({
    //   ...modals,
    //   ConfirmationSaveIsOpen: false,
    //   overShortTotalIsOpen: false,
    // });

  }
  function ConfirmationSavePopupModelCard() {
    return (
      <RACModalCard
        isOpen={modals.ConfirmationSaveIsOpen}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => handleModels('ConfirmationSaveIsOpen', false)}
        // eslint-disable-next-line react/no-children-prop
        children={ConfirmationSavePopupIsOpen()}
      // title="Validation Message"
      />
    );
  }
  function DropEnvelopModalCard() {
    return (
      <RACModalCard
        isOpen={dropEnvelopePopup}
        maxWidth="sm"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => {setDropEnvelopePopup(false);setShortExplanation('');setOverShortExplanationNotes('')}}
        // eslint-disable-next-line react/no-children-prop
        children={DropEnvelopePopUp()}
      // title="Validation Message"
      />
    );
  }
  const DropEnvelopePopUp = ()=>{
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <Typography className={`${classes.RACPOPMsg} ${classes.marginTop}`}>
            The Drop Envelope Number has already been used today.
            <Typography className={classes.RACPOPMsg} >Please enter a new Envelope Number</Typography>
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
        >
          <RACButton
            className={`${classes.spacerMR2} ${classes.foc}`}
            size="small"
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => {setDropEnvelopePopup(false);setShortExplanation('');setOverShortExplanationNotes('')}}
          // onClick={() => handleModels('validateNonCashIsOpen',false)}
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  function ConfirmationCancelIsOpen() {
    return (
      <RACModalCard
        isOpen={modals.ConfirmationCancelIsOpen}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => handleModels('ConfirmationCancelIsOpen', false)}
        // eslint-disable-next-line react/no-children-prop
        children={ConfirmationCancelPopupIsOpen()}
      // title="Validation Message"
      />
    );
  }

  function CurrentTillAcceptBtnLessDefaultStartingTillModelCard() {
    // debugger;
    return (
      <RACModalCard
        // className={classes.ModelCardWidth}
        isOpen={modals.currentTillAcceptBtnLessIsOpen}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => handleModels('currentTillAcceptBtnLessIsOpen', false)}
        // eslint-disable-next-line react/no-children-prop
        children={currentTillAcceptLessBtn()}
      // title="Validation Message"
      />
    );
  }
  function CurrentTillAcceptBtnGreaterDefaultStartingTillModelCard() {
    return (
      <RACModalCard
        isOpen={modals.currentTillAcceptBtnGreaterIsOpen}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => handleModels('currentTillAcceptBtnGreaterIsOpen', false)}
        // eslint-disable-next-line react/no-children-prop
        children={currentTillAcceptGreaterBtn()}
      // title="Validation Message"
      />
    );
  }
  function FinalNotRequiredModelCard() {
    return (
      <RACModalCard
        isOpen={modals.finalNotRequired}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => handleModels('finalNotRequired', false)}
        // eslint-disable-next-line react/no-children-prop
        children={finalNotRequiredPopup()}
      // title="Validation Message"
      />
    );
  }
  function ValidateCashBeingDropModelCardForGreater() {
    return (
      <RACModalCard
        isOpen={modals.CashBeingValidateGreaterBtn}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => handleModels('CashBeingValidateGreaterBtn', false)}
        // eslint-disable-next-line react/no-children-prop
        children={ValidateCashBeingDropGreaterPopup()}
      // title="Validation Message"
      />
    );
  }
  const finalNotRequiredPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <Typography className={`${classes.RACPOPMsg} ${classes.marginTop}`}>
            Final Drop is Not required.
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
        >
          <RACButton
            className={`${classes.spacerMR2} ${classes.foc}`}
            size="small"
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => handle_OkFinalDropNotRequired()}
          // onClick={() => handleModels('validateNonCashIsOpen',false)}
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  function ValidateCashBeingDropModelCardForLesser() {
    return (
      <RACModalCard
        isOpen={modals.CashBeingValidateLessBtn}
        maxWidth="sm"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => handleModels('CashBeingValidateLessBtn', false)}
        // eslint-disable-next-line react/no-children-prop
        children={ValidateCashBeingDropLessPopup()}
      // title="Validation Message"
      />
    );
  }
  function ConfiguredDropMaxLimit() {
    return (
      <RACModalCard
        isOpen={modals.ConfiguredDropMaxTimeLimit}
        maxWidth="sm"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => handleModels('ConfiguredDropMaxTimeLimit', false)}
        // eslint-disable-next-line react/no-children-prop
        children={ConfiguredDropMaxLimitPopup()}
      // title="Validation Message"
      />
    );
  }
  function ValidateNonCashModelCard() {
    return (
      <RACModalCard
        isOpen={modals.validateNonCashIsOpen}
        maxWidth="sm"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => handleModels('validateNonCashIsOpen', false)}
        // eslint-disable-next-line react/no-children-prop
        children={ValidateNonCashPopup()}
      // title="Validation Message"
      />
    );
  }
  function OverShortTotalModalcard() {
    return (
      <RACModalCard
        isOpen={modals.overShortTotalIsOpen}
        maxWidth="sm"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => handleModels('overShortTotalIsOpen', false)}
        // eslint-disable-next-line react/no-children-prop
        children={overShortTotalIsOpenPopUp()}
      // title="Validation Message"
      />
    );
  }
  // function ExplanationCancelModalCard() {
  //     return (
  //         <RACModalCard
  //             isOpen={modals.ExplanationCancelPopupIsOpen}
  //             maxWidth="sm"
  //             closeIcon={true}
  //             borderRadius="20px"
  //             onClose={() => handleModels("ExplanationCancelPopupIsOpen", false)}
  //             // eslint-disable-next-line react/no-children-prop
  //             // children={ExplanationCancelPopup()}
  //             title="Validation Message"
  //         />
  //     )
  // }
  function OverShortAmountModalCard() {
    return (
      <RACModalCard
        isOpen={modals.OverShortAmountIsOpen}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => handleModels('OverShortAmountIsOpen', false)}
        // eslint-disable-next-line react/no-children-prop
        children={OverShortAmountPopUp()}
      // title="Validation Message"
      />
    );
  }
  function nonCashAck_NoBtn() {
    handleModels('validateNonCashIsOpen', false);
  }
  function handleOverShortExplanationNotes(e: any) {
    // debugger;
    if(e.target.value!==undefined && e.target.value!=='' && e.target.value!== null && e.target.value.trim().length !== 0){
      setOverShortExplanationNotes(e.target.value);
    }
    if(e.target.value =='') {
      setOverShortExplanationNotes(e.target.value);
    }
  }
  function nonCahAck_YesBtn() {
    let nonCashShortAmount: any = 0;
    for (
      let nonCashDetails = 0;
      nonCashDetails < drawerDetails?.NonCashDetails.length;
      nonCashDetails++
    ) {
      if (
        drawerDetails !== undefined &&
          drawerDetails?.NonCashDetails !== undefined &&
          drawerDetails?.NonCashDetails !== '' &&
          drawerDetails?.NonCashDetails !== null
          ? !drawerDetails?.NonCashDetails[nonCashDetails].isChecked
          : ''
      ) {
        nonCashShortAmount += Number(
          drawerDetails?.NonCashDetails[nonCashDetails].cashAmount
        );
      }
    }
    setNonCashEnableSave(true)
    // setSaveEnable(true);
    setOver_Short({ ...over_Short, nonCash: nonCashShortAmount });
    console.log('overShort', nonCashShortAmount);
    handleModels('validateNonCashIsOpen', false);
    setValidateYes(true);
    setEnableSave(true);
  }
  function overShortAck_YesBtn() {
    setModals({
      ...modals,
      overShortTotalIsOpen: true,
      OverShortAmountIsOpen: false,
    });
  }
  function overShortAck_NoBtn() {
    handleModels('OverShortAmountIsOpen', false);
  }
  function handle_LessOkBtn() {
    handleModels('currentTillAcceptBtnLessIsOpen', false);
  }
  function handle_GreaterOkBtn() {
    handleModels('currentTillAcceptBtnGreaterIsOpen', false);
    setDisableFieldsCurrentTill(true);
    setDisableFieldsCashBeingDropped(false);
  }
  function handleExplanation(e: any) {
    setShortExplanation(e.target.value);
  }
  function handleExplanation_CancelBtn() {
    handleModels('ConfirmationSaveIsOpen', false);
    handleModels('explanationModalIsOpen', false);
    //  handleModels('ExplanationCancelPopupIsOpen',false)
    setShortExplanation('');
  }
  function handleOverShortTotal_CancelBtn() {
    handleModels('overShortTotalIsOpen',false)
    setOverShortExplanationNotes('');
    setShortExplanation('') ;
  }
  const handleOverShortTotal_SaveBtn = async (e: Event)=> {
    handleModels('overShortTotalIsOpen', false);
    setLoader(true);
    setDefaultLoader(true)
    const CashBeingDropped = [];
    const NonCashBeingDropped: any = [];
    drawerDetails?.NonCashDetails.map((value: any) => {
      if (value.isChecked) {
        NonCashBeingDropped.push({
          receiptTransactionId: value.receiptTransactionId.toString(),
          amount: value.cashAmount.toFixed(2),
        });
      }
    });
    for (const [key, value] of Object.entries(denominationQuantityCBD)) {
      if (value !== '0') {
        CashBeingDropped.push({
          denomination:
            key == 'denominationQuantity100'
              ? '100'
              : key == 'denominationQuantity50'
                ? '50'
                : key == 'denominationQuantity20'
                  ? '20'
                  : key == 'denominationQuantity10'
                    ? '10'
                    : key == 'denominationQuantity5'
                      ? '5'
                      : key == 'denominationQuantity2'
                        ? '2'
                        : key == 'denominationQuantity1'
                          ? '1'
                          : key == 'denominationQuantity001'
                            ? '0.01'
                            : key == 'denominationQuantity005'
                              ? '0.05'
                              : key == 'denominationQuantity010'
                                ? '0.10'
                                : key == 'denominationQuantity025'
                                  ? '0.25'
                                  : key == 'denominationQuantity050'
                                    ? '0.50'
                                    : '0',

          totalQuatity: Number(value),
        });
      }
    }
    const payload = {
      storeNumber: window.sessionStorage.getItem('storeNumber'),

      dropVerifiedBy: '',

      dropNotes: dropNotes !== undefined ? dropNotes : '',

      dropEnvelopNumber: envNumber !== undefined ? envNumber : '',

      isFinalDrop: '1',

      defaultCashAmount: String(defaultStringAmount),

      cashBeingDropped: CashBeingDropped,

      nonCashBeingDropped: NonCashBeingDropped,

      nonCashOverallShort:
        over_Short.nonCash !== undefined ? String(over_Short.nonCash) : '0.00',

      cashOverallShort:
        over_Short.cash !== undefined ? String(over_Short.cash) : '0.00',

      overAllShortNotes:
        overShortExplanationNotes !== undefined
          ? overShortExplanationNotes
          : '',
    };
    console.log('Drop payload', payload);
    console.log('Drop payload', payload);
 


    const response = await manageDrop(payload);


    setLoader(false);
    setDefaultLoader(false)
    const errorcode = 'Drop Envelop Number Already Exits'
    const status = 200;
    if (response.status == status) {
      setSuccessPopup(true);
      const StoreExceptionRequest = storeExceptionPayload

      if (response.status == 200 && StoreExceptionRequest) {
        StoreExceptionRequest[0].identifierId = Number(response?.data?.drawerManagementId)
        StoreExceptionRequest[0].identifierType = 'DRAWERMANAGEMENT'
        const Request = {
          storeException: StoreExceptionRequest
        }
        const StoreExceptionRes = storeException(Request)
        setstoreExceptionPayload([])


      }
    }
    else if (response.data.errors[0].error == errorcode) {
      setDropEnvelopePopup(true)
      setstoreExceptionPayload([])

    }
    else {
      setSuccessPopup(true); setIsSucess(false); 
      setstoreExceptionPayload([])
    }
    // handleModels('ConfirmationSaveIsOpen', true);
  }
  function handleExplanation_SaveBtn() {
    setModals({
      ...modals,
      OverShortAmountIsOpen: true,
      explanationModalIsOpen: false,
    });
  }
  function handle_OkCashbDGreaterValidate() {
    handleModels('CashBeingValidateGreaterBtn', false);
  }
  function handle_OkFinalDropNotRequired() {
    const search = window.location.search;
    const origin = new URLSearchParams(search).get('origin');
    if(origin == 'closeeod'){
      history.push({
        pathname: `/storemgmt/closeeod`,
      });
    }
    else{
      handleModels('finalNotRequired', false);
      setSaveEnableDefaultStartingTill(false)
    }
  }
  function handleNo_SaveBtn() {
    handleModels('ConfirmationSaveIsOpen', false);
  }
  function handle_CancelNoBtn() {
    handleModels('ConfirmationCancelIsOpen', false);
  }
  function handle_CancelYesBtn() {
    history.push({
      pathname: `/dashboard/homepage`,
    });
  }
  const calculateTotalAmountCT = (denomination: string, value: string) => {
    const cashAmount =
      parseFloat(
        denomination == '0.01'
          ? value
          : denominationQuantityCT.denominationQuantity001 !== ''
            ? denominationQuantityCT.denominationQuantity001
            : '0'
      ) *
      0.01 +
      parseFloat(
        denomination == '0.05'
          ? value
          : denominationQuantityCT.denominationQuantity005 !== ''
            ? denominationQuantityCT.denominationQuantity005
            : '0'
      ) *
      0.05 +
      parseFloat(
        denomination == '0.10'
          ? value
          : denominationQuantityCT.denominationQuantity010 !== ''
            ? denominationQuantityCT.denominationQuantity010
            : '0'
      ) *
      0.1 +
      parseFloat(
        denomination == '0.25'
          ? value
          : denominationQuantityCT.denominationQuantity025 !== ''
            ? denominationQuantityCT.denominationQuantity025
            : '0'
      ) *
      0.25 +
      parseFloat(
        denomination == '0.50'
          ? value
          : denominationQuantityCT.denominationQuantity050 !== ''
            ? denominationQuantityCT.denominationQuantity050
            : '0'
      ) *
      0.5 +
      parseFloat(
        denomination == '1'
          ? value
          : denominationQuantityCT.denominationQuantity1 !== ''
            ? denominationQuantityCT.denominationQuantity1
            : '0'
      ) *
      1 +
      parseFloat(
        denomination == '10'
          ? value
          : denominationQuantityCT.denominationQuantity10 !== ''
            ? denominationQuantityCT.denominationQuantity10
            : '0'
      ) *
      10 +
      parseFloat(
        denomination == '100'
          ? value
          : denominationQuantityCT.denominationQuantity100 !== ''
            ? denominationQuantityCT.denominationQuantity100
            : '0'
      ) *
      100 +
      parseFloat(
        denomination == '2'
          ? value
          : denominationQuantityCT.denominationQuantity2 !== ''
            ? denominationQuantityCT.denominationQuantity2
            : '0'
      ) *
      2 +
      parseFloat(
        denomination == '20'
          ? value
          : denominationQuantityCT.denominationQuantity20 !== ''
            ? denominationQuantityCT.denominationQuantity20
            : '0'
      ) *
      20 +
      parseFloat(
        denomination == '5'
          ? value
          : denominationQuantityCT.denominationQuantity5 !== ''
            ? denominationQuantityCT.denominationQuantity5
            : '0'
      ) *
      5 +
      parseFloat(
        denomination == '50'
          ? value
          : denominationQuantityCT.denominationQuantity50 !== ''
            ? denominationQuantityCT.denominationQuantity50
            : '0'
      ) *
      50;
    //    console.log(cashAmount);
    return cashAmount.toFixed(2);
  };
  const calculateTotalAmountCBT = (denomination: string, value: string) => {
    const cashAmount =
      parseFloat(
        denomination == '0.01'
          ? value
          : denominationQuantityCBD.denominationQuantity001 !== ''
            ? denominationQuantityCBD.denominationQuantity001
            : '0'
      ) *
      0.01 +
      parseFloat(
        denomination == '0.05'
          ? value
          : denominationQuantityCBD.denominationQuantity005 !== ''
            ? denominationQuantityCBD.denominationQuantity005
            : '0'
      ) *
      0.05 +
      parseFloat(
        denomination == '0.10'
          ? value
          : denominationQuantityCBD.denominationQuantity010 !== ''
            ? denominationQuantityCBD.denominationQuantity010
            : '0'
      ) *
      0.1 +
      parseFloat(
        denomination == '0.25'
          ? value
          : denominationQuantityCBD.denominationQuantity025 !== ''
            ? denominationQuantityCBD.denominationQuantity025
            : '0'
      ) *
      0.25 +
      parseFloat(
        denomination == '0.50'
          ? value
          : denominationQuantityCBD.denominationQuantity050 !== ''
            ? denominationQuantityCBD.denominationQuantity050
            : '0'
      ) *
      0.5 +
      parseFloat(
        denomination == '1'
          ? value
          : denominationQuantityCBD.denominationQuantity1 !== ''
            ? denominationQuantityCBD.denominationQuantity1
            : '0'
      ) *
      1 +
      parseFloat(
        denomination == '10'
          ? value
          : denominationQuantityCBD.denominationQuantity10 !== ''
            ? denominationQuantityCBD.denominationQuantity10
            : '0'
      ) *
      10 +
      parseFloat(
        denomination == '100'
          ? value
          : denominationQuantityCBD.denominationQuantity100 !== ''
            ? denominationQuantityCBD.denominationQuantity100
            : '0'
      ) *
      100 +
      parseFloat(
        denomination == '2'
          ? value
          : denominationQuantityCBD.denominationQuantity2 !== ''
            ? denominationQuantityCBD.denominationQuantity2
            : '0'
      ) *
      2 +
      parseFloat(
        denomination == '20'
          ? value
          : denominationQuantityCBD.denominationQuantity20 !== ''
            ? denominationQuantityCBD.denominationQuantity20
            : '0'
      ) *
      20 +
      parseFloat(
        denomination == '5'
          ? value
          : denominationQuantityCBD.denominationQuantity5 !== ''
            ? denominationQuantityCBD.denominationQuantity5
            : '0'
      ) *
      5 +
      parseFloat(
        denomination == '50'
          ? value
          : denominationQuantityCBD.denominationQuantity50 !== ''
            ? denominationQuantityCBD.denominationQuantity50
            : '0'
      ) *
      50;
    //    console.log(cashAmount);
    const totalCash: any =
      cashAmount - parseFloat(drawerDetails?.storeTransaction.totalCash);
    setOver_Short({ ...over_Short, cash: totalCash });
    console.log('OVER SHORT', totalCash);
    return cashAmount.toFixed(2);
  };
  function currentTillAcceptGreaterBtn() {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <Typography className={`${classes.RACPOPMsg} ${classes.marginTop}`}>
            Please remove $ {(parseFloat(currentTill) - parseFloat(defaultStringAmount)).toFixed(2)} from
            the cash drawer and validate your final drop.
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
        >
          <RACButton
            className={`${classes.spacerMR2} ${classes.foc}`}
            size="small"
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => handle_GreaterOkBtn()}
          // onClick={() => handleModels('validateNonCashIsOpen',false)}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );
  }
  function handleValidateCashAck_YesBtn() {
    // add sort amount to the state ShadowRoot.cash
    setCashBeingDropAmount(dropAmount.cash)
    setDenominationQuantityCT({
      denominationQuantity001: (Math.abs(Number(denominationQuantityCBD.denominationQuantity001) - Number(denominationQuantityCT.denominationQuantity001))).toString(),
      denominationQuantity005: (Math.abs(Number(denominationQuantityCBD.denominationQuantity005) - Number(denominationQuantityCT.denominationQuantity005))).toString(),
      denominationQuantity010: (Math.abs(Number(denominationQuantityCBD.denominationQuantity010) - Number(denominationQuantityCT.denominationQuantity010))).toString(),
      denominationQuantity025: (Math.abs(Number(denominationQuantityCBD.denominationQuantity025) - Number(denominationQuantityCT.denominationQuantity025))).toString(),
      denominationQuantity050: (Math.abs(Number(denominationQuantityCBD.denominationQuantity050) - Number(denominationQuantityCT.denominationQuantity050))).toString(),
      denominationQuantity1: (Math.abs(Number(denominationQuantityCBD.denominationQuantity001) - Number(denominationQuantityCT.denominationQuantity001))).toString(),
      denominationQuantity10: (Math.abs(Number(denominationQuantityCBD.denominationQuantity10) - Number(denominationQuantityCT.denominationQuantity10))).toString(),
      denominationQuantity100: (Math.abs(Number(denominationQuantityCBD.denominationQuantity100) - Number(denominationQuantityCT.denominationQuantity100))).toString(),
      denominationQuantity2: (Math.abs(Number(denominationQuantityCBD.denominationQuantity2) - Number(denominationQuantityCT.denominationQuantity2))).toString(),
      denominationQuantity20: (Math.abs(Number(denominationQuantityCBD.denominationQuantity20) - Number(denominationQuantityCT.denominationQuantity20))).toString(),
      denominationQuantity5: (Math.abs(Number(denominationQuantityCBD.denominationQuantity5) - Number(denominationQuantityCT.denominationQuantity5))).toString(),
      denominationQuantity50: (Math.abs(Number(denominationQuantityCBD.denominationQuantity50) - Number(denominationQuantityCT.denominationQuantity50))).toString()
    })
    if (saveLessDefaultStartTill) {
      setSaveBtnEnable(true);
    }
    setDisableFieldsCashBeingDropped(true);
    setSaveEnable(true)
    handleModels('CashBeingValidateLessBtn', false);
  }
  function handleValidateCashAck_NoBtn() {
    handleModels('CashBeingValidateLessBtn', false);
  }
  // function ConfiguredDropMaxLimit_NoBtn(){
  //     history.push({
  //         pathname: `/dashboard/homepage`,
  //       });
  //     } 
  //     function ConfiguredDropMaxLimit_YesBtn(){
  //         handleModels('ConfiguredDropMaxTimeLimit',false)
  //     }
  function handleEnvelopInp(e: any) {
    setEnvNumber(e.target.value);
  }
  function handleDropNotesInp(e: any) {
    setDropNotes(e.target.value);
  }
  const currentTillAcceptLessBtn = () => {
    return (
      <Grid item md={12}>
        <Grid
          item
          md={12}
          style={{
            marginLeft: '1.5%',
            marginRight: '1.5%',
          }}
          className={`${classes.textCenter}`}
        >
          <Typography className={`${classes.RACPOPMsg} ${classes.marginTop}`}>
            The total cash left in drawer should not be less than the default
            starting till.
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
        >
          <RACButton
            className={`${classes.spacerMR2} ${classes.foc}`}
            size="small"
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => handle_LessOkBtn()}
          // onClick={() => handleModels('validateNonCashIsOpen',false)}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  // const ExplanationCancelPopup = () => {
  //     return (

  //         <Grid item md={12}>
  //             <Grid item md={12} className={classes.textCenter}>
  //                 <Typography className={classes.RACPOPMsg}>
  //                     Please enter the exception reason.
  //                 </Typography>
  //             </Grid>
  //             <Grid
  //                 container
  //                 className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
  //             >
  //                 <RACButton
  //                     className={`${classes.spacerMR2} ${classes.foc}`}
  //                     size="small"
  //                     variant="outlined"
  //                     color="primary"
  //                     isRounded={false}
  //                     onClick={() => handleExplanation_OkBtn()}
  //                 // onClick={() => handleModels('validateNonCashIsOpen',false)}
  //                 >
  //                     Ok
  //                 </RACButton>
  //             </Grid>
  //         </Grid>

  //     );
  // }
  const handleOnClick = () => {
    const search = window.location.search;
    const origin = new URLSearchParams(search).get('origin');
    if (origin == 'closeeod') {
      history.push({
        pathname: `/storemgmt/closeeod`,
      });
    }
    else {
      history.push({
        pathname: `/dashboard/homepage`,
      });
    }
  }
  const UpdateInfoMsgPopup = () => {
    return (
      <Grid item md={12} className="px-2">
        <Grid
          item
          md={12}
          className="text-center"
          style={{ textAlign: 'center' }}
        >
          {isSuccess ? (
            <SuccessIcon style={{ textAlign: 'center' }}></SuccessIcon>
          ) : (
            <Alerticon style={{ textAlign: 'center' }}></Alerticon>
          )}
          <Typography
            variant={'body2'}
            style={{ marginTop: '1%', marginBottom: '1%' }}
          >
            {isSuccess
              ? 'Drop Successfully Completed'
              : // eslint-disable-next-line sonarjs/no-duplicate-string
              'Something went wrong'}
          </Typography>
        </Grid>
        <Grid
          container
          className="text-center mt-4"
          style={{ justifyContent: 'center', marginTop: '20px' }}
        >
          <RACButton
            className={`${classes.spacerMR2} ${classes.foc}`}
            size="small"
            color="primary"
            variant="contained"
            isRounded={false}
            style={{
              textTransform: 'none',
              // eslint-disable-next-line sonarjs/no-duplicate-string
              width: '100px !important',
              height: '35px',
            }}
            onClick={() => handleOnClick()}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const ConfirmationSavePopupIsOpen = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <Typography className={`${classes.RACPOPMsg} ${classes.marginTop}`}>
            Are you sure want to save?
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
        >
          <RACButton
            className={`${classes.spacerMR2} ${classes.foc}`}
            size="small"
            variant="outlined"
            color="primary"
            isRounded={false}
            onClick={() => handleNo_SaveBtn()}
          // onClick={() => handleModels('validateNonCashIsOpen',false)}
          >
            No
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => handleYes_SaveBtn()}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const ConfirmationCancelPopupIsOpen = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <Typography className={`${classes.RACPOPMsg} ${classes.marginTop}`}>
            Your changes will not be saved.
            <Grid>Are you sure you want to exit?</Grid>
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
        >
          <RACButton
            className={`${classes.spacerMR2} ${classes.foc}`}
            size="small"
            variant="outlined"
            color="primary"
            isRounded={false}
            onClick={() => handle_CancelNoBtn()}
          // onClick={() => handleModels('validateNonCashIsOpen',false)}
          >
            No
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => handle_CancelYesBtn()}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const ExplanationModalPopUpIsOpen = () => {
    return (
      <Grid
        item
        md={12}
        style={{
          marginLeft: '2%',
          marginRight: '2%',
        }}
      >
        <Grid
          // className={classes.mt3}
          item
          style={{
            float: 'left',
            width: '100%',
            // marginLeft: "2%",
            // marginRight: "2%",
            // paddingBottom: "20px",
          }}
        >
          <Typography
            // className={classes.header}
            style={{
              marginTop: '14px',
              width: '100%',
              fontSize: '16px',
              //  fontFamily: "",
              marginBottom: '15px',
            }}
          >
            Explanation<span style={{ color: 'red' }}> *</span>
          </Typography>
          <textarea
            style={{
              marginTop: '5px',
              width: '100%',
              padding: '6px',
              paddingBottom: '31px',
              resize: 'none',
              border: '1px solid #C4C4C4',
              paddingTop: '5px',
              borderRadius: '0.6rem',
              backgroundColor: '#fff',
              transition:
                'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
              fontSize: '14px',
              fontFamily: 'OpenSans-semibold',
            }}
            value={shortExplanation}
            onChange={(e) => handleExplanation(e)}
            rows={3}
            cols={54}
            className={classes.dropNotes}
          ></textarea>
        </Grid>
        <Grid
          style={{ float: 'right' }}
          container
          className={`${classes.explanationContent} ${classes.spacerMT4}`}
        >
          <RACButton
            className={`${classes.spacerMR2} ${classes.foc}`}
            size="small"
            variant="outlined"
            color="primary"
            isRounded={false}
            onClick={() => handleExplanation_CancelBtn()}
          // onClick={() => handleModels('validateNonCashIsOpen',false)}
          >
            Cancel
          </RACButton>
          <RACButton
            disabled={shortExplanation != '' ? false : true}
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => handleExplanation_SaveBtn()}
          >
            Save
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const ValidateCashBeingDropGreaterPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <Typography className={`${classes.RACPOPMsg} ${classes.marginTop}`}>
            Drop Must not exceed till amount.
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
        >
          <RACButton
            className={`${classes.spacerMR2} ${classes.foc}`}
            size="small"
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => handle_OkCashbDGreaterValidate()}
          // onClick={() => handleModels('validateNonCashIsOpen',false)}
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const ValidateCashBeingDropLessPopup = () => {
    return (
      <Grid item md={12}>
        <Grid
          item
          md={12}
          className={`${classes.textCenter} ${classes.marginTop}`}
        >
          <Typography className={classes.RACPOPMsg}>
            The total cash being drop does not match with the expected amount.
            <Typography className={classes.RACPOPMsg}>
              do you want to continue? </Typography>
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
        >
          <RACButton
            className={`${classes.spacerMR2} ${classes.foc}`}
            size="small"
            variant="outlined"
            color="primary"
            isRounded={false}
            onClick={() => handleValidateCashAck_NoBtn()}
          // onClick={() => handleModels('validateNonCashIsOpen',false)}
          >
            No
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => handleValidateCashAck_YesBtn()}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  // const ConfiguredDropMaxLimitPopup = () => {
  //     return (
  //       <Grid item md={12}>
  //         <Grid
  //           item
  //           md={12}
  //           className={`${classes.textCenter} ${classes.marginTop}`}
  //         >
  //           <Typography className={classes.RACPOPMsg}>
  //           The time now is less than the configured final drop time.
  //           <Typography className={classes.RACPOPMsg}> Do you want to continue?</Typography>
  //           </Typography>
  //         </Grid>
  //         <Grid
  //           container
  //           className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
  //         >
  //           <RACButton
  //             className={`${classes.spacerMR2} ${classes.foc}`}
  //             size="small"
  //             variant="outlined"
  //             color="primary"
  //             isRounded={false}
  //             onClick={() => ConfiguredDropMaxLimit_NoBtn()}
  //           // onClick={() => handleModels('validateNonCashIsOpen',false)}
  //           >
  //             No
  //           </RACButton>
  //           <RACButton
  //             variant="contained"
  //             color="primary"
  //             isRounded={false}
  //             onClick={() => ConfiguredDropMaxLimit_YesBtn()}
  //           >
  //             Yes
  //           </RACButton>
  //         </Grid>
  //       </Grid>
  //     );
  //   };
  const ValidateNonCashPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <Typography className={`${classes.RACPOPMsg} ${classes.marginTop}`}>
            All non-cash item have not been selected.
            <Grid>do you want to continue ?</Grid>
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
        >
          <RACButton
            className={`${classes.spacerMR2} ${classes.foc}`}
            size="small"
            variant="outlined"
            color="primary"
            isRounded={false}
            onClick={() => nonCashAck_NoBtn()}
          // onClick={() => handleModels('validateNonCashIsOpen',false)}
          >
            No
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => nonCahAck_YesBtn()}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const overShortTotalIsOpenPopUp = () => {
    return (
      <Grid style={{ width: '100%' }}>
        <Grid className={classes.row}>
          <Grid style={{ width: '40%' }}>
            <Typography
              className={classes.RACPOPMsg}
              style={{ marginTop: '-1rem' }}
            >
              Over / Short Total
            </Typography>
            <Grid className={classes.row} style={{ marginTop: '8%' }}>
              <Grid style={{ width: '40%' }}>
                <Typography>Non-Cash</Typography>
              </Grid>
              <Grid style={{ width: '50%' }}>
                <RACTextbox
                  disabled={true}
                  value={ Number((drawerDetails?.storeTransaction?.totalNonCash) >= Number(over_Short.nonCash) &&  Number(over_Short.nonCash)!= 0) ? `$`+` `+ `${Number(over_Short.nonCash).toFixed(2)}` + ``: '$'+` `+ Number(over_Short.nonCash).toFixed(2) }
                  className={`${classes.overShort}`}
                  maxlength={9}
                  type="number"
                // name="$001"
                // id="$001"
                // Onblur={(e)=>e.preventDefault()}
                />
              </Grid>
            </Grid>
            <Grid className={classes.row} style={{ marginTop: '8%' }}>
              <Grid style={{ width: '40%' }}>
                <Typography>Cash</Typography>
              </Grid>
              <Grid style={{ width: '50%' }}>
                <RACTextbox
                  disabled={true}
                  className={`${classes.overShort}`}
                  value={(Number(drawerDetails?.storeTransaction?.totalCash) > Number(over_Short.cash) &&  Number(over_Short.cash)!= 0) ? `$`+` `+ `${Math.abs(Number(over_Short.cash)).toFixed(2)}` + ` ` : '$'+` `+ Number(over_Short.cash).toFixed(2) }
                  maxlength={9}
                  type="number"
                // name="$001"
                // id="$001"
                // Onblur={(e)=>e.preventDefault()}
                // OnChange={(e) => handleCurrentTillInp(e)}
                />
              </Grid>
            </Grid>
            <Grid className={classes.row} style={{ marginTop: '8%' }}>
              <Grid style={{ width: '40%' }}>
                <Typography>Total</Typography>
              </Grid>
              <Grid style={{ width: '50%' }}>
                <RACTextbox
                  disabled={true}
                  className={`${classes.overShort}`}
                  value={`${`$`+` `+ Number(Math.abs(Number(over_Short.cash)) + Math.abs(Number(over_Short.nonCash))).toFixed(2) + ``}`}
                  maxlength={9}
                  type="number"
                // name="$001"
                // id="$001"
                // Onblur={(e)=>e.preventDefault()}
                // OnChange={(e) => handleCurrentTillInp(e)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid style={{ width: '58%', marginRight: '2%' }}>
            <Typography
              className={classes.RACPOPMsg}
              style={{ marginTop: '-1rem' }}
            >
              Over / Short Explanation Notes
              <span style={{ color: 'red' }}> *</span>
            </Typography>
            <textarea
              style={{
                marginTop: '5%',
                width: '100%',
                padding: '6px',
                paddingBottom: '31px',
                resize: 'none',
                border: '1px solid #C4C4C4',
                paddingTop: '5px',
                paddingRight: '5px',
                // marginRight:"4px",
                // paddingLeft:"20px",
                borderRadius: '0.6rem',
                backgroundColor: '#fff',
                transition:
                  'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                fontSize: '14px',
                fontFamily: 'OpenSans-semibold',
              }}
              // onChange={(e) => handleDropNotesInp(e)}
              rows={6}
              cols={54}
              value={overShortExplanationNotes}
              onChange={(e) => handleOverShortExplanationNotes(e)}
              className={classes.dropNotes}
            ></textarea>
          </Grid>
        </Grid>
        <Grid
          style={{ float: 'right' }}
          container
          className={`${classes.explanationContent} ${classes.spacerMT4}`}
        >
          <RACButton
            className={`${classes.spacerMR2} ${classes.foc}`}
            size="small"
            variant="outlined"
            color="primary"
            isRounded={false}
            onClick={() => handleOverShortTotal_CancelBtn()}
          // onClick={() => handleModels('validateNonCashIsOpen',false)}
          >
            Cancel
          </RACButton>
          <RACButton
            disabled={overShortExplanationNotes != '' ? false : true}
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={(e:any) => handleOverShortTotal_SaveBtn(e)}
          >
            Save
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const OverShortAmountPopUp = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          {/* //Including both cash and Noncash */}
          <Typography className={`${classes.RACPOPMsg} ${classes.marginTop}`}>
            There is an Over/Short amount of{' '}
            {Number(parseFloat(over_Short.cash)) < 0.0 ||
              Number(parseFloat(over_Short.nonCash)) > 0
              ? `$(${(
                -parseFloat(over_Short.cash) + parseFloat(over_Short.nonCash)
              ).toFixed(2)})`
              : `$ ${parseFloat(over_Short.cash).toFixed(2)}`}
            <Grid>Are you sure you want to continue?</Grid>
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
        >
          <RACButton
            className={`${classes.spacerMR2} ${classes.foc}`}
            size="small"
            variant="outlined"
            color="primary"
            isRounded={false}
            onClick={() => overShortAck_NoBtn()}
          // onClick={() => handleModels('validateNonCashIsOpen',false)}
          >
            No
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => overShortAck_YesBtn()}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  //non cash
  const truncateString = (str: string): string => {
    if (str?.length > 12 && str!==undefined && str!==null && str!=='') {
      return (str?.substring(0, 10) + '...').toString();
    }
    return str;
  };
  const tableStricky: any = () => {
    if (defaultLoader) {
      return -0;
    }
  }
  const renderNonCashTableHead = () => {
    return (
      <React.Fragment>
        <RACTableCell className={`"racstrap-tablecell-BgColor"`} style={{zIndex: tableStricky()}}>
          <Typography variant="h5">Customer</Typography>
        </RACTableCell>
        <RACTableCell className={`"racstrap-tablecell-BgColor"`} style={{zIndex: tableStricky()}}>
          <Typography variant="h5">Doc Type</Typography>
        </RACTableCell>
        <RACTableCell className={`"racstrap-tablecell-BgColor"`} style={{zIndex: tableStricky()}}>
          <Typography variant="h5">Doc #</Typography>
        </RACTableCell>
        <RACTableCell className={`"racstrap-tablecell-BgColor"`} style={{zIndex: tableStricky()}}>
          <Typography variant="h5">Name on Doc</Typography>
        </RACTableCell>
        <RACTableCell className={`"racstrap-tablecell-BgColor"`} style={{zIndex: tableStricky()}}>
          <Typography variant="h5">Receipt #</Typography>
        </RACTableCell>
        <RACTableCell
          className={`"racstrap-tablecell-BgColor"`}
          style={{zIndex: tableStricky()}}
        // style={{paddingLeft:"8.5%"}}
        >
          <Typography variant="h5">Amount</Typography>
        </RACTableCell>
        <RACTableCell className={`"racstrap-tablecell-BgColor"`}
          style={{zIndex: tableStricky()}}>
          <Typography variant="h5">Verified</Typography>
        </RACTableCell>
      </React.Fragment>
    );
  };
  const renderNonCashTableContent = (): any => {
    return drawerDetails?.NonCashDetails?.map((data: any) => {
      return (
        <>
          <React.Fragment>
            {/* Row 1 */}
            <RACTableRow className={classes.tableRow} style={{ width: '100%' }}>
              <RACTableCell title={data?.vendorName}>
                <Typography style={{ color: '#4A5174' }}>
                  {truncateString(data?.vendorName)}
                  {/* {data.vendorName} */}
                </Typography>
              </RACTableCell>
              <RACTableCell>
                <Typography style={{ color: '#4A5174' }}>
                  {data?.tenderType}
                </Typography>
              </RACTableCell>
              <RACTableCell>
                <Typography style={{ color: '#4A5174' }}>
                  {data?.documentNumber}
                </Typography>
              </RACTableCell>
              <RACTableCell title={data.documentWrittenBy}>
                <Typography style={{ color: '#4A5174' }}>
                  {truncateString(data?.documentWrittenBy)}
                  {/* {data.documentWrittenBy} */}
                </Typography>
              </RACTableCell>
              <RACTableCell style={{ width: '14%' }}>
                <Typography style={{ color: '#4A5174' }}>
                  {data?.reciptNumber}
                </Typography>
              </RACTableCell>
              <RACTableCell
              // style={{marginRight:"5px"}}
              >
                <Typography
                  style={{
                    // paddingRight: "8px",
                    color: '#4A5174',
                    textAlign: 'right',
                  }}
                >
                  $ {formatMoney(parseFloat(data?.cashAmount).toFixed(2))}
                </Typography>
              </RACTableCell>
              <RACTableCell style={{ textAlign: 'center' }}>
                <RACCheckBox
                  style={{ marginLeft: '10px' }}
                  checked={data.isChecked}
                  // checked ={true}
                  // onClick={(e) => checkBoxChecked(e,data.amount)}
                  onClick={(e: any) =>
                    handleNonCashDetail(e, data?.receiptTransactionId)
                  }
                  size="medium"
                />
              </RACTableCell>
            </RACTableRow>
          </React.Fragment>
        </>
      );
    });
  };
  //current till
  const renderCashTableHeadCurrentTill = () => {
    return (
      <React.Fragment>
        <RACTableCell className={`${classes.tableCellDenomination}`}>
          <Typography
          // variant="h5"
          >
            Denomination
          </Typography>
        </RACTableCell>
        <RACTableCell className={`${classes.tableCell}`}>
          <Typography
            // variant="h5"
            style={{
              marginLeft: '10.5%',
              width: '100%',
            }}
          >
            <Grid className={classes.row}>
              <Grid>Total</Grid>
              <Grid> Quantity</Grid>
            </Grid>
          </Typography>
        </RACTableCell>
        <RACTableCell
          style={{ paddingRight: '6.5%', textAlign: 'right' }}
          className={`${classes.tableCellTotal}`}
        >
          <Typography
          // variant="h5"
          >
            Total
          </Typography>
        </RACTableCell>
      </React.Fragment>
    );
  };
  const handleCurrentTillBtn = (e: any) => {
    console.log('event', e.currentTarget.id);
    switch (e.currentTarget.id) {
      case '001$':
        setCurrentTill(
          calculateTotalAmountCT(
            '0.01',
            (
              Number(denominationQuantityCT.denominationQuantity001) + 1
            ).toString()
          )
        );
        setDenominationQuantityCT({
          ...denominationQuantityCT,
          denominationQuantity001: (
            Number(denominationQuantityCT.denominationQuantity001) + 1
          ).toString(),
        });
        break;
      case '005$':
        setCurrentTill(
          calculateTotalAmountCT(
            '0.05',
            (
              Number(denominationQuantityCT.denominationQuantity005) + 1
            ).toString()
          )
        );
        setDenominationQuantityCT({
          ...denominationQuantityCT,
          denominationQuantity005: (
            Number(denominationQuantityCT.denominationQuantity005) + 1
          ).toString(),
        });
        break;
      case '010$':
        setCurrentTill(
          calculateTotalAmountCT(
            '0.10',
            (
              Number(denominationQuantityCT.denominationQuantity010) + 1
            ).toString()
          )
        );
        setDenominationQuantityCT({
          ...denominationQuantityCT,
          denominationQuantity010: (
            Number(denominationQuantityCT.denominationQuantity010) + 1
          ).toString(),
        });
        break;
      case '025$':
        setCurrentTill(
          calculateTotalAmountCT(
            '0.25',
            (
              Number(denominationQuantityCT.denominationQuantity025) + 1
            ).toString()
          )
        );
        setDenominationQuantityCT({
          ...denominationQuantityCT,
          denominationQuantity025: (
            Number(denominationQuantityCT.denominationQuantity025) + 1
          ).toString(),
        });
        break;
      case '050$':
        setCurrentTill(
          calculateTotalAmountCT(
            '0.50',
            (
              Number(denominationQuantityCT.denominationQuantity050) + 1
            ).toString()
          )
        );
        setDenominationQuantityCT({
          ...denominationQuantityCT,
          denominationQuantity050: (
            Number(denominationQuantityCT.denominationQuantity050) + 1
          ).toString(),
        });
        break;
      case '100$':
        setCurrentTill(
          calculateTotalAmountCT(
            '100',
            (
              Number(denominationQuantityCT.denominationQuantity100) + 1
            ).toString()
          )
        );
        setDenominationQuantityCT({
          ...denominationQuantityCT,
          denominationQuantity100: (
            Number(denominationQuantityCT.denominationQuantity100) + 1
          ).toString(),
        });
        break;
      case '50$':
        setCurrentTill(
          calculateTotalAmountCT(
            '50',
            (
              Number(denominationQuantityCT.denominationQuantity50) + 1
            ).toString()
          )
        );
        setDenominationQuantityCT({
          ...denominationQuantityCT,
          denominationQuantity50: (
            Number(denominationQuantityCT.denominationQuantity50) + 1
          ).toString(),
        });
        break;
      case '20$':
        setCurrentTill(
          calculateTotalAmountCT(
            '20',
            (
              Number(denominationQuantityCT.denominationQuantity20) + 1
            ).toString()
          )
        );
        setDenominationQuantityCT({
          ...denominationQuantityCT,
          denominationQuantity20: (
            Number(denominationQuantityCT.denominationQuantity20) + 1
          ).toString(),
        });
        break;
      case '10$':
        setCurrentTill(
          calculateTotalAmountCT(
            '10',
            (
              Number(denominationQuantityCT.denominationQuantity10) + 1
            ).toString()
          )
        );
        setDenominationQuantityCT({
          ...denominationQuantityCT,
          denominationQuantity10: (
            Number(denominationQuantityCT.denominationQuantity10) + 1
          ).toString(),
        });
        break;
      case '5$':
        setCurrentTill(
          calculateTotalAmountCT(
            '5',
            (
              Number(denominationQuantityCT.denominationQuantity5) + 1
            ).toString()
          )
        );
        setDenominationQuantityCT({
          ...denominationQuantityCT,
          denominationQuantity5: (
            Number(denominationQuantityCT.denominationQuantity5) + 1
          ).toString(),
        });
        break;
      case '2$':
        setCurrentTill(
          calculateTotalAmountCT(
            '2',
            (
              Number(denominationQuantityCT.denominationQuantity2) + 1
            ).toString()
          )
        );
        setDenominationQuantityCT({
          ...denominationQuantityCT,
          denominationQuantity2: (
            Number(denominationQuantityCT.denominationQuantity2) + 1
          ).toString(),
        });
        break;
      case '1$':
        setCurrentTill(
          calculateTotalAmountCT(
            '1',
            (
              Number(denominationQuantityCT.denominationQuantity1) + 1
            ).toString()
          )
        );
        setDenominationQuantityCT({
          ...denominationQuantityCT,
          denominationQuantity1: (
            Number(denominationQuantityCT.denominationQuantity1) + 1
          ).toString(),
        });
        break;
    }
  };
  // total quantity
  const handleNonEmptyValueCT = (e: any) => {
    console.log(" +++++++++value of 100", e.target.value)
    switch (e.target.id) {
      case '$001':
        if (e.target.value == '' || Number(e.target.value) == 0) {
          setDenominationQuantityCT({ ...denominationQuantityCT, denominationQuantity001: '0' })
        }
        break;
      case '$005':
        if (e.target.value == '' || Number(e.target.value) == 0) {
          setDenominationQuantityCT({ ...denominationQuantityCT, denominationQuantity005: '0' })
        }
        break;
      case '$010':
        if (e.target.value == '' || Number(e.target.value) == 0) {
          setDenominationQuantityCT({ ...denominationQuantityCT, denominationQuantity010: '0' })
        }
        break;
      case '$025':
        if (e.target.value == '' || Number(e.target.value) == 0) {
          setDenominationQuantityCT({ ...denominationQuantityCT, denominationQuantity025: '0' })
        }
        break;
      case '$050':
        if (e.target.value == '' || Number(e.target.value) == 0) {
          setDenominationQuantityCT({ ...denominationQuantityCT, denominationQuantity050: '0' })
        }
        break;
      case '$100':
        if (e.target.value == '' || Number(e.target.value) == 0) {
          setDenominationQuantityCT({ ...denominationQuantityCT, denominationQuantity100: '0' })
        }
        break;
      case '$50':
        if (e.target.value == '' || Number(e.target.value) == 0) {
          setDenominationQuantityCT({ ...denominationQuantityCT, denominationQuantity50: '0' })
        }
        break;
      case '$20':
        if (e.target.value == '' || Number(e.target.value) == 0) {
          setDenominationQuantityCT({ ...denominationQuantityCT, denominationQuantity20: '0' })
        }
        break;
      case '$10':
        if (e.target.value == '' || Number(e.target.value) == 0) {
          setDenominationQuantityCT({ ...denominationQuantityCT, denominationQuantity10: '0' })
        }
        break;
      case '$5':
        if (e.target.value == '' || Number(e.target.value) == 0) {
          setDenominationQuantityCT({ ...denominationQuantityCT, denominationQuantity5: '0' })
        }
        break;
      case '$2':
        if (e.target.value == '' || Number(e.target.value) == 0) {
          setDenominationQuantityCT({ ...denominationQuantityCT, denominationQuantity2: '0' })
        }
        break;
      case '$1':
        if (e.target.value == '' || Number(e.target.value) == 0) {
          setDenominationQuantityCT({ ...denominationQuantityCT, denominationQuantity1: '0' })
        }
        break;
    }

  }
  const handleNonEmptyValueCBD = (e: any) => {
    console.log(" +++++++++value of 100", e.target.value)
    switch (e.target.id) {
      case '$001':
        if (e.target.value == '' || Number(e.target.value) == 0) {
          setDenominationQuantityCBD({ ...denominationQuantityCBD, denominationQuantity001: '0' })
        }
        break;
      case '$005':
        if (e.target.value == '' || Number(e.target.value) == 0) {
          setDenominationQuantityCBD({ ...denominationQuantityCBD, denominationQuantity005: '0' })
        }
        break;
      case '$010':
        if (e.target.value == '' || Number(e.target.value) == 0) {
          setDenominationQuantityCBD({ ...denominationQuantityCBD, denominationQuantity010: '0' })
        }
        break;
      case '$025':
        if (e.target.value == '' || Number(e.target.value) == 0) {
          setDenominationQuantityCBD({ ...denominationQuantityCBD, denominationQuantity025: '0' })
        }
        break;
      case '$050':
        if (e.target.value == '' || Number(e.target.value) == 0) {
          setDenominationQuantityCBD({ ...denominationQuantityCBD, denominationQuantity050: '0' })
        }
        break;
      case '$100':
        if (e.target.value == '' || Number(e.target.value) == 0) {
          setDenominationQuantityCBD({ ...denominationQuantityCBD, denominationQuantity100: '0' })
        }
        break;
      case '$50':
        if (e.target.value == '' || Number(e.target.value) == 0) {
          setDenominationQuantityCBD({ ...denominationQuantityCBD, denominationQuantity50: '0' })
        }
        break;
      case '$20':
        if (e.target.value == '' || Number(e.target.value) == 0) {
          setDenominationQuantityCBD({ ...denominationQuantityCBD, denominationQuantity20: '0' })
        }
        break;
      case '$10':
        if (e.target.value == '' || Number(e.target.value) == 0) {
          setDenominationQuantityCBD({ ...denominationQuantityCBD, denominationQuantity10: '0' })
        }
        break;
      case '$5':
        if (e.target.value == '' || Number(e.target.value) == 0) {
          setDenominationQuantityCBD({ ...denominationQuantityCBD, denominationQuantity5: '0' })
        }
        break;
      case '$2':
        if (e.target.value == '' || Number(e.target.value) == 0) {
          setDenominationQuantityCBD({ ...denominationQuantityCBD, denominationQuantity2: '0' })
        }
        break;
      case '$1':
        if (e.target.value == '' || Number(e.target.value) == 0) {
          setDenominationQuantityCBD({ ...denominationQuantityCBD, denominationQuantity1: '0' })
        }
        break;
    }

  }
  const handleCurrentTillInp = (e: any) => {
    e.preventDefault();
    console.log('id', e.target.id);
    switch (e.target.id) {
      case '$001':
        setCurrentTill(
          calculateTotalAmountCT(
            '0.01',
            e.target.value !== '' && e.target.value !== undefined
              ? e.target.value
              : '0'
          )
        );
        setDenominationQuantityCT({
          ...denominationQuantityCT,
          denominationQuantity001: e.target.value,
        });
        break;
      case '$005':
        setCurrentTill(
          calculateTotalAmountCT(
            '0.05',
            e.target.value !== '' && e.target.value !== undefined
              ? e.target.value
              : '0'
          )
        );
        setDenominationQuantityCT({
          ...denominationQuantityCT,
          denominationQuantity005: e.target.value,
        });
        break;
      case '$010':
        setCurrentTill(
          calculateTotalAmountCT(
            '0.10',
            e.target.value !== '' && e.target.value !== undefined
              ? e.target.value
              : '0'
          )
        );
        setDenominationQuantityCT({
          ...denominationQuantityCT,
          denominationQuantity010: e.target.value,
        });
        break;
      case '$025':
        setCurrentTill(
          calculateTotalAmountCT(
            '0.25',
            e.target.value !== '' && e.target.value !== undefined
              ? e.target.value
              : '0'
          )
        );
        setDenominationQuantityCT({
          ...denominationQuantityCT,
          denominationQuantity025: e.target.value,
        });
        break;
      case '$050':
        setCurrentTill(
          calculateTotalAmountCT(
            '0.50',
            e.target.value !== '' && e.target.value !== undefined
              ? e.target.value
              : '0'
          )
        );
        setDenominationQuantityCT({
          ...denominationQuantityCT,
          denominationQuantity050: e.target.value,
        });
        break;
      case '$100':
        setCurrentTill(
          calculateTotalAmountCT(
            '100',
            e.target.value !== '' && e.target.value !== undefined
              ? e.target.value
              : '0'
          )
        );
        setDenominationQuantityCT({
          ...denominationQuantityCT,
          denominationQuantity100: e.target.value,
        });
        break;
      case '$50':
        setCurrentTill(
          calculateTotalAmountCT(
            '50',
            e.target.value !== '' && e.target.value !== undefined
              ? e.target.value
              : '0'
          )
        );
        setDenominationQuantityCT({
          ...denominationQuantityCT,
          denominationQuantity50: e.target.value,
        });
        break;
      case '$20':
        setCurrentTill(
          calculateTotalAmountCT(
            '20',
            e.target.value !== '' && e.target.value !== undefined
              ? e.target.value
              : '0'
          )
        );
        setDenominationQuantityCT({
          ...denominationQuantityCT,
          denominationQuantity20: e.target.value,
        });
        break;
      case '$10':
        setCurrentTill(
          calculateTotalAmountCT(
            '10',
            e.target.value !== '' && e.target.value !== undefined
              ? e.target.value
              : '0'
          )
        );
        setDenominationQuantityCT({
          ...denominationQuantityCT,
          denominationQuantity10: e.target.value,
        });
        break;
      case '$5':
        setCurrentTill(
          calculateTotalAmountCT(
            '5',
            e.target.value !== '' && e.target.value !== undefined
              ? e.target.value
              : '0'
          )
        );
        setDenominationQuantityCT({
          ...denominationQuantityCT,
          denominationQuantity5: e.target.value,
        });
        break;
      case '$2':
        setCurrentTill(
          calculateTotalAmountCT(
            '2',
            e.target.value !== '' && e.target.value !== undefined
              ? e.target.value
              : '0'
          )
        );
        setDenominationQuantityCT({
          ...denominationQuantityCT,
          denominationQuantity2: e.target.value,
        });
        break;
      case '$1':
        setCurrentTill(
          calculateTotalAmountCT(
            '1',
            e.target.value !== '' && e.target.value !== undefined
              ? e.target.value
              : '0'
          )
        );
        setDenominationQuantityCT({
          ...denominationQuantityCT,
          denominationQuantity1: e.target.value,
        });
        break;
    }
  };
  const renderCashTableContentCurrentTill = () => {
    return (
      <>
        <React.Fragment>
          {/* $0.01 */}
          <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
            <RACTableCell className="racstarp-tablecell-BgColor">
              <RACButton
                disabled={disableFieldsCurrentTill || disableAmtBtn}
                name="001$"
                id="001$"
                // data-testid="001$"
                // value={denominationQuantityCT.denominationQuantity001.toString()}
                onClick={(e: any) => handleCurrentTillBtn(e)}
                className={`${classes.RACbutton} ${classes.currenttill}`}
              >
                $ 0.01
              </RACButton>
            </RACTableCell>
            <RACTableCell style={{ marginLeft: '10px' }}>
              <RACTextbox
                disabled={disableFieldsCurrentTill || disableAmtBtn}
                className={classes.totalQuantity}
                value={denominationQuantityCT.denominationQuantity001.toString()}
                maxlength={9}
                type="number"
                name="$001"
                id="$001"
                Onblur={(e: any) => handleNonEmptyValueCT(e)}
                OnChange={(e: any) => handleCurrentTillInp(e)}
              />
            </RACTableCell>
            <RACTableCell
              style={{ color: '#4A5174' }}
              className={`${'racstrap-tablecell-BgColor'} ${classes.total}`}
            >
              {/* <Typography >$ {(Number(count.count001) * 0.01).toFixed(2)}</Typography> */}
              <Typography>
                ${' '}
                {formatMoney((
                  Number(denominationQuantityCT.denominationQuantity001) * 0.01
                ).toFixed(2))}
              </Typography>
            </RACTableCell>
          </RACTableRow>
          {/* 0.05$ */}
          <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACButton
                disabled={disableFieldsCurrentTill || disableAmtBtn}
                onClick={(e: any) => handleCurrentTillBtn(e)}
                name="005$"
                id="005$"
                className={`${classes.RACbutton} ${classes.currenttill}`}
              >
                $ 0.05
              </RACButton>
            </RACTableCell>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACTextbox
                disabled={disableFieldsCurrentTill || disableAmtBtn}
                className={classes.totalQuantity}
                value={denominationQuantityCT.denominationQuantity005.toString()}
                maxlength={9}
                type="number"
                name="$005"
                id="$005"
                Onblur={(e: any) => handleNonEmptyValueCT(e)}
                OnChange={(e: any) => handleCurrentTillInp(e)}
              />
            </RACTableCell>
            <RACTableCell
              style={{ color: '#4A5174' }}
              className={`${classes.total} "racstrap-tablecell-BgColor" ${classes.tableCell1}`}
            >
              <Typography>
                ${' '}
                {formatMoney((
                  Number(denominationQuantityCT.denominationQuantity005) * 0.05
                ).toFixed(2))}
              </Typography>
            </RACTableCell>
          </RACTableRow>
          {/* $0.10 */}
          <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACButton
                disabled={disableFieldsCurrentTill || disableAmtBtn}
                onClick={(e: any) => handleCurrentTillBtn(e)}
                name="010$"
                id="010$"
                className={`${classes.RACbutton} ${classes.currenttill}`}
              >
                $ 0.10
              </RACButton>
            </RACTableCell>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACTextbox
                disabled={disableFieldsCurrentTill || disableAmtBtn}
                className={classes.totalQuantity}
                value={denominationQuantityCT.denominationQuantity010.toString()}
                maxlength={9}
                type="number"
                name="$010"
                id="$010"
                Onblur={(e: any) => handleNonEmptyValueCT(e)}
                OnChange={(e: any) => handleCurrentTillInp(e)}
              />
            </RACTableCell>
            <RACTableCell
              style={{ color: '#4A5174' }}
              className={`${classes.total} "racstrap-tablecell-BgColor" ${classes.tableCell1}`}
            >
              <Typography>
                ${' '}
                {formatMoney((
                  Number(denominationQuantityCT.denominationQuantity010) * 0.1
                ).toFixed(2))}
              </Typography>
            </RACTableCell>
          </RACTableRow>
          {/* $0.25 */}
          <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACButton
                disabled={disableFieldsCurrentTill || disableAmtBtn}
                onClick={(e: any) => handleCurrentTillBtn(e)}
                name="025$"
                id="025$"
                className={`${classes.RACbutton} ${classes.currenttill}`}
              >
                $ 0.25
              </RACButton>
            </RACTableCell>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACTextbox
                disabled={disableFieldsCurrentTill || disableAmtBtn}
                className={classes.totalQuantity}
                value={denominationQuantityCT.denominationQuantity025.toString()}
                maxlength={9}
                type="number"
                name="$025"
                id="$025"
                Onblur={(e: any) => handleNonEmptyValueCT(e)}
                OnChange={(e: any) => handleCurrentTillInp(e)}
              />
            </RACTableCell>
            <RACTableCell
              style={{ color: '#4A5174' }}
              className={`${classes.total} "racstrap-tablecell-BgColor" ${classes.tableCell1}`}
            >
              <Typography>
                ${' '}
                {formatMoney((
                  Number(denominationQuantityCT.denominationQuantity025) * 0.25
                ).toFixed(2))}
              </Typography>
            </RACTableCell>
          </RACTableRow>
          {/*0.50 $ */}
          <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACButton
                disabled={disableFieldsCurrentTill || disableAmtBtn}
                onClick={(e: any) => handleCurrentTillBtn(e)}
                name="050$"
                id="050$"
                className={`${classes.RACbutton} ${classes.currenttill}`}
              >
                $ 0.50
              </RACButton>
            </RACTableCell>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACTextbox
                disabled={disableFieldsCurrentTill || disableAmtBtn}
                className={classes.totalQuantity}
                value={denominationQuantityCT.denominationQuantity050.toString()}
                maxlength={9}
                type="number"
                name="$050"
                id="$050"
                Onblur={(e: any) => handleNonEmptyValueCT(e)}
                OnChange={(e: any) => handleCurrentTillInp(e)}
              />
            </RACTableCell>
            <RACTableCell
              style={{ color: '#4A5174' }}
              className={`${classes.total} "racstrap-tablecell-BgColor" ${classes.tableCell1}`}
            >
              <Typography>
                ${' '}
                {formatMoney((
                  Number(denominationQuantityCT.denominationQuantity050) * 0.5
                ).toFixed(2))}
              </Typography>
            </RACTableCell>
          </RACTableRow>
          {/* 1$ */}
          <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACButton
                disabled={disableFieldsCurrentTill || disableAmtBtn}
                onClick={(e: any) => handleCurrentTillBtn(e)}
                name="1$"
                id="1$"
                className={`${classes.RACbutton} ${classes.currenttill}`}
              >
                $ 1
              </RACButton>
            </RACTableCell>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACTextbox
                disabled={disableFieldsCurrentTill || disableAmtBtn}
                className={classes.totalQuantity}
                value={denominationQuantityCT.denominationQuantity1.toString()}
                maxlength={9}
                type="number"
                name="$1"
                id="$1"
                Onblur={(e: any) => handleNonEmptyValueCT(e)}
                OnChange={(e: any) => handleCurrentTillInp(e)}
              />
            </RACTableCell>
            <RACTableCell
              style={{ color: '#4A5174' }}
              className={`${classes.total} "racstrap-tablecell-BgColor" ${classes.tableCell1}`}
            >
              <Typography>
                ${' '}
                {formatMoney((
                  Number(denominationQuantityCT.denominationQuantity1) * 1
                ).toFixed(2))}
              </Typography>
            </RACTableCell>
          </RACTableRow>
          {/* 2$ */}
          <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACButton
                disabled={disableFieldsCurrentTill || disableAmtBtn}
                onClick={(e: any) => handleCurrentTillBtn(e)}
                name="2$"
                id="2$"
                className={`${classes.RACbutton} ${classes.currenttill}`}
              >
                $ 2
              </RACButton>
            </RACTableCell>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACTextbox
                disabled={disableFieldsCurrentTill || disableAmtBtn}
                className={classes.totalQuantity}
                value={denominationQuantityCT.denominationQuantity2.toString()}
                maxlength={9}
                type="number"
                name="$2"
                id="$2"
                Onblur={(e: any) => handleNonEmptyValueCT(e)}
                OnChange={(e: any) => handleCurrentTillInp(e)}
              />
            </RACTableCell>

            <RACTableCell
              style={{ color: '#4A5174' }}
              className={`${classes.total} "racstrap-tablecell-BgColor" ${classes.tableCell1}`}
            >
              <Typography>
                ${' '}
                {formatMoney((
                  Number(denominationQuantityCT.denominationQuantity2) * 2
                ).toFixed(2))}
              </Typography>
            </RACTableCell>
          </RACTableRow>
          {/* 5$ */}
          <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACButton
                disabled={disableFieldsCurrentTill || disableAmtBtn}
                onClick={(e: any) => handleCurrentTillBtn(e)}
                name="5$"
                id="5$"
                className={`${classes.RACbutton} ${classes.currenttill}`}
              >
                $ 5
              </RACButton>
            </RACTableCell>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACTextbox
                disabled={disableFieldsCurrentTill || disableAmtBtn}
                className={classes.totalQuantity}
                value={denominationQuantityCT.denominationQuantity5.toString()}
                maxlength={9}
                type="number"
                name="$5"
                id="$5"
                Onblur={(e: any) => handleNonEmptyValueCT(e)}
                OnChange={(e: any) => handleCurrentTillInp(e)}
              />
            </RACTableCell>
            <RACTableCell
              style={{ color: '#4A5174' }}
              className={`${classes.total} ${classes.tableCell1} "racstrap-tablecell-BgColor" ${classes.tableCell1}`}
            >
              <Typography>
                ${' '}
                {formatMoney((
                  Number(denominationQuantityCT.denominationQuantity5) * 5
                ).toFixed(2))}
              </Typography>
            </RACTableCell>
          </RACTableRow>
          {/* 10$ */}
          <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACButton
                disabled={disableFieldsCurrentTill || disableAmtBtn}
                onClick={(e: any) => handleCurrentTillBtn(e)}
                name="10$"
                id="10$"
                className={`${classes.RACbutton} ${classes.currenttill}`}
              >
                $ 10
              </RACButton>
            </RACTableCell>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACTextbox
                disabled={disableFieldsCurrentTill || disableAmtBtn}
                className={classes.totalQuantity}
                value={denominationQuantityCT.denominationQuantity10.toString()}
                maxlength={9}
                type="number"
                name="$10"
                id="$10"
                Onblur={(e: any) => handleNonEmptyValueCT(e)}
                OnChange={(e: any) => handleCurrentTillInp(e)}
              />
            </RACTableCell>
            <RACTableCell
              style={{ color: '#4A5174' }}
              className={`${classes.total} "racstrap-tablecell-BgColor" ${classes.tableCell1}`}
            >
              <Typography>
                ${' '}
                {formatMoney((
                  Number(denominationQuantityCT.denominationQuantity10) * 10
                ).toFixed(2))}
              </Typography>
            </RACTableCell>
          </RACTableRow>
          {/* 20$ */}
          <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACButton
                onClick={(e: any) => handleCurrentTillBtn(e)}
                name="20$"
                id="20$"
                disabled={disableFieldsCurrentTill || disableAmtBtn}
                className={`${classes.RACbutton} ${classes.currenttill}`}
              >
                $ 20
              </RACButton>
            </RACTableCell>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACTextbox
                disabled={disableFieldsCurrentTill || disableAmtBtn}
                className={classes.totalQuantity}
                value={denominationQuantityCT.denominationQuantity20.toString()}
                maxlength={9}
                type="number"
                name="$20"
                id="$20"
                Onblur={(e: any) => handleNonEmptyValueCT(e)}
                OnChange={(e: any) => handleCurrentTillInp(e)}
              />
            </RACTableCell>
            <RACTableCell
              style={{ color: '#4A5174' }}
              className={`${classes.total} "racstrap-tablecell-BgColor" ${classes.tableCell1}`}
            >
              <Typography>
                ${' '}
                {formatMoney((
                  Number(denominationQuantityCT.denominationQuantity20) * 20
                ).toFixed(2))}
              </Typography>
            </RACTableCell>
          </RACTableRow>
          {/* 50$ */}
          <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACButton
                disabled={disableFieldsCurrentTill || disableAmtBtn}
                onClick={(e: any) => handleCurrentTillBtn(e)}
                name="50$"
                id="50$"
                className={`${classes.RACbutton} ${classes.currenttill}`}
              >
                $ 50
              </RACButton>
            </RACTableCell>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACTextbox
                disabled={disableFieldsCurrentTill || disableAmtBtn}
                className={classes.totalQuantity}
                value={denominationQuantityCT.denominationQuantity50.toString()}
                maxlength={9}
                type="number"
                name="$50"
                id="$50"
                Onblur={(e: any) => handleNonEmptyValueCT(e)}
                OnChange={(e: any) => handleCurrentTillInp(e)}
              />
            </RACTableCell>
            <RACTableCell
              style={{ color: '#4A5174' }}
              className={`${classes.total} "racstrap-tablecell-BgColor" ${classes.tableCell1}`}
            >
              <Typography>
                ${' '}
                {formatMoney((
                  Number(denominationQuantityCT.denominationQuantity50) * 50
                ).toFixed(2))}
              </Typography>
            </RACTableCell>
          </RACTableRow>
          {/* 100$ */}
          <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACButton
                disabled={disableFieldsCurrentTill || disableAmtBtn}
                onClick={(e: any) => handleCurrentTillBtn(e)}
                name="100$"
                id="100$"
                className={`${classes.RACbutton} ${classes.currenttill}`}
              >
                $ 100
              </RACButton>
            </RACTableCell>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACTextbox
                disabled={disableFieldsCurrentTill || disableAmtBtn}
                className={classes.totalQuantity}
                value={denominationQuantityCT.denominationQuantity100.toString()}
                maxlength={10}
                type="number"
                name="$100"
                id="$100"
                OnChange={(e: any) => handleCurrentTillInp(e)}
                Onblur={(e: any) => handleNonEmptyValueCT(e)}
              />
            </RACTableCell>
            <RACTableCell
              style={{ color: '#4A5174' }}
              className={`${classes.total} "racstrap-tablecell-BgColor" ${classes.tableCell1}`}
            >
              <Typography>
                ${' '}
                {formatMoney((
                  Number(denominationQuantityCT.denominationQuantity100) * 100
                ).toFixed(2))}
              </Typography>
            </RACTableCell>
          </RACTableRow>
        </React.Fragment>
      </>
    );
  };
  //cash being dropped
  const renderCashTableContentCashBeingDropped = () => {
    return (
      <>
        <React.Fragment>
          {/* $0.01 */}
          <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
            <RACTableCell className="racstarp-tablecell-BgColor">
              <RACButton
                disabled={disableFieldsCashBeingDropped || disableAmtBtn}
                name="001$"
                id="001$"
                onClick={(e: any) => handleCashBeingDroppedBtn(e)}
                className={`${classes.RACbutton} ${classes.currenttill}`}
              >
                $ 0.01
              </RACButton>
            </RACTableCell>
            <RACTableCell style={{ marginLeft: '10px' }}>
              <RACTextbox
                disabled={disableFieldsCashBeingDropped || disableAmtBtn}
                className={classes.totalQuantity}
                value={denominationQuantityCBD.denominationQuantity001.toString()}
                maxlength={9}
                type="number"
                name="$001"
                id="$001"
                Onblur={(e: any) => handleNonEmptyValueCBD(e)}
                OnChange={(e: any) => handleCashBeingDroppedInp(e)}
              />
            </RACTableCell>
            <RACTableCell
              style={{ color: '#4A5174' }}
              className={`${'racstrap-tablecell-BgColor'} ${classes.total}`}
            >
              <Typography>
                ${' '}
                {formatMoney((
                  Number(denominationQuantityCBD.denominationQuantity001) * 0.01
                ).toFixed(2))}
                {/* {setCurrentTill({...currentTill,denominationQuantityValue001:(Number(denominationQuantityCBD.denominationQuantity001) * 0.01)}) } */}
              </Typography>
            </RACTableCell>
          </RACTableRow>
          {/* 0.05$ */}
          <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACButton
                disabled={disableFieldsCashBeingDropped || disableAmtBtn}
                onClick={(e: any) => handleCashBeingDroppedBtn(e)}
                name="005$"
                id="005$"
                className={`${classes.RACbutton} ${classes.currenttill}`}
              >
                $ 0.05
              </RACButton>
            </RACTableCell>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACTextbox
                disabled={disableFieldsCashBeingDropped || disableAmtBtn}
                className={classes.totalQuantity}
                value={denominationQuantityCBD.denominationQuantity005.toString()}
                maxlength={9}
                type="number"
                name="$005"
                id="$005"
                Onblur={(e: any) => handleNonEmptyValueCBD(e)}
                OnChange={(e: any) => handleCashBeingDroppedInp(e)}
              />
            </RACTableCell>
            <RACTableCell
              style={{ color: '#4A5174' }}
              className={`${classes.total} "racstrap-tablecell-BgColor" ${classes.tableCell1}`}
            >
              <Typography>
                ${' '}
                {formatMoney((
                  Number(denominationQuantityCBD.denominationQuantity005) * 0.05
                ).toFixed(2))}
              </Typography>
            </RACTableCell>
          </RACTableRow>
          {/* $0.10 */}
          <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACButton
                disabled={disableFieldsCashBeingDropped || disableAmtBtn}
                onClick={(e: any) => handleCashBeingDroppedBtn(e)}
                name="010$"
                id="010$"
                className={`${classes.RACbutton} ${classes.currenttill}`}
              >
                $ 0.10
              </RACButton>
            </RACTableCell>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACTextbox
                disabled={disableFieldsCashBeingDropped || disableAmtBtn}
                className={classes.totalQuantity}
                value={denominationQuantityCBD.denominationQuantity010.toString()}
                maxlength={9}
                type="number"
                name="$010"
                id="$010"
                Onblur={(e: any) => handleNonEmptyValueCBD(e)}
                OnChange={(e: any) => handleCashBeingDroppedInp(e)}
              />
            </RACTableCell>
            <RACTableCell
              style={{ color: '#4A5174' }}
              className={`${classes.total} "racstrap-tablecell-BgColor" ${classes.tableCell1}`}
            >
              <Typography>
                ${' '}
                {formatMoney((
                  Number(denominationQuantityCBD.denominationQuantity010) * 0.1
                ).toFixed(2))}
              </Typography>
            </RACTableCell>
          </RACTableRow>
          {/* $0.25 */}
          <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACButton
                disabled={disableFieldsCashBeingDropped || disableAmtBtn}
                onClick={(e: any) => handleCashBeingDroppedBtn(e)}
                name="025$"
                id="025$"
                className={`${classes.RACbutton} ${classes.currenttill}`}
              >
                $ 0.25
              </RACButton>
            </RACTableCell>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACTextbox
                disabled={disableFieldsCashBeingDropped || disableAmtBtn}
                className={classes.totalQuantity}
                value={denominationQuantityCBD.denominationQuantity025.toString()}
                maxlength={9}
                type="number"
                name="$025"
                id="$025"
                Onblur={(e: any) => handleNonEmptyValueCBD(e)}
                OnChange={(e: any) => handleCashBeingDroppedInp(e)}
              />
            </RACTableCell>
            <RACTableCell
              style={{ color: '#4A5174' }}
              className={`${classes.total} "racstrap-tablecell-BgColor" ${classes.tableCell1}`}
            >
              <Typography>
                ${' '}
                {formatMoney((
                  Number(denominationQuantityCBD.denominationQuantity025) * 0.25
                ).toFixed(2))}
              </Typography>
            </RACTableCell>
          </RACTableRow>
          {/*0.50 $ */}
          <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACButton
                disabled={disableFieldsCashBeingDropped || disableAmtBtn}
                onClick={(e: any) => handleCashBeingDroppedBtn(e)}
                name="050$"
                id="050$"
                className={`${classes.RACbutton} ${classes.currenttill}`}
              >
                $ 0.50
              </RACButton>
            </RACTableCell>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACTextbox
                disabled={disableFieldsCashBeingDropped || disableAmtBtn}
                className={classes.totalQuantity}
                value={denominationQuantityCBD.denominationQuantity050.toString()}
                maxlength={9}
                type="number"
                name="$050"
                id="$050"
                Onblur={(e: any) => handleNonEmptyValueCBD(e)}
                OnChange={(e: any) => handleCashBeingDroppedInp(e)}
              />
            </RACTableCell>
            <RACTableCell
              style={{ color: '#4A5174' }}
              className={`${classes.total} "racstrap-tablecell-BgColor" ${classes.tableCell1}`}
            >
              <Typography>
                ${' '}
                {formatMoney((
                  Number(denominationQuantityCBD.denominationQuantity050) * 0.5
                ).toFixed(2))}
              </Typography>
            </RACTableCell>
          </RACTableRow>
          {/* 1$ */}
          <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACButton
                disabled={disableFieldsCashBeingDropped || disableAmtBtn}
                onClick={(e: any) => handleCashBeingDroppedBtn(e)}
                name="1$"
                id="1$"
                className={`${classes.RACbutton} ${classes.currenttill}`}
              >
                $ 1
              </RACButton>
            </RACTableCell>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACTextbox
                disabled={disableFieldsCashBeingDropped || disableAmtBtn}
                className={classes.totalQuantity}
                value={denominationQuantityCBD.denominationQuantity1.toString()}
                maxlength={9}
                type="number"
                name="$1"
                id="$1"
                Onblur={(e: any) => handleNonEmptyValueCBD(e)}
                OnChange={(e: any) => handleCashBeingDroppedInp(e)}
              />
            </RACTableCell>
            <RACTableCell
              style={{ color: '#4A5174' }}
              className={`${classes.total} "racstrap-tablecell-BgColor" ${classes.tableCell1}`}
            >
              <Typography>
                ${' '}
                {formatMoney((
                  Number(denominationQuantityCBD.denominationQuantity1) * 1
                ).toFixed(2))}
              </Typography>
            </RACTableCell>
          </RACTableRow>
          {/* 2$ */}
          <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACButton
                disabled={disableFieldsCashBeingDropped || disableAmtBtn}
                onClick={(e: any) => handleCashBeingDroppedBtn(e)}
                name="2$"
                id="2$"
                className={`${classes.RACbutton} ${classes.currenttill}`}
              >
                $ 2
              </RACButton>
            </RACTableCell>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACTextbox
                disabled={disableFieldsCashBeingDropped || disableAmtBtn}
                className={classes.totalQuantity}
                value={denominationQuantityCBD.denominationQuantity2.toString()}
                maxlength={9}
                type="number"
                name="$2"
                id="$2"
                Onblur={(e: any) => handleNonEmptyValueCBD(e)}
                OnChange={(e: any) => handleCashBeingDroppedInp(e)}
              />
            </RACTableCell>

            <RACTableCell
              style={{ color: '#4A5174' }}
              className={`${classes.total} "racstrap-tablecell-BgColor" ${classes.tableCell1}`}
            >
              <Typography>
                ${' '}
                {formatMoney((
                  Number(denominationQuantityCBD.denominationQuantity2) * 2
                ).toFixed(2))}
              </Typography>
            </RACTableCell>
          </RACTableRow>
          {/* 5$ */}
          <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACButton
                disabled={disableFieldsCashBeingDropped || disableAmtBtn}
                onClick={(e: any) => handleCashBeingDroppedBtn(e)}
                name="5$"
                id="5$"
                className={`${classes.RACbutton} ${classes.currenttill}`}
              >
                $ 5
              </RACButton>
            </RACTableCell>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACTextbox
                disabled={disableFieldsCashBeingDropped || disableAmtBtn}
                className={classes.totalQuantity}
                value={denominationQuantityCBD.denominationQuantity5.toString()}
                maxlength={9}
                type="number"
                name="$5"
                id="$5"
                Onblur={(e: any) => handleNonEmptyValueCBD(e)}
                OnChange={(e: any) => handleCashBeingDroppedInp(e)}
              />
            </RACTableCell>
            <RACTableCell
              style={{ color: '#4A5174' }}
              className={`${classes.total} ${classes.tableCell1} "racstrap-tablecell-BgColor" ${classes.tableCell1}`}
            >
              <Typography>
                ${' '}
                {formatMoney((
                  Number(denominationQuantityCBD.denominationQuantity5) * 5
                ).toFixed(2))}
              </Typography>
            </RACTableCell>
          </RACTableRow>
          {/* 10$ */}
          <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACButton
                disabled={disableFieldsCashBeingDropped || disableAmtBtn}
                onClick={(e: any) => handleCashBeingDroppedBtn(e)}
                name="10$"
                id="10$"
                className={`${classes.RACbutton} ${classes.currenttill}`}
              >
                $ 10
              </RACButton>
            </RACTableCell>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACTextbox
                disabled={disableFieldsCashBeingDropped || disableAmtBtn}
                className={classes.totalQuantity}
                value={denominationQuantityCBD.denominationQuantity10.toString()}
                maxlength={9}
                type="number"
                name="$10"
                id="$10"
                Onblur={(e: any) => handleNonEmptyValueCBD(e)}
                OnChange={(e: any) => handleCashBeingDroppedInp(e)}
              />
            </RACTableCell>
            <RACTableCell
              style={{ color: '#4A5174' }}
              className={`${classes.total} "racstrap-tablecell-BgColor" ${classes.tableCell1}`}
            >
              <Typography>
                ${' '}
                {formatMoney((
                  Number(denominationQuantityCBD.denominationQuantity10) * 10
                ).toFixed(2))}
              </Typography>
            </RACTableCell>
          </RACTableRow>
          {/* 20$ */}
          <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACButton
                onClick={(e: any) => handleCashBeingDroppedBtn(e)}
                name="20$"
                id="20$"
                disabled={disableFieldsCashBeingDropped || disableAmtBtn}
                className={`${classes.RACbutton} ${classes.currenttill}`}
              >
                $ 20
              </RACButton>
            </RACTableCell>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACTextbox
                disabled={disableFieldsCashBeingDropped || disableAmtBtn}
                className={classes.totalQuantity}
                value={denominationQuantityCBD.denominationQuantity20.toString()}
                maxlength={9}
                type="number"
                name="$20"
                id="$20"
                Onblur={(e: any) => handleNonEmptyValueCBD(e)}
                OnChange={(e: any) => handleCashBeingDroppedInp(e)}
              />
            </RACTableCell>
            <RACTableCell
              style={{ color: '#4A5174' }}
              className={`${classes.total} "racstrap-tablecell-BgColor" ${classes.tableCell1}`}
            >
              <Typography>
                ${' '}
                {formatMoney((
                  Number(denominationQuantityCBD.denominationQuantity20) * 20
                ).toFixed(2))}
              </Typography>
            </RACTableCell>
          </RACTableRow>
          {/* 50$ */}
          <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACButton
                disabled={disableFieldsCashBeingDropped || disableAmtBtn}
                onClick={(e: any) => handleCashBeingDroppedBtn(e)}
                name="50$"
                id="50$"
                className={`${classes.RACbutton} ${classes.currenttill}`}
              >
                $ 50
              </RACButton>
            </RACTableCell>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACTextbox
                disabled={disableFieldsCashBeingDropped || disableAmtBtn}
                className={classes.totalQuantity}
                value={denominationQuantityCBD.denominationQuantity50.toString()}
                maxlength={9}
                type="number"
                name="$50"
                id="$50"
                Onblur={(e: any) => handleNonEmptyValueCBD(e)}
                OnChange={(e: any) => handleCashBeingDroppedInp(e)}
              />
            </RACTableCell>
            <RACTableCell
              style={{ color: '#4A5174' }}
              className={`${classes.total} "racstrap-tablecell-BgColor" ${classes.tableCell1}`}
            >
              <Typography>
                ${' '}
                {formatMoney((
                  Number(denominationQuantityCBD.denominationQuantity50) * 50
                ).toFixed(2))}
              </Typography>
            </RACTableCell>
          </RACTableRow>
          {/* 100$ */}
          <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACButton
                disabled={disableFieldsCashBeingDropped || disableAmtBtn}
                onClick={(e: any) => handleCashBeingDroppedBtn(e)}
                name="100$"
                id="100$"
                className={`${classes.RACbutton} ${classes.currenttill}`}
              >
                $ 100
              </RACButton>
            </RACTableCell>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACTextbox
                disabled={disableFieldsCashBeingDropped || disableAmtBtn}
                className={classes.totalQuantity}
                value={denominationQuantityCBD.denominationQuantity100.toString()}
                maxlength={10}
                type="number"
                name="$100"
                id="$100"
                Onblur={(e: any) => handleNonEmptyValueCBD(e)}
                OnChange={(e: any) => handleCashBeingDroppedInp(e)}
              />
            </RACTableCell>
            <RACTableCell
              style={{ color: '#4A5174' }}
              className={`${classes.total} "racstrap-tablecell-BgColor" ${classes.tableCell1}`}
            >
              <Typography>
                ${' '}
                {formatMoney((
                  Number(denominationQuantityCBD.denominationQuantity100) * 100
                ).toFixed(2))}
              </Typography>
            </RACTableCell>
          </RACTableRow>
        </React.Fragment>
      </>
    );
  };
  const renderCashTableHeadCashBeingDropped = () => {
    return (
      <React.Fragment>
        <RACTableCell className={`${classes.tableCellDenomination}`}>
          <Typography
          // variant="h5"
          >
            Denomination
          </Typography>
        </RACTableCell>
        <RACTableCell className={`${classes.tableCell}`}>
          <Typography
            // variant="h5"
            style={{
              marginLeft: '10.5%',
              width: '100%',
            }}
          >
            <Grid className={classes.row}>
              <Grid>Total</Grid>
              <Grid> Quantity</Grid>
            </Grid>
          </Typography>
        </RACTableCell>
        <RACTableCell
          style={{ paddingRight: '6.5%', textAlign: 'right' }}
          className={`${classes.tableCellTotal}`}
        >
          <Typography
          // variant="h5"
          >
            Total
          </Typography>
        </RACTableCell>
      </React.Fragment>
    );
  };
  const handleCashBeingDroppedBtn = (e: any) => {
    console.log(e.currentTarget.id, 'cashCurrent');
    switch (e.currentTarget.id) {
      case '001$':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmountCBT(
            '0.01',
            (
              Number(denominationQuantityCBD.denominationQuantity001) + 1
            ).toString()
          ),
        });
        setDenominationQuantityCBD({
          ...denominationQuantityCBD,
          denominationQuantity001: (
            Number(denominationQuantityCBD.denominationQuantity001) + 1
          ).toString(),
        });

        break;
      case '005$':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmountCBT(
            '0.05',
            (
              Number(denominationQuantityCBD.denominationQuantity005) + 1
            ).toString()
          ),
        });
        setDenominationQuantityCBD({
          ...denominationQuantityCBD,
          denominationQuantity005: (
            Number(denominationQuantityCBD.denominationQuantity005) + 1
          ).toString(),
        });
        break;
      case '010$':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmountCBT(
            '0.10',
            (
              Number(denominationQuantityCBD.denominationQuantity010) + 1
            ).toString()
          ),
        });
        setDenominationQuantityCBD({
          ...denominationQuantityCBD,
          denominationQuantity010: (
            Number(denominationQuantityCBD.denominationQuantity010) + 1
          ).toString(),
        });
        break;
      case '025$':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmountCBT(
            '0.25',
            (
              Number(denominationQuantityCBD.denominationQuantity025) + 1
            ).toString()
          ),
        });
        setDenominationQuantityCBD({
          ...denominationQuantityCBD,
          denominationQuantity025: (
            Number(denominationQuantityCBD.denominationQuantity025) + 1
          ).toString(),
        });
        break;
      case '050$':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmountCBT(
            '0.50',
            (
              Number(denominationQuantityCBD.denominationQuantity050) + 1
            ).toString()
          ),
        });
        setDenominationQuantityCBD({
          ...denominationQuantityCBD,
          denominationQuantity050: (
            Number(denominationQuantityCBD.denominationQuantity050) + 1
          ).toString(),
        });
        break;
      case '100$':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmountCBT(
            '100',
            (
              Number(denominationQuantityCBD.denominationQuantity100) + 1
            ).toString()
          ),
        });
        setDenominationQuantityCBD({
          ...denominationQuantityCBD,
          denominationQuantity100: (
            Number(denominationQuantityCBD.denominationQuantity100) + 1
          ).toString(),
        });
        break;
      case '50$':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmountCBT(
            '50',
            (
              Number(denominationQuantityCBD.denominationQuantity50) + 1
            ).toString()
          ),
        });
        setDenominationQuantityCBD({
          ...denominationQuantityCBD,
          denominationQuantity50: (
            Number(denominationQuantityCBD.denominationQuantity50) + 1
          ).toString(),
        });
        break;
      case '20$':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmountCBT(
            '20',
            (
              Number(denominationQuantityCBD.denominationQuantity20) + 1
            ).toString()
          ),
        });
        setDenominationQuantityCBD({
          ...denominationQuantityCBD,
          denominationQuantity20: (
            Number(denominationQuantityCBD.denominationQuantity20) + 1
          ).toString(),
        });
        break;
      case '10$':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmountCBT(
            '10',
            (
              Number(denominationQuantityCBD.denominationQuantity10) + 1
            ).toString()
          ),
        });
        setDenominationQuantityCBD({
          ...denominationQuantityCBD,
          denominationQuantity10: (
            Number(denominationQuantityCBD.denominationQuantity10) + 1
          ).toString(),
        });
        break;
      case '5$':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmountCBT(
            '5',
            (
              Number(denominationQuantityCBD.denominationQuantity5) + 1
            ).toString()
          ),
        });
        setDenominationQuantityCBD({
          ...denominationQuantityCBD,
          denominationQuantity5: (
            Number(denominationQuantityCBD.denominationQuantity5) + 1
          ).toString(),
        });
        break;
      case '2$':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmountCBT(
            '2',
            (
              Number(denominationQuantityCBD.denominationQuantity2) + 1
            ).toString()
          ),
        });
        setDenominationQuantityCBD({
          ...denominationQuantityCBD,
          denominationQuantity2: (
            Number(denominationQuantityCBD.denominationQuantity2) + 1
          ).toString(),
        });
        break;
      case '1$':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmountCBT(
            '1',
            (
              Number(denominationQuantityCBD.denominationQuantity1) + 1
            ).toString()
          ),
        });
        setDenominationQuantityCBD({
          ...denominationQuantityCBD,
          denominationQuantity1: (
            Number(denominationQuantityCBD.denominationQuantity1) + 1
          ).toString(),
        });
        break;
    }
  };
  const handleCashBeingDroppedInp = (e: any) => {
    console.log(e.target.id, 'id');
    switch (e.target.id) {
      case '$001':
        // let cbd001 = Number(e.target.value) * 0.01
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmountCBT(
            '0.01',
            e.target.value !== '' &&
              e.target.value !== undefined &&
              e.target.value !== null
              ? e.target.value
              : '0'
          ),
        });
        setDenominationQuantityCBD({
          ...denominationQuantityCBD,
          denominationQuantity001: e.target.value,
        });
        // setCashBeingDrop(cashBeingDrop + cbd001)
        break;
      case '$005':
        // let cbd005 = Number(e.target.value) * 0.05
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmountCBT(
            '0.05',
            e.target.value !== '' &&
              e.target.value !== undefined &&
              e.target.value !== null
              ? e.target.value
              : '0'
          ),
        });
        setDenominationQuantityCBD({
          ...denominationQuantityCBD,
          denominationQuantity005: e.target.value,
        });
        // setCashBeingDrop(cashBeingDrop + cbd005)
        break;
      case '$010':
        // let cbd010 = Number(e.target.value) * 0.10
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmountCBT(
            '0.10',
            e.target.value !== '' &&
              e.target.value !== undefined &&
              e.target.value !== null
              ? e.target.value
              : '0'
          ),
        });
        setDenominationQuantityCBD({
          ...denominationQuantityCBD,
          denominationQuantity010: e.target.value,
        });
        // setCashBeingDrop(cashBeingDrop + cbd010)
        break;
      case '$025':
        // let cbd025 = Number(e.target.value) * 0.25
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmountCBT(
            '0.25',
            e.target.value !== '' &&
              e.target.value !== undefined &&
              e.target.value !== null
              ? e.target.value
              : '0'
          ),
        });
        setDenominationQuantityCBD({
          ...denominationQuantityCBD,
          denominationQuantity025: e.target.value,
        });
        // setCashBeingDrop(cashBeingDrop + cbd025)
        break;
      case '$050':
        // let cbd050 = Number(e.target.value) * 0.50
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmountCBT(
            '0.50',
            e.target.value !== '' &&
              e.target.value !== undefined &&
              e.target.value !== null
              ? e.target.value
              : '0'
          ),
        });
        setDenominationQuantityCBD({
          ...denominationQuantityCBD,
          denominationQuantity050: e.target.value,
        });
        // setCashBeingDrop(cashBeingDrop + cbd050)
        break;
      case '$100':
        // let cbd100 = Number(e.target.value) * 100
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmountCBT(
            '100',
            e.target.value !== '' &&
              e.target.value !== undefined &&
              e.target.value !== null
              ? e.target.value
              : '0'
          ),
        });
        setDenominationQuantityCBD({
          ...denominationQuantityCBD,
          denominationQuantity100: e.target.value,
        });
        // setCashBeingDrop(cashBeingDrop + cbd100)
        break;
      case '$50':
        // let cbd50 = Number(e.target.value) * 50
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmountCBT(
            '50',
            e.target.value !== '' &&
              e.target.value !== undefined &&
              e.target.value !== null
              ? e.target.value
              : '0'
          ),
        });
        setDenominationQuantityCBD({
          ...denominationQuantityCBD,
          denominationQuantity50: e.target.value,
        });
        // setCashBeingDrop(cashBeingDrop + cbd50)
        break;
      case '$20':
        // let cbd20 = Number(e.target.value) * 20
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmountCBT(
            '20',
            e.target.value !== '' &&
              e.target.value !== undefined &&
              e.target.value !== null
              ? e.target.value
              : '0'
          ),
        });
        setDenominationQuantityCBD({
          ...denominationQuantityCBD,
          denominationQuantity20: e.target.value,
        });
        // setCashBeingDrop(cashBeingDrop + cbd20)
        break;
      case '$10':
        // let cbd10 = Number(e.target.value) * 10
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmountCBT(
            '10',
            e.target.value !== '' &&
              e.target.value !== undefined &&
              e.target.value !== null
              ? e.target.value
              : '0'
          ),
        });
        setDenominationQuantityCBD({
          ...denominationQuantityCBD,
          denominationQuantity10: e.target.value,
        });
        // setCashBeingDrop(cashBeingDrop + cbd10)
        break;
      case '$5':
        // let cbd5 = Number(e.target.value) * 5
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmountCBT(
            '5',
            e.target.value !== '' &&
              e.target.value !== undefined &&
              e.target.value !== null
              ? e.target.value
              : '0'
          ),
        });
        setDenominationQuantityCBD({
          ...denominationQuantityCBD,
          denominationQuantity5: e.target.value,
        });
        // setCashBeingDrop(cashBeingDrop + cbd5)
        break;
      case '$2':
        // let cbd2 = Number(e.target.value) * 2
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmountCBT(
            '2',
            e.target.value !== '' &&
              e.target.value !== undefined &&
              e.target.value !== null
              ? e.target.value
              : '0'
          ),
        });
        setDenominationQuantityCBD({
          ...denominationQuantityCBD,
          denominationQuantity2: e.target.value,
        });
        // setCashBeingDrop(cashBeingDrop + cbd2)
        break;
      case '$1':
        // let cbd1 = Number(e.target.value) * 1
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmountCBT(
            '1',
            e.target.value !== '' &&
              e.target.value !== undefined &&
              e.target.value !== null
              ? e.target.value
              : '0'
          ),
        });
        setDenominationQuantityCBD({
          ...denominationQuantityCBD,
          denominationQuantity1: e.target.value,
        });
        // setCashBeingDrop(cashBeingDrop + cbd1)
        break;
    }
  };
  const storeExceptionCompleted = async (updatedPayload?: any) => {
    setStoreExceptionEnable(false)
    setstoreExceptionPayload(updatedPayload)
    setModals({
      ...modals,
      OverShortAmountIsOpen: true,
      explanationModalIsOpen: false,
    });
  }
  const storeExceptionCancelled = async () => {
    setStoreExceptionEnable(false)
    handleModels('ConfirmationSaveIsOpen', false);
    handleModels('explanationModalIsOpen', false);
    //  handleModels('ExplanationCancelPopupIsOpen',false)
    setShortExplanation('');

  }
  return (
    <>
      {secondLevelAuthenOn ? (
        <SecondFactor
          setTwoFactorCancelClick={() => setSecondLevelAuthenOn(false)}
          setTwoFactorCompleted={(e: any) => seconduthenResponses(e)}
          currentRole="CAR"
          moduleName="Accept Payment"
        //  storeNumber="02202"
        ></SecondFactor>
      ) : null}

      {StoreExceptionEnable ? (< StoreException
        refCode={'FDOS'}
        storeExceptionCancelled={storeExceptionCancelled}
        storeExceptionCompleted={storeExceptionCompleted}
      />) : null}


      {/* parent container */}
      <Grid
        style={{
          // marginBottom: "35%",
          //  width: "100%",
          paddingRight: '0.75rem',
          paddingLeft: '0.75rem',
          marginRight: 'auto',
          marginLeft: 'auto',
          marginTop: '60px',
          paddingBottom: '80px !important',
        }}
      //  className={classes.container}
      >
        <ValidateCashBeingDropModelCardForGreater />
        <ValidateCashBeingDropModelCardForLesser />
        <ValidateNonCashModelCard />
        <DropEnvelopModalCard />
        {/* <ExplanationCancelModalCard /> */}
        <OverShortAmountModalCard />
        <CurrentTillAcceptBtnLessDefaultStartingTillModelCard />
        <CurrentTillAcceptBtnGreaterDefaultStartingTillModelCard />
        <ConfirmationSavePopupModelCard />
        {/* <HandleExplanation_OverShort /> */}
        {/* <OverShortTotalModalcard /> */}
        <ConfirmationCancelIsOpen />
        {/* <ValidateNonCashPopup /> */}
        <ConfiguredDropMaxLimit />
        <SaveBtnLessDefaultStartingTillModelCard />
        <FinalNotRequiredModelCard />
        <RACModalCard
          isOpen={modals.explanationModalIsOpen}
          maxWidth="sm"
          closeIcon={true}
          borderRadius="20px"
          onClose={() => {
            handleModels('ConfirmationSaveIsOpen', false);
            handleModels('explanationModalIsOpen', false);
            setShortExplanation('')
          }}
          // eslint-disable-next-line react/no-children-prop
          children={ExplanationModalPopUpIsOpen()}
          title="Enter Explanation for Final Drop Over Short Exception"
        />
        <RACModalCard
          isOpen={modals.overShortTotalIsOpen}
          maxWidth="sm"
          closeIcon={true}
          borderRadius="20px"
          onClose={() => { handleModels('overShortTotalIsOpen', false); setShortExplanation(''); setOverShortExplanationNotes('') }}
          // eslint-disable-next-line react/no-children-prop
          children={overShortTotalIsOpenPopUp()}
        // title="Validation Message"
        />
        {/* Subparent conatiner */}
        <Grid style={{ width: '100%', float: 'left' }}>
          <Grid className={classes.colMd12mt3}>
            <Grid className={classes.row} style={{ marginLeft: '2.5px' }}>
              {/* Drawer total starts here */}
              <Typography variant="h4" className={classes.header}>
                Final Drop
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          style={{
            width: '100%',
            // paddingLeft:"0.2%",
            float: 'left',
            marginBottom: '11%',
          }}
        >
          <Grid style={{ width: '51%' }}>
            <Grid style={{ width: '100%' }}>
              {/* <CurrentTillFD></CurrentTillFD> */}
              <Grid
                // className={classes.mt3}
                item
                style={{ float: 'left', width: '48%', marginRight: '2%' }}
              >
                <Typography variant="h4" className={classes.header}>
                  Current Till
                </Typography>

                <Card className={classes.card}>
                  <CardContent className={`${classes.cardBody2} ${classes.p2}`}>
                    <Grid className={`${classes.row} ${classes.mb3}`}>
                      <Grid className={classes.racColMD12}>
                        <RACTable
                          className={`${classes.table} ${classes.tableBorder}`}
                          renderTableHead={renderCashTableHeadCurrentTill}
                          renderTableContent={renderCashTableContentCurrentTill}
                        />
                        <Grid
                          style={{
                            width: '95%',
                            marginTop: '2%',
                            border: 'none',
                            borderRadius: '4px',
                            marginBottom: '-11px',
                          }}
                          className={`${classes.float} ${classes.tableRow1}`}
                        >
                          <Grid
                            style={{ width: '60%' }}
                            className={classes.floatLeft}
                          >
                            <Typography
                              style={{
                                color: '#2179FE',
                                padding: '3px',
                                paddingLeft: '8%',
                                width: '100%',
                                border: 'none',
                                borderRadius: '6px',
                                textAlign: 'left',
                              }}
                            >
                              Total Cash Left in Drawer
                            </Typography>
                          </Grid>
                          <Grid
                            style={{ width: '40%' }}
                            className={classes.floatLeft}
                          >
                            <Typography
                              style={{
                                width: '100%',
                                color: '#2179FE',
                                padding: '1px',
                                textAlign: 'right',
                                paddingRight: '10px',
                              }}
                            >
                              {/* $ {currentTill} */}${' '}
                              {((
                                parseFloat(currentTill)
                                -
                                parseFloat(cashBeingDropAmount)
                              ).toFixed(2))}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid className={classes.floatRight}>
                      <RACButton
                        size="small"
                        disabled={disableFieldsCurrentTill || disableAmtBtn}
                        variant="contained"
                        color="primary"
                        isRounded={false}
                        onClick={() => {
                          handleCurrentTillAcceptBtn();
                        }}
                      >
                        Accept
                      </RACButton>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              {/* <CashBeingDroppedFD></CashBeingDroppedFD> */}
              <Grid
                // className={classes.mt3}
                item
                style={{ float: 'left', width: '48%', marginRight: '2%' }}
              >
                <Typography variant="h4" className={classes.header}>
                  Cash Being Dropped
                </Typography>

                <Card className={classes.card}>
                  <CardContent className={`${classes.cardBody2} ${classes.p2}`}>
                    <Grid className={`${classes.row} ${classes.mb3}`}>
                      <Grid className={classes.racColMD12}>
                        <RACTable
                          className={`${classes.table} ${classes.tableBorder}`}
                          renderTableHead={renderCashTableHeadCashBeingDropped}
                          renderTableContent={
                            renderCashTableContentCashBeingDropped
                          }
                        />
                        <Grid
                          style={{
                            width: '95%',
                            marginTop: '2%',
                            border: 'none',
                            borderRadius: '4px',
                            marginBottom: '-10px',
                          }}
                          className={`${classes.float} ${classes.tableRow1}`}
                        >
                          <Grid
                            style={{ width: '60%' }}
                            className={classes.floatLeft}
                          >
                            <Typography
                              style={{
                                color: '#2179FE',
                                padding: '3px',
                                paddingLeft: '14px',
                                width: '100%',
                                border: 'none',
                                borderRadius: '6px',
                              }}
                            >
                              Total Cash Being Dropped
                            </Typography>
                          </Grid>
                          <Grid
                            style={{ width: '40%', paddingLeft: '0%' }}
                            className={classes.floatLeft}
                          >
                            <Typography
                              style={{
                                width: '100%',
                                color: '#2179FE',
                                textAlign: 'right',
                                padding: '1px',
                                paddingRight: '10px',
                              }}
                            >
                              $ {formatMoney(parseFloat(dropAmount.cash).toFixed(2))}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid className={classes.floatRight}>
                      <RACButton
                        disabled={disableFieldsCashBeingDropped}
                        size="small"
                        variant="contained"
                        color="primary"
                        isRounded={false}
                        onClick={() => {
                          handleCashBeingdropValidateBtn();
                        }}
                      >
                        Validate
                      </RACButton>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid style={{ width: '49%', float: 'right' }}>
            {/* Drawer Total */}
            <Grid style={{ width: '100%' }}>
              <Typography variant="h4" style={{ marginBottom: '15px' }}>
                Drawer Total
              </Typography>
              <Card className={classes.card} style={{ padding: '0.5%' }}>
                <CardContent className={classes.cardBody}>
                  <Grid
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      marginTop: '0',
                      //    marginRight: "7px",
                    }}
                  >
                    <Grid className={classes.colMd2}>
                      <Typography
                        variant="h5"
                        className={classes.formLabelNonCash}
                      >
                        Non-Cash
                      </Typography>

                      <Typography className={classes.formValue}>
                        {/* $ {drawerTotalGrid.noncash.toFixed(2)} */}
                        {formatMoney(getNonCashDetails())}
                      </Typography>
                    </Grid>
                    <Grid
                      style={{
                        flex: '0 0 auto',
                        width: '22.6666%',
                      }}
                    >
                      <Typography
                        variant="h5"
                        className={classes.formLabelCash}
                      >
                        Cash
                      </Typography>

                      <Typography className={classes.formValue}>
                        {/* ${drawerTotalGrid.cash.toFixed(2)} */}
                        {formatMoney(getCashDetails())}
                      </Typography>
                      <Typography className={classes.formLabelDefaultStarting}>
                        (Including default starting)
                      </Typography>
                    </Grid>
                    <Grid
                      style={{
                        flex: '0 0 auto',
                        width: '20.6666%',
                      }}
                    >
                      <Typography
                        variant="h5"
                        className={classes.formLabelTotalCash}
                      >
                        Total
                      </Typography>
                      <Typography className={classes.formValue}>
                        {/*returns the Total amount including nonCash and cash and the default amount.*/}
                        {formatMoney((drawerTotalAmt!=='' && drawerTotalAmt!== undefined ? ( parseFloat(drawerTotalAmt) >=0 ? '$'+` `+parseFloat(drawerTotalAmt).toFixed(2) :'($'+` `+(parseFloat(drawerTotalAmt) * -1).toFixed(2) +')') :"0.00"))}
                      </Typography>
                    </Grid>
                    <Grid
                      style={{
                        flex: '0 0 auto',
                        width: '31%',
                      }}
                    >
                      <Grid className={classes.row}>
                        <Grid className={classes.formLabelDefaultCash}>
                          <Typography
                            variant="h5"

                          // style={{  marginBottom: "0.2rem",
                          //              color: "#111111",
                          //             // fontFamily: "OpenSans-semibold",
                          //             fontSize: "14px",
                          //             float: "left",
                          //             width: "66%",}}
                          >
                            Default Starting Till
                          </Typography>
                        </Grid>
                        <Grid>
                          <Typography className={classes.formValue}>
                            {/*returns only the default amount from the object drawerTotalGrid.*/}
                            {/* ${drawerTotalGrid.defaultStartingValue.toFixed(2)} */}
                            {/* $ {drawerTotalGrid.defaultStartingValue.toFixed(2)} */}
                            $ {(parseFloat(defaultStringAmount).toFixed(2))}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            {/* NonCash */}
            <Grid style={{ width: '100%' }}>
              {/* <NonCashBeingDroped></NonCashBeingDroped> */}
              <Typography
                variant="h4"
                className={classes.header}
                style={{ marginTop: '32px', marginBottom: '20px' }}
              >
                Non-Cash Being Dropped
              </Typography>
              <Card
                style={{ paddingBottom: '3%',overflow: 'auto',overflowX:"hidden", height: '250px', width: '100%' }}
                className={`${classes.card}`}
              >
                <CardContent className={`${classes.cardBody} ${classes.p2} `}>
                  {drawerDetails !== undefined &&
                    drawerDetails?.NonCashDetails !== undefined &&
                    drawerDetails?.NonCashDetails !== null &&
                    drawerDetails?.NonCashDetails.length > 0  ?
                    <RACTable
                      className={`${classes.table} ${classes.tableBorder} ${classes.mb0}`}
                      renderTableHead={renderNonCashTableHead}
                      renderTableContent={renderNonCashTableContent}
                      stickyHeader
                    /> :
                    <>
                      <Grid className={classes.row}>
                        <Grid className={classes.Customer}>
                          <Typography variant="h5" >
                            Customer
                          </Typography>
                        </Grid>
                        <Grid className={classes.DocType}>
                          <Typography variant="h5">
                            Doc Type
                          </Typography>
                        </Grid>
                        <Grid className={classes.Doc} >
                          <Typography variant="h5">
                            Doc #
                          </Typography>
                        </Grid>
                        <Grid className={classes.NameonDoc}>
                          <Typography variant="h5">
                            Name on Doc
                          </Typography>
                        </Grid>
                        <Grid  className={classes.DocType}>
                          <Typography variant="h5">
                            Receipt #
                          </Typography>
                        </Grid>
                        <Grid  className={classes.DocType}>
                          <Typography variant="h5">
                            Amount
                          </Typography>
                        </Grid>
                        <Grid className={classes.Verified}>
                          <Typography variant="h5">
                            Verified
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid className={classes.NoRecordFound} >
                        <Typography variant="h6">
                          No Record Found
                        </Typography>
                      </Grid>
                    </>
                  }
                  {drawerDetails !== undefined &&
                    drawerDetails?.NonCashDetails !== undefined &&
                    drawerDetails?.NonCashDetails !== null ? (
                    drawerDetails?.NonCashDetails.length > 0 ? (
                      <Grid
                        style={{
                          border: 'none',
                          borderRadius: '6px',
                          width: '60%',
                          marginBottom: '18px',
                          marginTop: '10px',
                          padding: '7px',
                          background: '#EFF4FF',
                          marginRight: '55px',
                        }}
                        className={classes.float}
                      >
                        <Grid
                          style={{ width: '60%' }}
                          className={classes.floatLeft}
                        >
                          <Typography className={classes.drawerTotal}>
                            Total Non-Cash Being Dropped
                          </Typography>
                        </Grid>
                        <Grid
                          style={{ width: '40%' }}
                          className={classes.floatLeft}
                        >
                          <Typography
                            style={{
                              color: '#2179FE',
                              // padding: "2px",
                              // paddingRight: "25%",
                              paddingRight: '16%',
                              textAlign: 'right',
                            }}
                          >
                            $ {formatMoney(Number(dropAmount.nonCash).toFixed(2))}
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : null
                  ) : (
                    ''
                  )}
                </CardContent>
              </Card>
            </Grid>
            {/* Expected Drop Totals */}
            <Grid style={{ width: '100%' }}>
              <Typography
                variant="h4"
                style={{ marginTop: '30px', marginBottom: '20px' }}
              >
                Expected Drop Totals
              </Typography>
              <Card className={classes.card} style={{ padding: '0.5%' }}>
                <CardContent className={classes.cardBody}>
                  <Grid className={classes.row}>
                    <Grid className={classes.colMd2}>
                      <Typography
                        variant="h5"
                        className={classes.formLabelNonCash}
                      >
                        Non-Cash
                      </Typography>
                      <Typography className={classes.formValue}>
                        {formatMoney(getNonCashDetails())}
                      </Typography>
                    </Grid>
                    <Grid className={classes.colMd2}>
                      <Typography
                        variant="h5"
                        // className={classes.formLabelCash}
                        style={{
                          paddingRight: '10px',
                          // textAlign:"right",
                          marginBottom: '0.2rem',
                          color: '#111111',
                          // fontFamily: "OpenSans-semibold",
                          fontSize: '14px',
                          float: 'left',
                          width: '30%',
                        }}
                      >
                        Cash
                      </Typography>
                      <Typography className={classes.formValue}>
                        {/* ${drawerTotalGrid.cash.toFixed(2)} */}
                        {formatMoney(getExpectedCashDetails())}
                      </Typography>
                      <Typography className={classes.formLabelDefaultStarting}>
                        (Default Starting Excluded)
                      </Typography>
                    </Grid>
                    <Grid
                      style={{
                        flex: '0 0 auto',
                        width: '21.6666%',
                      }}
                    >
                      <Typography
                        variant="h5"
                        className={classes.formLabelTotalCash}
                      >
                        Total
                      </Typography>
                      <Typography className={classes.formValue}>
                        {/*returns the Total amount including nonCash and cash and the default amount.*/}
                        {formatMoney((expectedTotalAmt!==undefined && expectedTotalAmt!==''? ( parseFloat(expectedTotalAmt)>=0 ? '$'+` `+parseFloat(expectedTotalAmt).toFixed(2) : '($'+` `+ (parseFloat(expectedTotalAmt)* -1).toFixed(2)+')'):'0.00' ))}
                      </Typography>
                    </Grid>
                    <Grid
                      style={{
                        flex: '0 0 auto',
                        width: '27.6666%',
                      }}
                    >
                      <Typography
                        variant="h5"
                        // className={classes.formLabelDefaultCash}
                        style={{
                          // paddingRight:"11px"
                          // ,
                          marginBottom: '0.2rem',
                          color: '#111111',
                          // fontFamily: "OpenSans-semibold",
                          fontSize: '14px',
                          float: 'left',
                          width: '71%',
                          // textAlign:"right"
                        }}
                      >
                        Credit Card Total
                      </Typography>
                      <Typography
                        // style={{paddingLeft:"5%"}}
                        className={classes.formValue}
                      >
                        {/*returns only the default amount from the object drawerTotalGrid.*/}
                        {/* ${drawerTotalGrid.defaultStartingValue.toFixed(2)} */}
                        ${' '}
                        {formatMoney(parseFloat(
                          drawerDetails?.storeTransaction?.creditCardTotal !==
                            undefined &&
                            drawerDetails?.storeTransaction !== undefined &&
                            drawerDetails?.storeTransaction !== null &&
                            drawerDetails?.storeTransaction !== '' &&
                            drawerDetails !== undefined &&
                            drawerDetails !== ''
                            ? drawerDetails?.storeTransaction.creditCardTotal
                            : '0'
                        ).toFixed(2))}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            {/* Actual Drop Totals */}
            <Grid style={{ width: '100%' }}>
              <Typography
                variant="h4"
                style={{ marginTop: '30px', marginBottom: '20px' }}
              >
                Actual Drop Totals
              </Typography>
              <Card className={classes.card} style={{ padding: '0.5%' }}>
                <CardContent className={classes.cardBody}>
                  <Grid className={classes.row}>
                    <Grid style={{ flex: '0 0 auto', width: '35%' }}>
                      <Grid className={classes.row}>
                        <Grid style={{ width: '49%' }}>
                          <Typography
                            variant="h5"
                            style={{
                              paddingTop: '6.5px',
                              color: '#111111',
                              //  fontFamily: "OpenSans-semibold",
                              fontSize: '14px',
                              float: 'left',
                              width: '100%',
                            }}
                          >
                            Drop Envelope #
                            <span style={{ color: 'red' }}>*</span>
                          </Typography>
                        </Grid>
                        <Grid style={{ width: '50%' }}>
                          <RACTextbox
                            className={`${classes.textWidth} ${classes.formValue}`}
                            //  style={{width:"20px"}}

                            type="number"
                            name="Envelope"
                            id="Envelope"
                            maxlength={8}
                            value={envNumber.toString()}
                            required={false}
                            inputlabel=""
                            isCurrency={false}
                            OnChange={handleEnvelopInp}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      className={classes.row}
                      style={{ flex: '0 0 auto', width: '24%' }}
                    >
                      <Grid style={{ width: '45%' }}>
                        <Typography
                          variant="h5"
                          style={{
                            marginBottom: '0.2rem',
                            paddingTop: '6.5px',
                            color: '#111111',
                            //   fontFamily: "OpenSans-semibold",
                            fontSize: '14px',
                            float: 'left',
                            width: '100%',
                          }}
                        >
                          Non-Cash
                        </Typography>
                      </Grid>
                      <Grid
                        style={{
                          width: '50%',
                          // textAlign:"right"
                        }}
                      >
                        <Typography
                          className={classes.formValue}
                          style={{ width: '100%', paddingTop: '6.5px' }}
                        >
                          $ {formatMoney(Number(dropAmount.nonCash).toFixed(2))}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid style={{ flex: '0 0 auto', width: '23%' }}>
                      <Typography
                        variant="h5"
                        style={{ paddingTop: '6.5px' }}
                        className={classes.formLabelCash}
                      >
                        Cash
                      </Typography>
                      <Typography
                        style={{ width: '100%', paddingTop: '6.5px' }}
                        className={classes.formValue}
                      >
                        {/* $ {cashBeingDrop.toFixed(2)}
                         */}
                        $ {formatMoney(parseFloat(dropAmount.cash).toFixed(2))}
                      </Typography>
                      <Typography
                        variant="h5"
                        className={classes.formLabelDefaultStarting}
                      >
                        (Default Starting Excluded)
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.row}
                      style={{ flex: '0 0 auto', width: '20%' }}
                    >
                      <Typography
                        variant="h5"
                        style={{
                          marginBottom: '0.2rem',
                          paddingTop: '6.5px',
                          color: '#111111',
                          //  fontFamily: "OpenSans-semibold",
                          fontSize: '14px',
                          width: '37%',
                          //   textAlign:"right",
                          //   paddingRight:"17px"
                        }}
                      >
                        Total
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: 'OpenSans-semibold',
                          fontSize: '14px',
                          paddingTop: '6.5px',
                          color: '#4A5174',
                          width: '63%',
                        }}
                      >
                        {/*returns the Total amount including nonCash and cash and the default amount.*/}
                        ${' '}
                        {formatMoney((
                          parseFloat(dropAmount.cash) +
                          parseFloat(dropAmount.nonCash)
                        ).toFixed(2))}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            {/* Total Non-Cash Being Dropped */}
            <Grid style={{ width: '100%' }}>
              <Grid style={{ width: '40%', float: 'left' }}>
                <Typography
                  variant="h4"
                  style={{ marginTop: '30px', marginBottom: '20px' }}
                >
                  Total Non-Cash Being Dropped
                </Typography>
                <Card
                  className={classes.card}
                  style={{ padding: '8px' }}
                //  className={classes.card}
                >
                  <CardContent
                    style={{
                      flex: '1 1 auto',
                      padding: '20px 20px',
                      paddingBottom: '10px !important',
                    }}
                  >
                    {/* className={classes.cardBody} */}
                    <Grid className={classes.row}>
                      {/* <Grid> */}
                      <Grid style={{ width: '50%', marginRight: '15px' }}>
                        <RACTextbox
                          className={`${classes.textWidthamount} ${classes.currency}`}
                          dollarTextClassName={classes.dollarClass}
                          disabled={true}
                          type="text"
                          name="envelope"
                          id="envelope"
                          value={formatMoney(dropAmount.nonCash)}
                          // maxlength={30}
                          required={false}
                          inputlabel=""
                          // OnChange={(e)=>{handleText(e)}}
                          isCurrency={true}
                        />
                      </Grid>
                      <Grid>
                        <RACButton
                          className={classes.validatebtn}
                          style={{ padding: '-1px' }}
                          size="small"
                          variant="contained"
                          color="primary"
                          isRounded={false}
                          disabled={(disableNonCashValidateBtn() || validateYes)|| nonCashEnable}
                          onClick={() => handleValidateNonCash()}
                        >
                          Validate
                        </RACButton>
                      </Grid>
                    </Grid>
                    {/* </Grid> */}
                  </CardContent>
                </Card>
              </Grid>
              <Grid style={{ width: '55%', float: 'left', marginLeft: '5%' }}>
                <Grid
                  className={classes.mt3}
                  item
                  style={{
                    float: 'right',
                    width: '100%',
                    //  marginLeft: "1.5%",
                    paddingBottom: '20px',
                  }}
                >
                  <Typography
                    variant="h4"
                    className={classes.header}
                    style={{ marginTop: '14px' }}
                  >
                    Drop Notes
                  </Typography>
                  <textarea
                    style={{
                      marginTop: '5px',
                      width: '100%',
                      padding: '6px',
                      paddingBottom: '31px',
                      resize: 'none',
                      border: '1px solid #C4C4C4',
                      paddingTop: '5px',
                      paddingRight: '5px',
                      // marginRight:"4px",
                      // paddingLeft:"20px",
                      borderRadius: '0.6rem',
                      backgroundColor: '#fff',
                      transition:
                        'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                      fontSize: '14px',
                      fontFamily: 'OpenSans-semibold',
                    }}
                    onChange={(e) => handleDropNotesInp(e)}
                    rows={3}
                    cols={54}
                    value={dropNotes}
                    className={classes.dropNotes}
                  ></textarea>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* Footer */}
      <Grid container classes={{
        root: clsx(classes.customerToolbar, classes.fixedBottom),
      }}  style={{ zIndex: 0 }} >
        <Grid className={classes.footerFixed}>
          <Grid style={{ float: 'left' }}>
            <RACButton
              variant="outlined"
              color="secondary"
              onClick={() => handleCancelBtn()}
            >
              Cancel
            </RACButton>
          </Grid>

          <Grid style={{ float: 'right' }}>
            <RACButton
              // disabled={saveDisable.dropEnvpNum == true  && saveDisable.totalCash == true && saveDisable.totalNonCash == true ? false : true}
              // disabled={envNumber!=''&& dropAmount.nonCash!="0" ?false : true} dropAmount.nonCash !== '0' &&
              // (drawerDetails?.NonCashDetails.length == 0 || (dropAmount.nonCash !== '0' && enableSave) )
              disabled={
                ( (envNumber !== '' && saveEnableDefaultStartingTill &&
                  ((parseFloat(dropAmount.cash) !== 0 || saveEnable) &&
                    ((drawerDetails?.NonCashDetails.length == 0 && nonCashEnable) ||
                      (dropAmount.nonCash !== '0' || enableSave))) || (saveBtnEnable && envNumber !== '')) && nonCashEnableSave)
                  ? false
                  : true
              }
              variant="contained"
              color="primary"
              onClick={() => handleSaveBtn()}
            >
              Save
            </RACButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid>
        <RACModalCard
          isOpen={successPopup}
          maxWidth="xs"
          closeIcon={true}
          borderRadius="20px"
          onClose={() => {
            history.push({
              pathname: `/dashboard/homepage`,
            });
          }}
          // eslint-disable-next-line react/no-children-prop
          children={UpdateInfoMsgPopup()}
        />
      </Grid>
      <Modal
        isOpen={loader}
        className={classes.loaderStyle}
        id="openpopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid>
          <Grid>
            <Grid className={classes.RACLoaderPage}>
              <CircularProgress />
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
}