/* eslint-disable  react-hooks/exhaustive-deps */
/* eslint-disable  sonarjs/cognitive-complexity */
/* eslint-disable  no-console */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  sonarjs/no-duplicate-string */
/* eslint-disable   no-debugger */
/* eslint-disable prettier/prettier */

import React, { useEffect, useState, useContext } from 'react';
import Modal from 'react-modal';
import {
    Grid,
    Card,
    CardContent,
    Typography,
    RACTable,
    RACTableCell,
    RACTableRow,
    RACButton,
    List,
    ListItem,
    RACTextbox,
    RACCheckBox,
    RACModalCard,
    CircularProgress,
} from '@rentacenter/racstrap';
import { customerStyles } from '../DropReconciliation/customerStyles';
// import { CashDropSummary } from "./cashDropSummary";
// import { dropSummaryTotal } from "../constants/sampleConstants";
// import { storeTransactionTotals } from "../constants/sampleConstants";

// import { TotalsSummary } from "./TotalSummary";
// import { DropReconcilationTotalsSummary } from "./DropTotalSummary";
// import { NonCashDropSummary } from "./Non-CashDropSummary";
import { getDrawerDetail } from '../../api/user';
import { useHistory } from 'react-router-dom';
import { CashmgmtContext } from '../../context/CashManagementContext';
import { manageReconciliation } from '../../api/user';
import moment from 'moment';
import clsx from 'clsx';
import { de } from 'date-fns/locale';
export interface denominationQuantityInterface {
    denominationQuantity100: string;
    denominationQuantity50: string;
    denominationQuantity20: string;
    denominationQuantity10: string;
    denominationQuantity5: string;
    denominationQuantity2: string;
    denominationQuantity1: string;
    denominationQuantity001: string;
    denominationQuantity005: string;
    denominationQuantity010: string;
    denominationQuantity025: string;
    denominationQuantity050: string;
}
export interface dropSummary {
    activityId: number;
    activityDate: string;
    totalNonCash: string;
    dropedBy: string;
    verfiedBy: string;
    totalCash: string;
    overOrShortCash: string;
    overOrShortNonCash: string;
}

export function DropReconciliationDetails(props: any) {
    const classes = customerStyles();
    const [dropAmount, setDropAmount] = useState({ cash: '0', nonCash: '0' });
    const [showReconciliationSummary, setShowReconciliationSummary] = useState(false);
    const [overshortDropNotes, setOvershortDropNotes] = useState({
        overShort: '',
        dropNotes: '',
    });
    const [loader, setloader] = useState(false);
    const [over_Short, setOver_Short] = useState({ cash: 0, nonCash: 0 });
    const [dropReconcilationDetails, setDropReconcilationDetails] = useState<any>();
    const [mandatoryNotes, setmandatoryNotes] = useState(false)
    const [currentNonCahDetails, setCurrentNonCahDetails] = useState<any>([])
    const [confirmationCancel, setConfirmationCancel] = useState(false);
    const {
        dropReconciliationData,
        setDropReconciliationData,isReconciled,setIsRecociled
    } = useContext(CashmgmtContext);
    const [denominationActualQuantity, setDenominationActualQuantity] =
        useState<denominationQuantityInterface>({
            denominationQuantity100: '0',
            denominationQuantity50: '0',
            denominationQuantity20: '0',
            denominationQuantity10: '0',
            denominationQuantity5: '0',
            denominationQuantity2: '0',
            denominationQuantity1: '0',
            denominationQuantity001: '0',
            denominationQuantity005: '0',
            denominationQuantity010: '0',
            denominationQuantity025: '0',
            denominationQuantity050: '0',
        });
    const [denominationExpectedQuantity, setDenominationExpectedQuantity] =
        useState<denominationQuantityInterface>({
            denominationQuantity100: '0',
            denominationQuantity50: '0',
            denominationQuantity20: '0',
            denominationQuantity10: '0',
            denominationQuantity5: '0',
            denominationQuantity2: '0',
            denominationQuantity1: '0',
            denominationQuantity001: '0',
            denominationQuantity005: '0',
            denominationQuantity010: '0',
            denominationQuantity025: '0',
            denominationQuantity050: '0',
        });
    const [summaryTotal, setsummaryTotal] = useState<any>({ dropSummaryTotal: { cash: 0, nonCash: 0 }, storeTransactionTotal: { cash: 0, nonCash: 0 } })
    const history = useHistory();
    const [reconciliedDenomination,setReconciliedDenomination] = useState<any>(null);
    const historyData: any = history.location.state
    const [dropDetail, setdropDetail] = useState<any>([])
console.log(historyData,"historyData");


    useEffect(() => {
        setShowReconciliationSummary(true)
        pageLoad();
        console.log("denominationExpectedQuantity", denominationExpectedQuantity)
    }, []);

    const pageLoad = async () => {
        try {
            let dropSummary = []
            if (historyData?.mode == 'VIEW' && historyData?.stage == 'VALIDATED') {
                dropSummary.push(historyData?.data || [])
                setDenominationExpectedQuantity(historyData?.data?.denominationExpectedQuantity)
                setDenominationActualQuantity(historyData?.data?.denominationActualQuantity)
                setOver_Short(historyData?.data?.over_Short)
                setDropAmount(historyData?.data?.dropAmount)
            }
            else if (historyData == 'REDIRECT') {
                dropSummary = dropReconciliationData?.dropSummary || []
            }
            else {
                setloader(true)
                const storeNumber = window.sessionStorage.getItem('storeNumber');
                const res = await getDrawerDetail({ dropId: String(dropReconciliationData.validateDropId), storeNumber: storeNumber })
                console.log(res)
                dropSummary = res.dropSummary
                setdropDetail(res.dropSummary)
                setloader(false)
                setReconciliedDenomination(res.dropSummary[0].reconciliedCashDropDetails)
            }
            bindSummaryData(dropSummary, historyData)
            let nonCashResponse: any = [];
            const dropSummaryDetails: any = dropSummary !== undefined && dropSummary.length > 0 ? dropSummary[0] : {};
            if (historyData?.mode == 'VIEW' && historyData?.stage == 'VALIDATED') {
                nonCashResponse = dropSummaryDetails.nonCashDetails
            } else {
                const nonCashdetailArr = dropSummaryDetails?.nonCashDetails !== undefined && dropSummaryDetails?.nonCashDetails.length > 0 ? dropSummaryDetails.nonCashDetails : [];
                nonCashdetailArr.map((el: any) => {
                    nonCashResponse.push({ ...el, isChecked: false });
                })
            }

            // setCurrentNonCahDetails(JSON.parse(JSON.stringify(nonCashResponse)))
            setCurrentNonCahDetails(nonCashResponse)

            setOvershortDropNotes({ dropNotes: dropSummaryDetails.notes || '', overShort: dropSummaryDetails.overShortNotes || '' })
            setDropReconcilationDetails({
                ...dropReconcilationDetails,
                nonCashDetailsRes: nonCashResponse,
                dropSummary: dropSummary,
                cashDetailsRes: dropSummary[0].cashDetailsRes,
            });
            const obj = { ...denominationExpectedQuantity };
            dropSummary && dropSummary[0]?.cashDetailsRes?.forEach((el: any) => {
                console.log("denominationExpectedQuantity", denominationExpectedQuantity)
                if (el.denomination == 100) {
                    obj.denominationQuantity100 = el.quantity;
                }
                if (el.denomination == 50) {
                    obj.denominationQuantity50 = el.quantity;
                }
                if (el.denomination == 20) {
                    obj.denominationQuantity20 = el.quantity;
                }
                if (el.denomination == 10) {
                    obj.denominationQuantity10 = el.quantity;
                }
                if (el.denomination == 5) {
                    obj.denominationQuantity5 = el.quantity;
                }
                if (el.denomination == 2) {
                    obj.denominationQuantity2 = el.quantity;
                }
                if (el.denomination == 1) {
                    obj.denominationQuantity1 = el.quantity;
                }
                if (el.denomination == 0.01) {
                    obj.denominationQuantity001 = el.quantity;
                }
                if (el.denomination == 0.05) {
                    obj.denominationQuantity005 = el.quantity;
                }
                if (el.denomination == 0.1) {
                    obj.denominationQuantity010 = el.quantity;
                }
                if (el.denomination == 0.25) {
                    obj.denominationQuantity025 = el.quantity;
                }
                if (el.denomination == 0.5) {
                    obj.denominationQuantity050 = el.quantity;
                }
            });
            setDenominationExpectedQuantity(obj);
        }
        catch (e) {
            setloader(false)
            console.log('Error........', e);
        }
    };
    const bindSummaryData = (dropSummary: any, bindSummaryData?: any) => {
        const obj = { dropSummaryTotal: { cash: 0, nonCash: 0 }, storeTransactionTotal: { cash: 0, nonCash: 0, overShortCash: 0, overShortNonCash: 0 } }
        if (bindSummaryData && bindSummaryData.mode == 'VIEW') {
            const dropItem = dropReconciliationData?.dropSummaryDetails.filter((el: any) => el.drawerManagementId == dropSummary[0].drawerManagementId)
            // eslint-disable-next-line sonarjs/no-collapsible-if
            if (dropItem && dropItem.length > 0) {
                // setOver_Short({
                //     cash: Number(dropItem[0].reconcilledOverOrShortCash),
                //     nonCash: Number(dropItem[0].reconcilledOverOrShortNonCash),
                // })
                if (dropItem[0].reconcilledTotalCash && Number(dropItem[0].reconcilledTotalCash) >= 0) {
                    setDropAmount({
                        cash: (dropItem[0].reconcilledTotalCash),
                        nonCash: (dropItem[0].reconcilledTotalNonCash)
                    })
                }
                // else{
                //     setDropAmount({
                //         cash: (dropItem[0].totalCash),
                //         nonCash: (dropItem[0].totalNonCash)
                //     })
                // }

                // if (dropItem[0].reconcilledTotalCash) {
                //     obj.dropSummaryTotal.cash = Number(dropItem[0].reconcilledTotalCash)
                //     obj.storeTransactionTotal.cash = Number(dropItem[0].reconcilledTotalCash)
                // }
                // if (dropItem[0].reconcilledTotalNonCash) {
                //     obj.dropSummaryTotal.nonCash = Number(dropItem[0].reconcilledTotalNonCash)
                //     obj.storeTransactionTotal.nonCash = Number(dropItem[0].reconcilledTotalNonCash)
                // }
                // if (dropItem[0].reconcilledOverOrShortCash) {
                //     obj.storeTransactionTotal.overShortCash = parseFloat(dropItem[0].reconcilledOverOrShortCash)
                //     obj.storeTransactionTotal.cash += Number(dropItem[0].reconcilledOverOrShortCash) < 0 ? Math.abs(dropItem[0].reconcilledOverOrShortCash) : dropItem[0].reconcilledOverOrShortCash
                // }
                // if (dropItem[0].reconcilledOverOrShortNonCash) {
                //     obj.storeTransactionTotal.overShortNonCash = parseFloat(dropItem[0].reconcilledOverOrShortNonCash)
                //     obj.storeTransactionTotal.nonCash += Number(dropItem[0].reconcilledOverOrShortNonCash) < 0 ? Math.abs(dropItem[0].reconcilledOverOrShortNonCash) : dropItem[0].reconcilledOverOrShortNonCash

                // }
            }
            //Need to check whather to add particular drop of overshort amount or not on below state
            // setOver_Short({ cash: dropItem[0].reconcilledTotalCash, nonCash: dropItem[0].reconcilledTotalNonCash })
            // return
        }
        if (dropSummary[0].totalCash) {
            obj.dropSummaryTotal.cash = Number(dropSummary[0].totalCash)
            obj.storeTransactionTotal.cash = Number(dropSummary[0].totalCash)
        }
        if (dropSummary[0].totalNonCash) {
            obj.dropSummaryTotal.nonCash = Number(dropSummary[0].totalNonCash)
            obj.storeTransactionTotal.nonCash = Number(dropSummary[0].totalNonCash)
        }
        if (dropSummary[0].overOrShortCash) {
            obj.storeTransactionTotal.overShortCash = parseFloat(dropSummary[0].overOrShortCash)
            obj.storeTransactionTotal.cash -= Number(dropSummary[0].overOrShortCash)
        }
        if (dropSummary[0].overOrShortNonCash) {
            obj.storeTransactionTotal.overShortNonCash = parseFloat(dropSummary[0].overOrShortNonCash)
            obj.storeTransactionTotal.nonCash -= Number(dropSummary[0].overOrShortNonCash)

        }
        setsummaryTotal(obj)
        //Need to check whather to add particular drop of overshort amount or not on below state
        setOver_Short({ cash: dropSummary[0].totalCash, nonCash: dropSummary[0].totalNonCash })
    }
    const handleOverShortNotes = (e: any) => {
        setOvershortDropNotes({ ...overshortDropNotes, overShort: e.target.value });
    };
    const handleDropNotes = (e: any) => {
        setOvershortDropNotes({ ...overshortDropNotes, dropNotes: e.target.value });
    };
    const handleCancelBtn = (e: any) => {
        setConfirmationCancel(true);
    };
    function handle_CancelNoBtn() {
        setConfirmationCancel(false);
    }
    function handle_CancelYesBtn() {
        setConfirmationCancel(false);
    }
    const calculateDenominationPayload = () => {
        const arr: any = []
        const denominationMap : any = {
            '100': '0',
            '50': '0',
            '20': '0',
            '10': '0',
            '5': '0',
            '2': '0',
            '1': '0',
            '0.01': '0',
            '0.05': '0',
            '0.10': '0',
            '0.25': '0',
            '0.50': '0'
          };
        for (const key in denominationActualQuantity) {
            if (key == 'denominationQuantity100' && denominationActualQuantity[key] && denominationActualQuantity[key] != '0' ) {
                arr.push({ denomination: '100', quantity: denominationActualQuantity[key] })
            }
            if (key == 'denominationQuantity50' && denominationActualQuantity[key] && denominationActualQuantity[key] != '0' ) {
                arr.push({ denomination: '50', quantity: denominationActualQuantity[key] })
            }
            if (key == 'denominationQuantity20' && denominationActualQuantity[key] && denominationActualQuantity[key] != '0') {
                arr.push({ denomination: '20', quantity: denominationActualQuantity[key] })
            }
            if (key == 'denominationQuantity10' && denominationActualQuantity[key] && denominationActualQuantity[key] != '0') {
                arr.push({ denomination: '10', quantity: denominationActualQuantity[key] })
            }
            if (key == 'denominationQuantity5' && denominationActualQuantity[key] && denominationActualQuantity[key] != '0') {
                arr.push({ denomination: '5', quantity: denominationActualQuantity[key] })
            }
            if (key == 'denominationQuantity2' && denominationActualQuantity[key] && denominationActualQuantity[key] != '0') {
                arr.push({ denomination: '2', quantity: denominationActualQuantity[key] })
            }
            if (key == 'denominationQuantity1' && denominationActualQuantity[key] && denominationActualQuantity[key] != '0') {
                arr.push({ denomination: '1', quantity: denominationActualQuantity[key] })
            }
            if (key == 'denominationQuantity001' && denominationActualQuantity[key] && denominationActualQuantity[key] != '0') {
                arr.push({ denomination: '0.01', quantity: denominationActualQuantity[key] })
            }
            if (key == 'denominationQuantity005' && denominationActualQuantity[key] && denominationActualQuantity[key] != '0') {
                arr.push({ denomination: '0.05', quantity: denominationActualQuantity[key] })
            }
            if (key == 'denominationQuantity010' && denominationActualQuantity[key] && denominationActualQuantity[key] != '0') {
                arr.push({ denomination: '0.10', quantity: denominationActualQuantity[key] })
            }
            if (key == 'denominationQuantity025' && denominationActualQuantity[key] && denominationActualQuantity[key] != '0') {
                arr.push({ denomination: '0.25', quantity: denominationActualQuantity[key] })
            }
            if (key == 'denominationQuantity050' && denominationActualQuantity[key] && denominationActualQuantity[key] != '0') {
                arr.push({ denomination: '0.50', quantity: denominationActualQuantity[key] })
            }
        }
        if(arr.length == 0 && reconciliedDenomination && reconciliedDenomination.length > 0 ){
            for (const { denomination,quantity } of reconciliedDenomination) {
                if(quantity > 0)
                    arr.push({
                    denomination: String(denomination),
                    quantity: denominationMap[String(denomination)]
                  });
              }
        }
        return arr
    }
    async function handleValidateBtn() {
        debugger
        dropAmount
        if (((Number(summaryTotal.storeTransactionTotal.cash) + Number(summaryTotal.storeTransactionTotal.nonCash)) - (Number(dropAmount.cash) + Number(dropAmount.nonCash)) != 0) && (overshortDropNotes.overShort.trim().length == 0 || overshortDropNotes.dropNotes.trim().length == 0)) {
            setmandatoryNotes(true)
            return
        }

        const currentDropDetail: any = {
            drawerManagementId: String(dropReconciliationData.validateDropId),
            dropNotes: overshortDropNotes.dropNotes,
            overShortDropNotes: overshortDropNotes.overShort,
            overShortCash: Number(dropAmount.cash) == 0 ? -Math.abs(over_Short.cash) : (Math.abs(Number(dropAmount.cash)) - Math.abs(parseFloat(summaryTotal.storeTransactionTotal.cash))),
            overShortNonCash: -Math.abs(over_Short.nonCash),
            dropAmount: dropAmount,
            denominationActualQuantity: denominationActualQuantity,
            denominationExpectedQuantity: denominationExpectedQuantity,
            qauntityDetails: denominationExpectedQuantity,
            nonCashDetails: currentNonCahDetails,
        }
        setShowReconciliationSummary(true);

        const overShort: any = {
            cash: Number(currentDropDetail.overShortCash),
            nonCash: 0,
        }
        dropReconcilationDetails?.nonCashDetailsRes.forEach((el: any) => {
            if (!el.isChecked) {
                overShort.nonCash += el.cashAmount
            }
        })
        // overShort.nonCash = -Math.abs(overShort.nonCash)
        overShort.cash = Number(dropAmount.cash) - Number((summaryTotal.storeTransactionTotal.cash)?.toFixed(2))        
        overShort.nonCash = -Math.abs(Number(summaryTotal.storeTransactionTotal.nonCash) - Number(dropAmount.nonCash))

        currentDropDetail.reconcilledOverOrShortCash = Number(dropAmount.cash) - Number(summaryTotal.storeTransactionTotal.cash)
        currentDropDetail.reconcilledOverOrShortNonCash = -Math.abs(Number(summaryTotal.storeTransactionTotal.nonCash) - Number(dropAmount.nonCash))
        debugger
        const tempObj: any = {};
        tempObj.nonCashDetailsRes = dropReconcilationDetails?.nonCashDetailsRes?.filter((obj: any) => {
            return (obj.isChecked)
        });
        tempObj.validateIndex = dropReconciliationData.validateIndex
        tempObj.tempObjdenominationActualQuantity = denominationActualQuantity;
        tempObj.over_Short = overShort;
        tempObj.dropAmount = dropAmount;
        tempObj.currentDropDetail = currentDropDetail
        const activityDate: any = dropReconciliationData.dropSummaryDetails.filter((el: any) => el.drawerManagementId == dropReconciliationData.validateDropId)
        const payload: any = {
            storeNumber: sessionStorage.getItem('storeNumber'),
            dropDetails: {
                drawerManagementId: String(dropReconciliationData.validateDropId),
                dropNotes: overshortDropNotes.dropNotes,
                overShortDropNotes: overshortDropNotes.overShort,
                droppedCashAmount: String(dropAmount.cash),
                droppedNonCashAmount: String(dropAmount.nonCash),
                overShortCashAmount: String(overShort.cash),
                overShortNonCashAmount: String(overShort.nonCash),
                dropDate: moment(activityDate[0].activityDate).format('MM/DD/YYYY'),
                reconciliedDenomination: calculateDenominationPayload()
            }
        }
        setloader(true);
        const res :any = await manageReconciliation(payload)
        setloader(false);
        setIsRecociled(true);
        setDropReconciliationData({ ...dropReconciliationData, tempObj: tempObj, globalReconcilledId: res && res.drawerManagementId })
        // console.log('res', res)
        if(historyData?.mode =='RE-VALIDATE')
            history.push({ pathname: '/cashmgmt/reconcil/summary', state: 'RE-VALIDATED' })
        history.push({ pathname: '/cashmgmt/reconcil/summary', state: 'VALIDATED' })
        // props.dropReconciliationDetailsCompleted(tempObj);
    }
    function HandleCancelModelCard() {
        return (
            <RACModalCard
                isOpen={confirmationCancel}
                maxWidth="xs"
                closeIcon={true}
                borderRadius="20px"
                onClose={() => setConfirmationCancel(false)}
                // eslint-disable-next-line react/no-children-prop
                children={handleCancelModelCardPopup()}
            />
        );
    }
    const handleCancelModelCardPopup = () => {
        return (
            <Grid item md={12}>
                <Grid item md={12} className={classes.textCenter}>
                    <Typography className={`${classes.RACPOPMsg} ${classes.marginTop}`}>
                        Your changes will not be saved.
                        <Grid>Are you sure you want to exit?</Grid>
                    </Typography>
                </Grid>
                <Grid
                    container
                    className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
                >
                    <RACButton
                        className={`${classes.spacerMR2} ${classes.foc}`}
                        size="small"
                        variant="outlined"
                        color="primary"
                        isRounded={false}
                        onClick={() => handle_CancelNoBtn()}
                    // onClick={() => handleModels('validateNonCashIsOpen',false)}
                    >
                        No
                    </RACButton>
                    <RACButton
                        variant="contained"
                        color="primary"
                        isRounded={false}
                        onClick={() => handle_CancelYesBtn()}
                    >
                        Yes
                    </RACButton>
                </Grid>
            </Grid>
        );
    };
    const handleNonCashDetail = (e: any, recID: any) => {
        console.log("dropReconcilationDetails.nonCashDetailsRes", dropReconcilationDetails.nonCashDetailsRes);
        let nonCashbeingdrop = parseFloat(dropAmount.nonCash);
        if (e.target.checked) {
            for (
                let nonCashDetails = 0;
                nonCashDetails < dropReconcilationDetails.nonCashDetailsRes.length;
                nonCashDetails++
            ) {
                if (
                    dropReconcilationDetails.nonCashDetailsRes[nonCashDetails]
                        .receiptTransactionId == recID
                ) {
                    nonCashbeingdrop += parseFloat(
                        dropReconcilationDetails.nonCashDetailsRes[nonCashDetails].cashAmount
                    );
                    break;
                }
            }
        } else {
            for (
                let nonCashDetails = 0;
                nonCashDetails < dropReconcilationDetails.nonCashDetailsRes.length;
                nonCashDetails++
            ) {
                if (
                    dropReconcilationDetails.nonCashDetailsRes[nonCashDetails]
                        .receiptTransactionId == recID
                ) {
                    nonCashbeingdrop -= parseFloat(
                        dropReconcilationDetails.nonCashDetailsRes[nonCashDetails].cashAmount
                    );
                    break;
                }
            }
        }
        const nonCashArr = dropReconcilationDetails.nonCashDetailsRes.map(
            (el: any) => {
                if (!el.isChecked && e.target.checked && el.receiptTransactionId == recID) {
                    el.isChecked = true;
                } else if (el.isChecked && !e.target.checked && el.receiptTransactionId == recID) {
                    el.isChecked = false;
                }
                return el;
            }
        );
        let nonCashShortAmount: any = 0;
        for (
            let nonCashDetails = 0;
            nonCashDetails < dropReconcilationDetails.nonCashDetailsRes.length;
            nonCashDetails++
        ) {
            if (dropReconcilationDetails !== undefined && dropReconcilationDetails.nonCashDetailsRes !== undefined &&
                dropReconcilationDetails.nonCashDetailsRes !== '' &&
                dropReconcilationDetails.nonCashDetailsRes !== null ?
                (!dropReconcilationDetails.nonCashDetailsRes[nonCashDetails].isChecked) : '') {
                nonCashShortAmount += Number(
                    dropReconcilationDetails.nonCashDetailsRes[nonCashDetails].cashAmount
                );
            }
        }
        setOver_Short({ ...over_Short, nonCash: nonCashShortAmount });
        setDropReconcilationDetails({
            ...dropReconcilationDetails,
            nonCashDetailsRes: nonCashArr,
        });
        setDropAmount({ ...dropAmount, nonCash: nonCashbeingdrop.toFixed(2) });
    };
    const calculateTotalAmount = (denomination: string, value: string) => {
        const cashAmount =
            parseFloat(
                denomination == '0.01'
                    ? value
                    : denominationActualQuantity.denominationQuantity001 !== ''
                        ? denominationActualQuantity.denominationQuantity001
                        : '0'
            ) *
            0.01 +
            parseFloat(
                denomination == '0.05'
                    ? value
                    : denominationActualQuantity.denominationQuantity005 !== ''
                        ? denominationActualQuantity.denominationQuantity005
                        : '0'
            ) *
            0.05 +
            parseFloat(
                denomination == '0.10'
                    ? value
                    : denominationActualQuantity.denominationQuantity010 !== ''
                        ? denominationActualQuantity.denominationQuantity010
                        : '0'
            ) *
            0.1 +
            parseFloat(
                denomination == '0.25'
                    ? value
                    : denominationActualQuantity.denominationQuantity025 !== ''
                        ? denominationActualQuantity.denominationQuantity025
                        : '0'
            ) *
            0.25 +
            parseFloat(
                denomination == '0.50'
                    ? value
                    : denominationActualQuantity.denominationQuantity050 !== ''
                        ? denominationActualQuantity.denominationQuantity050
                        : '0'
            ) *
            0.5 +
            parseFloat(
                denomination == '1'
                    ? value
                    : denominationActualQuantity.denominationQuantity1 !== ''
                        ? denominationActualQuantity.denominationQuantity1
                        : '0'
            ) *
            1 +
            parseFloat(
                denomination == '10'
                    ? value
                    : denominationActualQuantity.denominationQuantity10 !== ''
                        ? denominationActualQuantity.denominationQuantity10
                        : '0'
            ) *
            10 +
            parseFloat(
                denomination == '100'
                    ? value
                    : denominationActualQuantity.denominationQuantity100 !== ''
                        ? denominationActualQuantity.denominationQuantity100
                        : '0'
            ) *
            100 +
            parseFloat(
                denomination == '2'
                    ? value
                    : denominationActualQuantity.denominationQuantity2 !== ''
                        ? denominationActualQuantity.denominationQuantity2
                        : '0'
            ) *
            2 +
            parseFloat(
                denomination == '20'
                    ? value
                    : denominationActualQuantity.denominationQuantity20 !== ''
                        ? denominationActualQuantity.denominationQuantity20
                        : '0'
            ) *
            20 +
            parseFloat(
                denomination == '5'
                    ? value
                    : denominationActualQuantity.denominationQuantity5 !== ''
                        ? denominationActualQuantity.denominationQuantity5
                        : '0'
            ) *
            5 +
            parseFloat(
                denomination == '50'
                    ? value
                    : denominationActualQuantity.denominationQuantity50 !== ''
                        ? denominationActualQuantity.denominationQuantity50
                        : '0'
            ) *
            50;
        //    console.log(cashAmount);

        const totalCash: any =
            cashAmount - (parseFloat(summaryTotal.dropSummaryTotal.cash));
        setOver_Short({ ...over_Short, cash: totalCash });
        console.log('OVER SHORT', totalCash);
        return cashAmount.toFixed(2);
    };
    const CalculateExpectedTotalAmount: any = () => {
        let cashAmount =
            parseFloat(denominationExpectedQuantity.denominationQuantity001) * 0.01 +
            parseFloat(denominationExpectedQuantity.denominationQuantity005) * 0.05 +
            parseFloat(denominationExpectedQuantity.denominationQuantity010) * 0.1 +
            parseFloat(denominationExpectedQuantity.denominationQuantity025) * 0.25 +
            parseFloat(denominationExpectedQuantity.denominationQuantity050) * 0.5 +
            parseFloat(denominationExpectedQuantity.denominationQuantity100) * 100 +
            parseFloat(denominationExpectedQuantity.denominationQuantity50) * 50 +
            parseFloat(denominationExpectedQuantity.denominationQuantity20) * 20 +
            parseFloat(denominationExpectedQuantity.denominationQuantity10) * 10 +
            parseFloat(denominationExpectedQuantity.denominationQuantity5) * 5 +
            parseFloat(denominationExpectedQuantity.denominationQuantity2) * 2 +
            parseFloat(denominationExpectedQuantity.denominationQuantity1) * 1;
        if (cashAmount == 0 && dropDetail.length > 0) {
            console.log(dropDetail)
            cashAmount = Math.abs(Number(dropDetail[0].totalCash))
        }
        return cashAmount.toFixed(2);
    };
    const handleDropReconciliationDetailsBtn = (e: any) => {
        switch (e.currentTarget.id) {
            case '001$':
                setDropAmount({
                    ...dropAmount,
                    cash: calculateTotalAmount(
                        '0.01',
                        (
                            Number(denominationActualQuantity.denominationQuantity001) + 1
                        ).toString()
                    ),
                });
                setDenominationActualQuantity({
                    ...denominationActualQuantity,
                    denominationQuantity001: (
                        Number(denominationActualQuantity.denominationQuantity001) + 1
                    ).toString(),
                });
                break;
            case '005$':
                setDropAmount({
                    ...dropAmount,
                    cash: calculateTotalAmount(
                        '0.05',
                        (
                            Number(denominationActualQuantity.denominationQuantity005) + 1
                        ).toString()
                    ),
                });
                setDenominationActualQuantity({
                    ...denominationActualQuantity,
                    denominationQuantity005: (
                        Number(denominationActualQuantity.denominationQuantity005) + 1
                    ).toString(),
                });
                break;
            case '010$':
                setDropAmount({
                    ...dropAmount,
                    cash: calculateTotalAmount(
                        '0.10',
                        (
                            Number(denominationActualQuantity.denominationQuantity010) + 1
                        ).toString()
                    ),
                });
                setDenominationActualQuantity({
                    ...denominationActualQuantity,
                    denominationQuantity010: (
                        Number(denominationActualQuantity.denominationQuantity010) + 1
                    ).toString(),
                });
                break;
            case '025$':
                setDropAmount({
                    ...dropAmount,
                    cash: calculateTotalAmount(
                        '0.25',
                        (
                            Number(denominationActualQuantity.denominationQuantity025) + 1
                        ).toString()
                    ),
                });
                setDenominationActualQuantity({
                    ...denominationActualQuantity,
                    denominationQuantity025: (
                        Number(denominationActualQuantity.denominationQuantity025) + 1
                    ).toString(),
                });
                break;
            case '050$':
                setDropAmount({
                    ...dropAmount,
                    cash: calculateTotalAmount(
                        '0.50',
                        (
                            Number(denominationActualQuantity.denominationQuantity050) + 1
                        ).toString()
                    ),
                });
                setDenominationActualQuantity({
                    ...denominationActualQuantity,
                    denominationQuantity050: (
                        Number(denominationActualQuantity.denominationQuantity050) + 1
                    ).toString(),
                });
                break;
            case '100$':
                setDropAmount({
                    ...dropAmount,
                    cash: calculateTotalAmount(
                        '100',
                        (
                            Number(denominationActualQuantity.denominationQuantity100) + 1
                        ).toString()
                    ),
                });
                setDenominationActualQuantity({
                    ...denominationActualQuantity,
                    denominationQuantity100: (
                        Number(denominationActualQuantity.denominationQuantity100) + 1
                    ).toString(),
                });
                break;
            case '50$':
                setDropAmount({
                    ...dropAmount,
                    cash: calculateTotalAmount(
                        '50',
                        (
                            Number(denominationActualQuantity.denominationQuantity50) + 1
                        ).toString()
                    ),
                });
                setDenominationActualQuantity({
                    ...denominationActualQuantity,
                    denominationQuantity50: (
                        Number(denominationActualQuantity.denominationQuantity50) + 1
                    ).toString(),
                });
                break;
            case '20$':
                setDropAmount({
                    ...dropAmount,
                    cash: calculateTotalAmount(
                        '20',
                        (
                            Number(denominationActualQuantity.denominationQuantity20) + 1
                        ).toString()
                    ),
                });
                setDenominationActualQuantity({
                    ...denominationActualQuantity,
                    denominationQuantity20: (
                        Number(denominationActualQuantity.denominationQuantity20) + 1
                    ).toString(),
                });
                break;
            case '10$':
                setDropAmount({
                    ...dropAmount,
                    cash: calculateTotalAmount(
                        '10',
                        (
                            Number(denominationActualQuantity.denominationQuantity10) + 1
                        ).toString()
                    ),
                });
                setDenominationActualQuantity({
                    ...denominationActualQuantity,
                    denominationQuantity10: (
                        Number(denominationActualQuantity.denominationQuantity10) + 1
                    ).toString(),
                });
                break;
            case '5$':
                setDropAmount({
                    ...dropAmount,
                    cash: calculateTotalAmount(
                        '5',
                        (
                            Number(denominationActualQuantity.denominationQuantity5) + 1
                        ).toString()
                    ),
                });
                setDenominationActualQuantity({
                    ...denominationActualQuantity,
                    denominationQuantity5: (
                        Number(denominationActualQuantity.denominationQuantity5) + 1
                    ).toString(),
                });
                break;
            case '2$':
                setDropAmount({
                    ...dropAmount,
                    cash: calculateTotalAmount(
                        '2',
                        (
                            Number(denominationActualQuantity.denominationQuantity2) + 1
                        ).toString()
                    ),
                });
                setDenominationActualQuantity({
                    ...denominationActualQuantity,
                    denominationQuantity2: (
                        Number(denominationActualQuantity.denominationQuantity2) + 1
                    ).toString(),
                });
                break;
            case '1$':
                setDropAmount({
                    ...dropAmount,
                    cash: calculateTotalAmount(
                        '1',
                        (
                            Number(denominationActualQuantity.denominationQuantity1) + 1
                        ).toString()
                    ),
                });
                setDenominationActualQuantity({
                    ...denominationActualQuantity,
                    denominationQuantity1: (
                        Number(denominationActualQuantity.denominationQuantity1) + 1
                    ).toString(),
                });
        }
    };
    const handleDropReconciliationDetailsInp = (e: any) => {
        switch (e.target.id) {
            case '$001':
                setDropAmount({
                    ...dropAmount,
                    cash: calculateTotalAmount(
                        '001',
                        e.target.value !== '' ? e.target.value : '0'
                    ),
                });
                setDenominationActualQuantity({
                    ...denominationActualQuantity,
                    denominationQuantity001: e.target.value,
                });
                break;
            case '$005':
                setDropAmount({
                    ...dropAmount,
                    cash: calculateTotalAmount(
                        '005',
                        e.target.value !== '' ? e.target.value : '0'
                    ),
                });
                setDenominationActualQuantity({
                    ...denominationActualQuantity,
                    denominationQuantity005: e.target.value,
                });
                break;
            case '$010':
                setDropAmount({
                    ...dropAmount,
                    cash: calculateTotalAmount(
                        '010',
                        e.target.value !== '' ? e.target.value : '0'
                    ),
                });
                setDenominationActualQuantity({
                    ...denominationActualQuantity,
                    denominationQuantity010: e.target.value,
                });
                break;
            case '$025':
                setDropAmount({
                    ...dropAmount,
                    cash: calculateTotalAmount(
                        '025',
                        e.target.value !== '' ? e.target.value : '0'
                    ),
                });
                setDenominationActualQuantity({
                    ...denominationActualQuantity,
                    denominationQuantity025: e.target.value,
                });
                break;
            case '$050':
                setDropAmount({
                    ...dropAmount,
                    cash: calculateTotalAmount(
                        '050',
                        e.target.value !== '' ? e.target.value : '0'
                    ),
                });
                setDenominationActualQuantity({
                    ...denominationActualQuantity,
                    denominationQuantity050: e.target.value,
                });
                break;
            case '$100':
                setDropAmount({
                    ...dropAmount,
                    cash: calculateTotalAmount(
                        '100',
                        e.target.value !== '' ? e.target.value : '0'
                    ),
                });
                setDenominationActualQuantity({
                    ...denominationActualQuantity,
                    denominationQuantity100: e.target.value,
                });
                break;
            case '$50':
                setDropAmount({
                    ...dropAmount,
                    cash: calculateTotalAmount(
                        '50',
                        e.target.value !== '' ? e.target.value : '0'
                    ),
                });
                setDenominationActualQuantity({
                    ...denominationActualQuantity,
                    denominationQuantity50: e.target.value,
                });
                break;
            case '$20':
                setDropAmount({
                    ...dropAmount,
                    cash: calculateTotalAmount(
                        '20',
                        e.target.value !== '' ? e.target.value : '0'
                    ),
                });
                setDenominationActualQuantity({
                    ...denominationActualQuantity,
                    denominationQuantity20: e.target.value,
                });
                break;
            case '$10':
                setDropAmount({
                    ...dropAmount,
                    cash: calculateTotalAmount(
                        '10',
                        e.target.value !== '' ? e.target.value : '0'
                    ),
                });
                setDenominationActualQuantity({
                    ...denominationActualQuantity,
                    denominationQuantity10: e.target.value,
                });
                break;
            case '$5':
                setDropAmount({
                    ...dropAmount,
                    cash: calculateTotalAmount(
                        '5',
                        e.target.value !== '' ? e.target.value : '0'
                    ),
                });
                setDenominationActualQuantity({
                    ...denominationActualQuantity,
                    denominationQuantity5: e.target.value,
                });
                break;
            case '$2':
                setDropAmount({
                    ...dropAmount,
                    cash: calculateTotalAmount(
                        '2',
                        e.target.value !== '' ? e.target.value : '0'
                    ),
                });
                setDenominationActualQuantity({
                    ...denominationActualQuantity,
                    denominationQuantity2: e.target.value,
                });
                break;
            case '$1':
                setDropAmount({
                    ...dropAmount,
                    cash: calculateTotalAmount(
                        '1',
                        e.target.value !== '' ? e.target.value : '0'
                    ),
                });
                setDenominationActualQuantity({
                    ...denominationActualQuantity,
                    denominationQuantity1: e.target.value,
                });
                break;


        }
    };

    const renderCashTableHead = () => {
        return (
            <React.Fragment>
                <RACTableCell style={{ width: '18%' }}>
                    <Grid>
                        {' '}
                        <Typography variant="h5">Expected</Typography>
                    </Grid>
                    <Grid>
                        <Typography variant="h5"> Quantity </Typography>
                    </Grid>
                </RACTableCell>
                <RACTableCell style={{ width: '15%' }}>
                    <Grid style={{ paddingLeft: '14px' }}>
                        <Typography variant="h5"> Expected</Typography>
                    </Grid>
                    <Grid style={{ paddingLeft: '14px' }}>
                        <Typography variant="h5">Total</Typography>
                    </Grid>
                </RACTableCell>
                <RACTableCell style={{ width: '20%', paddingBottom: '5%' }}>
                    <Typography variant="h5">Denomination</Typography>
                </RACTableCell>
                <RACTableCell style={{ width: '18%' }}>
                    <Grid>
                        <Typography variant="h5">Actual</Typography>{' '}
                    </Grid>
                    <Grid>
                        <Typography variant="h5"> Quantity</Typography>
                    </Grid>
                </RACTableCell>
                <RACTableCell style={{ width: '17%', paddingLeft: '30px' }}>
                    <Grid style={{ paddingLeft: '2px' }}>
                        <Typography variant="h5">Actual </Typography>
                    </Grid>
                    <Grid style={{ paddingLeft: '2px' }}>
                        <Typography variant="h5">Total </Typography>{' '}
                    </Grid>
                </RACTableCell>
            </React.Fragment>
        );
    };
    const renderCashTableContent = () => {
        denominationExpectedQuantity
        console.log('denominationExpectedQuantity_', denominationExpectedQuantity)
        return (
            <>
                <React.Fragment>
                    <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
                        {/* Expected Quantity */}
                        <RACTableCell className="racstrap-tablecell-BgColor">
                            <RACTextbox
                                disabled={true}
                                className={classes.quantity}
                                value={denominationExpectedQuantity.denominationQuantity001.toString()}
                                maxlength={8}
                                type="number"
                            // name="001$"
                            />
                        </RACTableCell>
                        {/* Expected Total */}
                        <RACTableCell
                            style={{ color: '#4a5174' }}
                            className="racstrap-tablecell-BgColor"
                        >
                            <Typography style={{ paddingLeft: '12px' }}>
                                ${' '}
                                {(
                                    Number(denominationExpectedQuantity.denominationQuantity001) *
                                    0.01
                                ).toFixed(2)}
                            </Typography>
                        </RACTableCell>
                        {/* Denomination */}
                        <RACTableCell className="racstarp-tablecell-BgColor">
                            <RACButton
                                disabled={historyData?.mode == 'VIEW' ? true : false}
                                name="001$"
                                id="001$"
                                onClick={(e) => { handleDropReconciliationDetailsBtn(e); }}
                                className={`${classes.RACbutton} ${classes.textwidthbutton}`}
                            // className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
                            >
                                $ 0.01
                            </RACButton>
                        </RACTableCell>
                        {/* Actual Quantity */}
                        <RACTableCell className="racstrap-tablecell-BgColor">
                            <RACTextbox
                                className={classes.quantity}
                                disabled={historyData?.mode == 'VIEW' ? true : false}
                                value={denominationActualQuantity.denominationQuantity001.toString()}
                                maxlength={8}
                                type="number"
                                name="$001"
                                id="$001"
                                OnChange={(e) => { handleDropReconciliationDetailsInp(e); }}
                            />
                        </RACTableCell>
                        {/* Actual Total */}
                        <RACTableCell
                            style={{ color: '#4a5174' }}
                            className="racstrap-tablecell-BgColor"
                        >
                            <Typography style={{ paddingLeft: '27px' }}>
                                ${' '}
                                {(
                                    Number(denominationActualQuantity.denominationQuantity001) *
                                    0.01
                                ).toFixed(2)}
                            </Typography>
                        </RACTableCell>
                    </RACTableRow>
                    {/* Expected Quantity */}
                    <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
                        <RACTableCell className="racstrap-tablecell-BgColor">
                            <RACTextbox
                                disabled={true}
                                className={classes.quantity}
                                value={denominationExpectedQuantity.denominationQuantity005.toString()}
                                maxlength={8}
                                type="number"
                                name="100$"
                            // OnChange={(e) => onValueChange(e)}
                            />
                        </RACTableCell>
                        {/* Expected Total */}
                        <RACTableCell
                            style={{ color: '#4a5174' }}
                            className="racstrap-tablecell-BgColor"
                        >
                            <Typography style={{ paddingLeft: '12px' }}>
                                ${' '}
                                {(
                                    Number(denominationExpectedQuantity.denominationQuantity005) *
                                    0.05
                                ).toFixed(2)}
                            </Typography>
                        </RACTableCell>
                        {/* Denomination */}
                        <RACTableCell className="racstarp-tablecell-BgColor">
                            <RACButton
                                disabled={historyData?.mode == 'VIEW' ? true : false}
                                name="005$"
                                id="005$"
                                onClick={(e) => { handleDropReconciliationDetailsBtn(e); }}
                                className={`${classes.RACbutton} ${classes.textwidthbutton}`}
                            >
                                $ 0.05
                            </RACButton>
                        </RACTableCell>
                        {/* Actual Quantity */}
                        <RACTableCell className="racstrap-tablecell-BgColor">
                            <RACTextbox
                                className={classes.quantity}
                                disabled={historyData?.mode == 'VIEW' ? true : false}
                                value={denominationActualQuantity.denominationQuantity005.toString()}
                                maxlength={8}
                                type="number"
                                name="$005"
                                id="$005"
                                OnChange={(e) => { handleDropReconciliationDetailsInp(e); }}
                            />
                        </RACTableCell>
                        {/* Actual Total */}
                        <RACTableCell
                            style={{ color: '#4a5174' }}
                            className="racstrap-tablecell-BgColor"
                        >
                            <Typography style={{ paddingLeft: '27px' }}>
                                ${' '}
                                {(
                                    Number(denominationActualQuantity.denominationQuantity005) *
                                    0.05
                                ).toFixed(2)}
                            </Typography>
                        </RACTableCell>
                    </RACTableRow>
                    {/* Expected Quantity */}
                    <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
                        <RACTableCell className="racstrap-tablecell-BgColor">
                            <RACTextbox
                                disabled={true}
                                className={classes.quantity}
                                value={denominationExpectedQuantity.denominationQuantity010.toString()}
                                maxlength={8}
                                type="number"
                                name="100$"
                            // OnChange={(e) => onValueChange(e)}
                            />
                        </RACTableCell>
                        {/* Expected Total */}
                        <RACTableCell
                            style={{ color: '#4a5174' }}
                            className="racstrap-tablecell-BgColor"
                        >
                            <Typography style={{ paddingLeft: '12px' }}>
                                ${' '}
                                {(
                                    Number(denominationExpectedQuantity.denominationQuantity010) *
                                    0.1
                                ).toFixed(2)}
                            </Typography>
                        </RACTableCell>
                        {/* Denomination */}
                        <RACTableCell className="racstarp-tablecell-BgColor">
                            <RACButton
                                disabled={historyData?.mode == 'VIEW' ? true : false}
                                name="010$"
                                id="010$"
                                onClick={(e) => { handleDropReconciliationDetailsBtn(e); }}
                                className={`${classes.RACbutton} ${classes.textwidthbutton}`}
                            >
                                $ 0.10
                            </RACButton>
                        </RACTableCell>
                        {/* Actual Quantity */}
                        <RACTableCell className="racstrap-tablecell-BgColor">
                            <RACTextbox
                                className={classes.quantity}
                                disabled={historyData?.mode == 'VIEW' ? true : false}
                                value={denominationActualQuantity.denominationQuantity010.toString()}
                                maxlength={8}
                                type="number"
                                name="$010"
                                id="$010"
                                OnChange={(e) => { handleDropReconciliationDetailsInp(e); }}
                            />
                        </RACTableCell>
                        {/* Actual Total */}
                        <RACTableCell
                            style={{ color: '#4a5174' }}
                            className="racstrap-tablecell-BgColor"
                        >
                            <Typography style={{ paddingLeft: '27px' }}>
                                ${' '}
                                {(
                                    Number(denominationActualQuantity.denominationQuantity010) *
                                    0.1
                                ).toFixed(2)}
                            </Typography>
                        </RACTableCell>
                    </RACTableRow>
                    {/* Expected Quantity */}
                    <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
                        <RACTableCell className="racstrap-tablecell-BgColor">
                            <RACTextbox
                                className={classes.quantity}
                                disabled={true}
                                value={denominationExpectedQuantity.denominationQuantity025.toString()}
                                maxlength={8}
                                type="number"
                                name="100$"
                            // OnChange={(e) => handleDropReconciliationDetailsInp(e)}
                            />
                        </RACTableCell>
                        {/* Expected Total */}
                        <RACTableCell
                            style={{ color: '#4a5174' }}
                            className="racstrap-tablecell-BgColor"
                        >
                            <Typography style={{ paddingLeft: '12px' }}>
                                ${' '}
                                {(
                                    Number(denominationExpectedQuantity.denominationQuantity025) *
                                    0.25
                                ).toFixed(2)}
                            </Typography>
                        </RACTableCell>
                        {/* Denomination */}
                        <RACTableCell className="racstarp-tablecell-BgColor">
                            <RACButton
                                name="025$"
                                id="025$"
                                disabled={historyData?.mode == 'VIEW' ? true : false}
                                onClick={(e) => { handleDropReconciliationDetailsBtn(e); }}
                                className={`${classes.RACbutton} ${classes.textwidthbutton}`}
                            >
                                $ 0.25
                            </RACButton>
                        </RACTableCell>
                        {/* Actual Quantity */}
                        <RACTableCell className="racstrap-tablecell-BgColor">
                            <RACTextbox
                                className={classes.quantity}
                                disabled={historyData?.mode == 'VIEW' ? true : false}
                                value={denominationActualQuantity.denominationQuantity025.toString()}
                                maxlength={8}
                                type="number"
                                name="$025"
                                id="$025"
                                OnChange={(e) => { handleDropReconciliationDetailsInp(e); }}
                            />
                        </RACTableCell>
                        {/* Actual Total */}

                        <RACTableCell
                            style={{ color: '#4a5174' }}
                            className="racstrap-tablecell-BgColor"
                        >
                            <Typography style={{ paddingLeft: '27px' }}>
                                ${' '}
                                {(
                                    Number(denominationActualQuantity.denominationQuantity025) *
                                    0.25
                                ).toFixed(2)}
                            </Typography>
                        </RACTableCell>
                    </RACTableRow>
                    {/* Expected Quantity */}
                    <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
                        <RACTableCell className="racstrap-tablecell-BgColor">
                            <RACTextbox
                                className={classes.quantity}
                                value={denominationExpectedQuantity.denominationQuantity050.toString()}
                                disabled={true}
                                maxlength={8}
                                type="number"
                                name="$050"
                            // OnChange={(e) => handleDropReconciliationDetailsInp(e)}
                            />
                        </RACTableCell>
                        {/* Expected Total */}
                        <RACTableCell
                            style={{ color: '#4a5174' }}
                            className="racstrap-tablecell-BgColor"
                        >
                            <Typography style={{ paddingLeft: '12px' }}>
                                ${' '}
                                {(
                                    Number(denominationExpectedQuantity.denominationQuantity050) *
                                    0.5
                                ).toFixed(2)}
                            </Typography>
                        </RACTableCell>
                        {/* Denomination */}
                        <RACTableCell className="racstarp-tablecell-BgColor">
                            <RACButton
                                name="050$"
                                id="050$"
                                disabled={historyData?.mode == 'VIEW' ? true : false}
                                onClick={(e) => { handleDropReconciliationDetailsBtn(e); }}
                                className={`${classes.RACbutton} ${classes.textwidthbutton}`}
                            >
                                $ 0.50
                            </RACButton>
                        </RACTableCell>
                        {/* Actual Quantity */}
                        <RACTableCell className="racstrap-tablecell-BgColor">
                            <RACTextbox
                                className={classes.quantity}
                                disabled={historyData?.mode == 'VIEW' ? true : false}
                                value={denominationActualQuantity.denominationQuantity050.toString()}
                                maxlength={8}
                                type="number"
                                name="$050"
                                id="$050"
                                OnChange={(e) => { handleDropReconciliationDetailsInp(e); }}
                            />
                        </RACTableCell>
                        {/* Actual Total */}
                        <RACTableCell
                            style={{ color: '#4a5174' }}
                            className="racstrap-tablecell-BgColor"
                        >
                            <Typography style={{ paddingLeft: '27px' }}>
                                ${' '}
                                {(
                                    Number(denominationActualQuantity.denominationQuantity050) *
                                    0.5
                                ).toFixed(2)}
                            </Typography>
                        </RACTableCell>
                    </RACTableRow>
                    {/* Expected Quantity */}
                    <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
                        <RACTableCell className="racstrap-tablecell-BgColor">
                            <RACTextbox
                                className={classes.quantity}
                                disabled={true}
                                value={denominationExpectedQuantity.denominationQuantity1.toString()}
                                maxlength={8}
                                type="number"
                                name="100$"
                            // OnChange={(e) => onValueChange(e)}
                            />
                        </RACTableCell>
                        {/* Expected Total */}
                        <RACTableCell
                            style={{ color: '#4a5174' }}
                            className="racstrap-tablecell-BgColor"
                        >
                            <Typography style={{ paddingLeft: '12px' }}>
                                ${' '}
                                {(
                                    Number(denominationExpectedQuantity.denominationQuantity1) * 1
                                ).toFixed(2)}
                            </Typography>
                        </RACTableCell>
                        {/* Denomination */}
                        <RACTableCell className="racstarp-tablecell-BgColor">
                            <RACButton
                                name="1$"
                                disabled={historyData?.mode == 'VIEW' ? true : false}
                                id="1$"
                                onClick={(e) => { handleDropReconciliationDetailsBtn(e); }}
                                className={`${classes.RACbutton} ${classes.textwidthbutton}`}
                            >
                                $ 1
                            </RACButton>
                        </RACTableCell>
                        {/* Actual Quantity */}
                        <RACTableCell className="racstrap-tablecell-BgColor">
                            <RACTextbox
                                className={classes.quantity}
                                value={denominationActualQuantity.denominationQuantity1.toString()}
                                maxlength={8}
                                type="number"
                                disabled={historyData?.mode == 'VIEW' ? true : false}
                                name="$1"
                                id="$1"
                                OnChange={(e) => { handleDropReconciliationDetailsInp(e); }}
                            />
                        </RACTableCell>
                        {/* Actual Total */}
                        <RACTableCell
                            style={{ color: '#4a5174' }}
                            className="racstrap-tablecell-BgColor"
                        >
                            <Typography style={{ paddingLeft: '27px' }}>
                                ${' '}
                                {(
                                    Number(denominationActualQuantity.denominationQuantity1) * 1
                                ).toFixed(2)}
                            </Typography>
                        </RACTableCell>
                    </RACTableRow>
                    {/* Expected Quantity */}
                    <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
                        <RACTableCell className="racstrap-tablecell-BgColor">
                            <RACTextbox
                                className={classes.quantity}
                                value={denominationExpectedQuantity.denominationQuantity2.toString()}
                                disabled={true}
                                maxlength={8}
                                type="number"
                                name="100$"
                            // OnChange={(e) => onValueChange(e)}
                            />
                        </RACTableCell>
                        {/* Expected Total */}
                        <RACTableCell
                            style={{ color: '#4a5174' }}
                            className="racstrap-tablecell-BgColor"
                        >
                            <Typography style={{ paddingLeft: '12px' }}>
                                ${' '}
                                {(
                                    Number(denominationExpectedQuantity.denominationQuantity2) * 2
                                ).toFixed(2)}
                            </Typography>
                        </RACTableCell>
                        {/* Denomination */}
                        <RACTableCell className="racstarp-tablecell-BgColor">
                            <RACButton
                                name="2$"
                                id="2$"
                                disabled={historyData?.mode == 'VIEW' ? true : false}
                                onClick={(e) => { handleDropReconciliationDetailsBtn(e); }}
                                className={`${classes.RACbutton} ${classes.textwidthbutton}`}
                            >
                                $ 2
                            </RACButton>
                        </RACTableCell>
                        {/* Actual Quantity */}
                        <RACTableCell className="racstrap-tablecell-BgColor">
                            <RACTextbox
                                className={classes.quantity}
                                value={denominationActualQuantity.denominationQuantity2.toString()}
                                maxlength={8}
                                disabled={historyData?.mode == 'VIEW' ? true : false}
                                type="number"
                                name="$2"
                                id="$2"
                                OnChange={(e) => { handleDropReconciliationDetailsInp(e); }}
                            />
                        </RACTableCell>
                        {/* Actual Total */}
                        <RACTableCell
                            style={{ color: '#4a5174' }}
                            className="racstrap-tablecell-BgColor"
                        >
                            <Typography style={{ paddingLeft: '27px' }}>
                                ${' '}
                                {(
                                    Number(denominationActualQuantity.denominationQuantity2) * 2
                                ).toFixed(2)}
                            </Typography>
                        </RACTableCell>
                    </RACTableRow>
                    {/* Expected Quantity */}
                    <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
                        <RACTableCell className="racstrap-tablecell-BgColor">
                            <RACTextbox
                                className={classes.quantity}
                                value={denominationExpectedQuantity.denominationQuantity5.toString()}
                                disabled={true}
                                maxlength={8}
                                type="number"
                                name="100$"
                            // OnChange={(e) => onValueChange(e)}
                            />
                        </RACTableCell>
                        {/* Expected Total */}
                        <RACTableCell
                            style={{ color: '#4a5174' }}
                            className="racstrap-tablecell-BgColor"
                        >
                            <Typography style={{ paddingLeft: '12px' }}>
                                ${' '}
                                {(
                                    Number(denominationExpectedQuantity.denominationQuantity5) * 5
                                ).toFixed(2)}
                            </Typography>
                        </RACTableCell>
                        {/* Denomination */}
                        <RACTableCell className="racstarp-tablecell-BgColor">
                            <RACButton
                                name="5$"
                                disabled={historyData?.mode == 'VIEW' ? true : false}
                                id="5$"
                                onClick={(e) => { handleDropReconciliationDetailsBtn(e); }}
                                className={`${classes.RACbutton} ${classes.textwidthbutton}`}
                            >
                                $ 5
                            </RACButton>
                        </RACTableCell>
                        {/* Actual Quantity */}
                        <RACTableCell className="racstrap-tablecell-BgColor">
                            <RACTextbox
                                className={classes.quantity}
                                value={denominationActualQuantity.denominationQuantity5.toString()}
                                maxlength={8}
                                disabled={historyData?.mode == 'VIEW' ? true : false}
                                type="number"
                                name="$5"
                                id="$5"
                                OnChange={(e) => { handleDropReconciliationDetailsInp(e); }}
                            />
                        </RACTableCell>
                        {/* Actual Total */}
                        <RACTableCell
                            style={{ color: '#4a5174' }}
                            className="racstrap-tablecell-BgColor"
                        >
                            <Typography style={{ paddingLeft: '27px' }}>
                                ${' '}
                                {(
                                    Number(denominationActualQuantity.denominationQuantity5) * 5
                                ).toFixed(2)}
                            </Typography>
                        </RACTableCell>
                    </RACTableRow>
                    {/* Expected Quantity */}
                    <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
                        <RACTableCell className="racstrap-tablecell-BgColor">
                            <RACTextbox
                                className={classes.quantity}
                                value={denominationExpectedQuantity.denominationQuantity10.toString()}
                                disabled={true}
                                maxlength={8}
                                type="number"
                                name="100$"
                            // OnChange={(e) => onValueChange(e)}
                            />
                        </RACTableCell>
                        {/* Expected Total */}
                        <RACTableCell
                            style={{ color: '#4a5174' }}
                            className="racstrap-tablecell-BgColor"
                        >
                            <Typography style={{ paddingLeft: '12px' }}>
                                ${' '}
                                {(
                                    Number(denominationExpectedQuantity.denominationQuantity10) *
                                    10
                                ).toFixed(2)}
                            </Typography>
                        </RACTableCell>
                        {/* Denomination */}
                        <RACTableCell className="racstarp-tablecell-BgColor">
                            <RACButton
                                name="10$"
                                id="10$"
                                disabled={historyData?.mode == 'VIEW' ? true : false}
                                onClick={(e) => { handleDropReconciliationDetailsBtn(e); }}
                                className={`${classes.RACbutton} ${classes.textwidthbutton}`}
                            >
                                $ 10
                            </RACButton>
                        </RACTableCell>
                        {/* Actual Quantity */}
                        <RACTableCell className="racstrap-tablecell-BgColor">
                            <RACTextbox
                                className={classes.quantity}
                                value={denominationActualQuantity.denominationQuantity10.toString()}
                                maxlength={8}
                                disabled={historyData?.mode == 'VIEW' ? true : false}
                                type="number"
                                name="$10"
                                id="$10"
                                OnChange={(e) => { handleDropReconciliationDetailsInp(e); }}
                            />
                        </RACTableCell>
                        {/* Actual Total */}
                        <RACTableCell
                            style={{ color: '#4a5174' }}
                            className="racstrap-tablecell-BgColor"
                        >
                            <Typography style={{ paddingLeft: '27px' }}>
                                ${' '}
                                {(
                                    Number(denominationActualQuantity.denominationQuantity10) * 10
                                ).toFixed(2)}
                            </Typography>
                        </RACTableCell>
                    </RACTableRow>
                    {/* Expected Quantity */}
                    <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
                        <RACTableCell className="racstrap-tablecell-BgColor">
                            <RACTextbox
                                className={classes.quantity}
                                value={denominationExpectedQuantity.denominationQuantity20.toString()}
                                disabled={true}
                                maxlength={8}
                                type="number"
                                name="100$"
                            // OnChange={(e) => onValueChange(e)}
                            />
                        </RACTableCell>
                        {/* Expected Total */}
                        <RACTableCell
                            style={{ color: '#4a5174' }}
                            className="racstrap-tablecell-BgColor"
                        >
                            <Typography style={{ paddingLeft: '12px' }}>
                                ${' '}
                                {(
                                    Number(denominationExpectedQuantity.denominationQuantity20) *
                                    20
                                ).toFixed(2)}
                            </Typography>
                        </RACTableCell>
                        {/* Denomination */}
                        <RACTableCell className="racstarp-tablecell-BgColor">
                            <RACButton
                                name="20$"
                                disabled={historyData?.mode == 'VIEW' ? true : false}
                                id="20$"
                                onClick={(e) => { handleDropReconciliationDetailsBtn(e); }}
                                className={`${classes.RACbutton} ${classes.textwidthbutton}`}
                            >
                                $ 20
                            </RACButton>
                        </RACTableCell>
                        {/* Actual Quantity */}
                        <RACTableCell className="racstrap-tablecell-BgColor">
                            <RACTextbox
                                className={classes.quantity}
                                disabled={historyData?.mode == 'VIEW' ? true : false}
                                value={denominationActualQuantity.denominationQuantity20.toString()}
                                maxlength={8}
                                type="number"
                                name="$20"
                                id="$20"
                                OnChange={(e) => { handleDropReconciliationDetailsInp(e); }}
                            />
                        </RACTableCell>
                        {/* Actual Total */}
                        <RACTableCell
                            style={{ color: '#4a5174' }}
                            className="racstrap-tablecell-BgColor"
                        >
                            <Typography style={{ paddingLeft: '27px' }}>
                                ${' '}
                                {(
                                    Number(denominationActualQuantity.denominationQuantity20) * 20
                                ).toFixed(2)}
                            </Typography>
                        </RACTableCell>
                    </RACTableRow>
                    {/* Expected Quantity */}
                    <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
                        <RACTableCell className="racstrap-tablecell-BgColor">
                            <RACTextbox
                                className={classes.quantity}
                                value={denominationExpectedQuantity.denominationQuantity50.toString()}
                                disabled={true}
                                maxlength={8}
                                type="number"
                                name="100$"
                            // OnChange={(e) => onValueChange(e)}
                            />
                        </RACTableCell>
                        {/* Expected Total */}
                        <RACTableCell
                            style={{ color: '#4a5174' }}
                            className="racstrap-tablecell-BgColor"
                        >
                            <Typography style={{ paddingLeft: '12px' }}>
                                ${' '}
                                {(
                                    Number(denominationExpectedQuantity.denominationQuantity50) *
                                    50
                                ).toFixed(2)}
                            </Typography>
                        </RACTableCell>
                        {/* Denomination */}
                        <RACTableCell className="racstarp-tablecell-BgColor">
                            <RACButton
                                name="50$"
                                id="50$"
                                disabled={historyData?.mode == 'VIEW' ? true : false}
                                onClick={(e) => { handleDropReconciliationDetailsBtn(e); }}
                                className={`${classes.RACbutton} ${classes.textwidthbutton}`}
                            >
                                $ 50
                            </RACButton>
                        </RACTableCell>
                        {/* Actual Quantity */}
                        <RACTableCell className="racstrap-tablecell-BgColor">
                            <RACTextbox
                                className={classes.quantity}
                                value={denominationActualQuantity.denominationQuantity50.toString()}
                                maxlength={8}
                                disabled={historyData?.mode == 'VIEW' ? true : false}
                                type="number"
                                name="$50"
                                id="$50"
                                OnChange={(e) => { handleDropReconciliationDetailsInp(e); }}
                            />
                        </RACTableCell>
                        {/* Actual Total */}
                        <RACTableCell
                            style={{ color: '#4a5174' }}
                            className="racstrap-tablecell-BgColor"
                        >
                            <Typography style={{ paddingLeft: '27px' }}>
                                ${' '}
                                {(
                                    Number(denominationActualQuantity.denominationQuantity50) * 50
                                ).toFixed(2)}
                            </Typography>
                        </RACTableCell>
                    </RACTableRow>
                    {/* Expected Quantity */}
                    <RACTableRow style={{ height: '4rem' }} className={classes.tableRow}>
                        <RACTableCell className="racstrap-tablecell-BgColor">
                            <RACTextbox
                                className={classes.quantity}
                                value={denominationExpectedQuantity.denominationQuantity100.toString()}
                                disabled={true}
                                maxlength={8}
                                type="number"
                                name="100$"
                            // OnChange={(e) => onValueChange(e)}
                            />
                        </RACTableCell>
                        {/* Expected Total */}
                        <RACTableCell
                            style={{ color: '#4a5174' }}
                            className="racstrap-tablecell-BgColor"
                        >
                            <Typography style={{ paddingLeft: '12px' }}>
                                ${' '}
                                {(
                                    Number(denominationExpectedQuantity.denominationQuantity100) *
                                    100
                                ).toFixed(2)}
                            </Typography>
                        </RACTableCell>
                        {/* Denomination */}
                        <RACTableCell className="racstarp-tablecell-BgColor">
                            <RACButton
                                name="100$"
                                id="100$"
                                disabled={historyData?.mode == 'VIEW' ? true : false}
                                onClick={(e) => { handleDropReconciliationDetailsBtn(e); }}
                                className={`${classes.RACbutton} ${classes.textwidthbutton}`}
                            >
                                $ 100
                            </RACButton>
                        </RACTableCell>
                        {/* Actual Quantity */}
                        <RACTableCell className="racstrap-tablecell-BgColor">
                            <RACTextbox
                                className={classes.quantity}
                                value={denominationActualQuantity.denominationQuantity100.toString()}
                                maxlength={8}
                                disabled={historyData?.mode == 'VIEW' ? true : false}
                                type="number"
                                name="$100"
                                id="$100"
                                OnChange={(e) => { handleDropReconciliationDetailsInp(e); }}
                            />
                        </RACTableCell>
                        {/* Actual Total */}
                        <RACTableCell
                            style={{ color: '#4a5174' }}
                            className="racstrap-tablecell-BgColor"
                        >
                            <Typography style={{ paddingLeft: '27px' }}>
                                ${' '}
                                {(
                                    Number(denominationActualQuantity.denominationQuantity100) *
                                    100
                                ).toFixed(2)}
                            </Typography>
                        </RACTableCell>
                    </RACTableRow>
                </React.Fragment>
            </>
        );
    };
    const renderNonCashTableHead = () => {
        return (
            <React.Fragment>
                <RACTableCell className={`"racstrap-tablecell-BgColor"`}>
                    <Typography variant="h5">Customer</Typography>
                </RACTableCell>
                <RACTableCell className={`"racstrap-tablecell-BgColor"`}>
                    <Typography variant="h5">Doc Type</Typography>
                </RACTableCell>
                <RACTableCell className={`"racstrap-tablecell-BgColor"`}>
                    <Typography variant="h5">Doc #</Typography>
                </RACTableCell>
                <RACTableCell
                    className={
                        // ${classes.nameOnDoc}
                        'racstrap-tablecell-BgColor'
                    }
                >
                    <Typography variant="h5">Name on Doc</Typography>
                </RACTableCell>
                <RACTableCell
                    className={
                        // ${classes.receipt}
                        'racstrap-tablecell-BgColor'
                    }
                >
                    <Typography variant="h5">Receipt #</Typography>
                </RACTableCell>
                <RACTableCell
                    style={{ textAlign: 'right' }}
                    className={
                        // ${classes.amountDD}
                        'racstrap-tablecell-BgColor'
                    }
                >
                    <Typography variant="h5">Amount</Typography>
                </RACTableCell>
                <RACTableCell className={`"racstrap-tablecell-BgColor"`}>
                    <Grid style={{ paddingLeft: '10px' }}>
                        <Typography variant="h5">Verified</Typography>
                    </Grid>
                </RACTableCell>
            </React.Fragment>
        );
    };
    const truncateString = (str: string): string => {
        if (!str) {
            str = ''
        }
        if (str && str.length > 15) {
            return (str.substring(0, 12) + '...').toString();
        }

        return str;
    };
    const renderNonCashTableContent = (): any => {
        return dropReconcilationDetails && dropReconcilationDetails.nonCashDetailsRes?.map((data: any) => {
            return (
                <>
                    <React.Fragment>
                        {/* Row 1 */}
                        <RACTableRow className={classes.tableRow} style={{ width: '100%' }}>
                            <RACTableCell title={data?.vendorName}>
                                <Typography style={{ color: '#4a5174' }}>
                                    {truncateString(data?.vendorName)}
                                </Typography>
                            </RACTableCell>
                            <RACTableCell>
                                <Typography style={{ color: '#4a5174' }}>
                                    {data?.tenderType}
                                </Typography>
                            </RACTableCell>
                            <RACTableCell>
                                <Typography style={{ color: '#4a5174' }}>
                                    {data?.documentNumber}
                                </Typography>
                            </RACTableCell>
                            <RACTableCell>
                                <Typography style={{ color: '#4a5174' }}>
                                    {data?.documentWrittenBy}
                                </Typography>
                            </RACTableCell>
                            <RACTableCell>
                                <Typography style={{ color: '#4a5174' }}>
                                    {data?.reciptNumber}
                                </Typography>
                            </RACTableCell>
                            <RACTableCell>
                                <Typography style={{ textAlign: 'right', color: '#4a5174' }}>
                                    $ {parseFloat(data?.cashAmount).toFixed(2)}
                                </Typography>
                            </RACTableCell>
                            <RACTableCell>
                                <RACCheckBox
                                    disabled={historyData?.mode == 'VIEW' ? true : loader}
                                    checked={data.isChecked}
                                    onClick={(e) => handleNonCashDetail(e, data?.receiptTransactionId)}
                                    style={{ marginLeft: '16.5px' }}
                                    size="medium"
                                />
                            </RACTableCell>
                        </RACTableRow>
                    </React.Fragment>
                </>
            );
        });
    };
    const mandatoryDropNotesPopup = () => {
        return (
            <Grid item md={12}>
                <Grid item md={12} className={classes.textCenter}>
                    <Typography
                        variant={'body2'}
                        className={`${classes.my3} ${classes.RACPOPMsg}`}
                    >
                        <h3 style={{ margin: '0px' }}>{((((Number(summaryTotal.storeTransactionTotal.cash) + Number(summaryTotal.storeTransactionTotal.nonCash)) - (Number(dropAmount.cash) + Number(dropAmount.nonCash))) != 0) && overshortDropNotes?.overShort?.trim().length == 0) ? 'Please provide over/short notes' : ''}</h3>
                        <h3 style={{ margin: '0px' }}>{overshortDropNotes?.dropNotes?.trim().length == 0 ? 'Please provide drop notes' : ''}</h3>
                    </Typography>
                </Grid>
                <Grid
                    item
                    className={`${classes.textCenter} ${classes.mt4} ${classes.w100}`}
                >
                    <RACButton
                        variant="contained"
                        color="primary"
                        onClick={() => setmandatoryNotes(false)}
                    >
                        Ok
                    </RACButton>
                </Grid>
            </Grid>
        );
    }
    const addBracketForShort = (num1: any, num2: any) => {
        if (isNaN(num1) || isNaN(num2)) {
            return `$0.00`
        }
        const start = `(`
        const end = `)`
        if (Number(num1) + Number(num2) >= 0) {
            return `$` + (Number(num1) + Number(num2)).toFixed(2)
        } else {
            return start + `$` + Math.abs((Number(num1) + Number(num2))).toFixed(2) + end
        }
    }
    // const addBracketForReconcilShort = (num1: any, num2: any) => {
    //         const start = `(`
    //         const end = `)`
    //     if ((Number(summaryTotal.dropSummaryTotal.cash) + Number(summaryTotal.dropSummaryTotal.nonCash)) - ((Number(dropAmount.cash) + Number(dropAmount.nonCash))) > 0) {
    //             return (Number(num1) + Number(num2)).toFixed(2)
    //         } else {
    //         return start + Math.abs(((Number(summaryTotal.dropSummaryTotal.cash) + Number(summaryTotal.dropSummaryTotal.nonCash)) - ((Number(dropAmount.cash) + Number(dropAmount.nonCash)))).toFixed(2) + end
    //         }
    //     }
    return (
        <>
            <RACModalCard
                isOpen={mandatoryNotes}
                maxWidth="xs"
                closeIcon={true}
                borderRadius="20px"
                onClose={() => setmandatoryNotes(false)}
                // eslint-disable-next-line react/no-children-prop
                children={mandatoryDropNotesPopup()}
            />
            {/* parent container */}
            <HandleCancelModelCard />
            <Grid style={{ marginBottom: '35%' }} className={classes.container}>
                <Modal
                    isOpen={loader}
                    className={classes.loaderStyle}
                    id="openpopup"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    aria-hidden="true"
                >
                    <Grid>
                        <Grid>
                            <Grid className={classes.RACLoaderPage}>
                                <CircularProgress />
                            </Grid>
                        </Grid>
                    </Grid>
                </Modal>
                {/* Breadcrumbs */}
                <Grid>
                    <List className={`${classes.breadcrumb} ${classes.py1}`}>
                        <ListItem
                            style={{ paddingLeft: '0px !important' }}
                            className={`${classes.breadcrumbItemActive} ${classes.breadCrumbMarg} `}
                        >
                            <a
                                style={{ cursor: 'pointer', paddingLeft: '0px !important' }}
                                onClick={() => history.push(`/dashboard/homepage`)}
                            >
                                Dashboard
                            </a>
                        </ListItem>
                        <ListItem
                            className={`${classes.breadcrumbItem} ${classes.breadcrumbArrow} ${classes.racpadLinkCustomer}`}
                        >
                            {/* {customerInformation?.firstName
                    ? customerInformation?.firstName +
                      ' ' +
                      customerInformation?.lastName
                    : ''}{' '}
                  - {customerId} */}
                        </ListItem>
                        {/* <ListItem
                style={{paddingLeft:"0px !important"}}
                  className={`${classes.breadcrumbItemActive} ${classes.breadCrumbMarg} `}
                >
                  <a
                    style={{ cursor: 'pointer',paddingLeft:"0px !important" }}
                    // onClick={() => props.history.push(`/dashboard`)}
                  >
                    Store
                  </a>
                </ListItem> */}
                        {/* <ListItem
                  className={`${classes.breadcrumbItem} ${classes.breadcrumbArrow} ${classes.racpadLinkCustomer}`}
                > */}
                        {/* {customerInformation?.firstName
                    ? customerInformation?.firstName +
                      ' ' +
                      customerInformation?.lastName
                    : ''}{' '}
                  - {customerId} */}
                        {/* </ListItem> */}
                        {/* <ListItem
                style={{paddingLeft:"0px !important"}}
                  className={`${classes.breadcrumbItemActive} ${classes.breadCrumbMarg} `}
                >
                  <a
                    style={{ cursor: 'pointer',paddingLeft:"0px !important" }}
                    // onClick={() => props.history.push(`/dashboard`)}
                  >
                    Cash Management
                  </a>
                </ListItem> */}
                        {/* <ListItem
                  className={`${classes.breadcrumbItem} ${classes.breadcrumbArrow} ${classes.racpadLinkCustomer}`}
                > */}
                        {/* {customerInformation?.firstName
                    ? customerInformation?.firstName +
                      ' ' +
                      customerInformation?.lastName
                    : ''}{' '}
                  - {customerId} */}
                        {/* </ListItem> */}
                        <ListItem
                            style={{ paddingLeft: '0px !important' }}
                            className={`${classes.breadcrumbItemActive} ${classes.breadCrumbMarg} `}
                        >
                            <a
                                style={{ cursor: 'pointer', paddingLeft: '0px !important' }}
                                onClick={() => history.push({ pathname: '/cashmgmt/reconcil', state:isReconciled/* historyData?.mode === 'RE-VALIDATE' || historyData?.state =='VIEW VALIDATE' */ ? 'RECONCILCOMPLETED_COMPLETED':  'REDIRECT' })}
                            >
                                Drop Reconciliation
                            </a>
                        </ListItem>
                        <ListItem
                            className={`${classes.breadcrumbItem} ${classes.breadcrumbArrow} ${classes.racpadLinkCustomer}`}
                        >
                            {/* {customerInformation?.firstName
                    ? customerInformation?.firstName +
                      ' ' +
                      customerInformation?.lastName
                    : ''}{' '}
                  - {customerId} */}
                        </ListItem>
                        <ListItem
                            style={{
                                width: 'auto',
                                fontFamily: 'OpenSans-semibold',
                                fontSize: '12px',
                                //    color: '#3182FF',
                                paddingLeft: '3px',
                                paddingRight: '0',
                            }}
                            className={` ${classes.breadCrumbMarg} `}
                        >
                            <a
                                style={{ cursor: 'pointer', paddingLeft: '0px !important' }}
                                onClick={() => history.push({ pathname: `/cashmgmt/reconcil/summary`, state:isReconciled/* historyData?.mode === 'RE-VALIDATE' || historyData?.state =='VIEW VALIDATE' */ ? 'RE-VALIDATED':  'REDIRECT' })}
                            // onClick={() => props.redirectPageToSummary()}
                            >
                                Summary
                            </a>
                        </ListItem>
                        <ListItem
                            className={`${classes.breadcrumbItem} ${classes.breadcrumbArrow} ${classes.racpadLinkCustomer}`}
                        >
                            {/* {customerInformation?.firstName
                    ? customerInformation?.firstName +
                      ' ' +
                      customerInformation?.lastName
                    : ''}{' '}
                  - {customerId} */}
                        </ListItem>
                        <ListItem
                            style={{
                                width: 'auto',
                                fontFamily: 'OpenSans-semibold',
                                fontSize: '12px',
                                color: '#3182FF',
                                paddingLeft: '3px',
                                paddingRight: '0',
                            }}
                            className={` ${classes.breadCrumbMarg} `}
                        >
                            <a
                                style={{ cursor: 'pointer', paddingLeft: '0px !important' }}
                            // onClick={() => props.history.push(`/dashboard`)}
                            >
                                Details
                            </a>
                        </ListItem>
                    </List>
                </Grid>
                {/* Subparent conatiner */}
                <Grid style={{ width: '100%', float: 'left' }}>
                    <Grid className={classes.colMd12mt3}>
                        <Grid className={classes.row} style={{ marginLeft: '5px' }}>
                            {/* Drawer total starts here */}
                            <Typography variant="h4" className={classes.header}>
                                Drop Reconciliation Details
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid style={{ width: '99%', padding: '0.5%', float: 'left' }}>
                    <div className={classes.racFixedFooterMargin}>
                        <Grid style={{ width: '46.5%', float: 'left' }}>
                            {/* <CashDropSummary></CashDropSummary> */}
                            <Grid
                                // className={classes.mt3}
                                item
                                style={{ float: 'left', width: '100%' }}
                            // marginRight:"2%"
                            >
                                <Typography
                                    variant="h5"
                                    style={{
                                        width: '100%',
                                        fontSize: '18px',
                                        // fontFamily: "OpenSans-bold",
                                        marginBottom: '15px',
                                    }}
                                >
                                    Cash Drop Summary
                                </Typography>

                                <Card className={classes.card} style={{ marginBottom: '100px' }}>
                                    <CardContent className={`${classes.cardBody2} ${classes.p2}`}>
                                        <Grid className={`${classes.row} ${classes.mb3}`}>
                                            <Grid className={classes.racColMD12}>
                                                <RACTable
                                                    className={`${classes.table} ${classes.tableBorder}`}
                                                    renderTableHead={renderCashTableHead}
                                                    renderTableContent={renderCashTableContent}
                                                />
                                                <Grid
                                                    style={{
                                                        marginTop: '3%',
                                                        border: 'none',
                                                        borderRadius: '4px',
                                                        marginBottom: '10px',
                                                        width: '98%',
                                                        padding: '5px',
                                                        background: '#EFF4FF',
                                                        marginRight: '17px',
                                                        // marginLeft: "7px",
                                                        // marginBottom:"1px"
                                                    }}
                                                    className={`${classes.float} `}
                                                >
                                                    <Grid className={classes.row}>
                                                        <Grid style={{ width: '33.3%' }}>
                                                            <Grid
                                                                className={classes.floatLeft}
                                                                style={{ width: '50%' }}
                                                            >
                                                                <Typography
                                                                    style={{
                                                                        color: '#2179FE',
                                                                        padding: '1px',
                                                                        textAlign: 'right',
                                                                        //  paddingLeft: "2px",
                                                                        marginBottom: '6px',
                                                                        width: '100%',
                                                                    }}
                                                                >
                                                                    Expected Total:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid
                                                                // style={{ width: "32%", paddingLeft: "6.8%" }}
                                                                className={classes.floatLeft}
                                                                style={{ width: '50%' }}
                                                            >
                                                                <Typography
                                                                    style={{
                                                                        width: '100%',
                                                                        color: '#2179FE',
                                                                        padding: '1px',
                                                                        paddingLeft: '15px',
                                                                    }}
                                                                    variant="h5"
                                                                >
                                                                    ${summaryTotal && summaryTotal.storeTransactionTotal && summaryTotal.storeTransactionTotal.cash ? parseFloat(summaryTotal.storeTransactionTotal.cash).toFixed(2) : '0.00'}
                                                                    {/* $ {CalculateExpectedTotalAmount()} */}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid style={{ width: '30.3%' }}>
                                                            <Grid
                                                                className={classes.floatLeft}
                                                                style={{ width: '45%' }}
                                                            >
                                                                <Typography
                                                                    style={{
                                                                        color: '#2179FE',
                                                                        padding: '1px',
                                                                        //  paddingLeft: "2px",
                                                                        marginBottom: '6px',
                                                                        width: '100%',
                                                                    }}
                                                                >
                                                                    Actual Total:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid
                                                                // style={{ width: "32%", paddingLeft: "6.8%" }}
                                                                className={classes.floatLeft}
                                                                style={{ width: '55%' }}
                                                            >
                                                                <Typography
                                                                    style={{
                                                                        width: '100%',
                                                                        color: '#2179FE',
                                                                        padding: '1px',
                                                                    }}
                                                                    variant="h5"
                                                                >
                                                                    $ {parseFloat(dropAmount.cash).toFixed(2)}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid style={{ width: '36.3%' }}>
                                                            <Grid
                                                                className={classes.floatLeft}
                                                                style={{ width: '60%' }}
                                                            >
                                                                <Typography
                                                                    style={{
                                                                        color: '#2179FE',
                                                                        padding: '1px',
                                                                        //  paddingLeft: "2px",
                                                                        marginBottom: '6px',
                                                                        width: '100%',
                                                                    }}
                                                                >
                                                                    Difference of Totals:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid
                                                                // style={{ width: "32%", paddingLeft: "6.8%" }}
                                                                className={classes.floatLeft}
                                                                style={{ width: '40%' }}
                                                            >
                                                                <Typography
                                                                    style={{
                                                                        paddingLeft: '2%',
                                                                        width: '100%',
                                                                        color: '#2179FE',
                                                                        padding: '1px',
                                                                    }}
                                                                    variant="h5"
                                                                >
                                                                    {(parseFloat(summaryTotal && summaryTotal.storeTransactionTotal && summaryTotal.storeTransactionTotal.cash ? `${Number(summaryTotal.storeTransactionTotal.cash.toFixed(2))}` : '0.00') - parseFloat(Number(dropAmount.cash).toFixed(2))) > 0 ? `($${Math.abs((parseFloat(summaryTotal && summaryTotal.storeTransactionTotal && summaryTotal.storeTransactionTotal.cash ? summaryTotal.storeTransactionTotal.cash : '0.00') - parseFloat(dropAmount.cash))).toFixed(2)})` : `$${(Math.abs(parseFloat(summaryTotal && summaryTotal.storeTransactionTotal && summaryTotal.storeTransactionTotal.cash ? summaryTotal.storeTransactionTotal.cash : '0.00') - parseFloat(dropAmount.cash))).toFixed(2)}`}
                                                                    {/* {parseFloat(CalculateExpectedTotalAmount()) >
                                                                parseFloat(dropAmount.cash) ||
                                                                parseFloat(dropAmount.cash) == 0
                                                                ? `($ ${(
                                                                    parseFloat(
                                                                        CalculateExpectedTotalAmount()
                                                                    ) - parseFloat(dropAmount.cash)
                                                                ).toFixed(2)})`
                                                                : `$ ${(
                                                                    parseFloat(dropAmount.cash) -
                                                                    parseFloat(CalculateExpectedTotalAmount())
                                                                ).toFixed(2)}`} */}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                        <Grid style={{ width: '52%', float: 'right' }}>
                            <Grid style={{ width: '100%' }}>
                                {/* Totals summary */}
                                <Grid
                                    style={{ width: '59%', marginRight: '0.5%', float: 'left' }}
                                >
                                    <Grid style={{ width: '100%', float: 'left' }}>
                                        {/*Heading SubContainer*/}
                                        <Grid style={{ width: '100%' }}>
                                            <Typography
                                                variant="h5"
                                                style={{
                                                    paddingLeft: '0%',
                                                    width: '100%',
                                                    fontSize: '18px',
                                                    // fontFamily: "OpenSans-bold",
                                                    marginBottom: '15px',
                                                }}
                                            >
                                                Totals Summary
                                            </Typography>
                                            <Card
                                                className={classes.card}
                                                style={{ marginBottom: '3%' }}
                                            >
                                                <CardContent className={classes.cardBody}>
                                                    <Grid
                                                        style={{
                                                            borderBottom: '1px solid',
                                                            borderBottomColor: '#E0EAFF',
                                                            marginBottom: '6.7%',
                                                        }}
                                                    >
                                                        <Grid className={classes.row}>
                                                            <Grid style={{ width: '50%' }}>
                                                                <Typography
                                                                    variant="h5"
                                                                    style={{
                                                                        marginTop: '5px',
                                                                        marginBottom: '17px',
                                                                    }}
                                                                >
                                                                    Drop Summary Total
                                                                </Typography>
                                                                <Grid
                                                                    className={classes.row}
                                                                    style={{
                                                                        marginTop: '14px',
                                                                        marginBottom: '24px',
                                                                    }}
                                                                >
                                                                    <Typography style={{ width: '50%' }}>
                                                                        Non-Cash
                                                                    </Typography>
                                                                    <Typography
                                                                        style={{
                                                                            width: '30%',
                                                                            textAlign: 'right',
                                                                            color: '#4a5174',
                                                                        }}
                                                                    >
                                                                        ${' '}
                                                                        {parseFloat(summaryTotal.dropSummaryTotal.nonCash).toFixed(2)}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid
                                                                    className={classes.row}
                                                                    style={{
                                                                        marginTop: '14px',
                                                                        marginBottom: '24px',
                                                                    }}
                                                                >
                                                                    <Typography style={{ width: '50%' }}>
                                                                        Cash
                                                                    </Typography>
                                                                    <Typography
                                                                        style={{
                                                                            width: '30%',
                                                                            textAlign: 'right',
                                                                            color: '#4a5174',
                                                                        }}
                                                                    >
                                                                        ${' '}
                                                                        {parseFloat(summaryTotal.dropSummaryTotal.cash).toFixed(2)}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid
                                                                    className={classes.row}
                                                                    style={{
                                                                        marginTop: '14px',
                                                                        marginBottom: '28px',
                                                                    }}
                                                                >
                                                                    <Typography style={{ width: '50%' }}>
                                                                        Total
                                                                    </Typography>
                                                                    <Typography
                                                                        style={{
                                                                            width: '30%',
                                                                            textAlign: 'right',
                                                                            color: '#4a5174',
                                                                        }}
                                                                    >
                                                                        ${' '}
                                                                        {(parseFloat(summaryTotal.dropSummaryTotal.cash) + parseFloat(summaryTotal.dropSummaryTotal.nonCash)).toFixed(2)}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid style={{ width: '50%', float: 'right' }}>
                                                                <Typography
                                                                    variant="h5"
                                                                    style={{
                                                                        marginTop: '5px',
                                                                        marginBottom: '15px',
                                                                    }}
                                                                >
                                                                    Store Transaction Total
                                                                </Typography>
                                                                <Grid
                                                                    className={classes.row}
                                                                    style={{
                                                                        marginTop: '15px',
                                                                        marginBottom: '24px',
                                                                    }}
                                                                >
                                                                    <Typography style={{ width: '60%' }}>
                                                                        Non-Cash
                                                                    </Typography>
                                                                    <Typography
                                                                        style={{
                                                                            width: '28%',
                                                                            textAlign: 'right',
                                                                            color: '#4a5174',
                                                                        }}
                                                                    >
                                                                        ${' '}
                                                                        {summaryTotal && summaryTotal.storeTransactionTotal && summaryTotal.storeTransactionTotal.nonCash ? parseFloat(summaryTotal.storeTransactionTotal.nonCash).toFixed(2) : "0.00"}

                                                                    </Typography>
                                                                </Grid>
                                                                <Grid
                                                                    className={classes.row}
                                                                    style={{
                                                                        marginTop: '15px',
                                                                        marginBottom: '24px',
                                                                    }}
                                                                >
                                                                    <Typography style={{ width: '60%' }}>
                                                                        Cash
                                                                    </Typography>
                                                                    <Typography
                                                                        style={{
                                                                            width: '28%',
                                                                            textAlign: 'right',
                                                                            color: '#4a5174',
                                                                        }}
                                                                    >
                                                                        {/* $ {parseFloat(dropSummary[0].overOrShortCash) < 0 ? (parseFloat(dropSummary[0].overShortCash) + parseFloat(dropSummary[0].totalCash)).toFixed(2) : (parseFloat(dropSummary[0].totalCash) + parseFloat(dropSummary[0].overOrShortCash)).toFixed(2)} */}
                                                                        ${' '}
                                                                        {summaryTotal && summaryTotal.storeTransactionTotal && summaryTotal.storeTransactionTotal.cash ? parseFloat(summaryTotal.storeTransactionTotal.cash).toFixed(2) : '0.00'}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid
                                                                    className={classes.row}
                                                                    style={{
                                                                        marginTop: '17px',
                                                                        marginBottom: '40px',
                                                                    }}
                                                                >
                                                                    <Typography style={{ width: '60%' }}>
                                                                        Total
                                                                    </Typography>
                                                                    <Typography
                                                                        style={{
                                                                            width: '28%',
                                                                            textAlign: 'right',
                                                                            color: '#4a5174',
                                                                        }}
                                                                    >
                                                                        {/* $ {((parseFloat(dropSummary[0].totalNonCash) + (parseFloat(dropSummary[0].overOrShortNonCash))) + (parseFloat(dropSummary[0].totalCash) + parseFloat(dropSummary[0].overOrShortCash))).toFixed(2)} */}
                                                                        ${' '}
                                                                        {(parseFloat(summaryTotal.storeTransactionTotal.cash) + parseFloat(summaryTotal.storeTransactionTotal.nonCash)).toFixed(2)}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid
                                                        style={{
                                                            marginBottom: '8%',
                                                            border: 'none',
                                                            borderRadius: '4px',
                                                            width: '100%',
                                                            padding: '8px',
                                                            background: '#EFF4FF',
                                                            marginRight: '0px',
                                                        }}
                                                        className={`${classes.float} `}
                                                    >
                                                        <Grid
                                                            style={{ width: '80%' }}
                                                            className={classes.floatLeft}
                                                        >
                                                            <Typography
                                                                style={{
                                                                    color: '#2179FE',
                                                                    padding: '2px',
                                                                    //  paddingLeft: "6px",
                                                                    width: '100%',
                                                                }}
                                                            >
                                                                <Grid className={classes.row}>
                                                                    <Typography variant="h5">Over/Short</Typography>
                                                                    (Drop Summary-Store Transaction)
                                                                </Grid>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            style={{
                                                                // paddingLeft: "3.5%",
                                                                width: '20%',
                                                            }}
                                                            className={classes.floatLeft}
                                                        >
                                                            <Typography
                                                                variant="h5"
                                                                style={{
                                                                    color: '#2179FE',
                                                                    padding: '2px',
                                                                    width: '100%',
                                                                    textAlign: 'right',
                                                                }}
                                                            >
                                                                {addBracketForShort(parseFloat(summaryTotal.storeTransactionTotal.overShortCash), -Math.abs(parseFloat(summaryTotal.storeTransactionTotal.overShortNonCash)))}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    style={{ width: '40%', marginLeft: '0.5%', float: 'right' }}
                                >
                                    <Grid style={{ width: '100%', float: 'left' }}>
                                        {/*Heading SubContainer*/}
                                        <Grid style={{ width: '100%' }}>
                                            <Typography
                                                variant="h6"
                                                style={{
                                                    width: '100%',
                                                    fontFamily: 'OpenSans-bold',
                                                    marginBottom: '17px',
                                                }}
                                            >
                                                Drop Reconciliation Totals Summary
                                            </Typography>
                                            <Card
                                                className={classes.card}
                                                style={{ background: '#4f86f7' }}
                                            >
                                                <CardContent className={classes.cardBody}>
                                                    <Grid
                                                        className={classes.row}
                                                        style={{ marginTop: '6px', marginBottom: '15.5px' }}
                                                    >
                                                        <Typography style={{ width: '65%', color: 'white' }}>
                                                            Non-Cash
                                                        </Typography>
                                                        <Typography
                                                            style={{
                                                                width: '28%',
                                                                textAlign: 'right',
                                                                color: 'white',
                                                            }}
                                                        >
                                                            $ {parseFloat(dropAmount !== undefined && dropAmount !== null && dropAmount.nonCash !== undefined && dropAmount.nonCash !== null ?
                                                                dropAmount.nonCash : '0.00').toFixed(2)}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        className={classes.row}
                                                        style={{ marginTop: '6px', marginBottom: '16px' }}
                                                    >
                                                        <Typography style={{ width: '65%', color: 'white' }}>
                                                            Add-In Non-Cash
                                                        </Typography>
                                                        <Typography
                                                            style={{
                                                                width: '28%',
                                                                textAlign: 'right',
                                                                color: 'white',
                                                            }}
                                                        >
                                                            ${' '}
                                                            {/* {parseFloat(dropReconcilationDetails!==undefined && dropReconcilationDetails!== null &&
                                                    dropReconcilationDetails.dropSummary.overOrShortNonCash!==undefined && dropReconcilationDetails.dropSummary.overOrShortNonCash!== null?
                                                        dropReconcilationDetails.dropSummary.overOrShortNonCash:'') < 0
                                                        ? (-parseFloat(dropReconcilationDetails!==undefined && dropReconcilationDetails!== null &&
                                                            dropReconcilationDetails.dropSummary.overOrShortNonCash!==undefined && dropReconcilationDetails.dropSummary.overOrShortNonCash!== null?
                                                            dropReconcilationDetails.dropSummary.overOrShortNonCash:''
                                                        )).toFixed(2)
                                                        : '0.00'} */}
                                                            {(Number(summaryTotal.dropSummaryTotal.nonCash) - Number(dropAmount.nonCash)).toFixed(2)}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        className={classes.row}
                                                        style={{ marginTop: '6px', marginBottom: '17px' }}
                                                    >
                                                        <Typography style={{ width: '65%', color: 'white' }}>
                                                            Cash
                                                        </Typography>
                                                        <Typography
                                                            style={{
                                                                width: '28%',
                                                                textAlign: 'right',
                                                                color: 'white',
                                                            }}
                                                        >
                                                            $ {parseFloat(dropAmount !== undefined && dropAmount.cash !== undefined && dropAmount.cash !== null && dropAmount !== null ?
                                                                dropAmount.cash : '').toFixed(2)}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        className={classes.row}
                                                        style={{ marginTop: '6px', marginBottom: '20px' }}
                                                    >
                                                        <Typography style={{ width: '65%', color: 'white' }}>
                                                            Total
                                                        </Typography>
                                                        <Typography
                                                            style={{
                                                                width: '28%',
                                                                textAlign: 'right',
                                                                color: 'white',
                                                            }}
                                                        >
                                                            ${' '}
                                                            {(
                                                                parseFloat(dropAmount !== undefined && dropAmount.cash !== undefined && dropAmount.cash !== null && dropAmount !== null ?
                                                                    dropAmount.cash : '') +
                                                                parseFloat(dropAmount !== undefined && dropAmount.nonCash !== undefined && dropAmount.nonCash !== null && dropAmount !== null ?
                                                                    dropAmount.nonCash : '')
                                                            ).toFixed(2)}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        style={{
                                                            marginBottom: '8%',
                                                            marginTop: '12%',
                                                            display: 'flex',
                                                            border: 'none',
                                                            borderRadius: '6px',
                                                            width: '100%',
                                                            padding: '3px',
                                                            background: '#EFF4FF',
                                                            float: 'right',
                                                            backgroundColor: 'white',
                                                        }}
                                                        className={`${classes.float} `}
                                                    >
                                                        <Grid
                                                            className={classes.floatLeft}
                                                            style={{ width: '60%' }}
                                                        >
                                                            <Typography
                                                                style={{
                                                                    color: '#2179FE',
                                                                    padding: '5px',
                                                                    paddingLeft: '9px',
                                                                    width: '100%',
                                                                    marginTop: '2%',
                                                                    marginBottom: '2%',
                                                                }}
                                                            >
                                                                <span style={{ display: 'block' }}>
                                                                    <Typography
                                                                        variant="h5"
                                                                        style={{ paddingRight: '2px' }}
                                                                    >
                                                                        Over/Short
                                                                    </Typography>
                                                                </span>
                                                                (Drop Summary - store Transaction)
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            style={{
                                                                width: '40%',
                                                                alignSelf: 'center',
                                                                flexDirection: 'row-reverse',
                                                                textAlign: 'end',
                                                            }}
                                                            className={classes.floatLeft}
                                                        >
                                                            <Typography
                                                                variant="h5"
                                                                style={{
                                                                    color: '#2179FE',
                                                                    padding: '0px',
                                                                    float: 'right',
                                                                    paddingRight: '2%',
                                                                    width: '100%',
                                                                }}
                                                            >
                                                                {/* {parseFloat( dropAmount.cash!==undefined?
                                                            dropAmount.cash :'') +
                                                            parseFloat( dropAmount.nonCash!==undefined?
                                                                dropAmount.nonCash:'') >=
                                                            parseFloat(dropReconcilationDetails.dropSummary.totalNonCash!==undefined?
                                                                dropReconcilationDetails.dropSummary.totalNonCash:'') -
                                                            parseFloat(dropReconcilationDetails.dropSummary.overOrShortNonCash!==undefined?
                                                                dropReconcilationDetails.dropSummary.overOrShortNonCash:'') +
                                                            (parseFloat(dropReconcilationDetails.dropSummary.totalCash!==undefined?
                                                                dropReconcilationDetails.dropSummary.totalCash:'') -
                                                                parseFloat(dropReconcilationDetails.dropSummary.overOrShortCash!==undefined?
                                                                    dropReconcilationDetails.dropSummary.overOrShortCash:''))
                                                            ? `$ ${(-(
                                                                parseFloat(dropReconcilationDetails.dropSummary.totalNonCash!==undefined?
                                                                    dropReconcilationDetails.dropSummary.totalNonCash:'') -
                                                                parseFloat(dropReconcilationDetails.dropSummary.overOrShortNonCash!==undefined?
                                                                    dropReconcilationDetails.dropSummary.overOrShortNonCash:""
                                                                ) +
                                                                (parseFloat(dropReconcilationDetails.dropSummary.totalCash!==undefined?
                                                                    dropReconcilationDetails.dropSummary.totalCash:'') -
                                                                    parseFloat(dropReconcilationDetails.dropSummary.overOrShortCash!==undefined?
                                                                        dropReconcilationDetails.dropSummary.overOrShortCash:''
                                                                    )) -
                                                                (parseFloat(dropAmount.cash!==undefined?
                                                                    dropAmount.cash:'') +
                                                                    parseFloat(dropAmount.nonCash!==undefined?
                                                                        dropAmount.nonCash:''))
                                                            )).toFixed(2)}`
                                                            : `($ ${(
                                                                parseFloat(dropReconcilationDetails.dropSummary.totalNonCash!==undefined?
                                                                    dropReconcilationDetails.dropSummary.totalNonCash:'') -
                                                                parseFloat(dropReconcilationDetails.dropSummary.overOrShortNonCash!==undefined?
                                                                    dropReconcilationDetails.dropSummary.overOrShortNonCash:''
                                                                ) +
                                                                (parseFloat(dropReconcilationDetails.dropSummary.totalCash!==undefined?
                                                                    dropReconcilationDetails.dropSummary.totalCash:'') -
                                                                    parseFloat( dropReconcilationDetails.dropSummary.overOrShortCash!==undefined?
                                                                        dropReconcilationDetails.dropSummary.overOrShortCash:''
                                                                    )) -
                                                                (parseFloat(dropAmount.cash!==undefined ?
                                                                    dropAmount.cash:'') +
                                                                    parseFloat(dropAmount.nonCash!==undefined?
                                                                        dropAmount.nonCash:''))
                                                            ).toFixed(2)})`} */}
                                                                {((Number(Number(summaryTotal.storeTransactionTotal.cash).toFixed(2)) + Number(Number(summaryTotal.storeTransactionTotal.nonCash).toFixed(2))) - (Number(Number(dropAmount.cash)?.toFixed(2)) + Number(Number(dropAmount.nonCash).toFixed(2)))) > 0 ? `($${Math.abs(((Number(summaryTotal.storeTransactionTotal.cash) + Number(summaryTotal.storeTransactionTotal.nonCash)) - (Number(dropAmount.cash) + Number(dropAmount.nonCash)))).toFixed(2)})` : `$${(Math.abs((Number(summaryTotal.storeTransactionTotal.cash) + Number(summaryTotal.storeTransactionTotal.nonCash)) - (Number(dropAmount.cash) + Number(dropAmount.nonCash)))).toFixed(2)}`}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid style={{ width: '100%', float: 'left' }}>
                                {/* <NonCashDropSummary></NonCashDropSummary> */}
                                <Grid>
                                    <Typography
                                        variant="h5"
                                        style={{
                                            marginTop: '2%',
                                            marginBottom: '20px',
                                            width: '100%',
                                            fontSize: '18px',
                                            // fontFamily: "OpenSans-bold",
                                        }}
                                    >
                                        Non-Cash Drop Summary
                                    </Typography>
                                    <Card style={{ paddingBottom: '2%' }} className={classes.card}>
                                        <CardContent className={`${classes.cardBody} ${classes.p2}`}>
                                            <RACTable
                                                className={`${classes.table} ${classes.tableBorder} ${classes.mb0}`}
                                                renderTableHead={renderNonCashTableHead}
                                                renderTableContent={renderNonCashTableContent}
                                            />
                                            <Grid
                                                style={{
                                                    marginTop: '3%',
                                                    padding: '10px',
                                                    border: 'none',
                                                    borderRadius: '4px',
                                                    marginBottom: '10px',
                                                }}
                                                className={`${classes.float} ${classes.tableRow1}`}
                                            >
                                                <Grid className={classes.row}>
                                                    <Grid style={{ width: '33.3%' }}>
                                                        <Grid
                                                            className={classes.floatLeft}
                                                            style={{ width: '50%' }}
                                                        >
                                                            <Typography
                                                                style={{
                                                                    color: '#2179FE',
                                                                    padding: '1px',
                                                                    textAlign: 'right',
                                                                    //  paddingLeft: "2px",
                                                                    marginBottom: '6px',
                                                                    width: '100%',
                                                                }}
                                                            >
                                                                Expected Total:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            // style={{ width: "32%", paddingLeft: "6.8%" }}
                                                            className={classes.floatLeft}
                                                            style={{ width: '50%' }}
                                                        >
                                                            <Typography
                                                                style={{
                                                                    width: '100%',
                                                                    color: '#2179FE',
                                                                    padding: '1px',
                                                                    paddingLeft: '15px',
                                                                }}
                                                                variant="h5"
                                                            >
                                                                ${' '}
                                                                {Number(summaryTotal.dropSummaryTotal.nonCash).toFixed(2)}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid style={{ width: '30.3%' }}>
                                                        <Grid
                                                            className={classes.floatLeft}
                                                            style={{ width: '45%' }}
                                                        >
                                                            <Typography
                                                                style={{
                                                                    color: '#2179FE',
                                                                    padding: '1px',
                                                                    //  paddingLeft: "2px",
                                                                    marginBottom: '6px',
                                                                    width: '100%',
                                                                }}
                                                            >
                                                                Actual Total:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            // style={{ width: "32%", paddingLeft: "6.8%" }}
                                                            className={classes.floatLeft}
                                                            style={{ width: '55%' }}
                                                        >
                                                            <Typography
                                                                style={{
                                                                    width: '100%',
                                                                    color: '#2179FE',
                                                                    padding: '1px',
                                                                }}
                                                                variant="h5"
                                                            >
                                                                $ {parseFloat(dropAmount.nonCash).toFixed(2)}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid style={{ width: '36.3%' }}>
                                                        <Grid
                                                            className={classes.floatLeft}
                                                            style={{ width: '60%' }}
                                                        >
                                                            <Typography
                                                                style={{
                                                                    color: '#2179FE',
                                                                    padding: '1px',
                                                                    //  paddingLeft: "2px",
                                                                    marginBottom: '6px',
                                                                    width: '100%',
                                                                }}
                                                            >
                                                                Difference of Totals:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            // style={{ width: "32%", paddingLeft: "6.8%" }}
                                                            className={classes.floatLeft}
                                                            style={{ width: '40%' }}
                                                        >
                                                            <Typography
                                                                style={{
                                                                    paddingLeft: '2%',
                                                                    width: '100%',
                                                                    color: '#2179FE',
                                                                    padding: '1px',
                                                                }}
                                                                variant="h5"
                                                            >
                                                                {/* {dropReconcilationDetails.dropSummary
                                                            .totalNonCash == parseFloat(dropAmount!==undefined && dropAmount.nonCash!==undefined?
                                                                dropAmount.nonCash:'')
                                                            ? `$ ${( (dropReconcilationDetails.dropSummary
                                                                .totalNonCash!==undefined?
                                                                dropReconcilationDetails.dropSummary
                                                                    .totalNonCash :'') -
                                                                parseFloat(dropAmount!==undefined && dropAmount.nonCash!==undefined?
                                                                    dropAmount.nonCash:'')
                                                            ).toFixed(2)}`
                                                            : `($ ${((dropReconcilationDetails.dropSummary
                                                                .totalNonCash!==undefined?
                                                                dropReconcilationDetails.dropSummary
                                                                    .totalNonCash:'') -
                                                                parseFloat(dropAmount!==undefined && dropAmount.nonCash!==undefined?
                                                                    dropAmount.nonCash:'')
                                                            ).toFixed(2)})`} */}
                                                                {(Number(summaryTotal.dropSummaryTotal.nonCash) - Number(dropAmount.nonCash)) > 0 ? `($${(Number(summaryTotal.dropSummaryTotal.nonCash) - Number(dropAmount.nonCash)).toFixed(2)})` : `$${(Number(summaryTotal.dropSummaryTotal.nonCash) - Number(dropAmount.nonCash)).toFixed(2)}`}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {/* <Grid
                        style={{
                          marginTop: "3%", border: "none", borderRadius: "4px", marginBottom: "10px",
                          width: "98%",
                          padding: "5px",
                          background: "#EFF4FF",
                          marginRight: "17px",
                          // marginLeft: "7px",
                          // marginBottom:"1px"
                        }}
                        className={`${classes.float} `}
                      >
                        <Grid className={classes.row}>
                          <Grid style={{ width: "33.3%" }}>
                            <Grid className={classes.floatLeft} style={{ width: "50%" }}>
                              <Typography style={{
                                color: "#2179FE",
                                padding: "1px",
                                textAlign: "right",
                                //  paddingLeft: "2px",
                                marginBottom: "6px",
                                width: "100%"
                              }}>
                                Expected Total:
                              </Typography>
                            </Grid>
                            <Grid
                              // style={{ width: "32%", paddingLeft: "6.8%" }}
                              className={classes.floatLeft}
                              style={{ width: "50%" }}
                            >
                              <Typography
                                style={{
                                  width: "100%", color: "#2179FE",
                                  padding: "1px", paddingLeft: "15px"
                                }}
                                variant="h5"
                              >
                                $ {(dropReconcilationDetails.dropSummary[0].totalNonCash)}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid style={{ width: "30.3%" }}>
                            <Grid className={classes.floatLeft} style={{ width: "45%" }}>
                              <Typography style={{
                                color: "#2179FE",
                                padding: "1px",
                                //  paddingLeft: "2px",
                                marginBottom: "6px",
                                width: "100%"
                              }}>
                                Actual Total:
                              </Typography>
                            </Grid>
                            <Grid
                              // style={{ width: "32%", paddingLeft: "6.8%" }}
                              className={classes.floatLeft}
                              style={{ width: "55%" }}
                            >
                              <Typography
                                style={{
                                  width: "100%", color: "#2179FE",
                                  padding: "1px",
                                }}
                                variant="h5"
                              >
                                $ {parseFloat(dropAmount.nonCash).toFixed(2)}
                                
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid style={{ width: "36.3%" }}>
                            <Grid className={classes.floatLeft} style={{ width: "60%" }}>
                              <Typography style={{
                                color: "#2179FE",
                                padding: "1px",
                                //  paddingLeft: "2px",
                                marginBottom: "6px",
                                width: "100%"
                              }}>
                                Difference of Totals:
                              </Typography>
                            </Grid>
                            <Grid
                              // style={{ width: "32%", paddingLeft: "6.8%" }}
                              className={classes.floatLeft}
                              style={{ width: "40%" }}
                            >
                              <Typography
                                style={{
                                  paddingLeft: "2%", width: "100%", color: "#2179FE",
                                  padding: "1px",
                                }}
                                variant='h5'
                              >
                             {(dropReconcilationDetails.dropSummary[0].totalNonCash) == parseFloat(dropAmount.nonCash) ? `$ ${((dropReconcilationDetails.dropSummary[0].totalNonCash) - (parseFloat(dropAmount.nonCash))).toFixed(2)}` : `($ ${((dropReconcilationDetails.dropSummary[0].totalNonCash) - (parseFloat(dropAmount.nonCash))).toFixed(2)})`}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid> */}
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                            <Grid style={{ width: '100%' }}>
                                <Grid style={{ width: '49%', marginRight: '1%', float: 'left' }}>
                                    <Grid
                                        className={classes.mt3}
                                        item
                                        style={{
                                            float: 'right',
                                            width: '100%',
                                            //  marginLeft: "1.5%",
                                            paddingBottom: '20px',
                                        }}
                                    >
                                        <Typography
                                            variant="h5"
                                            className={classes.header}
                                            style={{ marginTop: '15px' }}
                                        >
                                            Over / Short Notes{' '}
                                            {/* {parseFloat(dropReconcilationDetails.dropSummary.totalCash) +
                                        parseFloat(dropReconcilationDetails.dropSummary.totalNonCash) -
                                        (parseFloat(dropReconcilationDetails.dropSummary.totalNonCash) +
                                            parseFloat(dropReconcilationDetails.dropSummary.overOrShortNonCash) +
                                            (parseFloat(dropReconcilationDetails.dropSummary.totalCash) +
                                                parseFloat(dropReconcilationDetails.dropSummary.overOrShortCash))) !==
                                        0 ? (
                                        <span style={{ color: 'red' }}>*</span>
                                    ) : null} */}
                                            {(((Number(summaryTotal.storeTransactionTotal.cash) + Number(summaryTotal.storeTransactionTotal.nonCash)) - (Number(dropAmount.cash) + Number(dropAmount.nonCash))) != 0) ? <span style={{ color: 'red' }}>*</span> : null}
                                            {/* <span style={{ color: 'red' }}>*</span> */}
                                        </Typography>
                                        <textarea
                                            style={{
                                                marginTop: '5px',
                                                width: '100%',
                                                padding: '25px',
                                                paddingBottom: '31px',
                                                resize: 'none',
                                                border: '1px solid #C4C4C4',
                                                paddingTop: '5px',
                                                paddingRight: '5px',
                                                // marginRight:"4px",
                                                // paddingLeft:"20px",
                                                borderRadius: '0.6rem',
                                                backgroundColor: '#fff',
                                                transition:
                                                    'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                                                fontSize: '14px',
                                                fontFamily: 'OpenSans-semibold',
                                            }}
                                            value={overshortDropNotes.overShort}
                                            onChange={(e) => handleOverShortNotes(e)}
                                            disabled={historyData?.mode == 'VIEW' ? true : false}
                                            rows={3}
                                            cols={54}
                                            className={classes.dropNotes}
                                        ></textarea>
                                    </Grid>
                                </Grid>
                                <Grid style={{ width: '49%', marginLeft: '1%', float: 'right' }}>
                                    <Grid
                                        className={classes.mt3}
                                        item
                                        style={{
                                            float: 'right',
                                            width: '100%',
                                            //  marginLeft: "1.5%",
                                            paddingBottom: '20px',
                                        }}
                                    >
                                        <Typography
                                            variant="h5"
                                            className={classes.header}
                                            style={{ marginTop: '15px' }}
                                        >
                                            Drop Notes <span style={{ color: 'red' }}> *</span>
                                        </Typography>
                                        <textarea
                                            style={{
                                                marginTop: '5px',
                                                width: '100%',
                                                padding: '25px',
                                                paddingBottom: '31px',
                                                resize: 'none',
                                                border: '1px solid #C4C4C4',
                                                paddingTop: '5px',
                                                paddingRight: '5px',
                                                // marginRight:"4px",
                                                // paddingLeft:"20px",
                                                borderRadius: '0.6rem',
                                                backgroundColor: '#fff',
                                                transition:
                                                    'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                                                fontSize: '14px',
                                                fontFamily: 'OpenSans-semibold',
                                            }}
                                            rows={3}
                                            cols={54}
                                            disabled={historyData?.mode == 'VIEW' ? true : false}
                                            value={overshortDropNotes.dropNotes}
                                            className={classes.dropNotes}
                                            onChange={(e) => handleDropNotes(e)}
                                        ></textarea>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>

                </Grid>
                <Grid container classes={{
                    root: clsx(classes.customerToolbar, classes.fixedBottom),
                }}
                    style={{ zIndex: 0 }}>
                    <Grid className={classes.footerFixed}>
                        <Grid className={classes.floatLeft}>
                            <RACButton
                                variant="outlined"
                                color="secondary"
                                onClick={() => history.push({ pathname: `/cashmgmt/reconcil/summary`, state:isReconciled /* || historyData?.mode === 'RE-VALIDATE' || historyData?.state =='VIEW VALIDATE' */? 'RE-VALIDATED':  'REDIRECT' })}
                            >
                                Cancel
                            </RACButton>
                        </Grid>

                        <Grid style={{ float: 'right', cursor: 'pointer' }}>
                            <RACButton
                                variant="contained"
                                color="primary"
                                style={{ cursor: 'pointer' }}
                                disabled={historyData?.mode == 'VIEW' ? true : loader}
                                onClick={() => handleValidateBtn()}
                            >
                               { historyData?.mode === 'RE-VALIDATE'? 'Re-validate':'Validated' } 
                            </RACButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </>
    )
}
