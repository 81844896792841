/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
import { client, clientCall, defaultClient } from './client';
import { appConfig } from '../config/app-config';
import { CancelToken } from 'axios';
import { getJWToken } from './client'
import { ProductPermissions } from '../constants/constants';
// import { User } from '../types/types';
import jwtDecode from 'jwt-decode';

const storemgmtDevUrl = appConfig.apiUrls.storemgmt;
const customerDevUrl = appConfig.apiUrls.customer;
const containerUrl = appConfig.apiUrls.container;
const agreementUrl = appConfig.apiUrls.agreement


// export const getCurrentUser = (): Promise<User> =>
//   defaultClient(
//     'users/current',
//     { method: 'GET' },
//     'https://dev-racpad.rentacenter.com/api'
//   );

export const getUserPreferences = (productType: string) =>
  defaultClient(
    `users/current/preferences/${productType}`,
    { method: 'GET' },
    containerUrl
  );

export const getCurrentUser = async () => {
  return await defaultClient('users/current', { method: 'GET' }, containerUrl, true);
};

export const getUserPermissions = (
  productType: string
): Promise<ProductPermissions[]> =>
  client(
    `users/current/permissions/${productType}`,
    { method: 'GET' },
    containerUrl
  );
export const PrintFaxSheet = async (storeNumber: any) => {
  try {
    return await client(
      `customer/print-fax-sheet/${storeNumber}`,
      { method: 'GET' },
      customerDevUrl
    );
  } catch (e: any) {
    return null;
  }
};
export const setUserPreferences = (
  productType: string,
  payload: Record<string, any>,
  cancelToken?: CancelToken
) =>
  client(
    `users/current/preferences/${productType}`,
    {
      method: 'PATCH',
      body: [payload],
      cancelToken,
    },
    containerUrl
  );

export const getUserStores = () =>
  client(
    'users/current/stores?q=permissions[:includes]=RACPAD_DAP',
    { method: 'GET' },
    containerUrl
  );
export const GetDropDetails = async (payload: any) => {
  const responseObj: any = {
    getDrawerDetail: '',
    getCoWorkerDetails: '',
    getConfigDetails:'',
  };
  try {
    await Promise.all([
      // GetCustomerInfoOptions(getDrawerDetail),
      // GetCustomerByIdInfo(customerId, storeNumber),
      getDrawerDetail(payload),
      getCoWorkerDetails(payload),
      getConfigDetails(payload),
    ])
      .then((response) => {
        [responseObj.getDrawerDetail, responseObj.getCoWorkerDetails ,responseObj.getConfigDetails ] =
          response;
      })
      .catch((resp) => {
        // eslint-disable-next-line no-console
        console.log('responseObj', resp);
      });
    return responseObj;
  } catch (e: any) {
    return responseObj;
  }
};
export const getConfigDetails = async (payload: any) => {
  try {
    const TempAccessToken = 'QAToken';
    return await client(
      `store-details`,
      { method: 'POST', body: payload },
      storemgmtDevUrl,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
export const getDrawerDetail = async (payload: any) => {
  /* eslint-disable no-console */
  /* eslint-enable no-console */
  /* eslint-enable no-console */
  try {
    const TempAccessToken = 'QAToken';
    return await client(
      `manage/reconcil`,
      { method: 'POST', body: payload },
      appConfig.apiUrls.micro,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};

export const manageDrawerAudit = async (payload: any) => {
  /* eslint-disable no-console */
  /* eslint-enable no-console */
  /* eslint-enable no-console */
  try {
    const TempAccessToken = 'QAToken';
    return await client(
      `cashmgmt/envelopedetails/manage`,
      { method: 'POST', body: payload },
      appConfig.apiUrls.cashmgmt,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
export const manageDrop = async (payload: any) => {
  /* eslint-disable no-console */
  /* eslint-enable no-console */
  /* eslint-enable no-console */
  try {
    const TempAccessToken = 'QAToken';
    return await clientCall(
      `cashmgmt/envelope/manage`,
      { method: 'POST', body: payload },
      appConfig.apiUrls.micro,
      TempAccessToken
    );
  } catch (e: any) {
    return e;
  }
};
export const getCoWorkerDetails = async (payload: any) => {
  /* eslint-disable no-console */
  /* eslint-enable no-console */
  /* eslint-enable no-console */
  try {
    const TempAccessToken = 'QAToken';
    return await client(
      `config/store/${payload.storeNumber}/salesperson`,
      { method: 'GET' },
      appConfig.apiUrls.cashmgmt,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
export const manageReconciliation = async (payload: any) => {
  /* eslint-disable no-console */
  /* eslint-enable no-console */
  /* eslint-enable no-console */
  try {
    const TempAccessToken = 'QAToken';
    return await client(
      `manage/transaction`,
      { method: 'POST', body: payload },
      appConfig.apiUrls.cashmgmt,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
export const getDropSummaryDetail = async (payload: any) => {
  /* eslint-disable no-console */
  /* eslint-enable no-console */
  /* eslint-enable no-console */
  try {
    const TempAccessToken = 'QAToken';
    return await client(
      `cashManagement/finalDropPageload`,
      { method: 'GET', body: payload },
      appConfig.apiUrls.cashmgmt,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};

export const getDropDocument = async (payload: any) => {
  /* eslint-disable no-console */
  /* eslint-enable no-console */
  /* eslint-enable no-console */
  try {
    const TempAccessToken = 'QAToken';
    return await client(
      `drop/document`,
      { method: 'POST', body: payload },
      appConfig.apiUrls.cashmgmt,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
// export const getCurrentInfo = async () => {
//   try {
//     return await clientCall(
//       `users/current`,
//       { method: 'GET' },
//       containerUrl,
//       'kjkjhkj',
//       true
//     );
//   } catch (e: any) {
//     return null;
//   }
// };
export const getPinDetails = async (Payload: any) => {
  try {
    return await clientCall(
      `coworker`,
      { method: 'POST', body: Payload },
      storemgmtDevUrl,
      'kjiokljlk',
      true
    );
  } catch (e: any) {
    return null;
  }
};
export const getprofileaccess = async (Payload: any) => {
  try {
    return await clientCall(
      `store-access`,
      { method: 'POST', body: Payload },
      storemgmtDevUrl,
      'kjhhjkhkj',
      true
    );
  } catch (e: any) {
    return null;
  }
};
export const getsecondfactor = async (Payload: any) => {
  try {
    return await clientCall(
      `coworker-authentication`,
      { method: 'POST', body: Payload },
      storemgmtDevUrl,
      'kjhjk',
      true
    );
  } catch (e: any) {
    return null;
  }
};
export const GetRemoteLoginStatus = async (Payload: any) => {
  return await clientCall(
    `cashmgmt/duo/auth`,
    { method: 'POST', body: Payload },
    appConfig.apiUrls.micro,
    'kjjkjilk',
    true
  );
};

//Need to change thisssssssssssssssssssss

//Need to change thisssssssssssssssssssss

//Need to change thisssssssssssssssssssss
//Need to change thisssssssssssssssssssss

//Need to change thisssssssssssssssssssss

//Need to change thisssssssssssssssssssss

export const getmenuandstore = async () => {
  try {
    const jwtToken = await getJWToken();
    const decodedToken: any = jwtDecode(jwtToken);
    // eslint-disable-next-line no-console
    console.log('decodedToken', decodedToken ,'appConfig.apiUrls',appConfig.apiUrls);
    const parameters = {
      coworkerId: decodedToken['custom:employeeId'],
    };
    return await client(
      'menu-and-stores',
      { method: 'POST', body: parameters },
      appConfig.apiUrls.menu
    );
  } catch (e: any) {
    return null;
  }
};
export const ManageTransaction = async (payload: any) => {
  /* eslint-disable no-console */
  /* eslint-enable no-console */
  /* eslint-enable no-console */
  try {
    const TempAccessToken = 'QAToken';
    return await client(
      `managetransaction`,
      { method: 'POST', body: payload },
      appConfig.apiUrls.cashmgmt,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
export const getSwitchOutAgreementOptions = async (StoreNumber: any) => {
  // eslint-disable-next-line no-console
  console.log('appConfig agreement', appConfig.apiUrls.agreement);
  return await client(
    `agreement/options/12345?storeNumber=${StoreNumber}&isSwitchOut=1`,
    { method: "GET" },
    agreementUrl
  );
};
const GetTransactionType = async (param: string) => {
  try {
    return await client(
      `reference?referenceName=${param}`,
      { method: 'GET' },
      appConfig.apiUrls.cashmgmt
    );
  } catch (e: any) {
    return null;
  }
};
export const storeConfigCall = async (payload: any) => {
  // eslint-disable-next-line no-console
  console.log('appConfig storemgmt', appConfig.apiUrls.storemgmt);
  // eslint-disable-next-line no-console
  console.log('appConfig agreement', appConfig.apiUrls.agreement);

  try {
    return await client(
      `store-details`,
      { method: 'POST', body: payload },
      storemgmtDevUrl
    );
  } catch (e: any) {
    return null;
  }
};
export const GetIntitailDataForManageTransaction = async (
  storeConfigPayload: any
) => {
  const responseObj: any = {
    pettyCashRes: '',
    receiptItemCategory: '',
    configValues: '',
  };
  try {
    await Promise.all([
      GetTransactionType("entity/petty_cash_type"),
      GetTransactionType("receipt_item_category"),
      storeConfigCall(storeConfigPayload),
    ])
      .then((response) => {
        [
          responseObj.pettyCashRes,
          responseObj.receiptItemCategory,
          responseObj.configValues,
        ] = response;
      })
      .catch((resp) => {
        // eslint-disable-next-line no-console
        console.log("responseObj", resp);
      });
      
    return responseObj;
  } catch (e: any) {
    return responseObj;
  }
};
export const GetIntitailDataForManageTransactionPaidOut = async (
  storeNumber: any,
  storeConfigPayload: any
) => {
  const responseObj: any = {
    pettyCashRes: '',
    receiptItemCategory: '',
    coworkerValues: '',
    configValues: '',
  };
  try {
    await Promise.all([
      GetTransactionType("entity/petty_cash_type"),
      GetTransactionType("receipt_item_category"),
      getSwitchOutAgreementOptions(storeNumber),
      storeConfigCall(storeConfigPayload),
    ])
      .then((response) => {
        [
          responseObj.pettyCashRes,
          responseObj.receiptItemCategory,
          responseObj.coworkerValues,
          responseObj.configValues,
        ] = response;
      })
      .catch((resp) => {
        // eslint-disable-next-line no-console
        console.log("responseObj", resp);
      });

    return responseObj;
  } catch (e: any) {
    return responseObj;
  }
};
export const storeException = async (Payload: any) => {
  try {
    return await clientCall(
      `agreement/store/exception`,
      { method: "POST", body: Payload },
      agreementUrl,
      "kjhhjkhkj",
      true
    );
  } catch (e: any) {
    return null;
  }
};

GetRemoteLoginStatus;
