/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/prefer-immediate-return */
import { makeStyles } from "@material-ui/core";
export const customerStyles = () => {
  const UseClasses = makeStyles((theme) => ({
    racFixedFooterMargin: {
      marginBottom: "120px",
    },
    fixedBottom: {
      position: "fixed",
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1030,
      backgroundColor: `white`,
      boxShadow: "0 0.5rem 1rem rgb(0 0 0 / 15%)",
      padding: "1rem",
    },
    customerToolbar: {
      position: "fixed",
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1030,
      backgroundColor: `white`,
      boxShadow: "0 0.5rem 1rem rgb(0 0 0 / 15%)",
      paddingLeft: theme.typography.pxToRem(16),
      paddingRight: theme.typography.pxToRem(16),
      paddingTop: theme.typography.pxToRem(16),
      paddingBottom: theme.typography.pxToRem(16),
    },
    container: {
      width: "100%",
      paddingRight: "0.75rem",
      paddingLeft: "0.75rem",
      marginRight: "auto",
      marginLeft: "auto",
      marginTop: "60px",
      paddingBottom: "80px !important",
    },
    printpopup: {
      width: "100%",
    },
    anchorview: {
      marginTop: "0.1%",
    },
    popupforprint: {
      padding: "3%",
      marginBottom: "25%",
    },
    mt4: {
      marginTop: theme.typography.pxToRem(24),
    },
    w100: {
      width: "100%",
    },
    my3: {
      marginTop: theme.typography.pxToRem(16),
      marginBottom: theme.typography.pxToRem(16),
    },
    colMd12mt3: {
      flex: "0 0 auto",
      width: "100%",
      marginTop: "1rem !important",
    },
    row: {
      display: "flex",
      flexWrap: "wrap",
      marginTop: "0",
      marginRight: "-0.75rem",
    },
    header: {
      width: "100%",
      fontSize: "18px",
      fontFamily: "OpenSans-bold",
      marginBottom: "15px",
    },
    loaderStyle: {
      border: "none",
      backgroundColor: "transparent",
      // userFocus: 'none',
      // MozUserFocus: 'none',
      outline: "none",
    },
    RACLoaderPage: {
      textAlign: "center",
      marginTop: "250px",
      fontSize: "16px",
      marginLeft: "30px",
      marginRight: "30px",
      outline: "none",
    },
    card: {
      border: "none",
      borderRadius: "1rem",
      boxShadow: "0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important",
    },
    cardBody: {
      flex: "1 1 auto",
      padding: "1rem 1rem",
      paddingBottom: "10px !important",
    },
    table: {
      width: "100%",
      color: "#212529",
      verticalAlign: "top",
      borderColor: "#dee2e6",
    },
    tableBorder: {
      captionSide: "bottom",
      borderCollapse: "collapse",
    },
    receipt: {
      width: "15%",
    },
    amount: {
      width: "15%",
    },
    amountDD: {
      width: "14%",
    },
    nameOnDoc: {
      width: "17%",
    },
    mb0: {
      marginBottom: "0 !important",
    },
    DateofDrop: {
      width: "40%",
      marginRight: "1%",
    },
    Drops: {
      width: "30%",
      // marginRight:"1%",
      paddingLeft: "8%",
    },
    Action: {
      width: "20%",
      marginRight: "14%",
      float: "right",
    },
    tableRow: {
      background: "none",
      width: "50%",
    },
    float: {
      float: "right",
    },
    tableRow2: {
      width: "38%",
      padding: "5px",
      background: "#EFF4FF",
      marginRight: "30px",
    },
    floatLeft: {
      float: "left",
    },
    dropNotes: {
      "&:focus": {
        color: "#212529",
        backgroundColor: "#fff",
        borderColor: "#86b7fe",
        outline: "0",
        boxShadow: "0 0 0 0.25rem rgb(13 110 253 / 25%)",
      },
    },
    footerGrid: {
      paddingTop: "120px !important",
      marginRight: "-0.75rem",
      marginLeft: "-0.75rem",
      display: "flex",
      flexWrap: "wrap",
    },
    footerFixed: {
      backgroundColor: "#fff !important",
      padding: "1rem !important",
      boxShadow: "0 0.5rem 1rem rgb(0 0 0 / 15%) !important",
      position: "fixed",
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1030,
    },
    mt3: {
      marginTop: "1rem !important",
    },
    quantity: {
      width: "85%",
      "& input": {
        height: "23px",
      },
    },
    RACbutton: {
      color: "#0d6efd",
      borderColor: "#0d6efd",
      display: "inline-block",
      fontWeight: 400,
      lineHeight: "1.5",
      textAlign: "center",
      textDecoration: "none",
      verticalAlign: "middle",
      userSelect: "none",
      backgroundColor: "transparent",
      border: "1px solid transparent",
      padding: "0.375rem 0.75rem",
      fontSize: "1rem",
      borderRadius: "0.25rem",
      width: "56%",
      transition:
        "color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
      "&:hover": {
        color: "#fff",
        backgroundColor: "#0d6efd",
        borderColor: "#0d6efd",
      },
      // border: "none",
      // borderRadius: "1rem",
      boxShadow: "0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important",
    },
    textWidth: {
      marginLeft: "-7%",
      width: "70%",
      float: "right",
    },
    textwidthbutton: {
      // marginLeft:"-7%",
      width: "80%",
      float: "left",
    },

    cardBody2: {
      flex: "1 1 auto",
      padding: "1rem 1rem",
      paddingBottom: "10px !important",
      marginLeft: "15px",
    },
    p2: {
      padding: "0.5rem !important",
    },
    mb3: {
      marginBottom: "1rem !important",
    },
    racColMD12: {
      flex: "0 0 auto",
      width: "100%",
    },
    tableRow1: {
      width: "96%",
      padding: "5px",
      background: "#EFF4FF",
      marginRight: "17px",
      marginLeft: "7px",
      // marginBottom:"1px"
    },
    breadcrumb: {
      display: "flex",
      flexWrap: "wrap",
      padding: 0,
      marginBottom: theme.typography.pxToRem(1),
      listStyle: "none",
    },
    breadcrumbItem: {
      width: "auto",
      fontFamily: "OpenSans-semibold",
      fontSize: "14px",
    },
    breadcrumbItemActive: {
      width: "auto",
      fontFamily: "OpenSans-semibold",
      fontSize: "12px",
      // color: '#3182FF',
      paddingLeft: "3px",
      paddingRight: 0,
    },
    breadCrumbMarg: {
      // marginLeft: '20px',
    },
    breadcrumbArrow: {
      "&:before": {
        content: '""',
        width: 0,
        height: 0,
        borderTop: "5px solid transparent",
        borderLeft: "7px solid #707070",
        borderBottom: "5px solid transparent",
        marginTop: 0,
        float: "left",
        // paddingRight: '13px',
        color: "#6c757d",
        paddingLeft: 0,
      },
    },
    py1: {
      paddingTop: theme.typography.pxToRem(4),
      paddingBottom: theme.typography.pxToRem(0),
    },
    racpadLinkCustomer: {
      fontFamily: "OpenSans-bold",
      fontSize: "14px",
      color: "#2179FE",
      textDecoration: "none",
    },
    paddingBreadCrumb: {},
    explanationContent: {
      justifyContent: "right",
    },
    overShort: {
      width: "100%",
      "& input": {
        height: "23px",
      },
      border: "null",
      borderRadius: "5px",
      // marginRight:"2%"
    },
    scrollbar: {
      overflow: "auto",

      height: "250px",

      width: "100%",
    },
    denominationDR: {
      width: "30%",
      paddingRight: "5%",
    },
    QuantityDR: {
      width: "35%",
    },
    totalDR: {
      width: "42%",
    },
    formValue: {
      fontFamily: "OpenSans-semibold",
      fontSize: "14px",
      color: "#656565",
    },
    drawerTotal: {
      color: "#2179FE",
      padding: "4px",
      paddingLeft: "24px",
    },
    textCenter: {
      textAlign: "center",
    },
    RACPOPMsg: {
      fontFamily: "OpenSans-bold",
      fontSize: theme.typography.pxToRem(16),
      letterSpacing: 0,
    },
    marginTop: {
      marginTop: "-1rem",
    },
    justifyContentCenter: {
      justifyContent: "center",
    },
    spacerMT4: {
      marginTop: theme.typography.pxToRem(24),
    },
    spacerMR2: {
      marginRight: theme.typography.pxToRem(8),
    },
    foc: {
      "&:focus": {
        borderColor: "#80bdff",
        boxShadow: "1px 1px 6px 3px #80bdff",
      },
    },
    me2: {
      marginRight: theme.typography.pxToRem(8),
    },
  }));

  const classes = UseClasses();
  return classes;
};
