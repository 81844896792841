import React, { createContext } from 'react';
import { Router } from 'react-router';
import {
  RACThemeProvider,
  makeStyles,
  createGenerateClassName,
  StylesProvider,
} from '@rentacenter/racstrap';
import clsx from 'clsx';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const History = require('history');
import { ErrorBoundary } from '../components/ErrorBoundary/ErrorBoundary';
import { InContainerAuthProvider } from '../context/auth/InContainerAuthProvider';
import { StandaloneAuthProvider } from '../context/auth/StandaloneAuthProvider';
import { AuthGuard } from '../components/AuthGuard/AuthGuard';
import { CustomPropInterface } from '../../src/index';
import { CashmanagementProvider } from '../context/CashManagementContext';
import { CashmgmmtProvider } from '../context/CashMgmtContext';

interface Props {
  history: typeof History;
  customProps?: CustomPropInterface;
}

export const appTestId = 'appTestId';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    paddingLeft: '0.75rem',
    paddingRight: '0.75rem',
  },
}));
// added production prefix for jss
const generateClassName = createGenerateClassName({
  //disableGlobal: true,
  //productionPrefix: 'racprodprefix',
  seed: 'RAC-CASHMANAGEMENT-',
});

export const ContainerContext = createContext<CustomPropInterface | undefined>(
  undefined
);

export const App = ({ history, customProps }: Props) => {
  // const enableLogs = window.sessionStorage.getItem('enableLogs');

  // if (enableLogs != '1') {
  //   // eslint-disable-next-line @typescript-eslint/no-empty-function, no-console
  //   console.log = function () {};
  // }
  const { isRenderedByContainer } = window;
  const classes = useStyles();
  // eslint-disable-next-line no-console
  console.log('cashmgmt repo....');
  return (
    <div data-testid={appTestId}>
      <StylesProvider generateClassName={generateClassName}>
        <RACThemeProvider>
          <CashmgmmtProvider>
            <CashmanagementProvider>
              <ContainerContext.Provider value={customProps}>
                <div className={clsx(classes.container)}>
                  <ErrorBoundary>
                    <Router history={history}>
                      {isRenderedByContainer ? (
                        <InContainerAuthProvider>
                          <AuthGuard />
                        </InContainerAuthProvider>
                      ) : (
                        <StandaloneAuthProvider>
                          <AuthGuard />
                        </StandaloneAuthProvider>
                      )}
                    </Router>
                  </ErrorBoundary>
                </div>
              </ContainerContext.Provider>
            </CashmanagementProvider>
          </CashmgmmtProvider>
        </RACThemeProvider>
      </StylesProvider>
    </div>
  );
};
