/* eslint-disable prettier/prettier */
// eslint-disable-next-line prettier/prettier
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable prettier/prettier */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { createContext, ReactChild, ReactFragment, ReactPortal, useState } from 'react'

export const CashmgmtContext = createContext<any>('')


export const CashmanagementProvider = (props:any) => {

    const [data, setData] = useState('hello');
    const [totalCashBeingDroped, settotalCashBeingDroped] = useState(0);
    const [dropReconciliationData,setDropReconciliationData]=useState({});
    const [isReconciled,setIsRecociled] = useState(false);

    return (
    <CashmgmtContext.Provider 

    value={{ data,
     setData, 
     settotalCashBeingDroped,
      totalCashBeingDroped,
      dropReconciliationData,
      setDropReconciliationData,isReconciled,setIsRecociled}}>{props.children}
    
    </CashmgmtContext.Provider>
    )
}