/* eslint-disable no-debugger */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-identical-functions */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  RACTextbox,
  RACSelect,
  RACButton,
  RACTable,
  RACTableCell,
  RACTableRow,
  RACCheckBox,
  RACModalCard,
  CircularProgress,
} from '@rentacenter/racstrap';
import { customerStyles } from '../customerStyles';
// import {CurrentTill} from './dropCash';
// import { CurrentTill } from "./CurrentTill";
// import { NonCashComponent } from './NonCashBeingDropped'
// import { DropInformation } from './DropInformation'
import {
  getDrawerDetail,
  manageDrop,
  getDropDocument,
  getConfigDetails,
  getSwitchOutAgreementOptions,
  getmenuandstore
} from '../../api/user';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Modal from 'react-modal';
import Skeleton from 'react-loading-skeleton';
import { ReactComponent as SuccessIcon } from '../../assets/images/success-icon.svg';
import { ReactComponent as Alerticon } from '../../assets/images/no-records-found.svg';
import 'react-loading-skeleton/dist/skeleton.css';
// import { drawerTotal, dropStrap } from '../constants/sampleConstants';
import SecondFactor from '../SecondAuthen/secondAuthen';
import { BindPDF } from './TodaysDrop';
import { formatMoney } from "../../constants/constants";
// import id from 'date-fns/esm/locale/id/index.js';
export interface denominationQuantityInterface {
  denominationQuantity100: string;
  denominationQuantity50: string;
  denominationQuantity20: string;
  denominationQuantity10: string;
  denominationQuantity5: string;
  denominationQuantity2: string;
  denominationQuantity1: string;
}
export function Drop() {
  // const storeTransaction = {
  //   totalNonCash: '300.45',
  //   totalCash: '300.00',
  //   creditCardTotal: '10.00',
  // };
  // const NonCashDetails: any = [
  //   {
  //     tenderType: 'Check',
  //     vendorName: 'David Vignesh Vignesh',
  //     documentNumber: '54536269',
  //     documentWrittenBy: 'David Vignesdh',
  //     reciptNumber: '9975445145645',
  //     amount: '100.00',
  //     isChecked: false,
  //   },
  //   {
  //     tenderType: 'Money Order',
  //     vendorName: 'David Vigsnesh',
  //     documentNumber: '54536269',
  //     documentWrittenBy: 'David Vifgnesh',
  //     reciptNumber: '9975445145646',
  //     amount: '100.00',
  //     isChecked: false,
  //   },
  //   {
  //     tenderType: 'Check',
  //     vendorName: 'David Vignesh',
  //     documentNumber: '54536269',
  //     documentWrittenBy: 'David Vignesh',
  //     reciptNumber: '9975445145647',
  //     amount: '100.00',
  //     isChecked: false,
  //   },
  // ];
  // const dropStrap = [
  //   {
  //     label: 'Store 89110, Co-worker4',
  //     value: '0',
  //   },
  //   {
  //     label: 'One',
  //     value: 'One',
  //   },
  //   {
  //     label: 'Two',
  //     value: 'Two',
  //   },
  //   {
  //     label: 'Three',
  //     value: 'Three',
  //   },
  // ];
  const [loader, setLoader] = useState(false);
  const [defaultLoader , setDefaultLoader] = useState(false);
  const classes = customerStyles();
  const history = useHistory();
  const [cancelBtn, setCancelBtn] = useState(false);
  const [envNumber, setEnvNumber] = useState('');
  const [dropNotes, setDropNotes] = useState('');
  const [secondLevelAuthenOn, setSecondLevelAuthenOn] = useState(false);
  const [drawerDetails, setDrawerDetails] = useState<any>();
  const [dropAmount, setDropAmount] = useState({
    cash: '0.00',
    nonCash: '0.00',
  });
  const [dropEnvelopePopup,setDropEnvelopePopup] = useState(false);
  const [dropVerifiedPopup, setDropVerifiedPopup] = useState(false);
  const [disableAmtBtn,setDisableAmtBtn]=useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const [isSuccess, setIsSucess] = useState(true);
  const [data, setData] = useState({ dropVerified: '' });
  // const [dropVerifiedBy, setDropVerifiedBy] = useState({ dropStrap });
  const [saveBtn, setSaveBtn] = useState(false);
  const [maxDropAllowed, setMaxDropAllowed] = useState('');
  const [drawerTotalAmt, setDrawerTotalAmt] = useState('');
  const [cashdropAllowded, setCashdropAllowded] = useState('0.00');
  const [totalCashExceed, setTotalCashExceed] = useState(false);
  const [isNoDataForTodaysDrop, setisNoDataForTodaysDrop] = useState(false);
  const [defaultStringAmount, setdefaultStringAmount] = useState('0.00');
  const [coWorkerProfile , setCoWorkerProfile] = useState<any>();
  const handleText = (event: any, src?: string) => {
    // debugger;
    console.log("event.target.value",event.target.value)
    if (src == 'DD') {
      setData({ ...data, dropVerified: event.target.value });
    }
  };
  console.log("DropVerified",data.dropVerified)
  const [denominationQuantity, setDenominationQuantity] =
    useState<denominationQuantityInterface>({
      denominationQuantity100: '0',
      denominationQuantity50: '0',
      denominationQuantity20: '0',
      denominationQuantity10: '0',
      denominationQuantity5: '0',
      denominationQuantity2: '0',
      denominationQuantity1: '0',
    });
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [coworkerDD, setcoworkerDD] = useState([]);

  useEffect(() => {
    
    pageLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const pageLoad = async () => {
    try {
      setDefaultLoader(true)
      setLoader(true)
      const storeNumber = window.sessionStorage.getItem('storeNumber');
    //   const draweractivity = await getDrawerDetail({
    //     storeNumber: storeNumber,
    //     businessDate: moment().format('MM/DD/YYYY'),
        const DrawerDetails = await Promise.all(
            [getDrawerDetail({storeNumber: storeNumber,businessDate: moment().format('YYYY-MM-DD'),}),
            getSwitchOutAgreementOptions(storeNumber),
           
        (storeNumber && storeNumber != null && storeNumber != undefined && storeNumber != '' && storeNumber != 'null' && storeNumber!= 'undefined') ?
            getConfigDetails({storeNumbers: [sessionStorage.getItem('storeNumber')],paramKeyNames: ['MaxDrawerLimit' ,'ReconciliationMaxLimit','DrawerDefaultAmount','MinDropAmount'],}) : Promise.resolve(),
            ]);
            const draweractivity = DrawerDetails[0]
            const coWorkerDetailsObj: any = await getmenuandstore();     
            console.log('MenuandStore',coWorkerDetailsObj)
            setCoWorkerProfile(coWorkerDetailsObj)
      setLoader(false)
      setDefaultLoader(false)
      const defaultAmount=DrawerDetails[2]
      setdefaultStringAmount(defaultAmount.storeProfileResponse.configDetails[0].configDetails.filter((el: any) => el.paramKeyName == 'DrawerDefaultAmount')[0].paramValue)
      console.log('drawer Detail Response', draweractivity);
       const coworkerDetails = DrawerDetails[1]
      console.log('coworkerDetails', coworkerDetails);
      const coworkerDropDown: any = [{ label: 'Select', value: '0' }];
      coworkerDetails?.salespersonsData.forEach((value: any) => {
        coworkerDropDown.push({
            label: String(value.appUserName),
            value: String(value.appUserId),
          });
      });
      if( draweractivity?.storeTransaction?.totalCash < 0 ){
        setDisableAmtBtn(true);
      }
      const nonCashResponse: any = [];
      draweractivity !== undefined &&
        draweractivity.storeTransaction !== undefined &&
        draweractivity.storeTransaction.nonCashDetails !== undefined &&
        draweractivity.storeTransaction.nonCashDetails !== null &&
        draweractivity.storeTransaction.nonCashDetails.length > 0
        ? draweractivity?.storeTransaction?.nonCashDetails.map((el: any) => {
          nonCashResponse.push({ ...el, isChecked: false });
        })
        : [];
      setDrawerDetails({
        storeTransaction: draweractivity.storeTransaction,
        NonCashDetails: nonCashResponse,
      });
      console.log('drawerDetails', drawerDetails);
      setcoworkerDD(coworkerDropDown);
      if (
        draweractivity !== undefined &&
        draweractivity !== null
        // && draweractivity !== ''
      ) {
        setIsSkeleton(false);
      }
      setMaxDropAllowed(
        (
          parseFloat(defaultStringAmount) -
          parseFloat(
            draweractivity !== undefined &&
              draweractivity.storeTransaction !== undefined &&
              draweractivity.storeTransaction !== null
              ? draweractivity.storeTransaction.totalCash
              : '0'
          )
        ).toFixed(2)
      );

      const totalCash =
        draweractivity !== undefined &&
          draweractivity.storeTransaction !== undefined &&
          draweractivity.storeTransaction.totalCash !== undefined &&
          draweractivity.storeTransaction.totalCash !== null
          ? parseFloat(draweractivity.storeTransaction.totalCash)
          : 0;
        
       if(totalCash){
        const value = totalCash % defaultAmount.storeProfileResponse.configDetails[0].configDetails.filter((el: any) => el.paramKeyName == 'MinDropAmount')[0].paramValue
        setCashdropAllowded(
          totalCash > 0 ? (totalCash - value).toFixed(2) : '0.00'
        );  
       }
      else{
        setCashdropAllowded(
        ('0.00'));
      }
      setDrawerTotalAmt(
        (
          parseFloat(defaultAmount.storeProfileResponse.configDetails[0].configDetails.filter((el: any) => el.paramKeyName == 'DrawerDefaultAmount')[0].paramValue) +
          parseFloat(
            draweractivity !== undefined &&
              draweractivity.storeTransaction !== undefined &&
              draweractivity.storeTransaction.totalNonCash !== undefined &&
              draweractivity.storeTransaction.totalNonCash !== null
              ? draweractivity.storeTransaction.totalNonCash
              : 0
          ) + 
          parseFloat(
            draweractivity !== undefined &&
              draweractivity.storeTransaction !== undefined &&
              draweractivity.storeTransaction !== null &&
              draweractivity.storeTransaction.totalCash !== undefined &&
              draweractivity.storeTransaction.totalCash !== null
              ? draweractivity.storeTransaction.totalCash
              : 0
          )
        ).toFixed(2)
      );
    } catch (e) {
      console.log(e);
    }
  };
 { console.log('defaultStringAmount',defaultStringAmount)}
 {console.log('drawerTotalAmt',drawerTotalAmt)}
 function DropVerifiedModelCard() {
  return (
    <RACModalCard
      isOpen={dropVerifiedPopup}
      maxWidth='xs'
      closeIcon={true}
      borderRadius="20px"
      onClose={() => handleDropVerifiedOkBtn()}
      // eslint-disable-next-line react/no-children-prop
      children={DropVerifiedPopup()}
    // title="Validation Message"
    />
  );
}
const handleDropVerifiedOkBtn= async ()=>{
  setDropVerifiedPopup(false)
  setLoader(true);
  setDefaultLoader(true)
  const CashBeingDropped = [];
  const NonCashBeingDropped: any = [];
  drawerDetails.NonCashDetails.map((value: any) => {
    if (value.isChecked) {
      NonCashBeingDropped.push({
        receiptTransactionId: value.receiptTransactionId.toString(),
        amount: value.cashAmount.toFixed(2),
      });
    }
  });
  for (const [key, value] of Object.entries(denominationQuantity)) {
    if (value !== '0') {
      CashBeingDropped.push({
        denomination:
          key == 'denominationQuantity100'
            ? '100'
            : key == 'denominationQuantity50'
              ? '50'
              : key == 'denominationQuantity20'
                ? '20'
                : key == 'denominationQuantity10'
                  ? '10'
                  : key == 'denominationQuantity5'
                    ? '5'
                    : key == 'denominationQuantity2'
                      ? '2'
                      : key == 'denominationQuantity1'
                        ? '1'
                        : '0',

        totalQuatity: Number(value),
      });
    }
  }
  const payload: any = {
    storeNumber: window.sessionStorage.getItem('storeNumber'),
    dropVerifiedBy: data.dropVerified !== undefined ? data.dropVerified : '',
    dropNotes: dropNotes !== undefined ? dropNotes : '',
    dropEnvelopNumber: envNumber !== undefined ? envNumber : '',
    isFinalDrop: '0',
    defaultCashAmount: String(defaultStringAmount),
    cashBeingDropped: CashBeingDropped,
    nonCashOverallShort: '0.00',
    cashOverallShort: '0.00',
    overAllShortNotes: '',
  };
  if (NonCashBeingDropped && NonCashBeingDropped.length > 0) {
    payload.nonCashBeingDropped = NonCashBeingDropped;
  }
  const response = await manageDrop(payload);
  setLoader(false);
  setDefaultLoader(false)
  const errorcode = 'Drop Envelop Number Already Exits'
  const status= 200 ;
 if (response.status == status) {
    setSuccessPopup(true);
  }
 else if(response.data.errors[0].error == errorcode){
    setDropEnvelopePopup(true)
  }
  else { setSuccessPopup(true); setIsSucess(false) }
}
const DropVerifiedPopup = ()=>{
  return (
    <Grid item md={12}>
      <Grid item md={12} className={classes.textCenter}>
        <Typography className={`${classes.RACPOPMsg} ${classes.marginTop}`}>
        Verification should be performed by a
        <Typography className={classes.RACPOPMsg} >different coworker than the coworker </Typography>
        <Typography className={classes.RACPOPMsg} >that performed the drop. </Typography>
        <Typography className={classes.RACPOPMsg} >Do you want to continue? </Typography>
        </Typography>
      </Grid>
      <Grid
        container
        className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
      >
        <RACButton
          className={`${classes.spacerMR2} ${classes.foc}`}
          size="small"
          variant="contained"
          color="primary"
          isRounded={false}
          onClick={() => handleDropVerifiedOkBtn()}
        // onClick={() => handleModels('validateNonCashIsOpen',false)}
        >
          OK
        </RACButton>
      </Grid>
    </Grid>
  );
};
  function DropEnvelopModalCard() {
    return (
      <RACModalCard
        isOpen={dropEnvelopePopup}
        maxWidth="sm"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => setDropEnvelopePopup(false)}
        // eslint-disable-next-line react/no-children-prop
        children={DropEnvelopePopUp()}
      // title="Validation Message"
      />
    );
  }
  const DropEnvelopePopUp = ()=>{
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <Typography className={`${classes.RACPOPMsg} ${classes.marginTop}`}>
          The Drop Envelope Number has already been used today.
          <Typography className={classes.RACPOPMsg} >Please enter a new Envelope Number</Typography>
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
        >
          <RACButton
            className={`${classes.spacerMR2} ${classes.foc}`}
            size="small"
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => setDropEnvelopePopup(false)}
          // onClick={() => handleModels('validateNonCashIsOpen',false)}
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const UpdateInfoMsgPopup = () => {
    return (
      <Grid item md={12} className="px-2">
        <Grid
          item
          md={12}
          className="text-center"
          style={{ textAlign: 'center' }}
        >
          {isSuccess ? (
            <SuccessIcon className={classes.updatePopupTextAlign}></SuccessIcon>
          ) : (
            <Alerticon className={classes.updatePopupTextAlign}></Alerticon>
          )}
          <Typography
            variant={'body2'}
            className={classes.updatePopupMarginWid}
          >
            {isSuccess
              ? 'Drop Successfully Completed'
              : // eslint-disable-next-line sonarjs/no-duplicate-string
              'Something went wrong'}
          </Typography>
        </Grid>
        <Grid
          container
          className="text-center mt-4"
          style={{ justifyContent: 'center', marginTop: '20px' }}
        >
          <RACButton
            className={`${classes.spacerMR2} ${classes.foc} ${classes.updatePopupRacBtn}`}
            size="small"
            color="primary"
            variant="contained"
            isRounded={false}
            onClick={() => {
              history.push('/dashboard/homepage');
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const noRecordFoundMsgPopup = () => {
    return (
      <Grid item md={12} className="px-2">
        <Grid
          item
          md={12}
          className="text-center"
          style={{ textAlign: 'center' }}
        >
          <Alerticon className={classes.updatePopupTextAlign}></Alerticon>
          <Typography
            variant={'body2'}
            className={classes.updatePopupMarginWid}
          >
            {'No Record Found'}
          </Typography>
        </Grid>
        <Grid
          container
          className="text-center mt-4"
          style={{ justifyContent: 'center', marginTop: '20px' }}
        >
          <RACButton
            className={`${classes.spacerMR2} ${classes.foc} ${classes.updatePopupRacBtn}`}
            size="small"
            color="primary"
            variant="contained"
            isRounded={false}
            onClick={() => {
              history.push('/dashboard/homepage');
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  function HandleTotalCashExceed() {
    return (
      <RACModalCard
        isOpen={totalCashExceed}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => setTotalCashExceed(false)}
        // eslint-disable-next-line react/no-children-prop
        children={handleTotalCashExceedPopup()}
      // title="Validation Message"
      />
    );
  }

  function handleOkBtn() {
    setTotalCashExceed(false);
  }
  const handleTotalCashExceedPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <Typography className={`${classes.RACPOPMsg} ${classes.marginTop}`}>
            The Total Cash Being Dropped Exceeds the max allowable cash amount
            threshold.
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
        >
          <RACButton
            className={`${classes.spacerMR2} ${classes.foc}`}
            size="small"
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => handleOkBtn()}
          // onClick={() => handleModels('validateNonCashIsOpen',false)}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const seconduthenResponses = async (e: Event) => {
    console.log("coming into secondAuth response")
    try {
      setSecondLevelAuthenOn(false)
      setDefaultLoader(true)
      const CashBeingDropped = [];
      const NonCashBeingDropped: any = [];
      drawerDetails.NonCashDetails.map((value: any) => {
        if (value.isChecked) {
          NonCashBeingDropped.push({
            receiptTransactionId: value.receiptTransactionId.toString(),
            amount: value.cashAmount.toFixed(2),
          });
        }
      });
      // drawerActivityId ,  amount: dropAmount.nonCash
      for (const [key, value] of Object.entries(denominationQuantity)) {
        if (value !== '0') {
          CashBeingDropped.push({
            denomination:
              key == 'denominationQuantity100'
                ? '100'
                : key == 'denominationQuantity50'
                  ? '50'
                  : key == 'denominationQuantity20'
                    ? '20'
                    : key == 'denominationQuantity10'
                      ? '10'
                      : key == 'denominationQuantity5'
                        ? '5'
                        : key == 'denominationQuantity2'
                          ? '2'
                          : key == 'denominationQuantity1'
                            ? '1'
                            : '0',

            totalQuatity: Number(value),
          });
        }
      }
      // drawerActivityId: string;

      // amount: string;
      const payload: any = {
        storeNumber: window.sessionStorage.getItem('storeNumber'),
        dropVerifiedBy: data.dropVerified !== undefined ? data.dropVerified : '',
        dropNotes: dropNotes !== undefined ? dropNotes : '',
        dropEnvelopNumber: envNumber !== undefined ? envNumber : '',
        isFinalDrop: '0',
        defaultCashAmount: String(defaultStringAmount),
        cashBeingDropped: CashBeingDropped,
        nonCashOverallShort: '0.00',
        cashOverallShort: '0.00',
        overAllShortNotes: '',
      };
      if (NonCashBeingDropped && NonCashBeingDropped.length > 0) {
        payload.nonCashBeingDropped = NonCashBeingDropped;
      }
      console.log('Drop payload', payload);
      if(coWorkerProfile?.coworkerProfile?.employeeId == data.dropVerified){
        setDropVerifiedPopup(true)
      }
      else {
        setLoader(true);
      const response = await manageDrop(payload);
      setLoader(false);
      setDefaultLoader(false)
      const errorcode = 'Drop Envelop Number Already Exits'
      const status= 200 ;
     if (response.status == status) {
        setSuccessPopup(true);
      }
     else if(response.data.errors[0].error == errorcode){
        setDropEnvelopePopup(true)
      }
      else { setSuccessPopup(true); setIsSucess(false) }
    }
    } catch (e: any) {
      setSuccessPopup(false);
      setLoader(false);
      setDefaultLoader(false)

    }

    // const dropResponse = await manageDrop(payload);
    // console.log('Drop Response', dropResponse);

    // if (
    //   dropResponse !== undefined &&
    //   dropResponse.drawerAuditId !== undefined
    // ) {
    //   setSuccessPopup(true);
    //   setIsSucess(true);
    // }
    // else{
    //   setSuccessPopup(true);
    //   setIsSucess(false);
    // }
    // setLoader(false);
  };
  const handleEnvelopInp = (e: any) => {
    setEnvNumber(e.target.value);
  };
  const handleDropNotes = (e: any) => {
    setDropNotes(e.target.value);
  };
  const handleNonCashDetail = (e: any, recID: any) => {
    let nonCashbeingdrop = parseFloat(dropAmount.nonCash);
    if (drawerDetails.NonCashDetails !== undefined) {
      if (drawerDetails.NonCashDetails !== undefined ? e.target.checked : '') {
        for (
          let nonCashDetails = 0;
          nonCashDetails < drawerDetails.NonCashDetails.length;
          nonCashDetails++
        ) {
          if (
            drawerDetails.NonCashDetails !== undefined
              ? drawerDetails.NonCashDetails[nonCashDetails].receiptTransactionId ==
              recID
              : ''
          ) {
            nonCashbeingdrop += parseFloat(
              drawerDetails.NonCashDetails[nonCashDetails].cashAmount
            );
            break;
          }
        }
      } else {
        for (
          let nonCashDetails = 0;
          nonCashDetails < drawerDetails.NonCashDetails.length;
          nonCashDetails++
        ) {
          if (
            drawerDetails !== undefined
              ? drawerDetails.NonCashDetails[nonCashDetails].receiptTransactionId ==
              recID
              : ''
          ) {
            nonCashbeingdrop -= parseFloat(
              drawerDetails.NonCashDetails[nonCashDetails].cashAmount
            );
            break;
          }
        }
      }
    }
    const nonCashArr =
      drawerDetails &&
      drawerDetails.NonCashDetails.map((el: any) => {
        if (!el.isChecked && e.target.checked && el.receiptTransactionId == recID) {
          el.isChecked = true;
        } else if (
          el.isChecked &&
          !e.target.checked &&
          el.receiptTransactionId == recID
        ) {
          el.isChecked = false;
        }
        return el;
      });
    setDrawerDetails({ ...drawerDetails, NonCashDetails: nonCashArr });
    setDropAmount({ ...dropAmount, nonCash: nonCashbeingdrop.toFixed(2) });
  };
  function handleCancelBtn() {
    setCancelBtn(true);
  }
  function handleSaveBtn() {
    if (parseFloat(dropAmount.cash) > parseFloat(cashdropAllowded)) {
      setTotalCashExceed(true);
    } else {
      setSaveBtn(true);
    }
  }
  function handleNo_SaveBtn() {
    setSaveBtn(false);
  }
  function handleYes_SaveBtn() {
    setDefaultLoader(true);
    setSaveBtn(false);
    setSecondLevelAuthenOn(true);
  }
  function handle_CancelNoBtn() {
    setCancelBtn(false);
  }
  function handle_CancelYesBtn() {
    setCancelBtn(false);
  }
  function HandleCancelModelCard() {
    return (
      <RACModalCard
        isOpen={cancelBtn}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => setCancelBtn(false)}
        // eslint-disable-next-line react/no-children-prop
        children={handleCancelModelCardPopup()}
      />
    );
  }
  function HandleSaveModelCard() {
    return (
      <RACModalCard
        isOpen={saveBtn}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => setSaveBtn(false)}
        // eslint-disable-next-line react/no-children-prop
        children={handleSavelModelCardPopup()}
      // title="Validation Message"
      />
    );
  }

  const getNonCashDetails = () => {
    const totalNonCash = parseFloat(
      drawerDetails !== undefined &&
        drawerDetails.storeTransaction !== undefined &&
        drawerDetails.storeTransaction !== null &&
        drawerDetails.storeTransaction !== '' &&
        drawerDetails?.storeTransaction?.totalNonCash !== undefined
        ? drawerDetails?.storeTransaction?.totalNonCash
        : '0.00'
    );
    return totalNonCash >=0 ? '$'+` `+totalNonCash.toFixed(2) : '($'+` `+(totalNonCash * -1).toFixed(2)+')';
  };
  const getCashDetails = () => {
    const totalNonCash = parseFloat(
      drawerDetails !== undefined &&
        drawerDetails.storeTransaction !== undefined &&
        drawerDetails.storeTransaction !== null &&
        drawerDetails.storeTransaction.totalCash !== undefined &&
        drawerDetails.storeTransaction.totalCash !== null
        ? drawerDetails.storeTransaction.totalCash
        : 0
    );
    return (totalNonCash + parseFloat(defaultStringAmount))>=0 ? '$'+` `+(totalNonCash + parseFloat(defaultStringAmount)).toFixed(2) : '($'+` `+((totalNonCash + parseFloat(defaultStringAmount))*-1).toFixed(2)+')';
  };

  const getMaxDropAllowed = () => {
    const maxdropValue = parseFloat(
      drawerDetails !== undefined &&
        drawerDetails.storeTransaction !== undefined &&
        drawerDetails.storeTransaction !== null &&
        drawerDetails.storeTransaction.totalCash !== undefined &&
        drawerDetails.storeTransaction.totalCash !== null
        ? drawerDetails.storeTransaction.totalCash
        : 0
    );
    return maxdropValue.toFixed(2);
  };

  // const clearDropInputValues = () => {
  //   setDenominationQuantity({
  //     ...denominationQuantity, denominationQuantity1: 0,
  //     denominationQuantity100: 0,
  //     denominationQuantity2: 0,
  //     denominationQuantity20: 0,
  //     denominationQuantity5: 0,
  //     denominationQuantity50: 0,
  //     denominationQuantity10: 0
  //   })
  //   setEnvNumber('')
  //   setDropNotes('')
  //   if (drawerDetails.NonCashDetails.length > 0) {
  //     let nonCashArr = drawerDetails.NonCashDetails.map((el: any) => {
  //       if (el.isChecked == true) {
  //         el.isChecked = false
  //       }
  //       return el
  //     })
  //     setDropAmount({ ...dropAmount, nonCash: "0.00" })
  //     setDrawerDetails({ ...drawerDetails, NonCashDetails: nonCashArr })
  //     setData({ ...data, stateInfo: '0' })
  //   }
  // }
  const handleSavelModelCardPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <Typography className={`${classes.RACPOPMsg} ${classes.marginTop}`}>
            Are you sure want to save?
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
        >
          <RACButton
            className={`${classes.spacerMR2} ${classes.foc}`}
            size="small"
            variant="outlined"
            color="primary"
            isRounded={false}
            onClick={() => handleNo_SaveBtn()}
          // onClick={() => handleModels('validateNonCashIsOpen',false)}
          >
            No
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => handleYes_SaveBtn()}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const handleCancelModelCardPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <Typography className={`${classes.RACPOPMsg} ${classes.marginTop}`}>
            Your changes will not be saved.
            <Grid>Are you sure you want to exit?</Grid>
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
        >
          <RACButton
            className={`${classes.spacerMR2} ${classes.foc}`}
            size="small"
            variant="outlined"
            color="primary"
            isRounded={false}
            onClick={() => handle_CancelNoBtn()}
          // onClick={() => handleModels('validateNonCashIsOpen',false)}
          >
            No
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() =>  {history.push({
              pathname: `/dashboard/homepage`,
            });}}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const calculateTotalAmount = (denomination: string, value: string) => {
    // console.log('********Start***********',denominationQuantity.denominationQuantity100 !=='' ? true : false);

    // console.log('********Start***********',value, denomination)

    // console.log("Inside",parseFloat(denominationQuantity.denominationQuantity100 !=='' ? denominationQuantity.denominationQuantity001: '2') * 100)

    const cashAmount =
      parseFloat(
        denomination == '100'
          ? value
          : denominationQuantity.denominationQuantity100 !== ''
            ? denominationQuantity.denominationQuantity100
            : '0'
      ) *
      100 +
      parseFloat(
        denomination == '50'
          ? value
          : denominationQuantity.denominationQuantity50 !== ''
            ? denominationQuantity.denominationQuantity50
            : '0'
      ) *
      50 +
      parseFloat(
        denomination == '20'
          ? value
          : denominationQuantity.denominationQuantity20 !== ''
            ? denominationQuantity.denominationQuantity20
            : '0'
      ) *
      20 +
      parseFloat(
        denomination == '10'
          ? value
          : denominationQuantity.denominationQuantity10 !== ''
            ? denominationQuantity.denominationQuantity10
            : '0'
      ) *
      10 +
      parseFloat(
        denomination == '5'
          ? value
          : denominationQuantity.denominationQuantity5 !== ''
            ? denominationQuantity.denominationQuantity5
            : '0'
      ) *
      5 +
      parseFloat(
        denomination == '2'
          ? value
          : denominationQuantity.denominationQuantity2 !== ''
            ? denominationQuantity.denominationQuantity2
            : '0'
      ) *
      2 +
      parseFloat(
        denomination == '1'
          ? value
          : denominationQuantity.denominationQuantity1 !== ''
            ? denominationQuantity.denominationQuantity1
            : '0'
      ) *
      1;
    return cashAmount.toFixed(2);
  };
  const handleCashBeingdropInp = (e: any) => {
    // console.log("InputID", e.target.id)

    switch (e.target.id) {
      case '$100':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmount(
            '100',
            e.target.value !== '' 
              ? e.target.value
              : '0'
          ),
        });
        setDenominationQuantity({
          ...denominationQuantity,
          denominationQuantity100: e.target.value,
        });
        break;
      case '$50':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmount(
            '50',
            e.target.value !== ''
              ? e.target.value
              : '0'
          ),
        });
        setDenominationQuantity({
          ...denominationQuantity,
          denominationQuantity50: e.target.value,
        });
        break;
      case '$20':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmount(
            '20',
            e.target.value !== ''
              ? e.target.value
              : '0'
          ),
        });
        setDenominationQuantity({
          ...denominationQuantity,
          denominationQuantity20: e.target.value,
        });
        break;
      case '$10':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmount(
            '10',
            e.target.value !== ''
              ? e.target.value
              : '0'
          ),
        });
        setDenominationQuantity({
          ...denominationQuantity,
          denominationQuantity10: e.target.value,
        });
        break;
      case '$5':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmount(
            '5',
            e.target.value !== ''
              ? e.target.value
              : '0'
          ),
        });
        setDenominationQuantity({
          ...denominationQuantity,
          denominationQuantity5: e.target.value,
        });
        break;
      case '$2':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmount(
            '2',
            e.target.value !== ''
              ? e.target.value
              : '0'
          ),
        });
        setDenominationQuantity({
          ...denominationQuantity,
          denominationQuantity2: e.target.value,
        });
        break;
      case '$1':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmount(
            '1',
            e.target.value !== ''
              ? e.target.value
              : '0'
          ),
        });
        setDenominationQuantity({
          ...denominationQuantity,
          denominationQuantity1: e.target.value,
        });
        break;
    }
  };
  const handleCashBeingdropBtn = (e: any) => {
    // console.log(e.currentTarget.id)

    switch (e.currentTarget.id) {
      case '$100':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmount(
            '100',
            (
              Number(denominationQuantity.denominationQuantity100) + 1
            ).toString()
          ),
        });
        setDenominationQuantity({
          ...denominationQuantity,
          denominationQuantity100: (
            Number(denominationQuantity.denominationQuantity100) + 1
          ).toString(),
        });
        break;
      case '$50':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmount(
            '50',
            (Number(denominationQuantity.denominationQuantity50) + 1).toString()
          ),
        });
        setDenominationQuantity({
          ...denominationQuantity,
          denominationQuantity50: (
            Number(denominationQuantity.denominationQuantity50) + 1
          ).toString(),
        });
        break;
      case '$20':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmount(
            '20',
            (Number(denominationQuantity.denominationQuantity20) + 1).toString()
          ),
        });
        setDenominationQuantity({
          ...denominationQuantity,
          denominationQuantity20: (
            Number(denominationQuantity.denominationQuantity20) + 1
          ).toString(),
        });
        break;
      case '$10':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmount(
            '10',
            (Number(denominationQuantity.denominationQuantity10) + 1).toString()
          ),
        });
        setDenominationQuantity({
          ...denominationQuantity,
          denominationQuantity10: (
            Number(denominationQuantity.denominationQuantity10) + 1
          ).toString(),
        });
        break;
      case '$5':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmount(
            '5',
            (Number(denominationQuantity.denominationQuantity5) + 1).toString()
          ),
        });
        setDenominationQuantity({
          ...denominationQuantity,
          denominationQuantity5: (
            Number(denominationQuantity.denominationQuantity5) + 1
          ).toString(),
        });
        break;
      case '$2':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmount(
            '2',
            (Number(denominationQuantity.denominationQuantity2) + 1).toString()
          ),
        });
        setDenominationQuantity({
          ...denominationQuantity,
          denominationQuantity2: (
            Number(denominationQuantity.denominationQuantity2) + 1
          ).toString(),
        });
        break;
      case '$1':
        setDropAmount({
          ...dropAmount,
          cash: calculateTotalAmount(
            '1',
            (Number(denominationQuantity.denominationQuantity1) + 1).toString()
          ),
        });
        setDenominationQuantity({
          ...denominationQuantity,
          denominationQuantity1: (
            Number(denominationQuantity.denominationQuantity1) + 1
          ).toString(),
        });
        break;
    }
  };
  const handleNonEmptyValue =(e:any)=>{
    console.log(" +++++++++value of 100",e.target.value)
    switch(e.target.id){
            case '$100':
              if(e.target.value == '' || Number(e.target.value) == 0){
                setDenominationQuantity({...denominationQuantity,denominationQuantity100:'0'})
              }
            break; 
            case '$50':
              if(e.target.value == '' || Number(e.target.value) == 0){
                setDenominationQuantity({...denominationQuantity,denominationQuantity50:'0'})
              }
            break; 
            case '$20':
              if(e.target.value == '' || Number(e.target.value) == 0){
                setDenominationQuantity({...denominationQuantity,denominationQuantity20:'0'})
              }
            break; 
            case '$10':
              if(e.target.value == '' || Number(e.target.value) == 0){
                setDenominationQuantity({...denominationQuantity,denominationQuantity10:'0'})
              }
            break; 
            case '$5':
              if(e.target.value == '' || Number(e.target.value) == 0){
                setDenominationQuantity({...denominationQuantity,denominationQuantity5:'0'})
              }
            break; 
            case '$2':
              if(e.target.value == '' || Number(e.target.value) == 0){
                setDenominationQuantity({...denominationQuantity,denominationQuantity2:'0'})
              }
            break; 
            case '$1':
              if(e.target.value == '' || Number(e.target.value) == 0){
                setDenominationQuantity({...denominationQuantity,denominationQuantity1:'0'})
              }
            break; 
    }
    
  }
  const renderCashTableContent = () => {
    return (
      <>
        <React.Fragment>
          {/* 100$ */}
          <RACTableRow className={`${classes.tableRow} ${classes.tableContentheight}`}>
            <RACTableCell className="racstarp-tablecell-BgColor">
              <RACButton
                style={{
                  boxShadow: '0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important',
                }}
                disabled={disableAmtBtn}
                name="$100"
                id="$100"
                onClick={(e: any) => handleCashBeingdropBtn(e)}
                className={`${classes.RACbutton} ${classes.currenttill}`}
              >
                $ 100
              </RACButton>
            </RACTableCell>
            <RACTableCell className="racstrap-tablecell-BgColor">
              <RACTextbox
               disabled={disableAmtBtn}
                className={classes.totalQuantity}
                value={denominationQuantity.denominationQuantity100.toString()}
                maxlength={10}
                type="number"
                name="$100"
                id="$100"
                Onblur={(e: any) => handleNonEmptyValue(e)}
                OnChange={(e: any) => handleCashBeingdropInp(e)}
              />
            </RACTableCell>
            <RACTableCell
              className={` ${classes.total} ${classes.racTableCellColor}`}
            >
              <Typography className={` ${classes.typoTotal}`}>
                {/* $ {count.toFixed(2)} */}${' '}
                {formatMoney(
                  (parseFloat(denominationQuantity.denominationQuantity100 !== '' ? denominationQuantity.denominationQuantity100: '0') * 100).toFixed(2)
                )}
              </Typography>
            </RACTableCell>
          </RACTableRow>
          {/* 50$ */}
          <RACTableRow className={`${classes.tableRow} ${classes.tableContentheight}`}>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACButton
               disabled={disableAmtBtn}
                onClick={(e: any) => handleCashBeingdropBtn(e)}
                name="$50"
                id="$50"
                className={`${classes.RACbutton} ${classes.currenttill}`}
              >
                $ 50
              </RACButton>
            </RACTableCell>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACTextbox
               disabled={disableAmtBtn}
                className={classes.totalQuantity}
                value={denominationQuantity.denominationQuantity50.toString()}
                maxlength={10}
                type="number"
                name="$50"
                id="$50"
                Onblur={(e: any) => handleNonEmptyValue(e)}
                OnChange={(e: any) => handleCashBeingdropInp(e)}
              />
            </RACTableCell>
            <RACTableCell
              className={`${classes.total} ${classes.racTableCellColor} ${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <Typography className={` ${classes.typoTotal}`}>
                ${' '}
                {formatMoney(
                  (parseFloat(denominationQuantity.denominationQuantity50 !== '' ? denominationQuantity.denominationQuantity50 : '0') * 50).toFixed(2)
                )}
              </Typography>
            </RACTableCell>
          </RACTableRow>
          {/* 20$ */}
          <RACTableRow  className={`${classes.tableRow} ${classes.racTableRowhyt}`}>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACButton
               disabled={disableAmtBtn}
                onClick={(e: any) => handleCashBeingdropBtn(e)}
                name="$20"
                id="$20"
                className={`${classes.RACbutton} ${classes.currenttill}`}
              >
                $ 20
              </RACButton>
            </RACTableCell>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACTextbox
               disabled={disableAmtBtn}
                className={classes.totalQuantity}
                value={denominationQuantity.denominationQuantity20.toString()}
                maxlength={10}
                type="number"
                name="$20"
                id="$20"
                Onblur={(e: any) => handleNonEmptyValue(e)}
                OnChange={(e: any) => handleCashBeingdropInp(e)}
              />
            </RACTableCell>
            <RACTableCell
      
              className={`${'racstrap-tablecell-BgColor'} ${classes.total}  ${classes.racTableCellColor}  ${classes.tableCell1
                }`}
            >
              <Typography className={` ${classes.typoTotal}`}>
                ${' '}
                {formatMoney(
                  (parseFloat(denominationQuantity.denominationQuantity20 !== '' ? denominationQuantity.denominationQuantity20 : '0') * 20).toFixed(2)
                )}
              </Typography>
            </RACTableCell>
          </RACTableRow>
          {/* 10$ */}
          <RACTableRow className={`${classes.tableRow} ${classes.racTableRowhyt}`}>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACButton
               disabled={disableAmtBtn}
                onClick={(e: any) => handleCashBeingdropBtn(e)}
                name="$10"
                id="$10"
                className={`${classes.RACbutton} ${classes.currenttill}`}
              >
                $ 10
              </RACButton>
            </RACTableCell>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACTextbox
               disabled={disableAmtBtn}
                className={classes.totalQuantity}
                value={denominationQuantity.denominationQuantity10.toString()}
                maxlength={10}
                type="number"
                name="$10"
                id="$10"
                Onblur={(e: any) => handleNonEmptyValue(e)}
                OnChange={(e: any) => handleCashBeingdropInp(e)}
              />
            </RACTableCell>
            <RACTableCell
              className={`${'racstrap-tablecell-BgColor'} ${classes.total} ${classes.racTableCellColor} ${classes.tableCell1
                }`}
            >
              <Typography className={` ${classes.typoTotal}`}>
                ${' '}
                {formatMoney(
                 ( parseFloat(denominationQuantity.denominationQuantity10 !== '' ? denominationQuantity.denominationQuantity10 : '0') * 10).toFixed(2)
                )}
              </Typography>
            </RACTableCell>
          </RACTableRow>
          {/* 5$ */}
          <RACTableRow  className={`${classes.tableRow} ${classes.racTableRowhyt}`}>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACButton
               disabled={disableAmtBtn}
                onClick={(e: any) => handleCashBeingdropBtn(e)}
                name="$5"
                id="$5"
                className={`${classes.RACbutton} ${classes.currenttill}`}
              >
                $ 5
              </RACButton>
            </RACTableCell>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACTextbox
               disabled={disableAmtBtn}
                className={classes.totalQuantity}
                value={denominationQuantity.denominationQuantity5.toString()}
                maxlength={10}
                type="number"
                name="$5"
                id="$5"
                Onblur={(e: any) => handleNonEmptyValue(e)}
                OnChange={(e: any) => handleCashBeingdropInp(e)}
              />
            </RACTableCell>
            <RACTableCell
             
              className={`${'racstrap-tablecell-BgColor'} ${classes.total} ${classes.racTableCellColor} ${classes.tableCell1
                }`}
            >
              <Typography className={` ${classes.typoTotal}`}>
                ${' '}
                {formatMoney(
                 ( parseFloat(denominationQuantity.denominationQuantity5 !== '' ? denominationQuantity.denominationQuantity5 : '0') * 5).toFixed(2)
                )}
              </Typography>
            </RACTableCell>
          </RACTableRow>
          {/* 2$ */}
          <RACTableRow  className={`${classes.tableRow} ${classes.racTableRowhyt}`}>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACButton
               disabled={disableAmtBtn}
                onClick={(e: any) => handleCashBeingdropBtn(e)}
                name="$2"
                id="$2"
                className={`${classes.RACbutton} ${classes.currenttill}`}
              >
                $ 2
              </RACButton>
            </RACTableCell>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACTextbox
               disabled={disableAmtBtn}
                className={classes.totalQuantity}
                value={denominationQuantity.denominationQuantity2.toString()}
                maxlength={10}
                type="number"
                name="$2"
                id="$2"
                Onblur={(e: any) => handleNonEmptyValue(e)}
                OnChange={(e: any) => handleCashBeingdropInp(e)}
              />
            </RACTableCell>
            <RACTableCell
              
              className={`${'racstrap-tablecell-BgColor'} ${classes.total} ${classes.racTableCellColor} ${classes.tableCell1
                }`}
            >
              <Typography className={` ${classes.typoTotal}`}>
                ${' '}
                {formatMoney(
                 ( parseFloat(denominationQuantity.denominationQuantity2 !== '' ? denominationQuantity.denominationQuantity2 : '0') * 2).toFixed(2)
                )}
              </Typography>
            </RACTableCell>
          </RACTableRow>
          {/* 1$ */}
          <RACTableRow  className={`${classes.tableRow} ${classes.racTableRowhyt}`}>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACButton
               disabled={disableAmtBtn}
                onClick={(e: any) => handleCashBeingdropBtn(e)}
                name="$1"
                id="$1"
                className={`${classes.RACbutton} ${classes.currenttill}`}
              >
                $ 1
              </RACButton>
            </RACTableCell>
            <RACTableCell
              className={`${classes.tableCell1} "racstrap-tablecell-BgColor"`}
            >
              <RACTextbox
               disabled={disableAmtBtn}
                className={classes.totalQuantity}
                value={denominationQuantity.denominationQuantity1.toString()}
                maxlength={10}
                type="number"
                name="$1"
                id="$1"
                Onblur={(e: any) => handleNonEmptyValue(e)}
                OnChange={(e: any) => handleCashBeingdropInp(e)}
              />
            </RACTableCell>
            <RACTableCell
              
              className={`${'racstrap-tablecell-BgColor'} ${classes.total} ${classes.racTableCellColor} ${classes.tableCell1
                }`}
            >
              <Typography className={` ${classes.typoTotal}`}>
                ${' '}
                {formatMoney(
                  (parseFloat(denominationQuantity.denominationQuantity1 !== '' ? denominationQuantity.denominationQuantity1 : '0') * 1).toFixed(2)
                )}
              </Typography>
            </RACTableCell>
          </RACTableRow>
        </React.Fragment>
      </>
    );
  };
  const renderCashTableHead = () => {
    return (
      <React.Fragment>
        <RACTableCell className={`${classes.tableCellDenomination}`}>
          <Typography variant="h5">Denomination</Typography>
        </RACTableCell>
        <RACTableCell className={`${classes.tableCellTotalQuantity}`}>
          <Typography
            variant="h5"
          >
            Total Quantity
          </Typography>
        </RACTableCell>
        <RACTableCell
          className={`${classes.tableCellTotal} ${classes.total}`}
        >
          <Typography variant="h5" className={` ${classes.typoTotal}`}>
            Total
          </Typography>
        </RACTableCell>
      </React.Fragment>
    );
  };
  const renderNonCashTableHead = () => {
    return (
      <React.Fragment>
        <RACTableCell className={`"racstrap-tablecell-BgColor"`} style={{zIndex: tableStricky()}}>
          <Typography variant="h5">Customer</Typography>
        </RACTableCell>
        <RACTableCell className={`"racstrap-tablecell-BgColor"`} style={{zIndex: tableStricky()}}>
          <Typography variant="h5">Doc Type</Typography>
        </RACTableCell>
        <RACTableCell className={`"racstrap-tablecell-BgColor"`} style={{zIndex: tableStricky()}}>
          <Typography variant="h5">Doc #</Typography>
        </RACTableCell>
        <RACTableCell className={`"racstrap-tablecell-BgColor"`} style={{zIndex: tableStricky()}}>
          <Typography variant="h5">Name on Doc</Typography>
        </RACTableCell>
        <RACTableCell
          className={`"racstrap-tablecell-BgColor"`}
          // style={{ width: '10%' }}
          style={{zIndex: tableStricky()}}
        >
          <Typography variant="h5">Receipt #</Typography>
        </RACTableCell>
        <RACTableCell
          // style={{paddingLeft:"5%"}}
          className={`"racstrap-tablecell-BgColor"`}
          style={{zIndex: tableStricky()}}
        >
          <Typography variant="h5" className={classes.textRight}>
            Amount
          </Typography>
        </RACTableCell>
        <RACTableCell className={`"racstrap-tablecell-BgColor"`}  style={{zIndex: tableStricky()}}>
          <Typography variant="h5" className={classes.textCenter}>
            Verified
          </Typography>
        </RACTableCell>
      </React.Fragment>
    );
  };

  const tableStricky: any = () => {
    if (defaultLoader) {
        return -0;
    }
}
const truncateString = (str: string): string => {
  if (str?.length > 12 && str!==undefined && str!==null && str!=='') {
    return (str?.substring(0, 10) + '...').toString();
  }
  return str;
};
  const renderNonCashTableContent = (): any => {
    return drawerDetails?.NonCashDetails?.map((data: any) => {
      return (
        <>
          <React.Fragment>
            {/* Row 1 */}
            <RACTableRow className={classes.tableRow}>
              <RACTableCell title={data?.vendorName}>
                <Typography className={classes.racTableCellColor}>
                  {truncateString(data?.vendorName)}
                </Typography>
              </RACTableCell>
              <RACTableCell>
              <Typography className={classes.racTableCellColor}>
                  {data?.tenderType}
                </Typography>
              </RACTableCell>
              <RACTableCell>
              <Typography className={classes.racTableCellColor}>
                  {data?.documentNumber}
                </Typography>
              </RACTableCell>
              <RACTableCell>
              <Typography className={classes.racTableCellColor}>
                  {data?.documentWrittenBy}
                </Typography>
              </RACTableCell>
              <RACTableCell>
              <Typography className={classes.racTableCellColor}>
                  {data?.reciptNumber}
                </Typography>
              </RACTableCell>
              <RACTableCell className={classes.racTablestyleWidth}>
                <Typography
                  className={`${classes.racTableCellColor} ${classes.textRight}`}
                >
                  $ {formatMoney(parseFloat(data?.cashAmount).toFixed(2))}
                </Typography>
              </RACTableCell>
              <RACTableCell className={classes.textCenter}>
                <RACCheckBox
                  checked={data.isChecked}
                  onClick={(e: any) =>
                    handleNonCashDetail(e, data?.receiptTransactionId)
                  }
                 className={classes.racCheckBoxMarginLeft}
                  size="medium"
                />
              </RACTableCell>
            </RACTableRow>
          </React.Fragment>
        </>
      );
    });
  };

  const todaysDropBtnHandler = async () => {
    // debugger
    try {
      setLoader(true)
      setDefaultLoader(true)
      //moment().format('MM/DD/YYYY'),
      const payload = {
        'businessDate': moment().format('MM/DD/YYYY'),
        'type': 'DROP',
        'storeNumber': sessionStorage.getItem('storeNumber')
      }
      //sessionStorage.getItem('storeNumber')
      const getDocumentDetailResponse = await getDropDocument(payload);
      if (!getDocumentDetailResponse) {
        setisNoDataForTodaysDrop(true)
      } else {
        BindPDF(getDocumentDetailResponse.dropDetails)
      }

      setLoader(false)
      setDefaultLoader(false)


    } catch (e: any) {
      setLoader(false)
      setDefaultLoader(false)
    }
  }
  return (
    <>
      {secondLevelAuthenOn ? (
        <SecondFactor
          setTwoFactorCancelClick={() => setSecondLevelAuthenOn(false)}
          setTwoFactorCompleted={(e: any) => seconduthenResponses(e)}
          currentRole="CAR"
          moduleName="Accept Payment"
        //  storeNumber="02202"
        ></SecondFactor>
      ) : null}
      <Grid className={classes.container}>
        <RACModalCard
          isOpen={isNoDataForTodaysDrop}
          maxWidth="xs"
          closeIcon={true}
          borderRadius="20px"
          onClose={() => setTotalCashExceed(false)}
          // eslint-disable-next-line react/no-children-prop
          children={noRecordFoundMsgPopup()}
        // title="Validation Message"
        />
        <DropVerifiedModelCard />
        <DropEnvelopModalCard />
        <HandleCancelModelCard />
        <HandleSaveModelCard />
        <HandleTotalCashExceed />
        <Grid className={classes.mb0}>
          {/* Drops starts here */}
          <Grid className={classes.rowMt2}>
            <Grid className={classes.colMd12mt3}>
              <Grid className={classes.row}>
                {/* Drawer total starts here */}
                <Typography variant="h4" className={classes.header}>
                  Drops
                </Typography>
                <Grid className={`${classes.racColMD7} ${classes.mt2}`}>
                  <Typography variant="h4" className={classes.header}>
                    Drawer Total
                  </Typography>
                  <Card className={classes.card}>
                    <CardContent className={classes.cardBody}>
                      <Grid className={classes.row}>
                        <Grid className={classes.colMd2}>
                          <Typography
                            variant="h5"
                            className={`${classes.formLabelNonCash} ${classes.typographyPadding}`}
                          >
                            Non-Cash
                          </Typography>

                          <Typography className={classes.formValue}>
                            {formatMoney(getNonCashDetails())}
                          </Typography>
                        </Grid>
                        <Grid className={classes.colMd2}>
                          <Typography
                            variant="h5"
            
                            className={`${classes.formLabelCash} ${classes.typographyPadding}`}
                          >
                            Cash
                          </Typography>
                          {
                            !isSkeleton ? (
                              <Typography className={classes.formValue}>
                                {formatMoney(getCashDetails())}
                                {/* $ {drawerTotalGrid.cash.toFixed(2)} */}
                              </Typography>
                            ) : (
                              ''
                            )
                            // <Typography>
                            //   <Skeleton
                            //     count={1}
                            //     baseColor="#FFFFFF"
                            //     highlightColor="#e7e7e7"
                            //   />
                            // </Typography>
                          }
                          <Typography
                            className={classes.formLabelDefaultStarting}
                          >
                            (Including default starting)
                          </Typography>
                        </Grid>
                        <Grid className={classes.colMd2}>
                          <Typography
                            variant="h5"
                           
                            className={`${classes.formLabelTotalCash} ${classes.typographyPadding}`}
                          >
                            Total
                          </Typography>
                          <Typography className={classes.formValue}>
                            {/*returns the Total amount including nonCash and cash and the default amount.*/}
                            {/* { 
                            formatMoney(
                              drawerTotalAmt !== undefined &&
                                drawerTotalAmt !== ''
                                ? parseFloat(drawerTotalAmt + defaultStringAmount) >=0 ? '$'+` `+ parseFloat(drawerTotalAmt + defaultStringAmount).toFixed(2) :
                                '($'+` `+ (parseFloat(drawerTotalAmt + defaultStringAmount) * -1).toFixed(2) +')'
                                : '0' + defaultStringAmount
                           )} */}
                             {formatMoney((drawerTotalAmt!==undefined && drawerTotalAmt!==''? (parseFloat(drawerTotalAmt)>=0? '$'+` `+parseFloat(drawerTotalAmt).toFixed(2) :'($'+` `+(parseFloat(drawerTotalAmt) * -1).toFixed(2) +')') :'$'+` `+(parseFloat("0.00")+ parseFloat(defaultStringAmount)).toFixed(2)))}
                          </Typography>
                        </Grid>
                        <Grid className={classes.colMd2}>
                          <Typography
                            variant="h5"
                            
                            className={`${classes.formLabelDefaultCash} ${classes.typographyPadding}`}
                          >
                            Default Starting Till
                          </Typography>
                          <Typography className={classes.formValue}>
                            {/*returns only the default amount from the object drawerTotalGrid.*/}
                            {/* ${drawerTotalGrid.defaultStartingValue.toFixed(2)} */}
                            {`$ ${parseFloat(defaultStringAmount).toFixed(2)}`}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                {/* Drawer total ends here */}
                {/* Cash Drop Range starts here */}
                <Grid className={`${classes.colLg4} ${classes.mt2}`}>
                  <Typography variant="h4" className={classes.header}>
                    Cash Drop Range
                  </Typography>
                  <Card className={classes.card}>
                    <CardContent className={classes.cardBody}>
                      <Grid className={classes.row}>
                        <Grid className={classes.columnMd2}>
                          <Typography
                            variant="h5"
                            className={classes.formLabelMaxDrop}
                          >
                            Max Drop Allowed
                          </Typography>
                          <Typography
                            className={classes.formLabelDefaultStarting}
                          >
                            (Total Cash-Default Starting Till)
                          </Typography>
                        </Grid>
                        <Grid className={classes.colMd4}>
                          <Typography className={classes.cashText}>
                            {/*returns cash -default amount .*/}
                            {/* $ {parseFloat(maxDropAllowed.toFixed(2)).toFixed(2)} */}
                            $ {formatMoney(cashdropAllowded)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            {/* Cash Drop Range ends here */}
          </Grid>
          {/* Drops ends here */}
          {/* Cash being dropped starts here */}
          <Grid className={classes.cashBeingDropWid}>
            {/* <CurrentTill></CurrentTill> */}
            <Grid
              className={`${classes.mt3} ${classes.cashBeingDropped}`}
              item
              
            >
              <Typography variant="h4" className={classes.header}>
                Cash Being Dropped
              </Typography>

              <Card className={classes.card}>
                <CardContent className={`${classes.cardBody2} ${classes.p2}`}>
                  <Grid className={`${classes.row} ${classes.mb3}`}>
                    <Grid className={classes.racColMD12}>
                      <RACTable
                        className={`${classes.table} ${classes.tableBorder}`}
                        renderTableHead={renderCashTableHead}
                        renderTableContent={renderCashTableContent}
                      />
                      {/* $ {totalCashValue.toFixed(2)} */}
                      <Grid
                        className={`${classes.float} ${classes.totalCashBeingDrop} `}
                      >
                        <Grid
                         
                          className={`${classes.floatLeft} ${classes.TotalCashBeingWidth}`}
                        >
                          <Typography className={classes.drawerTotal}>
                            Total Cash Being Dropped
                          </Typography>
                        </Grid>
                        <Grid
                         
                          className={`${classes.floatLeft} ${classes.TotalCashBeingGridWidth}`}
                        >
                          <Typography
                          className={classes.dropAmountCash}
                          >
                            {' '}
                            $ {formatMoney(parseFloat(dropAmount.cash).toFixed(2))}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            {/* <NonCashComponent></NonCashComponent> */}
            <Grid
              className={`${classes.mt3} ${classes.nonCashBeingDropWid}`}
              item
             
            >
              <Typography variant="h4" className={classes.header}>
                Non-Cash Being Dropped
              </Typography>
              <Card
                className={`${classes.card} ${classes.nonCashBeingDropCardWid}`}
              >
                <CardContent className={`${classes.cardBody} ${classes.p2}`}>
               { drawerDetails !== undefined &&
                    drawerDetails.NonCashDetails !== undefined &&
                    drawerDetails.NonCashDetails !== null &&
                    drawerDetails.NonCashDetails.length > 0  ? 
                    <RACTable
                className={`${classes.table} ${classes.tableBorder} ${classes.mb0}`}
                renderTableHead={renderNonCashTableHead}
                renderTableContent={renderNonCashTableContent}
                stickyHeader
              />  :
                <>
                <Grid className={classes.row}>
                <Grid className={classes.Customer}>
                  <Typography variant="h5" >
                  Customer
                  </Typography>
                </Grid>
                  <Grid className={classes.DocType}>
                  <Typography variant="h5">
                  Doc Type
                  </Typography>
                  </Grid>
                  <Grid className={classes.Doc} >
                  <Typography variant="h5">
                  Doc #
                  </Typography>
                  </Grid>
                  <Grid className={classes.NameonDoc}>
                  <Typography variant="h5">
                  Name on Doc
                  </Typography>
                  </Grid>
                  <Grid  className={classes.DocType}>
                  <Typography variant="h5">
                  Receipt #
                  </Typography>
                  </Grid>
                  <Grid  className={classes.DocType}>
                  <Typography variant="h5">
                  Amount
                  </Typography>
                  </Grid>
                  <Grid className={classes.Verified}>
                  <Typography variant="h5">
                  Verified
                  </Typography>
                  </Grid>
                </Grid> 
                <Grid className={classes.NoRecordFound} >
                  <Typography variant="h6">
                  No Record Found
                  </Typography>
                </Grid>
                </>
                }
                  {drawerDetails !== undefined &&
                    drawerDetails.NonCashDetails !== undefined &&
                    drawerDetails.NonCashDetails !== null ? (
                    drawerDetails.NonCashDetails.length > 0 ? (
                      <Grid
                       
                        className={`${classes.float} ${classes.TotalnonCashWidth}`}
                      >
                        <Grid
                         
                          className={`${classes.floatLeft} ${classes.totalNoncashwidths}`}
                        >
                          <Typography className={classes.drawerTotal}>
                            Total Non-Cash Being Dropped
                          </Typography>
                        </Grid>
                        <Grid
                          className={`${classes.floatLeft} ${classes.totalNoncashTypowidth}`}
                        >
                          <Typography
                            className={classes.totalNonCashhdropAmountCash}
                          >
                            $ {formatMoney(dropAmount.nonCash)}
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : null
                  ) : (
                    ''
                  )}
                </CardContent>
              </Card>
            </Grid>
            {/* <DropInformation></DropInformation> */}
            <Grid
              item
             className={classes.DropInformationWid}
            >
              <Typography
                variant="h4"
                className={classes.DropInformationMarginBtm}
              >
                Drop Information
              </Typography>
              <Card className={`${classes.card} ${classes.DropInformationPadding}`} >
                <CardContent className={classes.cardBody}>
                  <Grid className={classes.row}>
                    <Grid className={classes.colMd4}>
                      <Grid className={classes.row}>
                        <Grid className={classes.DropEnvelopeWid}>
                          <Typography
                            variant="h5"
                            className={classes.DropEnvelope}
                          >
                            Drop Envelope #
                            <span style={{ color: 'red' }}> *</span>
                          </Typography>
                        </Grid>
                        <Grid className={classes.DropEnvelopeWidth}>
                          <RACTextbox
                            className={`${classes.textWidth} ${classes.formValue}`}
                            //  style={{width:"20px"}}
                            type="number"
                            name="Envelope"
                            id="Envelope"
                            maxlength={8}
                            value={envNumber.toString()}
                            required={false}
                            inputlabel=""
                            isCurrency={false}
                            OnChange={(e: any) => handleEnvelopInp(e)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      className={`${classes.row} ${classes.nonCashWidth}`}
                    >
                      <Grid className={classes.nonCashGridWid}>
                        <Typography
                          variant="h5"
                          className={classes.nonCashTypoWid}
                        >
                          Non-Cash
                        </Typography>
                      </Grid>
                      <Grid
                        className={classes.dropAmountWids}
                      >
                        <Typography
                          className={`${classes.formValue} ${classes.formValueDropAmount}`}
                        >
                          $ {formatMoney(dropAmount.nonCash)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid className={classes.formValueGridDropAmount}>
                      <Typography
                        variant="h5"
                        className={`${classes.formLabelCash} ${classes.cashPaddingTop}`}
                      >
                        Cash
                      </Typography>
                      <Typography
                        className={`${classes.formValue} ${classes.formValueCash}`}
                      >
                        $ {formatMoney(parseFloat(dropAmount.cash).toFixed(2))}
                      </Typography>
                    </Grid>
                    <Grid
                      className={`${classes.row} ${classes.formValueTotal}`}
                    >
                      <Typography
                        variant="h5"
                        className={classes.formValueTotalTypo}
                      >
                        Total
                      </Typography>
                      <Typography
                       className={classes.formValueTotalAmount}
                      >
                        {/*returns the Total amount including nonCash and cash and the default amount.*/}
                        ${' '}
                        {formatMoney((
                          parseFloat(dropAmount.cash) +
                          parseFloat(dropAmount.nonCash)
                        ).toFixed(2))}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              // className={classes.mt3}
              item
              style={{
                float: 'left',
                width: '60%',
                marginLeft: '3.5%',
                paddingBottom: '20px',
              }}
            >
              <Grid
               className={classes.DropVerifiedByGrid}
              >
                <Grid  className={classes.DropVerifiedByGridWid}>
                  <Typography
                    variant="h5"
                   className={classes.DropVerifiedByTypoWid}
                  >
                    Drop Verified By
                    <span style={{color:"red"}}> *</span>
                  </Typography>
                  {/* <Card style={{ paddingBottom: "2%" }} className={classes.card}> */}
                  {/* <CardContent className={`${classes.cardBody} ${classes.p2}`}> */}
                  <RACSelect
                    classes={{ formControl: 'classes.RACTextBox' }}
                    name="dropVerified"
                    defaultValue={
                      // state!==''&& state.length> 0 ? state[0].value:'0'
                      data?.dropVerified !== undefined &&
                        data?.dropVerified !== null &&
                        data?.dropVerified !== ''
                        ? data?.dropVerified
                        : '0'
                    }
                    options={coworkerDD}
                    onChange={(event: any) => {
                      handleText(event, 'DD');
                    }}
                  />
                  {/* </CardContent> */}
                  {/* </Card> */}
                </Grid>
                {/* Drop verified by ends here */}
                {/* Drop notes starts here */}
                <Grid
                  item
                 className={classes.DropNotesGrid}
                >
                  <Typography variant="h4" className={classes.header}>
                    Drop Notes
                  </Typography>
                  <textarea
                    rows={4}
                    cols={74}
                    className={`${classes.dropNotes} ${classes.DropNotesTextArea}`}
                    value={dropNotes}
                    onChange={(e) => handleDropNotes(e)}
                  ></textarea>
                </Grid>
              </Grid>
              {/* Drop notes ends here */}
            </Grid>
          </Grid>
          {/* Footer starts here */}
          <Grid container classes={{
            root: clsx(classes.customerToolbar, classes.fixedBottom),
          }}  style={{ zIndex: 0 }} >
          <Grid className={classes.footerFixed}>
              <Grid className={classes.floatLeft}>
                <RACButton
                  variant="outlined"
                  color="secondary"
                  onClick={() => handleCancelBtn()}
                >
                  Cancel
                </RACButton>
              </Grid>
              <Grid  className={classes.SeeTodaysDropWid}>
                <RACButton
                  disabled={loader}
                  variant="contained"
                  color="primary"
                  className={classes.seeTodaysBtnMarginRyt}
                  onClick={todaysDropBtnHandler}
                >
                  {`See Today's Drops`}
                </RACButton>

                <RACButton
                  variant="contained"
                  color="primary"
                  disabled={
                    (parseFloat(dropAmount.cash) > 0 ||
                      (drawerDetails?.NonCashDetails.length > 0 &&
                        dropAmount.nonCash !== '0.00')) &&
                      (data.dropVerified !== '' && data.dropVerified !=='0') &&
                      envNumber !== ''
                      ? false
                      : true
                  }
                  onClick={() => handleSaveBtn()}
                >
                  Save
                </RACButton>
              </Grid>
          </Grid>
          </Grid>
          {/* Footer ends here */}
        </Grid>
      </Grid>
      <Grid>
        <RACModalCard
          isOpen={successPopup}
          maxWidth="xs"
          closeIcon={true}
          borderRadius="20px"
          onClose={() => 
            {history.push("/dashboard/homepage")}
          }
          // eslint-disable-next-line react/no-children-prop
          children={UpdateInfoMsgPopup()}
        />
      </Grid>
      <Modal
        isOpen={loader}
        className={classes.loaderStyle}
        id="openpopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid>
          <Grid>
            <Grid className={classes.RACLoaderPage}>
              <CircularProgress />
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
}

