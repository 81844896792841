/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-all-duplicated-branches */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-duplicated-branches */
/* eslint-disable prettier/prettier */
/* eslint-disable no-debugger */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-redundant-boolean */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import {
    Grid,
    Typography,
    Card,
    CardContent,
    RACSelect,
    RACTextbox,
    RACButton,
    RACModalCard,
    RACRadio,
    CircularProgress,

} from "@rentacenter/racstrap";
import { customerStyles } from "./PaidInStyle"
import { ReactComponent as GlobalSearch } from "../SearchButton/GlobalSearch.svg";
import PaidInSummary from "./PaidInSummary";

import { GetIntitailDataForManageTransaction } from "../../api/user";
import clsx from "clsx";

export function PaidIn(props: any) {
    console.log('props paidin', props)
    const classes = customerStyles();
    interface paidOutDetail {
        paidType: string;
        paidTypeRefCode: string;
        amount: string;
        receiptSubmittedBy: string;
        inventoryId: string;
        inventoryNumber: string;
        paidDesc: string;
        tenderType: string;
        writtenBy: string;
        checknumber: string;
        payer: string;
    }
    const [paidOutDetails, setpaidOutDetails] = useState<paidOutDetail | any>({ paidType: '', amount: '0.00', receiptSubmittedBy: '', inventoryId: '', paidDesc: '', writtenBy: '', checknumber: '', payer: '', tenderType: 'CASH', inventoryNumber: '' });
    const [paidOutDetailsArray, setPaidOutDetailsArray] = useState<paidOutDetail[]>();
    const [totalAmount, setTotalAmount] = useState<number>(0);
    const [hideItem, sethideItem] = useState(true);
    const [editIndex, setEditIndex] = useState<number>();
    const [Click, setClick] = useState(true)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [ValidateIsOpen, setValidateIsOpen] = useState(false);
    const [showPaidIn, setshowPaidIn] = useState(true);
    const [checklengthPopup, setchecklengthPopup] = useState(false);
    const [transactionTypeDD, settransactionTypeDD] = useState([]);
    const [configValues, setConfigValues] = useState({ maxPaidInAmount: '201', paidInInventoryReleatedItems: [], drawerDefaultAmount: '200' });
    const [loader, setloader] = useState(true);
    const [apiResponse, setApiResponse] = useState<any>();
    const [ValidateisopenZero, setValidateisopenZero] = useState(false);
    const [tenderType, setTenderType] = useState({
        cash: true,
        check: false,
        moneyOrder: false
    })
    const paidInInventoryReleatedItems = ['Reversal of Parts Appliance', 'Reversal of Parts Furniture', 'Reversal of Parts Electronic', 'Reversal of Parts Computer']
    const history = useHistory();
    useEffect(() => {
        const pageLoad = async () => {
            try {
                console.log('log for redirection state paidin', history, props)
                const historyData: any = history.location?.state
                console.log('historyData', historyData)

                if (historyData && historyData.apiResponse) {
                    sethideItem(false)
                    setApiResponse(historyData.apiResponse)
                    bindDDHandler(historyData.apiResponse)
                    bindConfigValues(historyData.apiResponse.configValues)
                    setpaidOutDetails({ ...historyData.paidOutDetails, inventoryId: historyData.inventoryId, inventoryNumber: historyData.inventoryNumber })
                    setloader(false)
                    setTenderType({
                        cash: historyData.paidOutDetails.tenderType == 'CASH' ? true : false,
                        check: historyData.paidOutDetails.tenderType == 'CHK' ? true : false,
                        moneyOrder: historyData.paidOutDetails.tenderType == 'MO' ? true : false
                    })
                    return
                }
                setloader(true)
                const payload = {
                    "storeNumbers": [
                        sessionStorage.getItem('storeNumber')
                    ],
                    "paramKeyNames": [
                        "MaxPaidInAmount",
                        "DrawerDefaultAmount"
                    ]
                }
                const response = await GetIntitailDataForManageTransaction(payload)
                console.log('responsee', response)
                bindConfigValues(response.configValues)

                // setConfigValues(response)
                bindDDHandler(response)
                setApiResponse(response)
                setloader(false)
            } catch (e: any) {
                setloader(false)
            }

        }
        // }
        pageLoad()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect(() => {
        const historyData: any = history.location?.state
        setClick(false)
        if (!paidOutDetails.amount?.trim() || !paidOutDetails.paidDesc.trim() || !paidOutDetails.payer.trim() || !paidOutDetails.paidType) {
            setClick(true)
        }
        if ((tenderType.check || tenderType.moneyOrder) && (!paidOutDetails?.checknumber.trim() || !paidOutDetails.writtenBy.trim())) {
            setClick(true)
        }

        console.log('historyData', historyData)
        // if ((paidOutDetails && (paidOutDetails?.paidType?.trim() != "" && paidOutDetails?.amount?.trim() != "" && paidOutDetails?.payer?.trim() != "" && paidOutDetails?.paidDesc?.trim() != "" && paidOutDetails?.writtenBy?.trim() != "" && paidOutDetails?.checknumber?.trim() != "") || (paidOutDetails?.inventoryId?.trim() != ""))) {
        //     if (hideItem == true || (hideItem == false && paidOutDetails.inventoryId !== undefined && paidOutDetails?.inventoryId == "")) {
        //         setClick(false)
        //     }
        //     else {
        //         setClick(true)
        //     }
        // }
        // else {
        //     setClick(true)
        // }

        // if (paidOutDetails && (paidOutDetails?.paidType?.trim() != "" && paidOutDetails?.amount?.trim() != "" && paidOutDetails?.payer?.trim() != "" && paidOutDetails?.paidDesc?.trim() != "")) {
        //     if (hideItem == true || (hideItem == false && paidOutDetails.inventoryId !== undefined && paidOutDetails?.inventoryId == "")) {
        //         setClick(false)
        //     }
        //     else {
        //         setClick(true)
        //     }
        //     if(tenderType.cash == true || (tenderType.cash == false && paidOutDetails.checknumber !== undefined  && paidOutDetails?.checknumber != ""  && paidOutDetails?.writtenBy?.trim() != "" )){
        //         setClick(false)
        //     }
        //     else{
        //         setClick(true)
        //     }
        // }
        // if(tenderType.cash == true){
        //     setClick(true)
        // }
        // console.log()
        setClick(false)
        if (!paidOutDetails.amount?.trim() || !paidOutDetails.paidDesc.trim() || !paidOutDetails.payer.trim() || !paidOutDetails.paidType) {
            setClick(true)
        }
        if ((tenderType.check || tenderType.moneyOrder) && (!paidOutDetails?.checknumber.trim() || !paidOutDetails.writtenBy.trim())) {
            setClick(true)
        }
        if (paidOutDetails.amount && parseFloat(paidOutDetails.amount) <= 0) {
            setClick(true)
        }
        if (!hideItem && !paidOutDetails.inventoryNumber) {
            setClick(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paidOutDetails.amount, paidOutDetails.checknumber, paidOutDetails.paidDesc, paidOutDetails.writtenBy, paidOutDetails.payer, paidOutDetails.paidType, paidOutDetails.tenderType, hideItem, paidOutDetails.inventoryNumber])
    const bindDDHandler = (response: any) => {
        let transactionTypeId = ''
        for (let id = 0; id < response.receiptItemCategory.referenceDetails.length; id++) {
            if (response.receiptItemCategory.referenceDetails[id].referenceCode == 'PAIDIN') {
                transactionTypeId = response.receiptItemCategory.referenceDetails[id].id
                break
            }
        }
        const buildStateDropDown: any = []
        response.pettyCashRes.referenceDetails.forEach((value: any, index: any) => {
            if (index == 0) {
                buildStateDropDown.push({ label: 'Select', value: '0' });
            }
            if (value.receiptItemCategoryId == transactionTypeId) {
                buildStateDropDown.push({
                    label: value.descEn,
                    value: value.descEn,
                });
            }
        });
        settransactionTypeDD(buildStateDropDown)
    }
    const bindConfigValues = (response: any) => {
        const configVal = {
            maxPaidInAmount: '',
            paidInInventoryReleatedItems: [],
            drawerDefaultAmount: ''
        }
        console.log(configValues)
        response.storeProfileResponse.configDetails[0].configDetails.forEach((el: any) => {
            if (el.paramKeyName == 'MaxPaidInAmount') {
                configVal.maxPaidInAmount = el.paramValue
            }
            if (el.paramKeyName == 'DrawerDefaultAmount') {
                configVal.drawerDefaultAmount = el.paramValue
            }

        })
        setConfigValues(configVal)
    }

    const redirectInventoryHandler = () => {
        history.push({
            pathname: '/inventory/inventorysearch',
            search: '?paidin=1',
            state: { paidOutDetails, paidOutDetailsArray, apiResponse }
        });
    }
    const handleChange = (e: any, src?: string) => {
        if (src == 'DD') {
            setpaidOutDetails({
                ...paidOutDetails, paidType: e.target.value,
                paidTypeRefCode: apiResponse.pettyCashRes.referenceDetails.filter((el: any) => el.descEn == e.target.value)[0].refCode
            })
            if (paidInInventoryReleatedItems.includes(e.target.value)) {
                sethideItem(false)
            }
            else {
                sethideItem(true)
            }
        }
        else if (src == 'RS') {
            setpaidOutDetails({ ...paidOutDetails, receiptSubmittedBy: e.target.value })
        }
        else {
            setpaidOutDetails({ ...paidOutDetails, [e.target.id]: e.target.value })
            console.log("paidOutDetails", paidOutDetails)
        }
    }
    const handleContinueBtn = () => {
        setshowPaidIn(false);
        if (paidOutDetails && ((Number(paidOutDetails.amount?.replaceAll(',', ''))) > Number(configValues.maxPaidInAmount) - 1)) {
            setValidateIsOpen(true);
            setshowPaidIn(true);

        }
        else if (paidOutDetails && ((Number(paidOutDetails.amount)) == 0)) {
            setshowPaidIn(true);
            setValidateisopenZero(true);
        }
        else {
            let sum = 0
            const currentPaidOutValues = paidOutDetailsArray !== undefined ? paidOutDetailsArray : [];
            if (editIndex !== undefined) {
                currentPaidOutValues.length > 0 && currentPaidOutValues.splice(editIndex, 1);
                currentPaidOutValues.push(paidOutDetails);
                console.log("PaidOut", currentPaidOutValues)
                setEditIndex(editIndex);

            } else {
                currentPaidOutValues.push(paidOutDetails);
                setPaidOutDetailsArray(currentPaidOutValues);
                console.log("module", currentPaidOutValues);
            }
            for (let amount = 0; amount < currentPaidOutValues.length; amount++) {
                sum += currentPaidOutValues && currentPaidOutValues[amount] && currentPaidOutValues[amount].amount ? Number(currentPaidOutValues[amount].amount) : 0
            }
            setTotalAmount(sum)
        }

    }
    const handleClickNo = () => {
        setValidateIsOpen(false)
    }
    const bindData = (value: any, id: any) => {
        setshowPaidIn(true);
        setEditIndex(id)
        setpaidOutDetails(value)
    }
    const handleRadio = (e: any) => {

        if (e.target.name == 'CASH') setTenderType({ cash: true, check: false, moneyOrder: false })
        if (e.target.name == 'CHK') setTenderType({ cash: false, check: true, moneyOrder: false })
        if (e.target.name == 'MO') setTenderType({ cash: false, check: false, moneyOrder: true })
        // setpaidOutDetails({ paidType: '', amount: '', paidDesc: '', payer: '', writtenBy: '', checknumber: '' })
        setpaidOutDetails({ tenderType: e.target.name, paidType: '', amount: '0.00', paidDesc: '', payer: '', writtenBy: '', checknumber: '' })
        sethideItem(true)
    }
    const handleCheck = () => {
        if (paidOutDetails && paidOutDetails.checknumber && paidOutDetails.checknumber.length < 5) {
            setchecklengthPopup(true)
        }

        if (paidOutDetails && paidOutDetails.checknumber == "") {
            setchecklengthPopup(true)
        }

    }
    const handleOk = () => {
        setchecklengthPopup(false)
    }
    const addressDoctorPopupChecklength = () => {
        return (
            <>
                <Grid item md={12} >
                    <Grid item md={12} className={classes.textCenter}>
                        <Typography className={classes.RACPOPMsg}>
                            Check number needs to be at least 5 numeric characters.<br></br>Please enter a value that is at least 5 numeric characters long.

                        </Typography>

                    </Grid>
                </Grid>
                <Grid

                >

                    <Grid className={`${classes.textCenter} ${classes.numericCharacter}`} >

                        <RACButton variant="contained" color="primary" onClick={() => { handleOk() }} >
                            OK
                        </RACButton>
                    </Grid>

                </Grid>
            </>
        );
    }
    const clearData = () => {
        setshowPaidIn(true)
        setPaidOutDetailsArray([])
        // setPaidOutDetailsArray([]);
        setTenderType({
            cash: true,
            check: false,
            moneyOrder: false
        })
        setpaidOutDetails({ paidType: '', amount: '0.00', inventoryId: '', paidDesc: '', writtenBy: '', checknumber: '', payer: '', tenderType: 'CASH' });
        // console.log("value", setPaidOutDetailsArray)
    }
    const handleFooterCancelBtn = () => {
        history.push("/dashboard/homepage")
    }


    const addressDoctorPopup = () => {

        return (
            <>
                <Grid item md={12} >
                    <Grid item md={12} className={`${classes.textCenter} ${classes.paddingmargin}`} >
                        <Typography className={`${classes.RACPOPMsg} ${classes.paddingbottom}`} >
                            The value entered in the amount field is invalid.<br></br>Please enter a valid entry.
                        </Typography>
                    </Grid>
                    <Grid>
                        <Grid className={classes.textCenter} >
                            <RACButton variant="contained" color="primary" onClick={() => { handleClickNo() }} >
                                OK
                            </RACButton>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    };

    const handleClickok = () => {
        setValidateisopenZero(false)
    }

    const addressDoctorPopupZero = () => {
        return (
            <>
                <Grid item md={12} >
                    <Grid item md={12} className={`${classes.textCenter} ${classes.paddingmargin}`} >
                        <Typography className={`${classes.RACPOPMsg} ${classes.paddingbottom}`}>
                            Amount must be greater than 0
                        </Typography>

                    </Grid>

                    <Grid

                    >

                        <Grid className={classes.textCenter} >

                            <RACButton variant="contained" color="primary" onClick={() => { handleClickok() }} >
                                OK
                            </RACButton>
                        </Grid>

                    </Grid>
                </Grid>
            </>
        );
    };

    // const handleamount = () => {

    //     if (paidOutDetails?.amount) {
    //         paidOutDetails.amount = '0.00'
    //     }
    // }



    return (
        (showPaidIn == true) ?
            <>
                <Modal
                    isOpen={loader}
                    className={classes.loaderStyle}
                    id="openpopup"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    aria-hidden="true"
                >
                    <Grid>
                        <Grid>
                            <Grid className={classes.RACLoaderPage}>
                                <CircularProgress />
                            </Grid>
                        </Grid>
                    </Grid>
                </Modal>
                {/* Parent container */}
                <Grid className={classes.containerWid}>
                    {/*Heading SubContainer*/}

                    <Grid className={classes.floatLeft}>
                        <Grid className={classes.colMd12mt3}>
                            <Grid className={`${classes.row} ${classes.paidInMarginLeft}`} >
                                {/* Drawer total starts here */}
                                <Typography variant="h4" className={classes.header} >
                                    Paid In
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* Sub Container */}
                    <Grid className ={classes.containerWid}>
                        <div className={classes.racFixedFooterMargin}>

                            <Card className={classes.card} >
                                <CardContent className={classes.cardBody}>
                                    <Grid className={classes.tenderType} >
                                        <Typography variant="h6" className={classes.tenderTypeMarginTop}>
                                            Tender Type
                                        </Typography>
                                        <Grid className={`${classes.row} ${classes.wid100}`} >
                                            <Grid  className={`${classes.radioPosition} ${classes.radioWidth}`} >
                                                <RACRadio
                                                    id="radio"
                                                    name="CASH"
                                                    checked={tenderType.cash}
                                                    value="CASH"
                                                    required={true}
                                                    label="Cash"

                                                    onClick={(e: any) => { handleRadio(e) }}

                                                />
                                            </Grid>
                                            <Grid className={classes.radioWidth}>
                                                <RACRadio
                                                    id="radio"
                                                    name="CHK"
                                                    checked={tenderType.check}
                                                    value="CHK"
                                                    required={true}
                                                    label="Check"
                                                    onClick={(e: any) => { handleRadio(e) }}
                                                />
                                            </Grid>
                                            <Grid className={classes.MoneyOrderRadioWidth}>
                                                <RACRadio

                                                    id="radio"
                                                    name="MO"
                                                    checked={tenderType.moneyOrder}
                                                    value="Money"
                                                    required={true}
                                                    label="Money Order"
                                                    onClick={(e: any) => { handleRadio(e) }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {/* Paid in type dropdown */}
                                    <Grid className={classes.wid100}>
                                        <Grid className={classes.row}>
                                            {/* paid type */}
                                            <Grid className={classes.paidInTypeWidth} >
                                                <Grid className={classes.paidInwid} >

                                                    <Typography variant="h6" className={classes.paidInMarginBtm} >
                                                        Paid In Type <span style={{ color: "red" }}>*</span>
                                                    </Typography>
                                                    <RACSelect
                                                        options={transactionTypeDD}
                                                        name='paidIn'
                                                        defaultValue={
                                                            paidOutDetails?.paidType !== undefined &&
                                                                paidOutDetails?.paidType !== null &&
                                                                paidOutDetails?.paidType !== ''
                                                                ? paidOutDetails?.paidType : '0'
                                                        }
                                                        onChange={(e: any) => {
                                                            handleChange(e, 'DD')
                                                        }}
                                                        loading={transactionTypeDD.length == 0}
                                                        required={false}
                                                    />
                                                </Grid>
                                            </Grid>
                                            {tenderType.cash == false ?
                                                <>
                                                    <Grid className={classes.AmountWid} >
                                                        <Grid className={classes.amountWidth} >
                                                            <Typography variant="h6" className={classes.paidInMarginBtm} >
                                                                Amount <span style={{ color: "red" }}>*</span>
                                                            </Typography>
                                                            {/* <Grid style={{ width: "100%" }}> */}
                                                            <RACTextbox
                                                                className={classes.amount}
                                                                type="number"
                                                                name="amount"
                                                                id="amount"
                                                                value={(paidOutDetails && paidOutDetails?.amount) ? paidOutDetails?.amount : ''}
                                                                maxlength={8}
                                                                required={false}
                                                                OnChange={(e: any) => { handleChange(e); }}
                                                                Onblur={() => { setpaidOutDetails({ ...paidOutDetails, amount: Number(String(paidOutDetails.amount).replace(',', '')).toFixed(2) }) }}
                                                                isCurrency={true}
                                                            />
                                                            {/* </Grid> */}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid className={classes.writtenByWid}>
                                                        <Grid className={classes.writtenByGrid}>
                                                            <Typography variant="h6" className={classes.paidInMarginBtm} >
                                                                Written By<span style={{ color: "red" }}> *</span>
                                                            </Typography>

                                                            <RACTextbox
                                                                className={classes.writtenBy}
                                                                type="text"
                                                                name="writtenBy"
                                                                id="writtenBy"
                                                                value={(paidOutDetails && paidOutDetails?.writtenBy) ? paidOutDetails.writtenBy : ''}
                                                                maxlength={30}
                                                                required={false}
                                                                OnChange={(e) => { handleChange(e) }}
                                                                isCurrency={false}
                                                            />
                                                            {/* </Grid> */}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid className={classes.moneyOrderWidth} >
                                                        <Grid className={classes.amountWidth}>
                                                            <Typography variant="h6" className={classes.paidInMarginBtm} >
                                                                Check/Money Order #<span style={{ color: "red" }}> *</span>
                                                            </Typography>

                                                            <RACTextbox
                                                                className={classes.writtenBy}
                                                                type="number"
                                                                name="check_money_number"
                                                                id="checknumber"
                                                                value={(paidOutDetails && paidOutDetails?.checknumber) ? paidOutDetails.checknumber : ''}
                                                                maxlength={10}
                                                                required={false}
                                                                OnChange={(e) => { handleChange(e) }}
                                                                Onblur={() => { handleCheck() }}
                                                                isCurrency={false}
                                                            />

                                                        </Grid>
                                                    </Grid>
                                                    <Grid className={classes.payerTotalWidth} >
                                                        <Grid className={classes.amountWidth} >
                                                            <Typography variant="h6" className={classes.paidInMarginBtm} >
                                                                Payer(vendor)<span style={{ color: "red" }}> *</span>
                                                            </Typography>

                                                            <RACTextbox
                                                                className={classes.amount}
                                                                type="text"
                                                                name="payer"
                                                                id="payer"
                                                                value={(paidOutDetails && paidOutDetails?.payer) ? paidOutDetails.payer : ''}
                                                                maxlength={30}
                                                                required={false}
                                                                OnChange={(e) => { handleChange(e) }}
                                                                isCurrency={false}
                                                            />

                                                        </Grid>
                                                    </Grid>
                                                </> : ''}
                                        </Grid>
                                    </Grid>

                                    <Grid className={`${classes.mt3} ${classes.PaidInCategoryDes}`}
                                        item
                                    >
                                        <Typography variant="h6" className={classes.stylebottomrow} >
                                            Paid In Category Description
                                        </Typography>
                                        <textarea
                                            rows={4}
                                            cols={74}
                                            disabled={true}
                                            // id="PaidDesc"
                                            // name="PaidDesc"
                                            className={`${classes.dropNotes} ${classes.styletextarea}`}
                                            value={(paidOutDetails && paidOutDetails?.paidType) ? paidOutDetails.paidType : ''}
                                        ></textarea>
                                    </Grid>

                                    {/* Amount , item,Receipt Submitted by*/}
                                    <Grid className={classes.wid100}>
                                        <Grid className={classes.row}>
                                            {tenderType.cash == true ?
                                                <Grid className={`${classes.AmountWid} ${classes.floatLeft}`} >
                                                    <Typography variant="h6" className={classes.paidInMarginBtm} >
                                                        Cash Amount <span style={{ color: "red" }}> *</span>
                                                    </Typography>
                                                    {/* <Grid style={{ width: "100%" }}> */}
                                                    <RACTextbox
                                                        className={classes.amount}
                                                        isCurrency={true}
                                                        type={'number'}
                                                        digitFormat={'currency'}
                                                        id="amount"
                                                        value={(paidOutDetails && paidOutDetails.amount) ? paidOutDetails.amount : ''}
                                                        maxlength={8}
                                                        required={false}
                                                        OnChange={(e: any) => { handleChange(e) }}
                                                        Onblur={() => { setpaidOutDetails({ ...paidOutDetails, amount: Number(String(paidOutDetails.amount).replace(',', '')).toFixed(2) }) }}
                                                    />
                                                    {/* </Grid> */}
                                                </Grid> : ''}
                                            <Grid hidden={hideItem} >
                                                <Grid className={`${classes.widthofstyle} ${classes.floatLeft}`}>
                                                    <Typography variant="h6" className={classes.paidInMarginBtm} >
                                                        Item <span style={{ color: "red" }}> *</span>
                                                    </Typography>
                                                    {/* <Grid style={{ width: "100%" }}> */}
                                                    <RACTextbox
                                                        className={`${classes.item} `}
                                                        type="number"
                                                        name="isInvField"
                                                        id="isInvField"
                                                        value={(paidOutDetails && paidOutDetails.inventoryNumber) ? paidOutDetails.inventoryNumber : ''}
                                                        disabled={true}
                                                        maxlength={13}
                                                        required={false}

                                                        OnChange={(e) => { handleChange(e) }}
                                                        isCurrency={false}
                                                    />
                                                    {/* </Grid> */}
                                                </Grid>
                                                <Grid className={classes.paidoutinstyle}
                                                    id="inventoryId" onClick={redirectInventoryHandler}>

                                                    <GlobalSearch />
                                                </Grid>
                                            </Grid>
                                            {tenderType.cash == true ?
                                                <Grid className={classes.payerVendor} >
                                                    <Grid
                                                    >
                                                        <Typography variant="h6" className={classes.paidInMarginBtm} >
                                                            Payer(Vendor) <span style={{ color: "red" }}> *</span>
                                                        </Typography>
                                                        <RACTextbox className={classes.vendor}
                                                            type="text"
                                                            name="payer"
                                                            id="payer"
                                                            value={(paidOutDetails && paidOutDetails.payer) ? paidOutDetails.payer : ''}
                                                            maxlength={30}
                                                            required={false}
                                                            OnChange={(e) => { handleChange(e) }}
                                                            isCurrency={false}
                                                        />
                                                    </Grid>
                                                </Grid> : ''}
                                        </Grid>
                                    </Grid>
                                    {/* Description */}
                                    <Grid
                                        className={`${classes.mt3} ${classes.PaidInCategoryDes}`}
                                        item
                                    >
                                        <Typography variant="h6" className={classes.stylebottomrow}  >
                                            Description
                                            <span style={{ color: "red" }}> *</span>
                                        </Typography>
                                        <textarea
                                            rows={4}
                                            cols={74}
                                            className={`${classes.dropNotes} ${classes.paidinoutpadding}`}
                                            id="paidDesc"
                                            name="paidDesc"
                                            value={(paidOutDetails && paidOutDetails.paidDesc) ? paidOutDetails.paidDesc : ''}
                                            onChange={(e: any) => { handleChange(e) }}

                                        ></textarea>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </div>

                        {/* </Grid>   */}
                    </Grid>
                    <Grid container classes={{
                        root: clsx(classes.customerToolbar, classes.fixedBottom),
                    }}
                        style={{ zIndex: 0 }}>
                        <Grid className={classes.footerFixed}>
                            <Grid className={classes.floatLeft}>
                                <RACButton variant="outlined" color="secondary" onClick={() => { handleFooterCancelBtn() }} >
                                    Cancel
                                </RACButton>
                            </Grid>

                            <Grid className={classes.floatRight}>
                                <RACButton
                                    variant="contained"
                                    color="primary"
                                    disabled={Click}
                                    onClick={() => handleContinueBtn()}

                                >
                                    Continue
                                </RACButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {/* {loader == true ?
                    <Grid container className={classes.footerGrid}>
                        <Grid className={classes.footerFixed}>
                            <Grid style={{ float: "left" }}>
                                <RACButton variant="outlined" color="secondary" onClick={() => { handleFooterCancelBtn() }} disabled={true} >
                                    Cancel
                                </RACButton>
                            </Grid>

                            <Grid style={{ float: "right" }}>
                                <RACButton
                                    variant="contained"
                                    color="primary"
                                    disabled={Click}
                                    onClick={() => handleContinueBtn()}


                                >
                                    Continue
                                </RACButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    : */}

                {/* } */}
                {/* Popups */}

                <Grid>
                    <RACModalCard
                        isOpen={checklengthPopup}
                        maxWidth="sm"
                        closeIcon={true}
                        borderRadius="20px"
                        onClose={() => setchecklengthPopup(false)}
                        // eslint-disable-next-line react/no-children-prop
                        children={addressDoctorPopupChecklength()}
                    />
                </Grid>
                <Grid>
                    <RACModalCard
                        isOpen={ValidateIsOpen}
                        maxWidth="sm"
                        closeIcon={true}
                        borderRadius="20px"
                        onClose={() => setValidateIsOpen(false)}
                        // eslint-disable-next-line react/no-children-prop
                        children={addressDoctorPopup()}
                    />
                </Grid>
                <Grid>
                    <RACModalCard
                        isOpen={ValidateisopenZero}
                        maxWidth="xs"
                        closeIcon={true}
                        borderRadius="20px"
                        onClose={() => setValidateisopenZero(false)}
                        // eslint-disable-next-line react/no-children-prop
                        children={addressDoctorPopupZero()}
                    />
                </Grid>

            </>
            : <PaidInSummary paidOutDetailsArray={paidOutDetailsArray} bindData={bindData} totalAmount={totalAmount} clearData={clearData} configVal={configValues} />

    )
}
