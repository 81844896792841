/* eslint-disable prettier/prettier */
export const ProductType = 'RPDCASHMGMT';

export enum ProductPermissions {
  CashMgmt = 'CashMgmt',
}

export function formatMoney(Money: any) {
  if (Money !== undefined && typeof Money === 'string') {
    let MoneyFormat = Money.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (MoneyFormat === '0') {
      MoneyFormat = `${MoneyFormat}.00`;
    }
    return MoneyFormat;
  }
  else {
    return ""
  }
}
export const StoreExceptionType = [
  { "abbreviation": "AGDC", "description": "Agreement declined" },
  { "abbreviation": "APSO", "description": "Agreement Not Digitally Signed By Customer" },
  { "abbreviation": "BNPRSNT", "description": "Agreements are being transferred without both parties being present." },
  { "abbreviation": "APP", "description": "Approval Performed By Same Person" },
  { "abbreviation": "EODOS", "description": "Auto EOD Final Drop Over or Short" },
  { "abbreviation": "BOS", "description": "Bank Over Short" },
  { "abbreviation": "CPO", "description": "Cash Price Override" },
  { "abbreviation": "COP", "description": "Cash Sale Outside BusParam" },
  { "abbreviation": "CPA", "description": "Changed Price On Agreement" },
  { "abbreviation": "CRT", "description": "Changed Rate or Term on Agreement" },
  { "abbreviation": "CB", "description": "Chargeback Activity" },
  { "abbreviation": "CLM", "description": "Claim Extensions" },
  { "abbreviation": "CPOA", "description": "Competitor Price Override On Agreement." },
  { "abbreviation": "CFWV", "description": "Convenience Fee Waived" },
  { "abbreviation": "DEB", "description": "Decision Engine Bypassed" },
  { "abbreviation": "DERLIM", "description": "DE Request Limit Reached" },
  { "abbreviation": "EPD", "description": "EPO Payout Discounted" },
  { "abbreviation": "FDOS", "description": "Final Drop Over Short" },
  { "abbreviation": "FDG", "description": "Free Days Given" },
  { "abbreviation": "POC", "description": "Home Office PO Receiving or Charge Off" },
  { "abbreviation": "IDC", "description": "Inventory Depreciation Cap" },
  { "abbreviation": "INS4A", "description": "Item Not Scanned In 4 Consecutive Audits" },
  { "abbreviation": "LPT", "description": "Large Payment" },
  { "abbreviation": "LGL7", "description": "Legal agreement due within 7 days" },
  { "abbreviation": "LGL90", "description": "Legal agreement was extended beyond 90 days" },
  { "abbreviation": "LNR30", "description": "Loaners – 30 days on rent" },
  { "abbreviation": "LNR", "description": "Loaners with no item in service" },
  { "abbreviation": "MIA", "description": "Missing Item On Inventory Audit" },
  { "abbreviation": "NER", "description": "Negative Receipt" },
  { "abbreviation": "NMRB", "description": "No manual receipt books are assigned to store" },
  { "abbreviation": "NSF", "description": "NSF Check Activity" },
  { "abbreviation": "AEET", "description": "Number of Audit Exceptions Exceeds Threshold" },
  { "abbreviation": "OSR", "description": "Over Short Drop Reconciliation" },
  { "abbreviation": "PDO", "description": "Paidout" },
  { "abbreviation": "PFEEW", "description": "Processing Fee Waived" },
  { "abbreviation": "PROMODIS", "description": "Promo Discount Used" },
  { "abbreviation": "RAO", "description": "Rate Of Agreement Override" },
  { "abbreviation": "RFN", "description": "Refund" },
  { "abbreviation": "RFO", "description": "Reinstatement Fee Override" },
  { "abbreviation": "REX", "description": "Rental Extension/Free Time" },
  { "abbreviation": "RAR", "description": "Returned and Redelivered" },
  { "abbreviation": "RVPO", "description": "Reverse PO Receipt" },
  { "abbreviation": "SAME4", "description": "Same person doing inventory in store 4 times in a row" },
  { "abbreviation": "SCC", "description": "Schedule Change" },
  { "abbreviation": "SCR", "description": "Scrap Item" },
  { "abbreviation": "SDO", "description": "Security Deposit Override" },
  { "abbreviation": "ANC", "description": "Store did not complete the required inventory audit for today" },
  { "abbreviation": "ANS", "description": "Store did not started the required inventory audit for yesterday" },
  { "abbreviation": "AUD", "description": "Store Managers must do an audit once a week" },
  { "abbreviation": "SWO", "description": "Switchout" },
  { "abbreviation": "TMM", "description": "Three Or More Messages" },
  { "abbreviation": "TRRAC", "description": "Transfer Reinstatement Rights Agreement Created" },
  { "abbreviation": "UIR", "description": "Unreserved Inventory Reason" },
  { "abbreviation": "UIISN", "description": "Update Inventory Item Serial Number" },
  { "abbreviation": "WLF", "description": "Waived Late Fee" },
  { "abbreviation": "WFO", "description": "Waiver Fee Override" }
]
