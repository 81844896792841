/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-duplicated-branches */
/* eslint-disable prettier/prettier */
/* eslint-disable no-debugger */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-redundant-boolean */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useContext } from "react";

import {
    Grid,
    Typography,
    Card,
    CardContent,
    RACSelect,
    RACTextbox,
    RACButton,
    RACModalCard,
    CircularProgress
} from "@rentacenter/racstrap";
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';

import { customerStyles } from "./PaidInStyle"
import { ReactComponent as GlobalSearch } from "../SearchButton/GlobalSearch.svg";
import PaidOutSummary from "./PaidOutSummary"
import { GetIntitailDataForManageTransactionPaidOut, getmenuandstore } from "../../api/user";
import SecondFactor from "../SecondAuthen/secondAuthen";
import { CustomPropInterface } from '../../index';
import { ContainerContext } from "../../app/App";
import clsx from "clsx";

export function PaidOut(props: any) {
    const classes = customerStyles();
    interface paidOutDetail {
        paidType?: string;
        amount?: string;
        receiptSubmittedBy?: string;
        inventoryId?: string;
        inventoryNumber?: string;
        paidDesc?: string;
        paidTypeRefCode?: string;
        tenderType?: string;
        writtenBy?: string;
        checknumber?: string;
        payer?: string;
        coworkerName?: string
    }
    const [paidOutDetails, setpaidOutDetails] = useState<paidOutDetail>({ paidType: '', amount: '0.00', receiptSubmittedBy: '', inventoryId: '', paidDesc: '', writtenBy: '', checknumber: '', payer: '', tenderType: 'CASH', coworkerName: '', inventoryNumber: '' });
    const [paidOutDetailsArray, setPaidOutDetailsArray] = useState<paidOutDetail[]>();
    const [hideItem, sethideItem] = useState(true);
    const [editIndex, setEditIndex] = useState<number>();
    const [Click, setClick] = useState(true)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [ValidateIsOpen, setValidateIsOpen] = useState(false);
    const [showPaidOut, setshowPaidOut] = useState(true);
    const [ValidateisopenZero, setValidateisopenZero] = useState(false);
    const [loader, setloader] = useState(true);
    const [transactionTypeDD, settransactionTypeDD] = useState([]);
    const [coworkerDD, setcoworkerDD] = useState([]);
    const [configValues, setConfigValues] = useState<any>();
    const [secondLevelAuthenOn, setSecondLevelAuthenOn] = useState<any>(null);
    const containerData = useContext(ContainerContext) as CustomPropInterface;
    const [coWorkerRole, setCoWorkerRole] = useState<string>('');
    const [apiResponse, setApiResponse] = useState<any>('')
    const paidOutInventoryReleatedItems = ['Parts Appliance', 'Parts Misc', 'Parts Furniture', 'Parts Electronic', 'Parts Computer']
    const history = useHistory()
    useEffect(() => {
        console.log('props', props)
        const pageLoad = async () => {
            try {
                console.log('log for redirection state paidout', history, props)
                const historyData: any = history.location?.state
                console.log('historyData', historyData)
                if (historyData && historyData.apiResponse) {
                    bindDDHandler(historyData.apiResponse)
                    bindConfigValues(historyData.apiResponse.configValues)
                    setpaidOutDetails({ ...historyData.paidOutDetails, inventoryId: historyData.inventoryId, inventoryNumber: historyData.inventoryNumber });
                    sethideItem(false);
                    setloader(false)
                    return
                }
                const storeNumber = sessionStorage.getItem('storeNumber')
                const payload = {
                    "storeNumbers": [
                        sessionStorage.getItem('storeNumber')
                    ],
                    "paramKeyNames": [
                        "MaxPaidOutAmount",
                        "DrawerDefaultAmount"
                    ]
                }
                setloader(true)
                await getWorkerRole();
                const response = await GetIntitailDataForManageTransactionPaidOut(storeNumber, payload)
                console.log('responsee', response)
                // setConfigValues(response)
                bindDDHandler(response)
                bindConfigValues(response.configValues)
                setloader(false)
            } catch (e: any) {
                setloader(false)
            }
        }
        // }
        pageLoad()
        setloader(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const bindDDHandler = (response: any) => {
        let transactionTypeId = ''
        for (let id = 0; id < response.receiptItemCategory.referenceDetails.length; id++) {
            if (response.receiptItemCategory.referenceDetails[id].referenceCode == 'PAIDOUT') {
                transactionTypeId = response.receiptItemCategory.referenceDetails[id].id
                break
            }
        }
        const buildtransactionDropDown: any = []
        const coworkerDropDown: any = [{ label: 'Select', value: '0' }]

        response.pettyCashRes.referenceDetails.forEach((value: any, index: any) => {
            if (index == 0) {
                buildtransactionDropDown.push({ label: 'Select', value: '0' });
            }
            if (value.receiptItemCategoryId == transactionTypeId) {
                buildtransactionDropDown.push({
                    label: value.descEn,
                    value: value.descEn,
                });
            }
        });
        response.coworkerValues.salespersonsData.forEach((value: any) => {
                coworkerDropDown.push({
                    label: String(value.appUserName),
                    value: String(value.appUserId),
                });
            
        });
        console.log(buildtransactionDropDown);
        settransactionTypeDD(buildtransactionDropDown)
        setcoworkerDD(coworkerDropDown)
        setApiResponse(response)
    }
    const redirectInventoryHandler = () => {
        history.push({
            pathname: '/inventory/inventorysearch',
            search: '?paidout=1',
            state: { paidOutDetails, paidOutDetailsArray, apiResponse }
        });
    }
    const bindConfigValues = (response: any) => {
        const configVal = {
            maxPaidOutAmount: '',
            drawerDefaultAmount: ''
        }
        console.log(configValues)
        response.storeProfileResponse.configDetails[0].configDetails.forEach((el: any) => {
            if (el.paramKeyName == 'MaxPaidOutAmount') {
                configVal.maxPaidOutAmount = el.paramValue
            }
            if (el.paramKeyName == 'DrawerDefaultAmount') {
                configVal.drawerDefaultAmount = el.paramValue
            }
        })
        setConfigValues(configVal)
    }
    const getWorkerRole = async () => {
        if (containerData !== undefined) {
            console.log('containerData', containerData);
            console.log('containerDataRole', containerData.GetRole());
            setCoWorkerRole(containerData.GetRole());
            return containerData.GetRole();
        } else {
            const coWorkerRoleObj: any = await getmenuandstore();
            if (
                coWorkerRoleObj?.coworkerProfile !== undefined &&
                coWorkerRoleObj?.coworkerProfile !== null &&
                coWorkerRoleObj?.coworkerProfile.role !== undefined &&
                coWorkerRoleObj?.coworkerProfile.role !== null
            ) {
                const roleObj = coWorkerRoleObj?.coworkerProfile.role;
                setCoWorkerRole(roleObj);
                return roleObj;
            }
        }
    };
    useEffect(() => {
        console.log('Paidout details log', paidOutDetails);
        enableContinueButton();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paidOutDetails])

    const enableContinueButton = () => {
        if (paidOutDetails && (paidOutDetails?.paidType?.trim() != "" && paidOutDetails?.amount?.trim() != "" && paidOutDetails?.paidDesc?.trim() != "" && paidOutDetails?.receiptSubmittedBy?.trim() != "")) {
            console.log('inside 1');
            if (hideItem == true || (hideItem == false && (paidOutDetails.inventoryId == undefined || paidOutDetails?.inventoryId != ""))) {
                console.log('inside 2');
                setClick(false)
            }
            else {
                console.log('inside 3');
                setClick(true)
            }

        }
        else {
            console.log('inside 4');
            setClick(true)
        }
        console.log()

    }
    const handleChange = (e: any, src?: string) => {
        if (src == 'DD') {
            //debugger;
            setpaidOutDetails({ ...paidOutDetails, paidType: e.target.value, paidTypeRefCode: apiResponse.pettyCashRes.referenceDetails.filter((el: any) => el.descEn == e.target.value)[0].refCode })
            //paidOutInventoryReleatedItems
            if (paidOutInventoryReleatedItems.includes(e.target.value)) {
                sethideItem(false)
            }
            else {
                sethideItem(true)
            }
        }
        else if (src == 'RS') {
            setpaidOutDetails({ ...paidOutDetails, receiptSubmittedBy: e.target.value, coworkerName: apiResponse.coworkerValues.salespersonsData.filter((el: any) => el.appUserId == e.target.value)[0].appUserName })
        }
        else if (e.target.id == 'amount') {
            if (Math.trunc(e.target.value.replaceAll(',', '')).toString().length <= 5)
                setpaidOutDetails({ ...paidOutDetails, [e.target.id]: e.target.value })
            console.log("paidOutDetails", paidOutDetails)
        }
        else {
            setpaidOutDetails({ ...paidOutDetails, [e.target.id]: e.target.value })
            console.log("paidOutDetails", paidOutDetails)
        }
    }
    const handleContinueBtn = () => {
        // 
        if (paidOutDetails && (Number(paidOutDetails.amount?.replaceAll(",", ''))) > Number(configValues.maxPaidOutAmount)) {
            setSecondLevelAuthenOn('RAC Coworker')
            // setValidateIsOpen(true);
            // setshowPaidOut(true);
        }
        else if (paidOutDetails && ((Number(paidOutDetails.amount)) == 0)) {
            setshowPaidOut(true);
            setValidateisopenZero(true);
        }
        else {
            setshowPaidOut(false);
            const currentPaidOutValues = paidOutDetailsArray !== undefined ? paidOutDetailsArray : [];
            if (editIndex !== undefined) {
                currentPaidOutValues.length > 0 && currentPaidOutValues.splice(editIndex, 1);
                currentPaidOutValues.push(paidOutDetails);
                console.log("PaidOut", currentPaidOutValues)
                setEditIndex(editIndex);

            } else {
                currentPaidOutValues.push(paidOutDetails);
                setPaidOutDetailsArray([...currentPaidOutValues]);
                console.log("module", currentPaidOutValues);
            }
        }

    }
    const errorMsgForPaidOutAmount = () => {
        setValidateIsOpen(true);
        setshowPaidOut(true);
    }
    const handleContinueButton = () => {
        setshowPaidOut(false);
        const currentPaidOutValues = paidOutDetailsArray !== undefined ? paidOutDetailsArray : [];
        if (editIndex !== undefined) {
            currentPaidOutValues.length > 0 && currentPaidOutValues.splice(editIndex, 1);
            currentPaidOutValues.push(paidOutDetails);
            console.log("PaidOut", currentPaidOutValues)
            setEditIndex(editIndex);
        } else {
            currentPaidOutValues.push(paidOutDetails);
            setPaidOutDetailsArray(currentPaidOutValues);
            console.log("module", currentPaidOutValues);
        }
    }
    const handleClickNo = () => {
        setValidateIsOpen(false)
    }
    const handleClickok = () => {
        setValidateisopenZero(false)
    }
    const handleClickYes = () => {
        setValidateIsOpen(false)
        handleContinueButton()
    }
    const addressDoctorPopup = () => {
        return (
            <>
                <Grid item md={12} >
                    <Grid item md={12} className={classes.textCenter}>
                        <Typography className={classes.RACPOPMsg}>
                            The entered amount exceeds the policy amount of $200.00.<br></br>The transaction will generate an exception.
                        </Typography>

                    </Grid>

                    <Grid
                        container
                        className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
                    >
                        <RACButton
                            variant="outlined"
                            color="primary"
                            className={classes.me2}
                            onClick={() => { handleClickNo() }}
                        >
                            No
                        </RACButton>
                        <RACButton
                            variant="contained"
                            color="primary"
                            className={`${classes.me2} ${classes.foc}`}
                            onClick={() => { handleClickYes() }}

                        >
                            Yes
                        </RACButton>
                    </Grid>
                </Grid>
            </>
        );
    };

    const addressDoctorPopupZero = () => {
        return (
            <>
                <Grid item md={12} >
                    <Grid item md={12} className={`${classes.textCenter} ${classes.paddingmargin}`} >
                        <Typography className={`${classes.RACPOPMsg} ${classes.paddingmargin}`}>
                            Amount must be greater than 0
                        </Typography>

                    </Grid>

                    <Grid

                    >

                        <Grid className={classes.textCenter} >

                            <RACButton variant="contained" color="primary" onClick={() => { handleClickok() }} >
                                OK
                            </RACButton>
                        </Grid>

                    </Grid>
                </Grid>
            </>
        );
    };

    const bindData = (value: any, id: any) => {
        setshowPaidOut(true);
        setEditIndex(id)
        setpaidOutDetails(value)
        // console.log("clearfunction",clearfunction)

    }
    const addNewPaidout = () => {
        setSecondLevelAuthenOn(null)
        setshowPaidOut(true)
        // setPaidOutDetailsArray([]);
        setpaidOutDetails({ paidType: '', amount: '0.00', receiptSubmittedBy: '', inventoryId: '', paidDesc: '' });
        setClick(true)

    }

    const handleFooterCancelBtn = () => {
        history.push("/dashboard/homepage")
    }
    return (
        (showPaidOut == true) ?
            <>
                <Modal
                    isOpen={loader}
                    className={classes.loaderStyle}
                    id="openpopup"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    aria-hidden="true"
                >
                    <Grid>
                        <Grid>
                            <Grid className={classes.RACLoaderPage}>
                                <CircularProgress />
                            </Grid>
                        </Grid>
                    </Grid>
                </Modal>
                {/* Parent container */}
                <Grid className={classes.stylebottom}>
                    {/*Heading SubContainer*/}
                    <Grid className={classes.stylebottom}>
                        <Grid className={classes.colMd12mt3}>
                            <Grid className={`${classes.row} ${classes.stylemarginleft}`} >
                                {/* Drawer total starts here */}
                                <Typography variant="h4" className={classes.header} >
                                    Cash Paid Out
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* Sub Container */}
                    <Grid className={classes.stylebottom}>
                        <div className={classes.racFixedFooterMargin}>
                            <Card className={classes.card} >
                                <CardContent className={classes.cardBody}>
                                    {/* Paid out type dropdown */}
                                    <Grid className={classes.w100}>
                                        <Grid className={classes.PaidOut} >
                                            {/* style={{fontSize:"0.875 rem",fontWeight:"400",fontFamily:"openSans-semibold"}} */}
                                            <Typography variant="h6" className={classes.rowsleft} >
                                                Paid Out Type <span style={{ color: "red" }}>*</span>
                                            </Typography>
                                            <Grid
                                                className={`${classes.heightDropdown} ${classes.paidinwidthstyle}`}>
                                                <RACSelect
                                                    options={transactionTypeDD}
                                                    name='paidOut'
                                                    defaultValue={
                                                        // state!==''&& state.length> 0 ? state[0].value:'0'
                                                        paidOutDetails?.paidType !== undefined &&
                                                            paidOutDetails?.paidType !== null &&
                                                            paidOutDetails?.paidType !== ''
                                                            ? paidOutDetails?.paidType : '0'
                                                    }
                                                    onChange={(e: any) => {
                                                        handleChange(e, 'DD')
                                                    }}
                                                    loading={transactionTypeDD.length == 0 ? true : false}
                                                    required={false}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {/* TextBox category Description */}
                                    <Grid 
                                        className={`${classes.mt3} ${classes.paidOutDes}`}
                                        item
                                    >
                                        <Typography variant="h6" className={classes.stylebottomrow}  >
                                            Paid Out Category Description
                                        </Typography>
                                        <textarea
                                            rows={4}
                                            cols={74}
                                            disabled={true}
                                            className={`${classes.dropNotes} ${classes.rowsright}`}
                                            value={(paidOutDetails && paidOutDetails?.paidType) ? paidOutDetails.paidType : ''}
                                            onChange={(e: any) => { handleChange(e) }}
                                        ></textarea>
                                    </Grid>
                                    {/* Amount , item,Receipt Submitted by*/}
                                    <Grid className={classes.w100}>
                                        <Grid className={classes.row}>
                                            <Grid className={classes.widthforgrid}>
                                                <Typography variant="h6" className={classes.stylebottomrow}>
                                                    Amount <span style={{ color: "red" }}>*</span>
                                                </Typography>
                                                {/* <Grid style={{ width: "100%" }}> */}
                                                <RACTextbox
                                                    className={classes.amount}
                                                    type="number"
                                                    name="amount"
                                                    id="amount"
                                                    value={(paidOutDetails && paidOutDetails.amount) ? paidOutDetails.amount : ''}
                                                    maxlength={8}
                                                    required={false}
                                                    OnChange={(e) => { handleChange(e) }}
                                                    Onblur={() => { setpaidOutDetails({ ...paidOutDetails, amount: Number(String(paidOutDetails.amount).replace(',', '')).toFixed(2) }) }}
                                                    isCurrency={true}
                                                />
                                                {/* </Grid> */}
                                            </Grid>
                                            <Grid className={classes.widthhidden} hidden={hideItem} >
                                                <Grid className={classes.paidstyletype}>
                                                    <Typography variant="h6" className={classes.paidoutstyle} >
                                                        Item <span style={{ color: "red" }}>*</span>
                                                    </Typography>
                                                    <RACTextbox
                                                        className={classes.item}
                                                        type="number"
                                                        name="isInvField"
                                                        disabled={true}
                                                        id="isInvField"
                                                        value={(paidOutDetails && paidOutDetails.inventoryNumber) ? paidOutDetails.inventoryNumber : ''}
                                                        maxlength={13}
                                                        required={false}
                                                        OnChange={(e) => { handleChange(e) }}

                                                        isCurrency={false}
                                                    />

                                                </Grid>
                                                <Grid className={classes.styelrowbottom}
                                                    id="inventoryId" onClick={redirectInventoryHandler}>

                                                    <GlobalSearch />
                                                </Grid>
                                            </Grid>
                                            <Grid className={classes.receiptSubmitted}>
                                                <Grid className={classes.receiptSubmittedWid} >
                                                    <Typography variant="h6" className={classes.stylebottomrow}>
                                                        Receipt Submitted By <span style={{ color: "red" }}>*</span>
                                                    </Typography>
                                                    <RACSelect
                                                        classes={{ 'paper': `${classes.heightDropdown}` }}
                                                        // className={classes.PaidOutCash}
                                                        // inputLabel='Receipt Submitted By'
                                                        options={coworkerDD}
                                                        name='receiptSubmittedBy'
                                                        defaultValue={
                                                            paidOutDetails?.receiptSubmittedBy !== undefined &&
                                                                paidOutDetails?.receiptSubmittedBy !== null &&
                                                                paidOutDetails?.receiptSubmittedBy !== ''
                                                                ? paidOutDetails?.receiptSubmittedBy : '0'
                                                        }
                                                        onChange={(e: any) => {
                                                            handleChange(e, 'RS')
                                                        }}
                                                        loading={coworkerDD.length == 0 ? true : false}
                                                        required={false}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {/* Description */}
                                    <Grid
                                        className={`${classes.mt3} ${classes.paidOutDes}`}
                                        item
                                    >
                                        <Typography variant="h6" style={{ marginBottom: "15px" }}  >
                                            Description
                                            <span style={{ color: "red" }}> *</span>
                                        </Typography>
                                        <textarea
                                           
                                            rows={4}
                                            cols={74}
                                            className={`${classes.dropNotes} ${classes.descriptiontextArea}`}
                                            id="paidDesc"
                                            name="paidDesc"
                                            value={(paidOutDetails && paidOutDetails.paidDesc) ? paidOutDetails.paidDesc : ''}
                                            onChange={(e: any) => { handleChange(e) }}
                                        ></textarea>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </div>
                        <Grid container classes={{
                            root: clsx(classes.customerToolbar, classes.fixedBottom),
                        }}
                            style={{ zIndex: 0 }}>
                            <Grid className={classes.footerFixed}>
                                <Grid className={classes.floatLeft}>
                                    <RACButton variant="outlined" color="secondary" onClick={() => { handleFooterCancelBtn() }} >
                                        Cancel
                                    </RACButton>
                                </Grid>

                                <Grid className={classes.floatRight}>
                                    <RACButton variant="contained"
                                        color="primary"
                                        disabled={Click}
                                        onClick={() => handleContinueBtn()}

                                    >
                                        Continue
                                    </RACButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* </Grid>   */}
                    </Grid>

                </Grid>


                {secondLevelAuthenOn !== null ? (
                    <SecondFactor
                        setTwoFactorCancelClick={() => setSecondLevelAuthenOn(null)}
                        setTwoFactorCompleted={() => errorMsgForPaidOutAmount()}
                        moduleName={'RAC Coworker'}
                        currentRole={coWorkerRole}
                    ></SecondFactor>
                ) : null}
                {/* Popups */}
                <Grid>
                    <RACModalCard
                        isOpen={ValidateIsOpen}
                        maxWidth="sm"
                        closeIcon={true}
                        borderRadius="20px"
                        onClose={() => setValidateIsOpen(false)}
                        // eslint-disable-next-line react/no-children-prop
                        children={addressDoctorPopup()}
                    />
                </Grid>

                <Grid>
                    <RACModalCard
                        isOpen={ValidateisopenZero}
                        maxWidth="xs"
                        closeIcon={true}
                        borderRadius="20px"
                        onClose={() => setValidateisopenZero(false)}
                        // eslint-disable-next-line react/no-children-prop
                        children={addressDoctorPopupZero()}
                    />
                </Grid>
            </>
            :
            <PaidOutSummary paidOutDetailsArray={paidOutDetailsArray} bindData={bindData} paidOutcallBackFunc={addNewPaidout} configVal={configValues} />

    )
}
